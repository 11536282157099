<!--
 * @Author: your name
 * @Date: 2022-04-20 10:03:19
 * @LastEditTime: 2022-04-27 17:02:31
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \aboom-vue\src\views\copyright\copyrightCenter\index.vue
-->
<template>
  <div class="wrapper">
    <!-- 侧边栏 -->
    <common-left1></common-left1>
    <!-- 内容 -->
    <div class="centerContent">
      <div class="title">版权中心</div>
      <div class="copyrightContent">
        <a class="jump first" @click="toBh">申请版权保护</a>
        <a class="jump" @click="toTg">申请版权托管</a>
        <a class="jump last" @click="goCopyrightPage">版权查询</a>
      </div>
    </div>
  </div>
</template>

<script>
import commonLeft1 from "../../../components/common_left1.vue";
export default {
  name: "copyrightCenter",
  components: { commonLeft1 },
  data() {
    return {
      shiming: ""
    }
  },
  created() {
    this.getIdentificationData()
  },
  methods: {
	  issure(){
		  if (!this.shiming.realname) {
		    this.$confirm('请先实名认证', '提示', {
		      confirmButtonText: '立即前往',
		      cancelButtonText: '取消',
		      type: 'warning'
		    }).then(() => {
		      this.$router.push({
		        path: "/realName",
		      });
		    }).catch(() => {
			  this.$router.push({
			    path: "/copyrightCenter",
			  });
		    });
		    return false
		  }
		  if (this.shiming.status==0) {
		    this.$confirm('实名认证未通过', '提示', {
		      confirmButtonText: '再次认证',
		      cancelButtonText: '取消',
		      type: 'warning'
		    }).then(() => {
		      this.$router.push({
		        path: "/realName",
		      });
		    }).catch(() => {
			  this.$router.push({
			    path: "/copyrightCenter",
			  });
		    });
		    return false
		  }
		  if (this.shiming.status==1) {
		    this.$message("实名认证审核中，请耐心等待")
		    return true
		  }
	  },
    toTg() {
	  if(this.issure()){
		  return false
	  }
      this.$router.push({
        path:"/manageExplain"
      })
    },
    toBh() {
	  if(this.issure()){
	  	return false
	  }
		  this.$router.push({
        path: "/protectionExplain"
      })
    },
    // 判断是否登录，登录后才能进入版权查询页面
    goCopyrightPage() {
      if (this.$isToken())
        this.$router.push({ name: "copyrightQuery" });
    },
    async getIdentificationData() {
      let token = localStorage.getItem("token")
      let res = await this.$netWork.getIdentificationData({
        url: "Identification/getIdentificationData",
        method: "post",
        data: {
          token: token
        }
      })
      this.shiming = res
      console.log(res);
    }
  },
};
</script>

<style scoped lang="less" >
* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@bg1Color: #35363f;

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;

  .centerContent {
    flex:1;
    margin-left: 20px;
    // height: 764px;
    height: 736px;
    border-radius: 7px;
    background: @bg1Color;

    .title {
      height: 38px;
      margin: 8px 8px 0px 8px;
      padding-left: 5px;
      line-height: 38px;
      color: @fsColor;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: @bgColor;
    }

    .copyrightContent {
      height: 764px - 38px - 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .jump {
        cursor: pointer;
        width: 250px;
        height: 50px;
        line-height: 54px;
        text-align: center;
        color: @btnfsColor;
        border-radius: 7px;
        background: @btnColor;
      }

      .first {
        margin-top: 150px;
      }

      .last {
        margin-bottom: 200px;
      }
    }
  }
}
</style>