<template>
  <div class="wrapper">
    <!-- 侧边栏 -->
    <common-left></common-left>
    <!-- 内容 -->
    <div class="centerContent">
      <div class="title">作品目录</div>
      <!-- nav -->
      <ul class="nav">
        <li class="active">
          <a href="">插画</a>
          <span class="triangle"></span>
        </li>
        <li>
          <a href="">漫画</a>
          <span class="triangle"></span>
        </li>
        <li>
          <a href="">动画</a>
          <span class="triangle"></span>
        </li>
      </ul>
      <!-- 作品 -->
      <div class="works">
        <!-- 分页 -->
        <ul class="pages">
          <li class="active">1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
          <li>6</li>
          <li class="next"></li>
        </ul>
        <!-- 作品展示 -->
        <ul class="showWork">
          <li class="item">
            <a href="#">
              <img src="../../../../assets/imgs/collect/tu1.png" alt="" />
              <span class="name">东方一</span>
            </a>
          </li>
          <li class="item">
            <a href="#">
              <img src="../../../../assets/imgs/collect/tu2.png" alt="" />
              <span class="name">东方二</span>
            </a>
          </li>
          <li class="item">
            <a href="#">
              <img src="../../../../assets/imgs/collect/tu3.png" alt="" />
              <span class="name">东方三</span>
            </a>
          </li>
          <li class="item">
            <a href="#">
              <img src="../../../../assets/imgs/collect/tu4.png" alt="" />
              <span class="name">东方一</span>
            </a>
          </li>
          <li class="item">
            <a href="#">
              <img src="../../../../assets/imgs/collect/tu5.png" alt="" />
              <span class="name">东方二</span>
            </a>
          </li>
          <li class="item">
            <a href="#">
              <img src="../../../../assets/imgs/collect/tu6.png" alt="" />
              <span class="name">东方三</span>
            </a>
          </li>
          <li class="item">
            <a href="#">
              <img src="../../../../assets/imgs/collect/tu7.png" alt="" />
              <span class="name">东方一</span>
            </a>
          </li>
          <li class="item">
            <a href="#">
              <img src="../../../../assets/imgs/collect/tu8.png" alt="" />
              <span class="name">东方二</span>
            </a>
          </li>
          <li class="item">
            <a href="#">
              <img src="../../../../assets/imgs/collect/tu9.png" alt="" />
              <span class="name">东方三</span>
            </a>
          </li>
        </ul>
        <!-- 分页 -->
        <ul class="pages bottomPages">
          <li class="active">1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
          <li>6</li>
          <li class="next"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import commonLeft from "../../../../components/common_left.vue";
export default {
  name: "otherUserWork",
  components: { commonLeft },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: @fsColor;
}
li {
  color: @fsColor;
  list-style: none;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;
.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
  width: 1200px;
  .centerContent {
    width: 996px;
    height: 1177px;
    border-radius: 7px;
    background: @bg1Color;
    .title {
      height: 38px;
      margin: 8px 8px 0px 8px;
      padding-left: 5px;
      line-height: 38px;
      color: @fsColor;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: @bgColor;
    }
    //nav
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46px;
      padding: 35px 310px 0;
      color: @fsColor;
      font-weight: lighter;
      border-bottom: 1px solid @lineColor;
      .active {
        position: relative;
        width: 96px;
        height: 38px;
        line-height: 36px;
        text-align: center;
        border-radius: 7px;
        background: @btnColor;
        a {
          color: @btnfsColor;
        }
        .triangle {
          position: absolute;
          left: 50%;
          bottom: -9px;
          transform: translateX(-50%);
          border: 5px solid transparent;
          border-top-color: @btnColor;
        }
      }
      li {
        position: relative;
        width: 96px;
        height: 38px;
        line-height: 36px;
        text-align: center;
        border-radius: 7px;
        &:hover {
          background: @btnColor;
          a {
            color: @btnfsColor;
          }
          .triangle {
            position: absolute;
            left: 50%;
            bottom: -9px;
            transform: translateX(-50%);
            border: 5px solid transparent;
            border-top-color: @btnColor;
          }
        }
      }
    }
    .works {
      // 分页
      .pages {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 42px 0;
        li {
          margin-right: 16px;
          cursor: pointer;
          &:hover {
            color: @iptfsColor;
          }
        }
        .active {
          color: @iptfsColor;
        }
        .next {
          width: 48px;
          height: 25px;
          background: @btnColor;
          background-image: url(../../../../assets/img/copyright/fanye3.png);
          background-repeat: no-repeat;
          background-size: 14px;
          background-position: center;
          border-radius: 3px;
        }
      }
      // 作品展示
      .showWork {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 38px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 280px;
          margin-bottom: 32px;
          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
            .name {
              padding: 10px 0;
            }
          }
        }
      }
      // 分页
      .bottomPages {
        margin-top: 25px;
      }
    }
  }
}
</style>