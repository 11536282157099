<template>
  <div class="content">
    <div class="content_middle">
      <common-left2></common-left2>
      <!-- 右边 -->
      <div class="content_middleR">
        <div class="frame">
          <div class="BiaoTi">私信</div>
          <!-- <div class="shoujian">收信箱</div> -->
          <div class="email_message">
            <div class="email_message_title">
              <img :src="userInfo.avatar" class="email_message_img" alt="" />
              <p class="email_message_name">{{ userInfo.nickname }}</p>
            </div>
            <!-- <div class="email_message_content">
              <p class="email_message_content1">大大是我，彦祖啊</p>
              <p class="email_message_content2">
                马卡·巴卡，乌西·迪西，汤姆·布利布，丁丁车，飞飞鱼，小点点们，阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴阿巴
              </p>
            </div> -->
            <div class="message scrollBar">
              <div
                class="item"
                v-for="(item, index) in messageDetailList"
                :key="index"
              >
                <div class="right">
                  <img
                    class="avatar"
                    :src="avatarMap[`${item.user_id}`].data.avatar"
                    alt=""
                  />
                  <div class="info">
                    <p class="name">
                      <!-- <span style="color: green">{{
                    item.user_id == userid ? "发给" : "收到"
                  }}</span> -->
                      {{ avatarMap[`${item.user_id}`].data.nickname }}
                      <span class="time">{{ item.send_time }}</span>
                    </p>
                    <p class="text">{{ item.message_content }}</p>
                  </div>
                </div>
                <!-- <div
                  class="reply"
                  @click="
                    toMyEmail2(item.talk_id, item.send_user_id, item.friend_id)
                  "
                >
                  回复
                </div> -->
              </div>
            </div>
          </div>
          <!-- 回复 -->
          <div class="reply">
            <p class="reply_p">回复</p>
            <textarea class="reply_txt" v-model="sandValue"></textarea>
            <!-- <textarea name="txt" clos=",50" rows="5" warp="virtual"></textarea> -->
          </div>
          <div class="reply">
            <div class="reply_button" @click="reply">发送</div>
            <div class="reply_button">返回</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonLeft2 from "../../components/components_left2.vue";
export default {
  name: "myEmail2.vue",
  components: { commonLeft2 },
  data() {
    return {
      talk_id: "",
      send_user_id: "",
      messageDetailList: [],
      userInfo: "",
      userInfoList: [],
      sandValue: "",
      avatarMap: {},
    };
  },
  computed: {},
  async created() {
    if (this.$route?.query?.uid) {
      console.log(1);
      this.talk_id = this.$route.query.talk_id;
      this.send_user_id = this.$route.query.uid;
      JSON.parse;
      this.messageDetail(
        this.$route.query.talk_id,
        this.$route.query.uid
      );
      this.getUserInfo(this.$route.query.uid);
      // this.avatarMap[`${this.$route.query.send_user_id}`] = GetUserInfo(
      //   this.$route.query.send_user_id
      // );
      // this.avatarMap[`${this.$route.query.friend_id}`] = GetUserInfo(
      //   this.$route.query.friend_id
      // );
    }
    if (this.$route?.query?.userid) {
      console.log(2);
      this.getUserInfo(this.$route?.query?.userid);
      this.Messagelist(this.$route?.query?.userid)
    }
  },
  methods: {
    async reply() {
      let token = localStorage.getItem("token");
      let id = JSON.parse(localStorage.getItem("userInfo")).id
      let friend_id
      // if(id==this.$route.query.send_user_id){
      //   friend_id = this.$route.query.friend_id
      // }
      // if(id==this.$route.query.friend_id){
      //   friend_id =this.$route.query.send_user_id
      // }
      let res = await this.$netWork.sendMessage({
        url: "/message/sendMessage",
        method: "post",
        data: {
          token: token,
          friend_id: friend_id||this.$route.query.uid||this.$route.query.userid,
          content: this.sandValue,
        },
      });
      console.log(res,"发送消息")
      if (res.code == 0) {
        this.$message.success("发送成功");
        setTimeout(() => {
          location.reload();
        }, 1000);
      }
    },
    //获取当前用户的聊天详情
    async talkList(){
      let token = localStorage.getItem("token");
      let res = await this.$netWork.getMessageDetail({
        url:"/message/getDetail",
        method:"post",
        data:{
          token:token,
          talk_id:this.talk_id,
          send_user_id:this.send_user_id
        }
      })
    },
    //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    async messageDetail(id1, id2) {
      let token = localStorage.getItem("token");
      let res = await this.$netWork.getMessageDetail({
        url: "/message/getDetail",
        method: "post",
        data: {
          token: token,
          talk_id: id1,
          send_user_id: id2,
        },
      });
      if (res.code == 0) {
        this.avatarMap[`${res.data[0].user_id}`] =
          await this.$netWork.getUserInfoById({
            url: "/user/getUserInfoById",
            method: "post",
            data: {
              uid: res.data[0].user_id,
            },
          });
        this.avatarMap[`${res.data[0].friend_id}`] =
          await this.$netWork.getUserInfoById({
            url: "/user/getUserInfoById",
            method: "post",
            data: {
              uid: res.data[0].friend_id,
            },
          });
        this.messageDetailList = res.data;
        // res.data.forEach(async (element) => {
        //   await setTimeout(async () => {
        //     let result = await this.$netWork.getUserInfoById({
        //       url: "/user/getUserInfoById",
        //       method: "post",
        //       data: {
        //         uid: element.user_id,
        //       },
        //     });
        //     this.userInfoList.push(result.data);
        //   }, 200);
        // });
      }
    },
    async getUserInfo(id) {
      let res = await this.$netWork.getUserInfoById({
        url: "/user/getUserInfoById",
        method: "post",
        data: {
          uid: id,
        },
      });
      console.log(3);
      this.userInfo = res.data;
    },
    // async GetUserInfo(id) {
    //   console.log("1232212312");
    //   let res = await this.$netWork.getUserInfoById({
    //     url: "/user/getUserInfoById",
    //     method: "post",
    //     data: {
    //       uid: id,
    //     },
    //   });
    //   console.log(res, "333333333333333");
    //   return res.data;
    // },
    async Messagelist(userid){
      let res = await this.$netWork.getMessageList({
        url: "/message/getList",
        method: "post",
        data: {
          token:localStorage.getItem("token"),
        },
      });
      let userInfo = eval("("+localStorage.getItem("userInfo")+")")
      let messageList_1 = []
      if(res.code==0){
        for (let index = 0; index < res.data.length; index++) {
          if (res.data[index].friend_id == this.$route.query.userid && res.data[index].user_id == userInfo.id) {
            messageList_1 = res.data[index]
          }
        }
      }
      this.messageDetail(messageList_1.talk_id, messageList_1.send_user_id)
    }
  },
};
</script>
<style lang="less" scoped>
.content_middle {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
}

/* 右边 */
.content_middleR {
  display: flex;
  flex-direction: column;
  width: 996px;
}
.frame {
  /* flex: 1; */
  background-color: #353641;
  border-radius: 0.4rem;
  padding: 8px 8px 2rem 8px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
  min-height: 736px;
}

.BiaoTi {
  color: #4db9c2;
  font-size: 14px;
  background-color: #26272e;
  border-radius: 0.2rem;
  padding: 0.4rem;
}

/* 收件箱 */
.shoujian {
  color: #4db9c2;
  font-size: 16px;
  margin-top: 60px;
  padding-left: 0.4em;
}
.shoujian_XX {
  min-height: 500px;
  background-color: rgb(38, 39, 46);
  padding: 0 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
  background-color: rgb(38, 39, 46);
}

th,
td {
  padding: 10px;
  text-align: center;
  border-bottom: 0.01rem solid rgb(47, 66, 68);
  color: #4db9c2;
  font-size: 14px;
}

.radio_my {
  color: #4db9c2;
}

/* 单选按钮 */
/* 先将按钮原来的样式隐藏 */
/* input[type=radio] {
    opacity: 0;
    display: inline-block;
    position: relative;
    left: 19px;
} */
/* 选择 */
.choice_div {
  display: flex;
}
.choice_p {
  color: #4db9c2;
  font-size: 14px;
  margin: 10px;
}
.choice_button {
  background-color: #4db9c2;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 0.3rem;
  outline: none;
  width: 80px;
  line-height: 25px;
  text-align: center;
}

/* 滚轴 */
.test {
  /* width: 50px; */
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  height: 500px;
  overflow: auto;
  border: none;
}

.scrollbar {
  /* width: 30px; */
  height: 1000px;
  margin: 0 auto;
}

.test-1::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 15px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  border-radius: 0.2rem;
}

.test-1::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  background: #4bbac0;
  border-radius: 0.2rem;
}

.test-1::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border: 1px solid #4bbac0;
  border-radius: 0.2rem;
}

/* 邮箱页面二级 */
.email_message {
  min-height: 300px;
  background-color: rgb(38, 39, 46);
  margin-bottom: 30px;
}
.email_message_title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(51, 62, 66);
}
.email_message_img {
  width: 50px;
  margin: 20px;
}
.email_message_name {
  color: #4db9c2;
  font-size: 14px;
}
/* 邮件内容 */
.email_message_content {
  padding: 20px;
}
.email_message_content1 {
  color: #bbbdbe;
  font-size: 14px;
  margin-bottom: 20px;
}
.email_message_content2 {
  color: #4bbac0;
  font-size: 14px;
}
/* 回复 */
.reply {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reply_p {
  color: #4bbac0;
  font-size: 14px;
  margin: 40px;
}
.reply_txt {
  width: 700px;
  height: 120px;
  border: none;
  resize: none; /* 去掉右下角图标 */
  border-radius: 0.5rem;
  background-color: #28262e;
  color: #4bbac0;
  font-size: 14px;
  padding: 10px;
}
.reply_txt:hover,
.reply_txt:visited {
  border: 1px solid #4bbac0;
}
.reply_button {
  cursor: pointer;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #4bbac0;
  border-radius: 0.3rem;
  margin: 35px;
}
.message {
  position: relative;
  overflow-y: auto;
  height: 312px;
  margin: 10px 10px 0 0;
  padding: 10px 50px 20px 20px;
  color: #5dbbbf;
}

.message .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid #46535a;
}

.message .item .right {
  display: flex;
}

.message .item .right .avatar {
  width: 40px;
  height: 40px;
  border-radius: 7px;
}

.message .item .right .info {
  margin-left: 20px;
}

.message .item .right .info .name {
  font-size: 13px;
  font-weight: lighter;
}

.message .item .right .info .name .time {
  font-size: 12px;
  padding-left: 20px;
}

.message .item .right .info .text {
  font-size: 13px;
  font-weight: lighter;
  color: #ffffff;
}

.message .item .right .info .text .replyName {
  color: #5dbbbf;
}

.message .item .reply {
  font-size: 14px;
  font-weight: lighter;
  cursor: pointer;
}
.scrollBar {
  overflow-y: auto;
}
/*滚动条整体样式*/
.scrollBar::-webkit-scrollbar {
  width: 18px;
  height: 10px;
  background: #35363f;
}

/*滚动条滑块*/
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #5accd8;
}

/*滚动条轨道*/
.scrollBar::-webkit-scrollbar-track {
  border: 1px solid #5accd8;
  border-radius: 4px;
}
</style>