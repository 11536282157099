<template>
	<div class="content">
		<div class="content_middle">
			<common-left1 ref="hello"></common-left1>
			<!-- 右边 -->
			<div class="content_middleR">
				<div class="frame">
					<!-- 个人信息 -->
					<div class="BiaoTi">个人信息</div>
					<div class="personage-XX">
						<div class="personage-XX-layout1">
							<button class="personage-XX-button max-width-label"
								style="left: 0;font-size: 0.85rem;padding: 0.25rem 0.5rem;" @click="changeAvatar">
								修改头像
							</button>
						</div>
					</div>
					<div class="personage-XX">
						<div class="personage-XX-layout1">
							<span class="personage-XX-label">昵称</span>
							<span v-if="isalter" class="personage-XX-matter">{{
									infoMessage.nickname
								}}
							</span>
							<input v-else class="personage-XX-input input_one" v-model="username" ref="input_name" />
							<!-- @focus="focus($event)" 自动选中内容 -->
							<button v-if="isalter" class="personage-XX-button min-wdith-button" @click="alter">
								修改
							</button>
							<button v-else class="personage-XX-button min-wdith-button" @click="save">
								保存
							</button>
						</div>

						<!-- <div class="personage-XX-layout">
							<span class="personage-XX-label">主页</span>
							<span class="personage-XX-matter">{{ infoMessage.home }}</span>
						</div> -->
					</div>
					<div class="personage-XX">
						<div class="personage-XX-layout">
							<span class="personage-XX-label">等级</span>
							<span class="personage-XX-matter">{{ infoMessage.grade }}</span>
						</div>
						<div class="personage-XX-layout">
							<span class="personage-XX-label">投稿数</span>
							<span class="personage-XX-matter">{{
									infoMessage.contributeNumber
								}}</span>
						</div>
					</div>
					<div class="personage-XX">
						<div class="personage-XX-layout">
							<span class="personage-XX-label">粉丝</span>
							<span class="personage-XX-matter">{{
										infoMessage.fansNumber
									}}</span>
						</div>
						<div class="personage-XX-layout">
							<span class="personage-XX-label">关注</span>
							<span class="personage-XX-matter">{{
										length
									}}</span>
						</div>
					</div>
					<!-- <div class="personage-XX">
						<div class="personage-XX-layout">
							<span class="personage-XX-label">经验值</span>
							<span class="personage-XX-matter">{{
                infoMessage.empiricalValue
              }}</span>
						</div>
						<div class="personage-XX-layout">
							<span class="personage-XX-label">击杀</span>
							<span class="personage-XX-matter">{{
                infoMessage.totalKillCount
              }}</span>
						</div>
					</div> -->
					<!-- <div class="personage-XX">
						<div class="personage-XX-layout">
							<span class="personage-XX-label">积分</span>
							<span class="personage-XX-matter">{{ infoMessage.credits }}</span>
						</div>
						<div class="personage-XX-layout">
							<span class="personage-XX-label">人气</span>
							<span class="personage-XX-matter">{{ infoMessage.popular }}</span>
						</div>
					</div> -->
				</div>

				<!-- 账号信息 -->
				<div class="frame">
					<!-- 账号信息 -->
					<div class="BiaoTi">账号信息</div>
					<div class="personage-XX">
						<div class="personage-XX-layout max-width-layout">
							<span class="personage-XX-label max-width-label">绑定邮箱</span>
							<span class="personage-XX-matter">{{ bindingMessage.mail }}</span>
							<button class="personage-XX-button" v-if="bindingMessage.mail == '未绑定邮箱'"
								@click="gobind(1)">
								绑定邮箱
							</button>
						</div>
					</div>
					<div class="personage-XX">
						<div class="personage-XX-layout max-width-layout">
							<span class="personage-XX-label max-width-label">绑定手机号</span>
							<span class="personage-XX-matter">{{
									bindingMessage.phone
								}}</span>
							<button class="personage-XX-button" v-if="bindingMessage.phone == '未绑定手机号'"
								@click="gobind(2)">
								绑定手机号
							</button>
						</div>
					</div>
					<div class="personage-XX">
						<div class="personage-XX-layout max-width-layout">
							<span class="personage-XX-label max-width-label">实名认证</span>
							<span class="personage-XX-matter">{{
									bindingMessage.IDcard
								}}</span>
							<button class="personage-XX-button" v-if="bindingMessage.IDcard == '未完成实名认证' ||
									bindingMessage.IDcard == '实名认证不通过'
									" @click="gobind(3)">
								实名认证
							</button>
						</div>
					</div>
				</div>
				<!-- 成就 -->
				<div class="frame1">
					<div class="BiaoTi">
						成就
						<div class="national-more" @click="more">更多</div>
					</div>
					<div class="national">
						<!-- 称号 -->
						<div class="national-BiaoTi">已获得成就</div>
						<div class="national-heng">
							<div class="national-layout">
								<!-- <div v-for="item in achievementList">
				  	<img :src="getUrl(item.file_path)" class="list-img" alt="" />
				  </div> -->
								<img v-if="achievementList" v-for="item in achievementList" :src="item.file_hd"
									class="national-img" alt="" :title="item.acc_name" />
								<div v-else class="national-div2">暂无成就</div>
							</div>
						</div>
					</div>
					<!--勋章 -->
					<!--  <div class="national">
            <div class="national-BiaoTi">已获得勋章</div>
            <div class="medal">暂无</div>
          </div>
          <div class="result">累计得分87231，击败全站80%的画师</div> -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import commonLeft1 from "@/components/common_left1";
export default {
	components: {
		commonLeft1,
	},

	data() {
		return {
			Attention: null, // 关注列表
			length: '',
			userInfo: null, //用户信息
			infoMessage: {
				nickname: "",
				home: "www.ty.com",
				grade: "",
				contributeNumber: "",
				fansNumber: "",
				attentionNumber: "",
				empiricalValue: "9999pt",
				totalKillCount: "999pt",
				credits: 9999999,
				popular: 99999,
			},
			bindingMessage: {
				phone: "未绑定手机号",
				mail: "未绑定邮箱",
				IDcard: "未完成实名认证",
			},
			isalter: true, //是否显示名字输入框
			username: "", //用户名称
			userlist: {}, //用户信息
			achievementList: {}, //成就信息
		};
	},
	created() {
		this.isIdcard();
		this.getUserInfo();
		this.getMyAttention();
		setTimeout(() => {
			this.getAchievementList();
		}, 500)
	},
	mounted() { },
	methods: {
		//处理图片url
		getUrl(url) {
			return "http://api.aboom.net" + url;
		},
		//跳转上传头像
		changeAvatar() {
			// console.log(11111)
			this.$router.push({
				path: "/avatar"
			});
		},
		async getAchievementList() { //成就
			console.log("个人中心");
			console.log(this.userInfo);
			let res = await this.$netWork.getUserAchievementListByID({
				url: "/user/getAchievementListByID",
				method: "post",
				data: {
					uid: JSON.parse(localStorage.getItem("userInfo")).id
				}
			})
			if (res.code == 0) {
				this.achievementList = res.data
			}
		},
		async getMyAttention() {
			const res = await this.$netWork.getMyAttention({
				method: "post",
				url: "/Attention/getMyAttention",
				data: {
					token: localStorage.getItem("token"),
				},
			});
			this.length = res.data.length
			console.log("关注用户attention", this.length);
		},
		// focus(event) {  //自动选中input内容 .TY
		// 	event.currentTarget.select();
		// },
		//获取账号信息
		async getUserInfo() {
			let token = localStorage.getItem("token");
			let res = await this.$netWork.getUserInfo({
				url: "/user/getUserInfo",
				method: "post",
				data: {
					token: token,
				},
			});
			console.log(res, "个人信息");
			this.infoMessage.grade = res.data.level;
			this.infoMessage.contributeNumber = res.data.works;
			this.infoMessage.fansNumber = res.data.fans;
			this.$refs.hello.userInfo.level = res.data.level;

			if (res.code == 0) {
				this.userlist = res.data;
				this.infoMessage.nickname = res.data.nickname;
				if (res.data.mobile) {
					console.log(res.data.mobile, "手机号");
					this.bindingMessage.phone = "已绑定手机号";
				}
				if (res.data.email) {
					this.bindingMessage.mail = "已绑定邮箱";
				}

				if (res.data == localStorage.getItem("userInfo")) { } else {
					localStorage.setItem("userInfo", JSON.stringify(res?.data));
				}
				// console.log(res.data);
				// console.log(this.userlist);
			}
		},
		//查询是否已实名认证
		async isIdcard() {
			let token = localStorage.getItem("token");
			let res = await this.$netWork.getIdentificationData({
				url: "/Identification/getIdentificationData",
				method: "post",
				data: {
					token: token,
				},
			});
			// console.log(res, "实名信息");
			if (Object.keys(res).length > 2) {
				if (res.status == 0) {
					this.bindingMessage.IDcard = "实名认证不通过";
				}
				if (res.status == 1) {
					this.bindingMessage.IDcard = "实名认证审核中";
				}
				if (res.status == 2) {
					this.bindingMessage.IDcard = "已通过实名认证";
				}
			}
		},
		more() {
			this.$router.push({
				path: "/designation",
			});
		},
		//等待页面开发 .TY
		gobind(id) {
			if (id === 1) {
				this.$router.push({
					path: "/bindEmail",
				});
			} else if (id === 2) {
				this.$router.push({
					path: "/bindTel",
				});
			} else {
				this.$router.push({
					path: "/realName",
				});
			}
		},
		//修改名字 .TY
		alter() {
			this.username = this.infoMessage.nickname;
			this.isalter = !this.isalter;
			this.$nextTick(() => {
				this.$refs.input_name.focus();
			});
		},
		//保持修改名字 .TY
		async save() {
			//已绑定接口
			if (this.infoMessage.nickname == this.username) {
				this.isalter = !this.isalter;
				this.$message("修改成功!");
			} else {
				let token = localStorage.getItem("token");
				let res = await this.$netWork.setUserName({
					url: "/user/editUserInfo",
					method: "post",
					data: {
						token: token,
						nickname: this.username,
						gender: this.userlist.gender,
						birthday: this.userlist.birthday,
					},
				});
				console.log(this.$refs.hello, "editeditedit");
				if (res.code == 0) {
					this.$message.success(res.msg);
					this.infoMessage.nickname = this.username;
					this.isalter = !this.isalter;
					this.getUserInfo();
					this.$refs.hello.userInfo.nickname = this.username;
				} else if (res.code == 1) {
					this.$message(res.msg);
				}
				console.log(res);
			}
		},
	},
};
</script>
<style scoped lang="less">
.content {
	width: 100%;
}

.content_middle {
	display: flex;
	justify-content: space-between;
	margin: 14.77px auto;
}

/* 右边 */
.content_middleR {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: 20px;
}

/* 标题 */
.frame {
	/* flex: 1; */
	background-color: #353641;
	border-radius: 0.4rem;
	padding: 8px 8px 2rem 8px;
	box-sizing: border-box;
	box-shadow: 0 0 2px #4b4b56;
	margin-bottom: 14.77px;
}

.frame1 {
	/* flex: 1; */
	background-color: #353641;
	border-radius: 0.4rem;
	padding: 8px 8px 1rem 8px;
	box-sizing: border-box;
	box-shadow: 0 0 2px #4b4b56;
}

.BiaoTi {
	display: flex;
	color: #4db9c2;
	background-color: #26272e;
	border-radius: 0.2rem;
	font-size: 14px;
	padding: 0.4rem;
}

/* 个人信息 */
.personage-XX {
	display: flex;
	margin: 1.7rem 1.2rem;
	cursor: pointer;
}

.personage-XX-layout {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #26272e;
	width: 100%;
	cursor: pointer;
	position: relative;
}

.personage-XX-layout1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #26272e;
	width: 470px;
	cursor: pointer;
	position: relative;
}

.max-width-layout {
	width: 58% !important;
}

.max-width-label {
	width: 5.5rem !important;
}

.personage-XX-label {
	background-color: #4db9c2;
	min-width: 3rem;
	max-width: 5rem;
	text-align: center;
	font-size: 14px;
	border-radius: 0.2rem;
	padding: 0.2rem 0.8rem;
	cursor: pointer;
}

.personage-XX-matter {
	color: #4db9c2;
	font-size: 14px;
	margin-left: 1.5rem;
	cursor: pointer;
}

.personage-XX-input {
	color: #4db9c2;
	background-color: #353641;
	width: 50%;
	font-size: 14px;
	margin-left: 1.5rem;
	border: none;
	appearance: none; //去掉默认样式
	outline: 0; //轮廓去掉
}

// 个人信息按钮 .TY
.personage-XX-button {
	position: absolute;
	right: 0;
	background-color: #e14b00;
	color: #fefefe;
	min-width: 6rem;
	text-align: center;
	// font-size: 0.9rem;
	font-size: 14px;
	border: none;
	border-radius: 0.2rem;
	padding: 0.2rem 0.5rem;
	cursor: pointer;
}

.min-wdith-button {
	min-width: 3rem !important;
	// right: 15px;
}

.input_one {
	height: 21px;
	width: 100%;
}

/* 成就 */
/* 称号 */
.national {
	display: flex;
	flex-direction: column;
	padding: 0.5rem 1rem 0 1rem;
}

.national-BiaoTi {
	color: #4db9c2;
	font-size: 14px;
	cursor: pointer;
}

.national-heng {
	display: flex;
	border-bottom: 1px solid #37464d;
	cursor: pointer;
	/* padding: 0.5rem 0; */
	min-height: 62px;
}

.national-layout {
	margin-right: 0.8rem;
	cursor: pointer;
}

.national-div {
	flex: 1;
	text-align: center;
	color: #4db9c2;
	font-size: 14px;
}

.national-div2 {
	flex: 1;
	text-align: center;
	color: #8d8d8d;
	font-size: 14px;
	padding-top: 1rem;
}

.national-img {
	width: 4rem;
	padding: 0.5rem;
	cursor: pointer;
}

.national-more {
	width: 97%;
	display: inline-block;
	color: #4db9c2;
	font-size: 14px;
	text-align: right;
	cursor: pointer;
	// margin-top: 1.5rem;
	// margin-left: 680px;
}

/* 勋章 */
.medal {
	min-height: 4.5rem;
	height: 4rem;
	font-size: 14px;
	color: #4db9c2;
	border-bottom: 1px solid #37464d;
	padding: 0.5rem 0;
}

.medal::before {
	display: inline-block;
	content: "";
	height: 100%;
	vertical-align: middle;
}

/* 结果 */
.result {
	color: #4db9c2;
	font-size: 14px;
	padding: 1rem 1rem 0 1rem;
}
</style>
