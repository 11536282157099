<template>
  <div class="wrap">
    <div class="title">联系我们</div>
    <div class="container" v-html="conList"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      conList: [],
      token: "",
    };
  },
  mounted() {
    this.getConcatUS();
  },
  created() {
    // this.sureLogin();
  },
  methods: {
    async getConcatUS() {
      // this.$router.push({ path: "/contact" });
      let res = await this.$netWork.getConcatUS({
        url: "/banner/lianxi",
        method: "post",
      });
      this.conList = res.data[0].content;
      console.log("联系", res);
    },

    // sureLogin() {
    //   let token = localStorage.getItem("token");
    //   this.token = token;
    //   console.log(token);
    //   if (!token) {
    //     this.$message.warning("未登录哦~");
    //     setTimeout(() => {
    //       this.$router.push({ path: "/login" });
    //     }, 1000);
    //   }
    // },
  },
};
</script>

<style scoped>
.main {
  display: flex;
  justify-content: space-between;
}

.main-left {
  display: flex;
  flex-direction: column;
}

.wrap {
  background: #353640;
  border-radius: 10px;
  padding: 14px 11px;
  margin: 30px 0;
}

.container {
  width: 450px;
  margin: 50px auto;
  padding: 33px 30px;
  background-color: #26272D;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  background: #26272D;
  border-radius: 10px;
  color: #5CE2ED;
  padding: 5px 12px;
  margin-bottom: 16px;
}

.flex {
  display: flex;

}

.flex img {
  width: 15px;
  height: 15px;
  margin-top: 11px;
}

.flex p {
  text-indent: 1rem;
  margin-top: 8px;
}

.contact {
  text-align: center;
  font-size: 20px;
  color: #4eb8c2;
  font-weight: 900;
  margin-bottom: 30px;
}

.footer {
  margin-bottom: 400px;
}

li {
  list-style: none;
  margin-top: 15px;
}
</style>