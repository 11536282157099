<template>
  <div class="content_middle">
    <div class="frame">
      <div class="BiaoTI">作品排行榜</div>
      <div class="page_navigation">
        <a href="#" class="page_navigation_a">
          <p>日排行</p>
        </a>
        <a href="#" class="page_navigation_a">
          <p>周排行</p>
        </a>
        <a href="#" class="page_navigation_a">
          <p>月排行</p>
        </a>
      </div>
      <div class="LorR">
        <img src="~@/assets/img/home/zuo.png" class="LorR_img" alt="" />
        <p class="LorR_zhong">2015年1月1日</p>
        <img src="~@/assets/img/home/you.png" class="LorR_img" alt="" />
      </div>
      <div class="rankingScope">
        <a href="#" class="rankingScope_a">
          <p>1-10</p>
        </a>
        <a href="#" class="rankingScope_a">
          <p>11-30</p>
        </a>
        <a href="#" class="rankingScope_a">
          <p>31-50</p>
        </a>
      </div>
      <!-- 排行信息 -->
      <div class="ranking">
        <div class="ranking_layout">
          <div class="ranking_detail">
            <p class="ranking_MingCi1">11名</p>
            <p class="ranking_MingCi2">初登场</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/di11.png" class="ranking_Img1" alt="" />
            <p class="ranking_p">地球仪</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/yonghu2.png" class="ranking_Img2" alt="" />
            <p class="ranking_p">madoka</p>
          </div>
        </div>
        <div class="ranking_layout">
          <div class="ranking_detail">
            <p class="ranking_MingCi1">12名</p>
            <p class="ranking_MingCi2">初登场</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/di12.png" class="ranking_Img1" alt="" />
            <p class="ranking_p">地球仪</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/yonghu2.png" class="ranking_Img2" alt="" />
            <p class="ranking_p">madoka</p>
          </div>
        </div>
        <div class="ranking_layout">
          <div class="ranking_detail">
            <p class="ranking_MingCi1">13名</p>
            <p class="ranking_MingCi2">初登场</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/di13.png" class="ranking_Img1" alt="" />
            <p class="ranking_p">地球仪</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/yonghu2.png" class="ranking_Img2" alt="" />
            <p class="ranking_p">madoka</p>
          </div>
        </div>
        <div class="ranking_layout">
          <div class="ranking_detail">
            <p class="ranking_MingCi1">14名</p>
            <p class="ranking_MingCi2">
              <img src="~@/assets/img/home/xia.png" class="ranking_Img3" alt="" />
              昨日第十位
            </p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/di14.png" class="ranking_Img1" alt="" />
            <p class="ranking_p">地球仪</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/yonghu2.png" class="ranking_Img2" alt="" />
            <p class="ranking_p">madoka</p>
          </div>
        </div>
        <div class="ranking_layout">
          <div class="ranking_detail">
            <p class="ranking_MingCi1">1名</p>
            <p class="ranking_MingCi2">初登场</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/di15.png" class="ranking_Img1" alt="" />
            <p class="ranking_p">地球仪</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/yonghu2.png" class="ranking_Img2" alt="" />
            <p class="ranking_p">madoka</p>
          </div>
        </div>
        <div class="ranking_layout">
          <div class="ranking_detail">
            <p class="ranking_MingCi1">1名</p>
            <p class="ranking_MingCi2">初登场</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/di16.png" class="ranking_Img1" alt="" />
            <p class="ranking_p">地球仪</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/yonghu2.png" class="ranking_Img2" alt="" />
            <p class="ranking_p">madoka</p>
          </div>
        </div>
        <div class="ranking_layout">
          <div class="ranking_detail">
            <p class="ranking_MingCi1">1名</p>
            <p class="ranking_MingCi2">初登场</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/di17.png" class="ranking_Img1" alt="" />
            <p class="ranking_p">地球仪</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/yonghu2.png" class="ranking_Img2" alt="" />
            <p class="ranking_p">madoka</p>
          </div>
        </div>
        <div class="ranking_layout">
          <div class="ranking_detail">
            <p class="ranking_MingCi1">1名</p>
            <p class="ranking_MingCi2">初登场</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/di18.png" class="ranking_Img1" alt="" />
            <p class="ranking_p">地球仪</p>
          </div>
          <div class="ranking_detail">
            <img src="~@/assets/img/home/yonghu2.png" class="ranking_Img2" alt="" />
            <p class="ranking_p">madoka</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.content_middle {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.frame {
  width: 1200px;
  background-color: #353641;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
  border-radius: 0.4rem;
}
.BiaoTI {
  line-height: 7rem;
  text-align: center;
  font-size: 20px;
  color: #58b5c0;
}
/* 周日月排行 */
.page_navigation {
  width: 1200px;
  height: 45px;
  background-color: #26282c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.page_navigation_a {
  margin: 0 8rem;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.page_navigation_a:hover {
  color: #233e45;
  background-color: #58b5c0;
  /* padding:0 1.5rem; */
  width: 100px;
  height: 40px;
  line-height: 40px;
  border-radius: 0.2rem;
  text-align: center;
}
/* 去掉a标签样式 */
a {
  text-decoration: none;
  color: #58b5c0;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}
/* 正在点击的链接*/
a:active {
  text-decoration: none;
}
/* 左右选择 */
.LorR {
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.LorR_zhong {
  color: #cbccd5;
  margin: 2rem 4rem;
}
.LorR_img {
  width: 12px;
}
/* 排行范围 */
.rankingScope {
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.rankingScope_a {
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-bottom: none;
  /* padding: 0.3rem 1.8rem; */
  background-color: rgb(53, 54, 65);
}
.rankingScope_a:hover
/* .rankingScope_a:visited,
.rankingScope_a:active */ {
  color: #cfcdda;
  /* padding: 0.3rem 1.8rem; */
  border: 0.15rem solid #37424a;
  border-bottom: none;
}
/* 信息排行 */
.seniority {
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.seniority_layout {
  width: 1000px;
  display: flex;
  justify-content: space-around;
  border-top: 0.15rem solid #37424a;
  padding: 60px 10px;
}
.seniority_layout:last-child {
  border-bottom: 0.15rem solid #37424a;
}
/*信息排行 文字  */
.seniority_detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
}
.seniority_MingCi1 {
  word-break: keep-all;
  white-space: nowrap;
  color: #5db1b8;
  font-size: 30px;
}
.seniority_MingCi2 {
  word-break: keep-all;
  white-space: nowrap;
  color: #c03a40;
  font-size: 14px;
}
.seniority_p {
  word-break: keep-all;
  white-space: nowrap;
  color: #5db1b8;
  font-size: 14px;
}
/* 信息排行 图片 */
.seniority_Img1 {
  width: 70%;
}
.seniority_Img2 {
  width: 60%;
}
.seniority_Img3 {
  width: 20%;
}
/* 排行角标 */
.cornerMark {
  color: #5db1b8;
  font-size: 14px;
  line-height: 60px;
  text-align: center;
}

/* 页面排行11-50 */
.ranking {
  width: 1200px;
  float: left;
  box-sizing: border-box;
  padding: 0 100px 30px 100px;
}

.ranking_layout {
  text-align: center;
  border-bottom: 0.15rem solid #37424a;
  padding-bottom: 50px;
  float: left;
}
.ranking_detail {
  width: 250px;
  margin: 1rem 0;
  text-align: center;
}
/* 排行11-50 图片信息 */
.ranking_Img1 {
  height: 150px;
}
.ranking_Img2 {
  width: 40px;
}
.ranking_Img3 {
  width: 12px;
}
/* 排行11-50 文字信息 */
.ranking_MingCi1 {
  word-break: keep-all;
  white-space: nowrap;
  color: #5db1b8;
  font-size: 30px;
}
.ranking_MingCi2 {
  word-break: keep-all;
  white-space: nowrap;
  color: #c03a40;
  font-size: 14px;
}
.ranking_p {
  word-break: keep-all;
  white-space: nowrap;
  color: #5db1b8;
  font-size: 14px;
}
</style>