<template>
	<div class="main">
		<common-left1></common-left1>
		<div class="main_center">
			<!-- 素材库 -->
			<div class="main_center_fodder">
				<div class="main_center_fodder_item1">作品投稿</div>
				<!-- 上传作品图片 -->
				<div class="main_center_fodder_item2 main_border_bottom"
					style="min-height:1px;line-height:160px;text-align:center;position:relative;">
					<!-- 封面裁剪 -->
					<el-dialog append-to-body title="封面裁剪" ref="dialog" width="1200" class="img-cropper"
						:visible.sync="dialogVisible" :close-on-click-modal="false">
						<div class="cropper-w">
							<div class="cropper" :style="{ width: '780px', height: '500px' }">
								<cropper-image :Name="cropperName" @uploadImgSuccess="handleUploadSuccess" :url="imgurl"
									ref="child">
								</cropper-image>
							</div>
						</div>
						<div slot="footer" class="dialog-footer">
							<el-button @click="dialogVisible = false">取 消</el-button>
							<el-button type="primary" @click="sure">确认</el-button>
						</div>
					</el-dialog>
					<!-- 上传图片封面 -->
					<div class="main_center_fodder_item2" v-if="coverid.length">
						<div class="item2_img">
							<img :src="coverid[0].url" />
							<i class="el-icon-close ishow" @click="deletecover"></i>
						</div>
					</div>
					<!-- 上传图片封面 -->
					<!-- 	<el-upload v-if="!coverid.length" class="upload-demo upload1"
						action="https://api.aboom.net/File/uploadFile" :show-file-list="false"
						:on-success="uploadSuccess1">
						<el-button size="mini" type="primary">封面</el-button>
					</el-upload> -->
					<div v-if="!coverid.length" class="upload-demo upload1">
						选择作品为封面
						<!-- <el-button size="mini" type="primary">封面</el-button> -->
					</div>
					<!-- 竖线分割线 -->
					<div class="main_border_right1"></div>
					<!-- 上传内容图片 -->
					<div class="main_center_fodder_item2 flex1" v-if="imgUrlArr.length">
						<div class="item2_img" v-for="(img, index) in imgUrlArr" :key="index">
							<img :src="img" />
							<div class="item2_i ishow">
								<i class="el-icon-close" @click="deletework(index, img)"></i>
								<div class="item2_div">
								</div>
								<el-button class="z-index" @click="getcover(index)" size="mini" type="primary">设为封面
								</el-button>
							</div>
						</div>
						<div class="item2_add">
							<div class="item2_add_w"></div>
							<div class="item2_add_h"></div>
							<el-upload class="avatar-uploader" action="https://api.aboom.net/File/uploadFile"
								:on-success="uploadSuccess" :show-file-list="false" :multiple="true">
							</el-upload>
						</div>
					</div>
					<!-- 上传内容图片 -->
					<el-upload v-if="!imgUrlArr.length" class="upload-demo upload"
						action="https://api.aboom.net/File/uploadFile" :show-file-list="false" :on-success="uploadSuccess"
						:before-upload="preview" :multiple="true">
						<el-button size="small" type="primary">上传作品</el-button>
					</el-upload>
				</div>

				<!-- 素材信息 -->
				<div class="main_center_fodderInfo">
					<!-- <div class="fodderInfo_item">
						<label for="fodder"> 作品封面 </label>
						<input id="fodder" type="text" v-model="coverid" />
					</div> -->

					<div class="fodderInfo_item">
						<label for="fodder"> 作品名称 </label>
						<input id="fodder" type="text" v-model="title" />
					</div>

					<div class="fodderInfo_item fodderInfo_set">
						<label> 作品类型 </label>
						<div class="select">
							<div class="select_1">
								<div>
									<input type="radio" name="select_1" v-model="works_type" value="原画" /><span>绘画邦</span>
								</div>
								<div>
									<input type="radio" name="select_1" v-model="works_type" value="漫画" /><span>漫画邦</span>
								</div>
							</div>
						</div>
					</div>
					<!-- 素材说明 -->
					<div class="fodderInfo_item fodderInfo_explain">
						<label> 作品说明 </label>
						<textarea rows="7" v-model="detail" maxlength="500"> </textarea>
					</div>
					<div class="fodderInfo_item">
						<label for="auth"> 作品标签 </label>
						<!-- <div id="labeldiv" @click="addlabel">+添加标签</div> -->
						<el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false"
							@close="handleClose(tag)">
							{{ tag }}
						</el-tag>
						<el-input class="input-new-tag" v-if="inputVisible" minlength="2" v-model="inputValue"
							ref="saveTagInput" size="small" @keyup.enter.native="complete" @blur="handleInputConfirm">
						</el-input>
						<el-button v-else class="button-new-tag" size="small" @click="showInput">+添加标签</el-button>
					</div>
					<div class="fodderInfo_btns">
						<el-checkbox style="color: #AAA;" text-color="#4eb8c2" fill="#4eb8c2" label="版权保护申请"
							v-model="checkbox"></el-checkbox>
					</div>
					<!-- 按钮 -->
					<div class="fodderInfo_btns" style="margin-top: 10px;">
						<div @click="uploadWorks">确定</div>
						<div @click="cancel">取消</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import commonLeft1 from "../../components/common_left1.vue";
import {
	VueCropper
} from 'vue-cropper'
import CropperImage from "./propper/cropperImage.vue";
export default {
	name: "material",
	components: {
		commonLeft1,
		VueCropper,
		CropperImage
	},
	data() {
		return {
			imgUrlArr: [], //全部图片地址
			title: "", //作品标题
			works_type: "", //作品类型
			age_classification: "", //作品年龄分级
			detail: "", //素材说明,
			files: [], //json文件ID数组
			coverid: [], //作品封面id和url
			label: "", //标签
			labellist: [], //上传标签数组对象
			//饿了么ui
			dynamicTags: [], //显示标签数组
			erorrTags: [], //显示错误标签数组
			inputVisible: false, //是否显示input
			inputValue: "", //输入标签值
			work_id: "", //修改的作品总id
			workid_list: [], //每张图片的id，数组
			revisedata: {}, //修改作品数据
			iscover: false,
			action: "", //上传图片
			dialogVisible: false,
			cropperName: '',
			imgurl: "", //传入组件的图片地址
			imgurllist: [], //待传入组件的图片地址集合
			screenshotUrl: {}, //截图url
			istoken: false, //token过期
			checkbox: true, //是否版权保护
		};
	},
	created() {
		this.work_id = this.$route.query?.workid;
		if (this.work_id) {
			this.getWorkDetail();
			console.log(this.work_id);
		}
	},
	mounted() {
		//创建时绑定
		this.$Bus.$on("getScreenshotUrl", this.getScreenshot)
	},
	beforeDestroy() {
		//销毁时解绑
		this.$Bus.$off("getScreenshotUrl")
	},
	methods: {
		preview(file) {
			console.log(file);
		},
		getScreenshot(value) {
			this.screenshotUrl = value
			console.log('??',value);
		},
		async sure() {
			// this.getScreenshot()
			let files = new File([this.screenshotUrl], Date.now() + "TY.jpg", {
				type: "image/jpeg",
				lastModified: Date.now(),
			});
			files.uid = new Date().getTime()
			let datas = new FormData()
			datas.append('file', files)
			let res = await this.$netWork.uploadImg({
				url: "/File/uploadFile",
				method: "post",
				data: datas
			});
			if (res.code === 0) {
				if (this.coverid.length != 0) {
					this.coverid = []
				}
				this.coverid.push({
					id: parseInt(res.data[0].file_id),
					url: res.data[0].file_path,
				});
			}
			console.log(res);
			this.dialogVisible = false
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			console.log(data)
			switch (data.name) {
				case 'flagImg':
					this.formValidate.mainImage = 'http://ydfblog.cn/dfs/' + data.url;
					console.log('最终输出' + data.name)
					break;
			}
			this.cropperModel = false;
		},
		getcover(value) {
			console.log(value);
			console.log(this.imgurllist);
			if (this.imgurllist[value] == "this.getUrl(item.file_path)") {
				this.$message('已上传作品不可截图，重新上传即可');
				return false
			}
			this.imgurl = this.imgurllist[value];
			this.dialogVisible = true
		},
		// 删除封面图片id,删除封面
		deletecover() {
			this.coverid = [];
		},
		// 删除作品图片id,删除作品
		deletework(index, item) {
			this.files.splice(index, 1);
			this.imgUrlArr.splice(this.imgUrlArr.indexOf(item), 1);
			this.imgurllist.splice(this.imgurllist.indexOf(item), 1);
			// console.log(this.files);
			// console.log(this.imgUrlArr);
		},
		//处理 url
		getUrl(url) {
			return "http://api.aboom.net" + url;
		},
		//获取修改作品详情
		async getWorkDetail() {
			let res = await this.$netWork.getWorkDetail({
				url: "/works/getDetail",
				method: "post",
				data: {
					work_id: this.work_id,
					page: "1",
					uid: ""
				},
			});
			this.revisedata = res;
			this.revisedata.fData.forEach((item) => {
				this.imgUrlArr.push(item.file_path);
				// this.urlToBase64(this.getUrl(item.file_path)).then((res)=>{
				// 	console.log(res);
				// })
				this.imgurllist.push("this.getUrl(item.file_path)")
				this.files.push({
					fid: parseInt(item.fid),
					"No.": this.imgUrlArr.length,
				});
			});
			console.log(this.imgurllist);
			this.title = res.title;
			this.detail = res.detail;
			this.revisedata.tData.forEach((item) => {
				this.dynamicTags.push(item.tag_name);
			});
			this.revisedata.tData.forEach((item) => {
				this.labellist.push({
					tid: item.tid,
				});
			});
			console.log(res, "作品详情");
		},
		urlToBase64(url) {
			return new Promise((resolve, reject) => {
				let image = new Image();
				image.onload = function () {
					let canvas = document.createElement('canvas');
					canvas.width = this.naturalWidth;
					canvas.height = this.naturalHeight;
					// 将图片插入画布并开始绘制
					canvas.getContext('2d').drawImage(image, 0, 0);
					// result
					let result = canvas.toDataURL('image/png')
					resolve(result);
				};
				// CORS 策略，会存在跨域问题https://stackoverflow.com/questions/20424279/canvas-todataurl-securityerror
				image.setAttribute("crossOrigin", 'Anonymous');
				image.src = url;
				// 图片加载失败的错误处理
				image.onerror = () => {
					reject(new Error('转换失败'));
				};
			});
		},
		//饿了么ui
		handleClose(tag) {
			this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
		},
		showInput() {
			if (!this.istoken) {
				this.$message('请用中文分号(、)来分隔标签');
				this.inputVisible = true;
				this.$nextTick((_) => {
					this.$refs.saveTagInput.$refs.input.focus();
					console.log(this.$refs.saveTagInput.$refs.input);
				});
			} else {
				this.$router.push("/login");
			}

		},
		handleInputConfirm() {
			let inputValue = null
			let value = /^\s*$/g
			let num = 0
			inputValue = this.inputValue.replace(/(^\s*)|(\s*$)/g, "");
			if (inputValue == "") {
				this.inputValue = "";
				this.inputVisible = false;
			} else {
				inputValue = inputValue.split("、");
				console.log(inputValue);
				inputValue.forEach((data) => {
					num = data.replace(/[^\x00-\xff]/g, "xx").length;
					// if (num >= 4) {
					// 	this.dynamicTags.push(data);
					// 	this.label = data;
					// 	this.getLabelId();
					// 	console.log(this.label);
					// } else {
					// 	this.erorrTags.push(data)
					// }
					this.dynamicTags.push(data);
					this.label = data;
					this.getLabelId();
					console.log(this.label);
				})
				this.inputVisible = false;
				this.inputValue = "";
			}
			if (this.erorrTags.length != 0) {
				alert("你输入的不合格标签：" + this.erorrTags)
				this.erorrTags = []
			}
		},
		complete(e) {
			e.srcElement.blur(); // 让输入框主动失焦
		},
		//请求获取标签id
		async getLabelId() {
			if (this.label && !this.istoken) {
				let res = await this.$netWork.getLabelId({
					url: "/tag/getTagId",
					method: "post",
					data: {
						tag: this.label,
						token: localStorage.getItem("token"),
					},
				});
				if (res.code === 0) {
					this.labellist.push({
						tid: res.id,
					});
				}
				if (res.code === 201) {
					if (!this.istoken) {
						alert(res.msg)
						this.istoken = true
					}
				}
				console.log(res);
			}
		},
		// 封面上传成功
		uploadSuccess1(response) {
			this.coverid.push({
				id: parseInt(response.data[0].file_id),
				url: response.data[0].file_path,
			});
			console.log(this.coverid);
		},
		// 作品上传成功
		uploadSuccess(response, file) {
			this.imgurllist.push(URL.createObjectURL(file.raw))
			// console.log(this.imgurllist);
			// console.log(file.raw);
			// this.imgurl = URL.createObjectURL(file.raw);
			// this.imgurl = response.data[0].abs_path
			this.imgUrlArr.push(response.data[0].file_path);
			this.files.push({
				fid: parseInt(response.data[0].file_id),
				"No.": this.imgUrlArr.length,
			});
			// console.log(this.imgUrlArr[0]);
		},
		// 上传作品
		async uploadWorks() {
			switch (true) {
				case !this.files.length:
					this.$message("作品图片不能为空");
					return false;
				case this.files.length > 50:
					this.$message("作品图片不能超过50张");
					return false;
				case !this.coverid.length:
					this.$message("作品封面不能为空");
					return false;
				case !this.title.trim():
					this.$message("作品标题不能为空");
					return false;
				case !this.works_type:
					this.$message("作品类型必须选择");
					return false;
				// case !this.age_classification: //后期注释掉
				// 	this.$message("作品年龄分级必须选择"); //后期注释掉
				// 	return false; //后期注释掉
				case !this.labellist.length:
					this.$message("作品标签不能为空");
					return false;
				case !this.detail.trim():
					this.$message("作品说明不能为空");
					return false;
			}
			let res = await this.$netWork.uploadWorks({
				url: "/works/uploadWorker",
				method: "post",
				data: {
					files: JSON.stringify(this.files),
					title: this.title,
					cover_img: this.coverid[0].id,
					works_type: this.works_type,
					// age_classification: this.age_classification, //后期注释掉
					tags: JSON.stringify(this.labellist),
					detail: this.detail,
					token: localStorage.getItem("token"),
					id: this.work_id,
				},
			});
			// console.log(this.coverid[0].id);
			if (res.code === 0) {
				// console.log(this.files);
				console.log(res);
				if (this.checkbox) {
					this.$confirm("作品投稿成功, 是否去申请版权保护?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "暂不申请",
						type: "warning",
					})
						.then(() => {
							this.$router.push({
								name: "copyrightProtection",
								query: {
									works_id: res.data.work_id,
									url: res.data.coverimg,
									istrue: "true"
								}
							});
						})
						.catch(() => {
							if (this.works_type === "绘画") {
								this.$router.push("/original");
							} else {
								this.$router.push("/cartoon");
							}
							this.$message("已取消版权申请");
							this.imgUrlArr = [];
							this.imgurllist = [];
							this.title = "";
							this.works_type = "";
							this.detail = "";
							this.files = [];
							this.coverid = [];
							this.labellist = [];
							this.dynamicTags = [];
						});
				} else {
					this.$confirm("作品投稿成功, 是否跳转首页?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
						.then(() => {
							if (this.works_type === "绘画") {
								this.$router.push("/original");
							} else {
								this.$router.push("/cartoon");
							}
						})
						.catch(() => {
							this.imgUrlArr = [];
							this.imgurllist = [];
							this.title = "";
							this.works_type = "";
							this.detail = "";
							this.files = [];
							this.coverid = [];
							this.labellist = [];
							this.dynamicTags = [];
						});
				}
			}
		},
		// 取消上传作品
		cancel() {
			this.imgUrlArr = [];
			this.imgurllist = [];

			this.title = "";
			this.works_type = "";
			// this.age_classification = "";
			this.detail = "";
			this.files = [];
			this.coverid = [];
			this.labellist = [];
			this.dynamicTags = [];
		},
	},

};
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
	background: #70737c;
	width: 780px;
}

/deep/.el-dialog__headerbtn {
	position: inherit;
	float: right;
}

/deep/.el-dialog__body {
	background: #70737c;
	width: 780px;
}

/deep/.el-dialog__footer {
	background: #70737c;
	width: 820px;
}

.el-dialog__wrapper {
	top: -80px;
}

.ishow {
	display: none;
}

.item2_img:hover {
	.ishow {
		display: block;
	}
}

//begin 新增标签饿了么ui .TY
#labeldiv {
	width: 80px;
	margin-left: 20px;
	height: 30px;
	border: none;
	.color();
	.bgColor();
	padding: 0 5px;
	border: none;
	outline: none;
	border-radius: 5px;
	line-height: 30px;
	cursor: pointer;
}

.el-tag {
	margin-left: 20px;
	height: 30px;
	cursor: pointer;
	border: none;
	outline: none;
	border-radius: 5px;
	.color();
	.bgColor();
}

.button-new-tag {
	margin-left: 20px;
	width: 80px;
	height: 30px;
	// line-height: 15px;
	border-radius: 5px;
	border: none;
	outline: none;
	color: black;
	background-color: #4eb8c2;
}

.input-new-tag {
	width: 90px;
	height: 30px;
	margin-left: 20px;
	border: none;
	outline: none;
	vertical-align: bottom;
}

//end
.br {
	border-radius: 7px;
}

.color {
	color: #4eb8c2;
}

.bgColor {
	background-color: rgb(38, 40, 44);
}

.main {
	display: flex;
	justify-content: space-between;
	margin: 14.77px auto;
	border-radius: 7px;
	min-height: calc(100vh - 200px - 14.77px);
	// background-color: rgb(113, 113, 125);
}

.main_center {
	flex: 1;
	margin-left: 20px;
	// height: 736px;
	border-radius: 7px;
	margin-right: 0 !important;
	background-color: rgb(53, 54, 63);

	.main_center_fodder {
		// border-bottom: 1px solid rgb(67, 80, 87);

		.main_center_fodder_item1 {
			.br();
			.color();
			.bgColor();
			padding-left: 10px;
			height: 30px;
			line-height: 30px;
			margin: 8px 8px 0 8px;
		}

		//分割线
		.main_border_bottom {
			border-bottom: 1px solid rgb(67, 80, 87);
		}

		.flex1 {
			flex: 1;
		}

		.main_center_fodder_item2 {
			display: flex;
			flex-wrap: wrap;
			padding: 0.5rem;

			.main_border_right1 {
				// padding-right: 1rem;
				border-right: 1px solid rgb(67, 80, 87);
			}

			.main_border_right2 {
				// margin-right: 1rem;
				border-right: 1px solid rgb(67, 80, 87);
			}

			// padding: 14.77px;
			// padding-bottom: 20px;

			.item2_img,
			.item2_add {
				display: flex;
				position: relative;
				align-items: center;
				justify-content: center;
				width: 150px;
				height: 150px;
				// margin:14.77px;
				margin: 0.5rem;
				.bgColor();
				.br();

				.item2_i {
					width: 100%;
					height: 100%;
					position: absolute;

					.item2_div {
						position: absolute;
						width: 100%;
						height: 100%;
						background: #000;
						opacity: 0.5;

					}

					.z-index {
						position: relative;
						z-index: 50;
					}
				}

				img {
					max-width: 150px;
					max-height: 150px;
					border-radius: 7px;
				}

				i {
					position: absolute;
					width: 1rem;
					height: 1rem;
					border-radius: 50%;
					text-align: center;
					line-height: 1rem;
					background-color: #4eb8c2;
					top: -0.4rem;
					right: -0.4rem;
					cursor: pointer;
					z-index: 50;
				}
			}

			.item2_add {
				position: relative;
				cursor: pointer;

				.item2_add_w {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 50px;
					height: 5px;
					border-radius: 5px;
					background-color: #4eb8c2;
				}

				.item2_add_h {
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					width: 5px;
					height: 50px;
					border-radius: 5px;
					background-color: #4eb8c2;
				}
			}
		}

		.upload {
			position: relative;
			width: 200px;
			height: 40px;
			margin: 72px auto;
			line-height: 40px;
			text-align: center;
			cursor: pointer;
			border-radius: 7px;
			background: #4eb8c2;
		}

		.upload1 {
			position: relative;
			width: 250px;
			text-align: center;
			cursor: pointer;
			border-radius: 7px;
			color: #4eb8c2;
		}
	}

	.main_center_fodderInfo {
		padding: 35px 15px 15px 15px;

		.fodderInfo_item {
			width: 100%;
			display: flex;
			align-items: center;
			.color();

			&>input {
				flex: 1;
				border: none;
				outline: none;
				padding: 0 5px;
				margin-left: 20px;
				.bgColor();
				.color();
				border-radius: 5px;
				height: 30px;
			}

			input:-internal-autofill-previewed,
			input:-internal-autofill-selected {
				-webkit-text-fill-color: #4eb8c2;
				transition: background-color 5000s ease-out 0.5s;
			}

			.select {
				padding: 0 5px;
				margin-left: 20px;

				.select_1,
				.select_2 {
					display: flex;

					input {
						margin-right: 10px;
					}

					div {
						min-width: 90px;
					}

					div:not(:first-child) {
						margin-left: 15px;
					}
				}

				.select_2 {
					margin-top: 15px;
				}
			}
		}

		.fodderInfo_item:not(:first-child) {
			margin-top: 25px;
		}

		.fodderInfo_set {
			align-items: flex-start;
		}

		.fodderInfo_explain {
			align-items: flex-start;

			textarea {
				height: 160px;
				flex: 1;
				margin-left: 20px;
				.br();
				resize: none;
				outline: none;
				border: none;
				.color();
				.bgColor();
				padding-top: 5px;
			}

			textarea:focus {
				outline: none !important;
				border-color: #719ece;
				box-shadow: 0 0 10px #719ece;
			}
		}

		.fodderInfo_btns {
			margin-top: 50px;
			display: flex;
			justify-content: center;
			line-height: 35px;

			div {
				cursor: pointer;
				width: 120px;
				background-color: #4eb8c2;
				margin-right: 20px;
				border-radius: 7px;
				text-align: center;
			}

			/deep/.el-checkbox__label {
				font-size: 16px;
			}
		}
	}
}

/deep/ .el-button--small,
.el-button--small.is-round {
	padding: 7px 10px;
}

/deep/ .el-button--primary {
	background-color: #4eb8c2;
	border-color: #4eb8c2;
	color: #000;
	font-size: 16px;
}

/deep/.avatar-uploader {
	width: 150px;
	height: 150px;
	display: flex;
}

/deep/.avatar-uploader .el-upload {
	width: 100%;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	padding: 30px;
}
</style>
