<template>
  <div class="content_middle">
    <div class="frame">
      <div class="BiaoTi">关于本站</div>
      <div class="NeiRong">
        本站总部位于国安局内部，防守森严，并配置最高等级的武器装备，连文字都飞不进来。所以想打本站帅哥们的主意是不可能的。不过你可以加QQ1234567，客服小哥在等着你。
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="less">
.content_middle {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.frame {
  width: 1200px;
  background-color: #353641;
  border-radius: 0.4rem;
  padding: 8px 8px 2rem 8px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
  min-height: 764px;
}
.BiaoTi {
  color: #4db9c2;
  background-color: #26272e;
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.4rem;
}
.NeiRong {
  color: #4db9c2;
  font-size: 14px;
  padding: 1rem 2rem;
}
</style>