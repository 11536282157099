import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import netWork from "@/common/axios/request";
import "@/common/element_ui";
// import { InfiniteScroll } from 'element-ui';
// import { DatetimePicker } from 'vant';
import 'vant/lib/datetime-picker/style';
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

import { isToken } from './common/util'
Vue.config.productionTip = false;
Vue.prototype.$netWork = netWork;
// Vue.use(ElementUI);
// Vue.use(DatetimePicker);
// Vue.use(InfiniteScroll);
Vue.prototype.$bus = new Vue();
new Vue({
  router,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$Bus = this
    Vue.prototype.$isToken = isToken
  }
}).$mount("#app");
