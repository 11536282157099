<template>
  <div>
    <div class="help">
      <div class="title">帮助中心</div>
      <template v-if="helpList.length != 0">
        <div class="options">
          <div class="width_op">
            <div @click="seIndex = i" :class="{ 'op_list': true, 'op_select': seIndex == i }" v-for="(v, i) in helpList"
              :key="'a' + i">
              {{ v.title }}
            </div>
          </div>
        </div>
        <div class="content" v-html="helpList[seIndex].content"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      seIndex: 0,
      activeNames: "1",
      helpList: []//帮助中心
    };
  },
  mounted() {
    this.getHelpCenter();
  },
  methods: {
    // 帮助中心
    async getHelpCenter() {
      let res = await this.$netWork.getHelpCenter({
        url: "/banner/help",
        method: "post",
      });
      // res.data[1].title = "帮助手册"
      this.helpList = res.data;
      console.log('帮助', this.helpList)
    },
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="less" scoped>
.help {
  margin: 30px 0;
  padding: 14px 11px;
  border-radius: 10px;
  background: #353640;

  .title {
    padding: 5px 12px;
    color: #5ce2ed;
    background: #26272D;
    border-radius: 5px;
  }

  .options {
    margin: 20px 0 35px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #535564;
    font-size: 14px;

    .width_op {
      width: 1000px;
      display: flex;
      margin: auto;
    }

    .op_list {
      width: 60px;
      margin-right: 30px;
      color: #4EB8C2;
      cursor: pointer;
    }

    .op_select {
      position: relative;
    }

    .op_select::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -15px;
      width: 60px;
      height: 1px;
      background: #4EB8C2;
    }
  }

  .content {
    max-width: 1000px;
    padding: 33px 30px;
    color: #5CE2ED;
    background: #26272D;
    margin: 0 auto 20px auto;
    box-sizing: border-box;
    border-radius: 12px;
  }

}
</style>