<template>

	<div class="registerForm">

		<el-form :label-position="labelPosition" label-width="80px" :model="form" class="register-form">
			<el-form-item label="登录账号">
				<el-input class="inputname" @blur="type" v-model.trim="form.name" placeholder="请输入邮箱/手机号"></el-input>
			</el-form-item>
			<el-form-item label="密码" prop="pass">
				<el-input type="password" v-model.trim="form.password" placeholder="请输入密码" @blur="PassLimit()">
				</el-input>
			</el-form-item>
			<div class="flex pwdstrength" v-if="form.password.length > 0">
				<span v-show="form.password.length < 3" class="strength">弱</span>
				<span v-show="form.password.length >= 3 && form.password.length <= 8" class="strength">中</span>
				<span v-show="form.password.length > 8" class="strength">强</span>
			</div>
			<!-- </el-form-item> -->
			<el-form-item label="确认密码">
				<el-input placeholder="请确认密码" type="password" v-model.trim="form.repassword" @blur="comparison">
				</el-input>
			</el-form-item>
			<el-form-item label="昵称" prop="nikname">
				<el-input v-model.trim="form.nikname" placeholder="请输入昵称" @blur="NikNameLimit()"></el-input>
			</el-form-item>
			<el-form-item label="性别" class="flex">
				<el-radio-group v-model="form.sex">
					<el-radio label="1">男</el-radio>
					<el-radio label="2">女</el-radio>
				</el-radio-group>
			</el-form-item>

			<el-form-item label="出生日期">
				<div class="date">
					<!-- 单选日期版。TY -->
					年：<el-select class="inputyear" v-model="form.year" placeholder="年" @focus="gettime(1)">
						<el-option v-for="item in options" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
					月：<el-select v-model="form.mooth" placeholder="月" @focus="gettime(2)">
						<el-option v-for="item in options" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
					日：<el-select v-model="form.day" placeholder="日" @focus="gettime(3)">
						<el-option v-for="item in options" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
					<!-- 输入框版 。TY-->
					<!-- 	年：<el-input v-model.trim="form.year" maxlength="4" @blur="years()"></el-input>
					月：<el-input v-model.trim="form.mooth" maxlength="2" @blur="mooths()"></el-input>
					日：<el-input v-model.trim="form.day" maxlength="2" @blur="days()"></el-input> -->
					<!-- 全选日期版 -->
					<!-- 		<el-date-picker
          v-model="value1"
          class="Date"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="请选择日期">
        </el-date-picker> -->
				</div>
			</el-form-item>
			<el-form-item label="图形验证码">
				<div class="code">
					<el-input v-model.trim="form.verifycode" placeholder="请输入正确的验证码"></el-input>
					<img :src="src" alt="!" @click="refresh" height="28px" width="76px" />
				</div>
			</el-form-item>

			<el-form-item label="邮箱/手机验证码">
				<div class="code">
					<el-input v-model="form.mac"></el-input>
					<button class="mac" ref="btn" type="button" @click="getMac">{{msg>0?msg:'获取验证码'}}</button>
				</div>
			</el-form-item>
			<!-- <template>
      <el-radio v-model="radio">已同意并阅读<a @click="toUserAgreement">《用户协议》</a></el-radio>
    </template> -->
			<div class="read">
				<input type="checkbox" class="radio" v-model="radio" />
				<a class="agree">已阅读并同意</a>
				<a class="agree" :href="href" target="_blank">《用户协议》</a>
				<a class="agree" :href="href2" target="_blank">《隐私政策》</a>
			<!-- 	<a @click="toUserAgreement" class="agree">《用户协议》</a>
				<a @click="toprivacyAgreement" class="agree">《隐私政策》</a> -->
			</div>
			<el-form-item>
				<div class="flex">
					<button class="btn" type="button" @click="register">注册</button>
				</div>
			</el-form-item>
		</el-form>
	</div>

</template>
<script>
	export default {
		name:"register",
		data() {
			return {
				href: "http://y.baenae.cn/#/userAgreement",
				href2: "http://y.baenae.cn/#/privacyAgreement",
				value1: '',
				msg: 0,
				options: [],
				radio: false,
				src: "http://api.aboom.net/captcha?a=" + Math.random(),
				labelPosition: "top",
				form: {
					name: "",
					password: "",
					repassword: "",
					sex: "0",
					nikname: "",
					year: null,
					mooth: null,
					day: null,
					verifycode: "",
					type: "1",
					//message authentication code 短信验证码
					mac: "",
				},
				minDate: new Date(1900, 0, 1),
				maxDate: new Date(2100, 0, 1),
				currentDate: new Date(),
			};
		},
		watch: {
			// "form.password"() {
			//   if (this.form.password.length > 0) {
			//     console.log(123456);
			//   }
			// },
		},
		methods: {
			gettime(value) {
				let leng = 1
				let begin = 1
				if (value == 3) {
					leng = 31
					this.options = []
				}
				if (value == 2) {
					leng = 12
					this.options = []
				}
				if (value == 1) {
					leng = 2100
					begin = 1950
					this.options = []
				}
				for (var i = begin; i <= leng; i++) {
					this.options.push(i)
				}
			},
			toUserAgreement() {
				this.$router.push({
					path: "/userAgreement"
				});
			},
			toprivacyAgreement() {
				this.$router.push({
					path: "/privacyAgreement"
				});
			},
			//图片验证码刷新
			refresh() {
				this.src = "http://api.aboom.net/captcha?a=" + Math.random();
			},
			//注册
			register() {
				console.log(this.form);
				let day = this.form.day
				let mooth = this.form.mooth
				if(this.form.mooth<10){
					mooth = "0"+this.form.mooth
				}
				if(this.form.day<10){
					day = "0"+this.form.day
				}
				console.log(this.form.year + '-' + mooth + '-' + day);
				this.$netWork
					.register({
						method: "post",
						url: "/user/register",
						data: {
							account: this.form.name,
							password: this.form.password,
							repassword: this.form.repassword,
							captcha: this.form.verifycode,
							gender: this.form.sex,
							nickname: this.form.nikname,
							birthday: this.form.year + '-' + mooth + '-' + day,
							// birthday: this.value1,
							code: this.form.mac,
						},
					})
					.then((result) => {
						console.log(result);
						if (result.code !== 0) {
							alert(result.msg);
							return;
						}
						console.log(this.form.name, this.form.pwd, this.form.verifycode);
						localStorage.setItem("token", result?.token);
						let token = localStorage.getItem("token");
						this.$netWork
							.getUserInfo({
								method: "post",
								url: "/user/getUserInfo",
								data: {
									token: token,
								},
							})
							.then((result) => {
								if (result.code !== 0) {
									alert(result.msg);
									return;
								}
								console.log(result.data);
								localStorage.setItem("userInfo", JSON.stringify(result?.data));
								this.$router.push({
									path: "/"
								});
							})
							.catch((arr) => {
								console.log(arr);
							});
						// this.$netWork
						//   .login({
						//     method: "post",
						//     url: "/user/login",
						//     data: {
						//       account: this.form.name,
						//       password: this.form.pwd,
						//       captcha: this.form.verifycode,
						//     },
						//   })
						//   .then((result) => {
						//     if (result.code !== 0) {
						//       alert(result.msg);
						//       return;
						//     }
						//     console.log(result, result?.token, "------------------------");

						//     localStorage.setItem("token", result?.token);
						//     var getData = localStorage.getItem("token");
						//     this.$netWork
						//       .getUserInfo({
						//         method: "post",
						//         url: "/user/getUserInfo",
						//         data: {
						//           token: getData,
						//         },
						//       })
						//       .then((result) => {
						//         if (result.code !== 0) {
						//           alert(result.msg);
						//           return;
						//         }
						//         console.log(result.data, "+++++++++++++++++++++++++");
						//         localStorage.setItem(
						//           "userInfo",
						//           JSON.stringify(result?.data)
						//         );
						//         this.$router.push({ path: "/home" });
						//       })
						//       .catch((arr) => {
						//         console.log(arr);
						//       });
						//   })
						//   .catch((err) => {
						//     console.log(err);
						//   });
					})
					.catch((err) => {
						console.log(err);
					});
			},
			//获取短信验证码
			getMac() {
				console.log(this.form);
				let type
				if (this.form.name.includes("@")) {
					type = '1'
				} else {
					type = '2'
				}
				this.$netWork
					.getMac({
						method: "post",
						url: "/verify/getVerify",
						data: {
							account: this.form.name,
							template: "register",
							type: type,
						},
					})
					.then((result) => {
						console.log(result, "验证码返回信息");
						if (result.code == 0) {
							this.$refs.btn.disabled = true
							this.$message.success("验证码发送成功,请注意查收")
							this.msg = 60
							let time = setInterval(() => {
								this.msg--
							}, 1000)
							setTimeout(() => {
								clearInterval(time)
								this.$refs.btn.disabled = false
							}, 60 * 1000)
						} else {
							this.$message.error(result.msg)
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			//比较两次输入的密码是否一致
			comparison() {
				if (this.form.password != this.form.repassword) {
					alert("输入的密码不一致!");
					// this.form.repassword = "";//重新输入
				}
			},
			//判断验证码是否为邮件
			type() {
				let str = this.form.name;
				if (str.indexOf("@") == -1) {
					this.form.type = "2";
				}
			},
			//密码限制
			PassLimit() {
				let pass = this.form.password
				if (pass.length < 6) {
					alert("密码输入至少六位")
					// this.form.password = ''//重新输入
				}
			},
			NikNameLimit() {
				let nik = this.form.nikname
				if (nik.length > 8) {
					// this.form.nikname = ''//重新输入
					alert("昵称限制为八位")
				}
			},
			// //年
			// years() {
			// 	let test = /^[0-9]*$/
			// 	let newyear = new Date().getFullYear()
			// 	if (this.form.year < 1900 || this.form.year > newyear) {
			// 		alert("请输入真实出生年份")
			// 		this.form.year=""
			// 	}
			// 	if(!test.test(this.form.year) ){
			// 		alert("请输入真实出生年份")
			// 		this.form.year=""
			// 	}
			// },
			// //月
			// mooths() {
			// 	let test = /^[0-9]*$/
			// 	if (this.form.mooth > 12 || this.form.mooth < 1) {
			// 		alert("请输入真实出生月份")
			// 		this.form.mooth=""
			// 	}
			// 	if(!test.test(this.form.year) ){
			// 		alert("请输入真实出生年份")
			// 		this.form.year=""
			// 	}
			// },
			// //日
			// days() {
			// 	let test = /^[0-9]*$/
			// 	if (this.form.day > 31 || this.form.day < 1) {
			// 		alert("请输入真实出生日期")
			// 		this.form.day=""
			// 	}
			// 	if(!test.test(this.form.year) ){
			// 		alert("请输入真实出生年份")
			// 		this.form.year=""
			// 	}
			// },
		},
	};
</script>
<style scoped lang="less">
	
	.read {
		cursor: pointer;
		font-size: 12px;
		color: white;
		line-height: 22px;
		display: flex;
	}
	.radio {
		margin-right: 10px;
	}

	.agree {
		font-size: 12px;
		color: white;
		text-decoration: none;
	}

	.Date {
		width: 296px;
	}

	.registerForm {
		width: 450px;
		// height: 610px;
		// border: 2px solid red;
		background-color: rgb(79, 81, 81);
		border-radius: 20px;
		margin: auto;
	}

	.pwdstrength {
		justify-content: center;
		align-items: center;
	}

	.strength {
		display: block;
		width: 50px;
		height: 20px;
		font-size: 14px;
		text-align: center;
		color: #000;
	}

	.strength:nth-child(1) {
		background-color: yellow;
	}

	.strength:nth-child(2) {
		background-color: red;
	}

	.strength:nth-child(3) {
		background-color: green;
	}

	.mac {
		width: 76px;
		height: 28px;
		margin: 7px 0 0 -2px;
		line-height: 28px;
		border: none;
		cursor: pointer;
	}

	.btn {
		width: 200px;
		height: 28px;
		margin: 20px auto;
		    line-height: 28px;
		border-radius: 8px;
		border: none;
		cursor: pointer;
		background-color: #46b3bd;
		color: white;
	}

	.date {
		// display: grid;
		// grid-template-columns: 40% 24% 24%;
		// column-gap: 6%;
		width: 296px;
		display: flex;
		color: #FFF;
	}

	.date ::v-deep.inputyear .el-input .el-input__inner{
		width: 80px;
	}
	.date ::v-deep.el-input__inner{
		width: 65px;
	}

	.code {
		display: grid;
		grid-template-columns: 65% 25%;
		column-gap: 10%;

		img {
			margin: 6px 0 0 -2px;
			cursor: pointer;
		}
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.el-radio-group {
		margin-left: 30px;
	}

	.register-form {
		padding-top: 40px;
		width: 300px;
		margin: 20px auto;

		::v-deep .el-form-item__label {
			color: #fff;
			line-height: 0;
			padding: 0;
		}
	}

	::v-deep .el-input__inner {
		height: 28px;
		line-height: 28px;
	}

	.el-form-item {
		margin: 2px;
	}

	::v-deep .el-radio__label {
		color: #fff;
	}
</style>
