<template>
  <div class="lawForm">
    <div class="title">关于我们</div>
    <div class="contet" v-html="about"></div>
  </div>
</template>

<script>
export default {
    name:"about",
    data() {
        return {
            about:""
        }
    },
    methods: {
        async getAbout(){
            let res = await this.$netWork.getAbout({
                method:"post",
                url:"/banner/abus",
            });
            console.log(res,'声明')
            this.about = res.data.content
        }
    },
    mounted() {
        this.getAbout();
    },
}
</script>

<style scoped>
/* 白色背景 */
.lawForm {
    margin: 30px auto;
    position: relative;
    border-radius: 10px;
    box-sizing: border-box;
    background: #353640;
    padding: 14px 11px;
}
/* 大标题 */
.title {
    background: #26272D;
    border-radius: 10px;
    color: #5CE2ED;
    padding: 5px 12px;
    margin-bottom: 16px;
}
/* 用户使用协议 */
.agreement {
    font-size: 18px;
    color: black;
    margin: 30px 0 20px 30px;
}
/* 文本 */
.textarea {
    width: 700px;
    margin: 20px auto;
}
.accept {
    font-size: 13px;
    color: rgb(98, 96, 100);
}
.contet{
    background: #26272D;
    width: 1000px;
    margin:20px auto;
    padding: 33px 30px;
    box-sizing: border-box;
    border-radius: 12px;
}
</style>