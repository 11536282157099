
const getUrl = function getUrl(url) {
  return "http://api.aboom.net" + url;
}

const isToken = function (value) {
  // console.log(this);
  if (!localStorage.getItem("token")) {
    let msg = ''
    if(value){
      msg = `登录后才能进行${value},请先登录`
    }else {
      msg = "请先登录哦！"
    }
    this.$confirm(msg, '登录提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      center: true
    }).then(() => {
      // this.$message({
      //   type: 'success',
      //   message: '跳转到登陆页面'
      // });
      this.$router.push("/login");
    }).catch(() => {
      this.$message({
        type: 'info',
        message: '已取消跳转'
      });
    });
    return false;
  }
  return true
}
export {
  getUrl,
  isToken
}