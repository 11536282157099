<template>
	<div class="wrapper clearfix">
		<!-- 侧边栏 -->
		<common-left1></common-left1>
		<!-- 内容 -->
		<div class="centerContent">
			<div class="title">申请版权保护</div>
			<div class="copyrightWorkContent">
				<!-- <div class="explain">未申请的作品</div> -->
				<!-- 作品 -->
				<div class="worksBox">
					<div class="item" v-for="(item, index) in allWorkList.slice((currPage - 1) * 8, (currPage * 8))"
						:key="index"
						@click="goCopyrightProtection({ id: item.id, pstatus: item.pstatus, index: index, dci: item.dci_content_id, title: item.title })">
						<img :src="item.coverimg" :title="item.title" alt="" />
						<p class="name">
							{{ item.title }}
							<!-- <span :class="{
                red: item.pstatus == 0,//不通过
                org: item.pstatus == 1,//审核中
                green: item.pstatus == 2,//已通过
                white: item.pstatus == null,//未申请
              }">
                {{ auditStatus(item.pstatus) }}
              </span> -->
						</p>
						<!-- TY -->
						<div class="state">
							<span class="sj"></span>
							<span style="fontsize: 14px;background-color: #35363f;color: #5dbbbf" class="revisespan">
								{{ auditStatus(item.pstatus) }}
							</span>
						</div>
					</div>
				</div>
				<!-- 分页 -->
				<ul v-if="totalPage > 1" class="pages bottomPages">
					<li class="prev" v-if="totalPage > 1" @click="prev"></li>
					<li v-for="item in totalPage" :key="item" :class="{ active: item == currPage }" @click="refash(item)">
						{{ item }}
					</li>
					<li class="next" @click="next" v-if="totalPage > 1"></li>
				</ul>
				<!-- 按钮 -->
				<div class="btn">
					<!-- <div class="submit">提交</div> -->
				</div>
			</div>
		</div>
		<!-- 状态为 1345 的弹窗 -->
		<el-dialog title="提示" :visible.sync="status1345" width="30%">
			<div class="s13459">
				<p>您的DCI版权保护申请已通过请前往查询</p>
				<p>DIC编号 : <span style="color: blue;">{{ popData.dci_code }}</span> </p>
				<p>作品名 : {{ popData.title }}</p>
				<div>
					<el-button @click="goBHpdf">点击查看证书文件</el-button>
				</div>
				<!-- popData.pre_reg_cert_url -->
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="status1345 = false">取 消</el-button>
				<el-button type="primary" @click="goBHhtml">前往</el-button>
			</span>
		</el-dialog>
		<!-- 状态为 9 的弹窗 -->
		<el-dialog title="提示" :visible.sync="status9" width="30%">
			<div class="s13459">
				<p>您好您申请的DCI版权保护未通过审核 , 原因是 : </p>
				<p>{{ popData.error_reason_cn }}</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="status9 = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import commonLeft1 from "../../../../components/common_left1.vue";
export default {
	name: "copyrightWork",
	components: {
		commonLeft1
	},
	data() {
		return {
			allWorkList: [], //全部作品
			totalPage: "",
			currPage: "1",
			allWorkListCopy: [],
			status1345: false,
			status9: false,
			popData: ''
		};
	},
	created() {
		this.getCopyrightMangerAllWorks();
	},
	mounted() {
		setTimeout(() => {
			this.isbaohu();
		}, 500)
	},
	methods: {
		isbaohu() {
			console.log(this.allWorkList.length);
			if (this.allWorkList.length == 0) {
				this.$alert('您暂时没有作品可申请版权保护', '注意', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/copyrightCenter");
					}
				})
			}
		},
		refash(item) {
			this.currPage = item;
			// this.getCopyrightMangerAllWorks();
		},
		//上一页
		prev() {
			if (this.currPage == 1) {
				this.$message.error("已经是第一页了");
				return false;
			}
			this.currPage--;
			// this.getCopyrightMangerAllWorks();
		},
		//下一页
		next() {
			if (this.currPage >= this.totalPage) {
				this.$message.error("已经是最后一页了");
				return false;
			}
			this.currPage++;
			// this.getCopyrightMangerAllWorks();
		},
		// 获取全部作品
		async getCopyrightMangerAllWorks() {
			if (!localStorage.getItem("userInfo")) {
				this.$message.warning("用户登录过期");
				return false;
			}
			let { id } = JSON.parse(localStorage.getItem("userInfo"));
			let res = await this.$netWork.getCopyrightMangerAllWorks({
				url: "/works/getWorksList",
				method: "post",
				data: {
					type: "my",
					page: this.currPage,
					id: `${id}`,
					works_type: "所有",
					status: '2'
				},
			});
			this.allWorkList = res.data.list
			// .filter((item) => {
			// 	return item.pstatus != 3 && item.pstatus != 4 && item.pstatus != 5
			// })
			// this.totalPage = res.data.totalPage;
			console.log('全部作品', this.allWorkList);
			if (this.allWorkList.length > 8) {
				for (let i = 2; i <= this.totalPage; i++) {
					console.log(i, this.totalPage, "666");
					let res = await this.$netWork.getCopyrightMangerAllWorks({
						url: "/works/getWorksList",
						method: "post",
						data: {
							type: "my",
							page: i,
							id: `${id}`,
							works_type: "所有",
							status: '2'
						},
					});
					if (res.code == 0) {
						res.data.list.forEach(element => {
							this.allWorkList.push(element)
						});
					}
					console.log(this.allWorkList);
				}
			}
			// this.allWorkList = this.allWorkList
			// .filter((item) => {
			// 	return item.pstatus != 3
			// })
			// if(!this.allWorkList[0]){
			// 	this.$message("您暂时没有作品可申版权保护")
			// }
			if (this.allWorkList.length > 8) {
				// console.log(this.allWorkList.length,parseInt(this.allWorkList.length/8)+1,"totalpage");
				this.totalPage = parseInt(this.allWorkList.length / 8) + 1
			}
			// this.allWorkListCopy = this.allWorkList
			// if (res.code === 0) {
			//   this.allWorkList = res.data.list
			//   console.log(this.allWorkList, "这是全部作品2")
			// }
		},
		getUrl(url) {
			return "http://api.aboom.net" + url;
		},
		goCopyrightProtection(id) {
			// 0:未请中1:申请成功,2:申请失败,3:申请托管,4:已拒绝托管,5:托管中,9:申请中,
			if (id.pstatus == 0) {
				this.$router.push({
					name: "copyrightProtection",
					query: {
						works_id: id.id,
						url: this.allWorkList[id.index].coverimg,
					},
				});
			} else if (id.pstatus == 1 || id.pstatus == 3 || id.pstatus == 4 || id.pstatus == 5 || id.pstatus == 2) {
				this.getClickInfo(id)
			} else if (id.pstatus == 9) {
				this.$alert('当前作品正在申请中，请等待', '提示', {
					confirmButtonText: '确定'
				})
			}
		},
		// 0:未请中 1 3 4 5:申请成功,2:申请失败,9:申请中,
		auditStatus(status) {
			if (status == 0 || status == null) {
				return "未申请"
			} else if (status == 1 || status == 3 || status == 4 || status == 5) {
				return "申请成功"
			} else if (status == 2) {
				return "申请失败"
			} else if (status == 9) {
				return "申请中"
			}
		},
		goBHhtml() {
			this.status1345 = false;
			window.open('https://www.ccopyright.com.cn/')
		},
		goBHpdf(){
			window.open(this.popData.pre_reg_cert_url)
		},
		// 查询情况
		async getClickInfo(v) {
			let res = await this.$netWork.getDciInfo({
				url: '/protection/dciQueryDetail',
				method: "post",
				data: {
					dciUserId: JSON.parse(localStorage.getItem('userInfo')).dci_uid,
					dciContentId: v.dci
				}
			})
			console.log('--查询结果--', res.data);
			if (res.code == 0) {
				this.popData = res.data;
				this.popData.title = v.title;
				if (v.pstatus == 2) {
					this.status9 = true;
				} else {
					this.status1345 = true;
				}
			} else {
				this.$message.error(res.msg)
			}
		}
		// async getProtetion() {
		// 	let res = await this.$netWork.getCopyrightMangerAllWorks({
		// 		url: "/protection/getprotection",
		// 		method: "post",
		// 		data: {
		// 			token: localStorage.getItem("token")
		// 		}
		// 	})
		// 	this.allWorkList = res.data
		// 	console.log(res, '参观参观参观参观工厂从')
		// }
	},
};
</script>

<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;

.wrapper {
	display: flex;
	justify-content: space-between;
	margin: 14.77px auto;

	.centerContent {
		margin-left: 20px;
		flex:1;
		// height: 1100px;
		border-radius: 7px;
		background: @bg1Color;

		.title {
			height: 38px;
			margin: 8px 8px 0px 8px;
			padding-left: 5px;
			line-height: 38px;
			color: @fsColor;
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
			background: @bgColor;
		}

		.copyrightWorkContent {
			position: relative;
			color: @fsColor;
			margin: 30px auto;

			.explain {
				padding: 20px 40px;
				font-size: 20px;
			}

			// 作品
			.worksBox {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				padding-left: 80px;

				.item {
					display: flex;
					flex-direction: column;
					align-items: center;
					position: relative;
					width: 180px;
					margin-bottom: 30px;
					cursor: pointer;
					border-radius: 7px;
					margin-right: 38px;

					.state {
						position: absolute;
						right: 0;
						top: -4px;

						.sj {
							height: 0;
							width: 0;
							float: left;
							left: -20px;
							border-top: solid 12px rgb(53, 54, 63);
							border-left: solid 12px transparent;
							border-right: solid 12px rgb(53, 54, 63);
							border-bottom: solid 12px transparent;
						}

						.revisespan {
							padding: 0 0 4px 1px;
							font-size: 1rem;
							height: 1.25rem;
						}
					}


					img {
						width: 180px;
						height: 180px;
						border-radius: 7px;
						object-fit: cover;
					}

					.name {
						margin-top: 10px;

						span {
							position: absolute;
							padding: 2px 5px;
							top: 0;
							right: 0;
							background-color: #28262e;
						}


					}

					.gou {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100px;
						height: 76px;
					}
				}
			}

			// 分页
			.pages {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				display: flex;
				align-items: center;

				li {
					margin-right: 16px;
					cursor: pointer;
				}

				.active {
					color: @iptfsColor;

					&:hover {
						color: @iptfsColor;
					}
				}

				.next {
					width: 48px;
					height: 25px;
					background: @btnColor;
					background-image: url(../../../../assets/img/copyright/fanye3.png);
					background-repeat: no-repeat;
					background-size: 14px;
					background-position: center;
					border-radius: 3px;
				}
			}

			// 按钮
			.btn {
				margin: 30px;
				// padding-top: 20px;
				border-top: 1px solid @lineColor;

				.submit {
					width: 116px;
					height: 36px;
					margin-top: 30px;
					margin-left: 50%;
					transform: translate(-50%);
					line-height: 36px;
					text-align: center;
					color: @btnfsColor;
					cursor: pointer;
					border-radius: 7px;
					background: @btnColor;
				}
			}
		}
	}
}

.red {
	color: red !important;
}

.green {
	color: green !important;
}

.org {
	color: orange !important;
}

.white {
	color: white !important;
}

.color {
	color: @fsColor !important;
}

.s13459 {
	font-size: 16px;

	p {
		margin-bottom: 10px;
	}

	img {
		width: 100%;
		height: 200px;
	}


}

/deep/.el-button {
	padding: 12px 20px !important;
}
</style>
