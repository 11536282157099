<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>

export default {
  created() {
    if (localStorage.getItem('token')) {
      this.$netWork.getUserInfo({
        url: '/user/getUserInfo',
        method: 'post',
        data: {
          token: localStorage.getItem('token')
        }
      }).then(res => {
        localStorage.setItem('userInfo', JSON.stringify(res.data))
      })
    }
    setInterval(() => {
      if (localStorage.getItem('userInfo')) {
        this.getMyss()
      }
    }, 1000);

  },
  methods: {
    async getMyss() {
      let token = localStorage.getItem("token");
      let res = await this.$netWork.getMessageList({
        url: "/message/getList",
        method: "post",
        data: {
          token: token,
        },
      });
      if(localStorage.getItem('messContent')){
        let locaContent = localStorage.getItem('messContent');
        let cha = Number(res.messageCount) - Number(locaContent);
        this.$bus.$emit('ssLoading',cha)
      }else{
        localStorage.setItem('messContent',res.messageCount)
      }
    },
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  font-family: '思源黑体ExtraLight,SimHei,STHeiti';
}

@font-face {
  font-family: '思源黑体ExtraLight';
  src: url('./assets/font/SourceHanSansCN-ExtraLight-2.otf');
}

.link {
  cursor: pointer;
}
</style>
