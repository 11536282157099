<template>
	<div class="wrapper">
		<!-- 侧边栏 -->
		<common-left1></common-left1>
		<!-- 内容 -->
		<div class="centerContent">
			<div class="title">申请版权托管</div>
			<!-- 表单 -->
			<form class="formInfo">
				<div class="workInfo">
					<label>申请托管理由</label>
					<textarea name="" id="" cols="30" rows="10" v-model="workInfo.work_info"
						@input="isCopyRightProtection = false" placeholder="请告诉我们您托管的理由。"></textarea>
				</div>
				<div class="item">
					<label>是否版权维权</label>
					<div class="item_radio">
						<el-radio v-model="radio1" label="1">是</el-radio>
						<el-radio v-model="radio1" label="0">否</el-radio>
					</div>
				</div>
				<div class="item">
					<label>是否商业化管理</label>
					<div class="item_radio">
						<el-radio v-model="radio2" label="2">是</el-radio>
						<el-radio v-model="radio2" label="0">否</el-radio>
					</div>
				</div>

			</form>
			<!-- 协议 -->
			<div class="agreement">
				<div class="scrollBar" ref="scrollBar">
					<h2 class="title">原画邦版权托管意向协议</h2>
					<div class="areementContent" v-html="html"></div>
				</div>
				<div class="fullView" @click="showBig">
					<span>全屏查看</span>
					<img src="../../../assets/imgs/quanping.png" alt="" />
				</div>
				<el-dialog title="原画邦版权托管意向协议" :visible.sync="centerDialogVisible" width="60%" center>
					<div class="areementContent" v-html="html"></div>
					<span slot="footer" class="dialog-footer">
						<!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
						<el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
					</span>
				</el-dialog>
				<div class="item">
					<label>用户作品托管协议</label>
					<div class="item_radio">
						<el-radio v-model="radio3" label="3" @change="isSures(3)">同意</el-radio>
						<el-radio v-model="radio3" label="0" @change="isSures(0)">不同意</el-radio>
					</div>
				</div>
			</div>
			<!-- 按钮 -->
			<div class="btnBox">
				<div class="btn2" :class="{ btn: isSure && (radio1==1 || radio2==2) }" @click="submit">确认</div>
				<div class="btn" @click="cancel">取消</div>
			</div>
		</div>
	</div>
</template>

<script>
import commonLeft1 from "../../../components/common_left1.vue";
export default {
	name: "copyrightEscrow",
	components: {
		commonLeft1
	},
	data() {
		return {
			centerDialogVisible: false,
			workInfo: {
				token: "",
				works_id: "", //作品id
				title: "", //作品标题
				content: "", //作品内容
				verify_files: "", //作品创作流程图片
				publish_time: "", //发布到站内作品的时间
				detail: "", //发布到站内作品的内容
				work_info: "", //作品信息
			},
			isSure: false, //是否同意协议
			isLook: false, //是否观看协议
			work_id: "", //申请保护作品总id
			radio1: "",
			radio2: "",
			radio3: "",
			work_reason: "", //托管理由
			isCopyRightProtection: true, //判断是否填入信息
			html: `<div class="useForm">
    <p class="head">【总则】</p>
    <div class="textarea">
      &emsp;&emsp;用户在接受原画邦（Anime Boom.com）托管服务之前，请务必仔细阅读本协议，用户同意协议后，都将被视作已无条件接受本协议所涉全部内容；若用户对本协议的任何条款有异议，请停止使用原画邦所提供的托管服务。
    </div>
    <div class="textarea">
	<p>第一条 作品著作权说明</p>
	&emsp;&emsp;1.著作人身权
	（1）发表权发表权：即决定作品是否公之于众的权利。发表权只能行使一次，除特殊情况外，仅能由作者行使。
	（2）署名权：署名权，即表明作者身份，在作品上署名的权利。它包括作者决定是否署名，署真名、假名、笔名，禁止或允许他人署名等权利。
	（3）修改权：修改权，即修改或者授权他人修改作品的权利。
	（4）保护作品完整权：保护作品完整权，即保护作品不受歪曲、篡改的权利。
	2.著作财产权
	（1）复制权：即以印刷、复印、拓印、录音、录像、翻录、翻拍、数字化等方式将作品制作一份或者多份的权利；
	（2）发行权：即以出售或者赠与方式向公众提供作品的原件或者复制件的权利；
	（3）出租权：即有偿许可他人临时使用视听作品、计算机软件的原件或者复制件的权利，计算机软件不是出租的主要标的的除外；
	（4）展览权：即公开陈列美术作品、摄影作品的原件或者复制件的权利；
	（5）表演权：即公开表演作品，以及用各种手段公开播送作品的表演的权利；
	（6）放映权：即通过放映机、幻灯机等技术设备公开再现美术、摄影、视听作品等的权利；
	（7）广播权：即以有线或者无线方式公开传播或者转播作品，以及通过扩音器或者其他传送符号、声音、图像的类似工具向公众传播广播的作品的权利，但不包括本款第十二项规定的权利；
	（8）信息网络传播权：即以有线或者无线方式向公众提供，使公众可以在其选定的时间和地点获得作品的权利；
	（9）摄制权：即以摄制视听作品的方法将作品固定在载体上的权利；
	（10）改编权：即改变作品，创作出具有独创性的新作品的权利；
	（11）翻译权：即将作品从一种语言文字转换成另一种语言文字的权利；
	（12）汇编权：即将作品或者作品的片段通过选择或者编排，汇集成新作品的权利；
	（13）应当由著作权人享有的其他权利。

    </div>
	<div class="textarea">
	<p>第二条 服务选择</p>
	&emsp;&emsp;平台提供3项服务供用户选择，用户视自身情况自愿选择如下服务：
	1）委托平台进行版权维护
	2）委托平台进行作品著作权商业化运营管理
	3）将作品著作权整体委托平台
	在此项服务中作品著作权指的是著作财产权全13项权力。

	</div>
	<div class="textarea">
	<p>第三条 托管服务说明</p>
	&emsp;&emsp;平台在服务中尊重每一个版权的完整性，用户勾选同意委托平台进行版权维护工作，服务一旦生效后，平台将针对侵犯已委托作品的著作权利益的行为进行司法维权工作，平台将出具已采集的用户信息、作品信息用于司法维权工作中去，此外平台不额外进行商业化运营管理工作；
	用户勾选同意委托平台作品著作权商业化运营管理，作品著作权整体托管服务一旦生效后平台将针对已托管的版权进行一系列的推广工作，包括但不限于自媒体宣传、新媒体宣传、媒体宣传、硬性广告和网络广告宣传工作；平台将会针对用户的著作财产权进行实体商品与虚拟商品的开发；视托管版权性质进行不同方向的运营，包括但不限于文学类、艺术类、影视类的产品开发，在托管期间的商务成本均有平台承担，此外平台对侵权行为不额外进行司法维权工作，由用户自行承担；
	用户勾选同意将作品著作权整体委托平台，则平台将承担作品著作权维权及商业化运营管理的两项服务工作。

	</div>
	<div class="textarea">
	<p>第四条 托管服务流程</p>
	&emsp;&emsp;用户自愿托管→勾选服务项→提交资料→平台审核→签订托管服务协议→开展托管服务
	平台审核周期约为14个工作日，审核结果会以电子邮件（animeboom@sakudora.com）的形式发送至用户在平台登记的电子邮箱中。
	为确保用户真实、自愿的意愿，用户需要签署纸质托管服务协议，签署完成后需通过扫描电子档发送至平台电子邮箱（animeboom@sakudora.com）、邮寄（上海市长宁区虹桥路996弄71号V19室）的形式提交给平台管理公司。
	</div>
  </div>`
		};
	},
	computed: {
		scrollBar() {
			return this.$refs.scrollBar;
		}
	},
	created() {
		this.work_id = this.$route.query?.works_id;
		// if (this.work_id) {
		// 	this.getWorkDetail();
		// 	console.log(this.work_id);
		// }
	},
	mounted() {
		this.$nextTick(() => {
			let scrollBar = this.scrollBar
			let scrollTop, scrollHeight, offsetHeight
			scrollBar.onscroll = () => {
				[scrollTop, scrollHeight, offsetHeight] = [scrollBar.scrollTop, scrollBar.scrollHeight, scrollBar.offsetHeight]
				if (scrollTop >= scrollHeight - offsetHeight) {
					this.isLook = true
				}
				// console.log(this.isLook);
			}
		})
	},

	methods: {
		isSures(value) {
			switch (value) {
				case 0:
					this.isSure = false;
					return false;
				case 3:
					this.isSure = true;
					return false;
			}
		},
		// 提交托管
		async submit() {
			console.log("123");
			let radios = this.radio2 != 0 ? this.radio2 : this.radio1
			console.log(radios);


			if (radios == 0) {
				this.$message.warning("请选择一个托管类型！");
				return false
			}
			if (!this.isLook) {
				// this.$message.warning("请阅读完用户协议再确定托管！");
				this.$message({
					showClose: true,
					message: '请阅读完用户协议再确定托管！',
					type: 'warning',
					offset:300,
				});
				return false
			}
			let res = await this.$netWork.getManangeWork({
				url: "/protection/deposit",
				method: "post",
				data: {
					token: localStorage.getItem("token"),
					works_id: this.work_id.toString(),
					type: radios.toString(),
				},
			});
			if (res.code === 0) {
				this.$message.success(res.msg);
			}
			if (res.code === 201 || res.code === 200) {
				this.$message.warning("用户登录过期");
				this.$router.push("/");
				localStorage.removeItem("token");
				localStorage.removeItem("userInfo");
			}
			this.$router.push({
				path: "/host"
			});
			setTimeout(() => {
				this.$router.push({
					path: "/host"
				});
			}, 1000)
		},


		async getWorkDetail() {
			let res = await this.$netWork.getWorkDetail({
				url: "/works/getDetail",
				method: "post",
				data: {
					work_id: this.work_id,
					page: "1",
					uid: ""
				},
			});
			console.log(res);
			this.workInfo.title = res.title;
			this.workInfo.content = res.detail;

		},
		showBig() {
			this.centerDialogVisible = true
			this.isLook = true;
		},

		// 取消申请版权保护
		cancel() {
			this.$router.push("/manageWork");
			if (this.isCopyRightProtection) {
				return false;
			}
			this.isCopyRightProtection = true;
			this.$message("取消申请版权托管");
			this.isSure = false; //是否同意协议
			this.isLook = false;//是否看完协议
			this.radio1 = "";
			this.radio2 = "";
			this.radio3 = "";
			this.work_reason = ""; //托管理由
		},
	},
};
</script>

<style lang="less" scoped>
.br {
	border-radius: 7px;
}

.color {
	color: #4eb8c2;
}

.bgColor {
	background-color: rgb(38, 40, 44);
}

.flex1 {
	flex: 1;
}

.ishow {
	display: none;
}

.item2_img:hover {
	.ishow {
		display: block;
	}
}

.main_center_fodder_item2 {
	display: flex;
	flex-wrap: wrap;
	padding: 0.5rem;

	.main_border_right1 {
		// padding-right: 1rem;
		border-right: 1px solid rgb(67, 80, 87);
	}

	.main_border_right2 {
		// margin-right: 1rem;
		border-right: 1px solid rgb(67, 80, 87);
	}

	// padding: 14.77px;
	// padding-bottom: 20px;

	.item2_img,
	.item2_add {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		width: 150px;
		height: 150px;
		// margin:14.77px;
		margin: 0.5rem;
		background-color: #222222;
		.br();

		.item2_i {
			width: 100%;
			height: 100%;
			position: absolute;

			.item2_div {
				position: absolute;
				width: 100%;
				height: 100%;
				background: #000;
				opacity: 0.5;

			}

			.z-index {
				position: relative;
				z-index: 50;
			}
		}

		img {
			max-width: 150px;
			max-height: 150px;
			border-radius: 7px;
		}

		i {
			position: absolute;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			text-align: center;
			line-height: 1rem;
			background-color: #4eb8c2;
			color: #000000;
			top: -0.4rem;
			right: -0.4rem;
			cursor: pointer;
			z-index: 50;
		}
	}

	.item2_add {
		position: relative;
		cursor: pointer;

		.item2_add_w {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 50px;
			height: 5px;
			border-radius: 5px;
			background-color: #4eb8c2;
		}

		.item2_add_h {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			width: 5px;
			height: 50px;
			border-radius: 5px;
			background-color: #4eb8c2;
		}
	}
}



// .title {
//   font-size: 25px;
//   font-weight: 700;
//   padding-top: 20px;
//   color: #4eb8c2;
//   text-align: center;
// }


/deep/.el-input--prefix .el-input__inner {
	// width: 100%;
	width: 770px;
	height: 40px;
	margin-left: 15px;
	padding-left: 10px;
	color: #ffffff;
	background: #28262e;
	border-radius: 7px;
	border: none;
}

/deep/.el-input__icon {
	height: 100%;
	width: 0px;
	text-align: center;
	transition: all 0.3s;
	line-height: 40px;
	display: none;
}

/deep/ .el-button--small,
.el-button--small.is-round {
	padding: 7px 10px;
}

/deep/ .el-button--primary {
	background-color: #4eb8c2;
	border-color: #4eb8c2;
	color: #000;
	// font-size: 1rem;
}

/deep/.avatar-uploader .el-upload {
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	padding: 30px;
}



* {
	margin: 0;
	// padding: 0;
}

.scrollBar {
	overflow-y: auto;
}

/*滚动条整体样式*/
.scrollBar::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background: @bgColor;
}

/*滚动条滑块*/
.scrollBar::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: @bgColor;
	border: 2px solid @iptfsColor;
}

/*滚动条轨道*/
.scrollBar::-webkit-scrollbar-track {
	border-radius: 5px;
	background: @iptfsColor;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btn2Color: #c2c2c2;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;

.wrapper {
	display: flex;
	justify-content: space-between;
	margin: 14.77px auto;
	width: 1200px;

	.centerContent {
		width: 996px;
		// height: 736px;
		border-radius: 7px;
		background: @bg1Color;

		.title {
			height: 38px;
			margin: 8px 8px 0px 8px;
			padding-left: 5px;
			line-height: 38px;
			color: @fsColor;
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
			background: @bgColor;
		}

		// 表单
		.formInfo {
			display: flex;
			flex-direction: column;
			margin: 40px 55px 0 55px;

			.item {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 20px;
				color: @fsColor;

				label {
					width: 200px;
					text-align: center;
				}

				.item_radio {
					width: 100%;
					margin-left: 30px;

					label {
						width: 50px;
						text-align: center;
					}
				}
			}

			.workInfo {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				color: @fsColor;
				margin-bottom: 20px;

				label {
					width: 200px;
					text-align: center;
				}

				textarea {
					width: 100%;
					height: 90px;
					margin-left: 30px;
					padding-left: 10px;
					color: @iptfsColor;
					background: @bgColor;
					border-radius: 7px;
					resize: none;
					border: none;

				}
			}
		}

		// 协议
		.agreement {
			height: 200px;
			margin: 30px 55px 0 55px;
			background: @bgColor;
			border-radius: 7px;

			/deep/.el-dialog__wrapper {
				.el-dialog {
					.el-dialog__body {
						.areementContent {
							.useForm {
								// width: 800px;
								// height: 550px;
								background: white;
								margin: 30px auto;
								position: relative;
								border-radius: 10px;

								// text-align: center;
								p {
									padding: 5px 30px;
								}

								.textarea {
									margin-left: 30px;
									font-size: 14px;
									margin-right: 30px;
									margin-top: 15px;

									p {
										padding: 0;
									}
								}
							}



						}
					}
				}
			}

			.item {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 20px;
				color: @fsColor;

				label {
					width: 200px;
					text-align: center;
				}

				.item_radio {
					width: 100%;
					margin-left: 30px;

					label {
						width: 100px;
						text-align: center;
					}
				}
			}

			.scrollBar {
				height: 200px;

				.title {
					color: @iptfsColor;
					font-weight: lighter;
					text-align: center;
				}

				.areementContent {
					color: #FFF;
					margin: 0 16px;





				}
			}

			.fullView {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-top: 6px;
				cursor: pointer;

				span {
					color: @fsColor;
					padding-right: 10px;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}

		// 按钮
		.btnBox {
			margin-top: 130px;
			margin-bottom: 30px;
			display: flex;
			justify-content: center;
			color: @btnfsColor;

			:last-child {
				margin-left: 50px;
			}

			.btn2 {
				width: 116px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				cursor: pointer;
				background: @btn2Color;
				border: none;
				border-radius: 7px;
				pointer-events: none;
			}

			.btn {
				width: 116px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				cursor: pointer;
				background: @btnColor;
				border: none;
				border-radius: 7px;
				pointer-events: auto;
			}


		}
	}
}
</style>
