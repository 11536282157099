<template>
  <div>
    <div class="main_left" ref="main_left" :class="{ float: isfloat }">
      <div class="main_left_1">
        <!-- 头像和呢称 -->
        <div v-if="isLogin" class="main_left_1_photo">
          <img v-if="userInfo ? userInfo.avatar_path : ''" :src="'http://api.aboom.net' + userInfo.avatar_path" alt="" />
          <div class="level" v-if="isLogin">
            <div class="main_left_1_photo_level">LV{{ userInfo.level }}</div>
            <div class="triangle"></div>
          </div>
          <!-- <div class="exit" @click="exitLogin">
						<div class="main_left_2_photo_level">退出</div>
						<div class="triangle2"></div>
					</div> -->
          <!-- <div class="changeAvatar" @click="changeAvatar">更换头像</div> -->
        </div>
        <!-- 未登录 -->
        <div v-else class="main_left_1_photo1" @click="photo"></div>
        <div class="main_left_1_nickname">
          <div class="prompt" v-if="!isLogin">未登录</div>
          <div v-if="userInfo ? userInfo.nickname : ''">
            {{ userInfo.nickname }}
          </div>
          <div class="immediately" @click="login" v-if="!isLogin">登录</div>
          <div class="immediately m-left" @click="toRegister" v-if="!isLogin">
            注册
          </div>
          <!-- <span v-show="userInfo"> <初学者> </span> -->

          <div class="touxiang" v-if="!isLogin">
            <img src="../assets/imgs/icontouxiang2.png" />
          </div>
        </div>
        <div v-if="isLogin" class="money">
          <div>原画币：{{ userInfo.balance }}</div>
          <div @click="myMoney" class="money_button">购买</div>
        </div>
        <!-- 收藏点赞 -->
        <div class="main_left_1_info">
          <div @click="photo" style="cursor: pointer">
            <img src="~@/assets/img/home/icon.png" alt="" title="作品" />
            <span>{{ userInfo ? userInfo.works : 0 }}</span>
          </div>
          <div @click="toAttention" style="cursor: pointer">
            <img src="~@/assets/img/home/fensi.png" alt="" title="粉丝" />
            <span>{{ userInfo ? userInfo.fans : 0 }}</span>
            <!-- <span>{{ length ? length : 0 }}</span> -->
          </div>
          <div @click="collect" style="cursor: pointer">
            <img src="~@/assets/img/home/guanzhu.png" alt="" title="收藏" />
            <span>{{ userInfo ? userInfo.collection : 0 }}</span>
          </div>
        </div>
        <!-- 称号 -->
        <div class="main_left_1_cj">
          <div class="main_left_nav">
            <div class="main_left_1_cj_text" @click="cj">
              <img src="~@/assets/icon/chengjiu.png" alt="" />
              <span class="chengjiu">&nbsp;成就</span>
            </div>
            <div @click="toInfoMessage" class="chengjiu">更多</div>
          </div>
          <div class="main_left_1_cj_icon" v-if="userInfo">
            <div v-for="(item, index) in achievementList" :key="index">
              <img :src="item.file_path" :title="item.acc_name" class="list-img" alt="" />
            </div>
          </div>
        </div>
        <!-- <div class="main_left_1_more">
        <span @click="toInfoMessage">更多</span>
      </div> -->
      </div>
      <!-- 投稿 信箱 收藏 -->
      <div class="main_left_2" ref="main_left_2" style="position: relative">
        <div class="link" @click="contribute" style="height: 48px !important">
          <i>
            <img src="../assets/img/home/tougao.png" alt="" width="100%" />
          </i>
          <span>&nbsp;作品投稿</span>
        </div>
        <div class="link" @click="photo">
          <i>
            <img src="../assets/imgs/zuopingguanli.png" alt="" width="100%" />
          </i>
          <span>&nbsp;作品管理</span>
        </div>
        <div class="link" @click="myEmail">
          <i>
            <img src="../assets/img/home/xinxiang.png" alt="" width="100%" />
          </i>
          <p class="afterLink">
            <span>&nbsp;私信</span>
            <span class="afe_link" v-show="chajuNumber != 0">{{ chajuNumber }}</span>
          </p>
        </div>
        <div class="link" @click="myMoney">
          <i>
            <img src="../assets/img/home/money.png" alt="" width="100%" />
          </i>
          <span>&nbsp;钱包</span>
        </div>
        <div class="link" @click="collect" ref="sc">
          <i>
            <img src="../assets/img/home/shoucang.png" alt="" width="100%" />
          </i>
          <span>&nbsp;收藏</span>
        </div>

        <div class="link exits" style="
            border-top: 1px solid #46b3bd;
            width: 188.3px;
            height: 48px;
            background: #35353f;
            position: absolute;
            bottom: -48px;
            z-index: 999;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
          " @click="exitLogin" ref="exit">
          <i>
            <img src="../assets/imgs/dengchu.png" alt="" width="100%" />
          </i>
          <span>&nbsp;登出</span>
        </div>
      </div>
      <!-- 状态 -->
      <!-- <div class="main_left_3 text">
      <div class="main_left_3_gz">
        <div class="main_left_3_gz_user">
          <img src="../assets/icon/zhuangtai.png" alt="" />
          <span class="chengjiu">&nbsp;状态</span>
        </div>
      </div>
      <div class="progressBar">
        <img src="../assets/icon/exp.png" alt="" />
        <el-progress
          color="#5DE2EC"
          :show-text="false"
          :stroke-width="11"
          :percentage="70"
        ></el-progress>
      </div>
      <div class="progressBar2">
        <img src="../assets/icon/jifen.png" alt="" />
        <el-progress
          color="#5DE2EC"
          :show-text="false"
          :stroke-width="11"
          :percentage="30"
        ></el-progress>
      </div>
      <div class="progressBar">
        <img src="../assets/icon/renqi.png" alt="" />
        <el-progress
          color="#5DE2EC"
          :show-text="false"
          :stroke-width="11"
          :percentage="40"
        ></el-progress>
      </div>
    </div> -->
      <!-- 关注用户 -->
      <div class="main_left_3">
        <div class="main_left_3_gz">
          <div class="main_left_3_gz_user">
            <img src="~@/assets/icon/tuijianyonghu.png" alt="" />
            <span>&nbsp;关注用户</span>
          </div>
          <div @click="toMore" class="chengjiu">更多</div>
        </div>
        <div class="main_left_3_icon" v-if="Attention">
          <div class="main_left_3_icon_item" v-for="item in Attention" :key="item.uid">
            <img @click="otherUser(item.uid)" :src="item.file_path" class="gzyh" />
          </div>
        </div>
        <div class="main_left_3_icon" v-else>
          <span>暂无内容</span>
        </div>
      </div>
      <!-- 推荐用户 -->
      <div class="main_left_4">
        <div class="main_left_3_gz">
          <div class="main_left_3_gz_user">
            <!-- <i></i> -->
            <img src="~@/assets/icon/tuijianyonghu.png" alt="" />
            <span>&nbsp;推荐用户</span>
          </div>
          <div @click="toRecommend" class="chengjiu">更多</div>
        </div>
        <div class="main_left_3_icon" v-if="user.length">
          <div class="main_left_3_icon_item" v-for="item in user" :key="item.id">
            <img @click="otherUser(item.id)" :src="item.avatar_path" class="gzyh" alt="" />
          </div>
        </div>
        <div class="main_left_3_icon" v-else>
          <span>暂无内容</span>
        </div>
      </div>
      <!-- <div class="main_left_5"></div> -->
    </div>
    <div style="width: 189px" v-if="isfloat"></div>
  </div>
</template>

<script>
import { TimeSelect } from "element-ui";

export default {
  name: "commonLeft",
  data() {
    return {
      userIcon: "", // 用户头像
      isLogin: false, // 是否登录
      userInfo: null, // 用户信息
      Attention: null, // 关注列表
      user: [], //推荐用户列表
      length: "",
      isfloat: false,
      scrollTop: 0,
      chajuNumber: 0,
      achievementList: {}, //成就信息
    };
  },
  components: {},
  mounted() {
    this.$bus.$on('ssLoading', (data) => {
      if (data > 99) {
        this.chajuNumber = '99+';
      } else {
        this.chajuNumber = data;
      }
    })
    window.addEventListener("scroll", this.handleScroll, true);
    // console.log(this.$refs.main_left)
    this.$refs.exit.style.display = "none";
    this.$refs.sc.style.border = "none";
    if (localStorage.getItem("token")) {
      this.$refs.main_left_2.addEventListener("mouseenter", this.exit);
      this.$refs.exit.addEventListener("mouseenter", () => {
        this.$refs.exit.style.backgroundColor = "#26272c";
      });
      this.$refs.exit.addEventListener("mouseleave", () => {
        this.$refs.exit.style.backgroundColor = "#35353f";
      });
      this.$refs.main_left_2.addEventListener("mouseleave", this.Oexit);
    }
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener("scroll", this.handleScroll, true);
    this.$bus.$off('ssLoading');
  },
  created() {
    // let a = localStorage.getItem("avatar");
    // console.log(a, "aaaaaa");
    // let a = localStorage.getItem("avatar")
    // console.log(a,'aaaaaa')
    this.getMyAttention();
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (token) {
      this.isLogin = true;
      this.userInfo = userInfo;
      // console.log(this.userInfo.avatar_path, "头像111");
      // this.userInfo.avatar_path = a;
      console.log(this.userInfo, "头像111");
      // this.userInfo.avatar_path = a
    }
    this.getUser();
    this.getAchievementList();
    // this.getAttentionByID()
  },
  activated() {
    this.getMyAttention();
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (token) {
      this.isLogin = true;
      this.userInfo = userInfo;
    }
  },

  methods: {
    //去粉丝页面
    toAttention() {
      this.$router.push({
        path: "/attention",
        query: { uid: this.userInfo?.id, fans: true },
      });
    },
    // async getAttentionByID(){
    // 	let res = await this.$netWork.getAttentionByID({
    // 		url: "/Attention/getAttentionByID",
    // 		method: "post",
    // 		data: {
    // 			uid: this.userInfo.id
    // 		}
    // 	});
    // 	console.log(res);
    // 	// if (res.code == 0) {
    // 	// 	this.achievementList = res.data
    // 	// }
    // },
    async getAchievementList() {
      //成就
      let res = await this.$netWork.getUserAchievementListByID({
        url: "/user/getAchievementListByID",
        method: "post",
        data: {
          uid: this.userInfo?.id,
        },
      });
      if (res.code == 0) {
        this.achievementList = res.data;
      }
    },
    exit() {
      this.$refs.exit.style.display = "flex";
      this.$refs.main_left_2.style.borderBottomRightRadius = "0px";
      this.$refs.main_left_2.style.borderBottomLeftRadius = "0px";

      // border-bottom: 1px solid #46b3bd;border-bottom-right-radius: 7px;
      //border-bottom-left-radius: 7px;
      // this.$refs.sc.style.borderBottom = "1px solid #46b3bd"
    },
    Oexit() {
      this.$refs.exit.style.display = "none";
      this.$refs.sc.style.border = "none";
      this.$refs.main_left_2.style.borderBottomRightRadius = "7px";
      this.$refs.main_left_2.style.borderBottomLeftRadius = "7px";
    },
    handleScroll() {
      return
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop > 215) {
        // console.log("okok")
        this.isfloat = true;
        if (this.scrollTop <= document.body.scrollHeight - this.$refs.main_left.scrollHeight - 104) {
          this.$refs.main_left.style.top = this.scrollTop + "px";
        }
        // if((document.body.scrollHeight - this.$refs.main_left.scrollHeight- this.$refs.main_left.offsetTop>=104)){

        // 	this.$refs.main_left.style.top = this.scrollTop+"px"
        // }
      } else {
        this.isfloat = false;
      }
    },
    getOffsetTop() {
      console.log("网页被卷起来的高度" + document.documentElement.scrollTop);
      console.log("视口高度" + window.innerHeight);
      console.log("文档高度" + document.body.scrollHeight);
      console.log("元素距离文档顶部高度" + this.$refs.main_left.offsetTop);
      console.log("自己的高度" + this.$refs.main_left.scrollHeight);
      console.log(
        "元素距离文档底部高度" +
        (document.body.scrollHeight -
          this.$refs.main_left.scrollHeight -
          this.$refs.main_left.offsetTop)
      );
      console.log(
        document.body.scrollHeight - this.$refs.main_left.scrollHeight - 104
      );
    },

    //获取推荐用户
    async getUser() {
      let res = await this.$netWork.getUser({
        url: "/user/getUser",
        method: "post",
        data: {
          id: this.userInfo?.id,
        },
      });
      if (res.code == 0) {
        this.user = res.data.filter((item) => {
          return item.id != null;
        });
      }
    },
    //去关注用户的主页
    otherUser(uid) {
      let abcd = this.$router.resolve({
        name: "otherUserProfile",
        query: {
          uid: uid,
        },
      });
      window.open(abcd.href, '_blank')
    },
    //退出登录
    exitLogin() {
      this.$confirm("确定退出登录吗？", "友情提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(() => {
          if (localStorage.getItem("token")) {
            this.$message.success("退出成功.");
            localStorage.removeItem("token");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("avatar");
            localStorage.removeItem("loginTime");
            // this.$router.push({path:"/"})
            window.location.reload();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //跳转上传头像
    // changeAvatar() {
    // 	// console.log(11111)
    // 	this.$router.push({
    // 		path: "/avatar"
    // 	});
    // },
    //头像
    // Avatar(){
    //   let avatar = localStorage.getItem("avatar")
    //   let avatarPath = localStorage.getItem("userInfo.avatar_path")
    //   avatarPath = avatar
    //   console.log(avatar,'avataravatar')
    // },
    //关注用户
    toMore() {
      if (!this.$isToken()) {
        return;
      }
      let abcd = this.$router.resolve({
        path: "/attention",
        query: { uid: this.userInfo?.id },
      });
      window.open(abcd.href, '_blank')
    },
    //处理图片url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    cj() {
      this.$router.push({
        path: "./designation",
      });
    },
    photo() {
      if (this.$isToken())
        this.$router.push({
          path: "./userProfile",
        });
      // this.$router.push({ path: "/infoMessage" });
    },
    //登录
    login() {
      if (this.isLogin) {
        return false;
      }
      this.$router.push("/login");
    },
    //到注册
    toRegister() {
      this.$router.push({ path: "/register" });
    },
    async getMyAttention() {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const res = await this.$netWork.getMyAttention({
        method: "post",
        url: "/Attention/getMyAttention",
        data: {
          token: localStorage.getItem("token"),
        },
      });
      if (res.code == 0) {
        const datalist = res.data.filter((item) => item.uid != userInfo?.id);
        this.Attention = datalist;
        this.length = res.data.length;
        console.log("关注用户home", datalist);
      }
    },
    //投稿
    contribute() {
      if (this.$isToken("投稿"))
        this.$router.push({
          path: "/contribute",
        });
    },
    //信箱
    myEmail() {
      if (this.$isToken("私信"))
        this.$router.push({
          path: "/myEmail",
        });
    },
    // 钱包
    myMoney() {
      if (this.$isToken('钱包')) {
        sessionStorage.setItem('route', '个人中心')
        this.$router.push({
          path: '/money'
        })
      }
    },
    //收藏
    collect() {
      if (this.$isToken("收藏"))
        this.$router.push({
          path: "/collect",
          query: { uid: this.userInfo?.id },
        });
    },
    //去推荐用户页面
    toRecommend() {
      if (!this.$isToken()) {
        return;
      }
      let abcd = this.$router.resolve({
        path: "/recommend",
      });
      window.open(abcd.href, '_blank')
    },
    //去个人信息页面
    toInfoMessage() {
      if (this.$route.path == "/infoMessage") {
        return false;
      }
      if (this.$isToken())
        this.$router.push({
          path: "/infoMessage",
        });
    },
  },
};
</script>

<style scoped lang="less">
.float {
  position: absolute;
  // top: 366px;
}

.touxiang {
  img {
    width: 173px;
    height: 158px;
    object-fit: cover;
  }

  width: 173px;
  height: 188px;
  // background: rgb(116, 113, 113);
  // background: url(../../assets/imgs/icontouxiang.png);
  position: absolute;
  // border-radius: 55px;
  z-index: 99;
  margin-top: -199px;
  margin-left: -86px;
}

.immediately {
  position: absolute;
  z-index: 999;
  width: 68px;
  height: 23px;
  // left: 58px;
  background: #46b3bd;
  border: 1px solid #46b3bd;
  color: black;
  margin-left: -81px;
  text-align: center;
  line-height: 23px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: -11px;
}

.m-left {
  margin-left: 12px;
  background: none;
  color: #fff;
  border: 1px solid #fff;
}

.prompt {
  font-size: 15px;
  color: #35353f;
  width: 50px;
  height: 20px;
  position: absolute;
  margin-top: -181px;
  margin-left: 38px;
  z-index: 999;
  font-weight: 600;
}

.changeAvatar {
  width: 65px;
  height: 20px;
  line-height: 20px;
  background-color: orangered;
  color: white;
  text-align: center;
  margin-top: -30px;
  font-size: 8px;
  z-index: 999;
  position: absolute;
  font-weight: lighter;
  margin-left: 105px;
  border-radius: 5px;
  opacity: 0.8;
  cursor: pointer;
}

.exit {
  width: 60px;
  text-align: center;
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  color: white;
  position: absolute;
  margin-top: -192px;
  z-index: 999;
  left: 0;
  overflow: hidden;
  border-top-left-radius: 7px;
  opacity: 0.9;
  cursor: pointer;

  .main_left_2_photo_level {
    text-align: center;
    font-size: 12px;
    line-height: 26px;
    background-color: #494c4c;
    width: 40px;
  }

  .triangle2 {
    position: absolute;
    top: -26px;
    left: 40px;
    border-left: 20px solid #494c4c;
    border-right: 20px solid transparent;
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    z-index: 9;
  }
}

.chengjiu {
  cursor: pointer;
}

.main_left_1_photo {
  img {
    border-radius: 7px;
  }
}

.main_left_1_photo1 {
  position: relative;
  border-radius: 7px;
  margin-top: 8px;
  width: 173px;
  height: 188px;
  background-color: rgb(50, 52, 51);
}

.main_left_1_info {
  width: 100%;
  margin-top: 14.77px;
  display: flex;
  height: 52px;

  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #4eb8c2;
    font-size: 12px;

    img {
      width: 15.69px;
      height: 17px;
    }
  }

  .text {
    text-align: left !important;
  }

  div:not(:last-child) {
    border-right: 1px solid rgb(67, 80, 87);
  }
}

.main_left_1_cj {
  width: 100%;
  padding: 0 8px;
  margin-top: 10px;
  box-sizing: border-box;

  .main_left_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    color: #4eb8c2;
    background: rgb(38, 39, 44);

    .main_left_1_cj_text {
      display: flex;
      align-items: center;
      height: 26px;
      color: #4eb8c2;
      font-size: 12px;
      line-height: 24px;
      box-sizing: border-box;
      background-color: rgb(38, 39, 44);

      img {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 16px;
      }
    }
  }

  .main_left_1_cj_icon {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;

    .list-img {
      min-width: 20px;
      height: 22px;
      margin-left: 5px;
      // background: url("~@/assets/imgs/icon.png") no-repeat center/contain;
    }
  }
}

.main_left_1_more {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0 8px 8px 0;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 14px;

  span {
    color: #4eb8c2;
  }
}

.gzyh {
  width: 40px;
  height: 40px;
}

.main_left_2 {
  width: 188.31px;
  margin-top: 14.77px;
  border-radius: 7px;
  background: #35353f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #4eb8c2;

  .exits:hover {
    background: #26272c;
  }

  :nth-child(1) {
    &:hover {
      background: #26272c;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }
  }

  :nth-child(2) {
    &:hover {
      background: #26272c;
    }
  }

  :nth-child(3) {
    &:hover {
      background: #26272c;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }

  :nth-child(4) {
    &:hover {
      background: #26272c;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }

  :nth-child(5) {
    &:hover {
      background: #26272c;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }

  &>div {
    width: 100%;
    height: 48px;
    line-height: 48px;
    flex: 1;
    display: flex;
    align-items: center;
    // padding-left: 50px;
    justify-content: center;
    // box-sizing: border-box;

    i {
      width: 18px;
      height: 42px;
    }
  }

  >div:not(:last-child) {
    border-bottom: 1px solid #46b3bd;
  }
}

.exits:hover {
  background: #26272c;
  // border-bottom-left-radius: 7px;
  // border-bottom-right-radius: 7px;
}

.main_left_3,
.main_left_4 {
  width: 175px;
  min-height: 50px;
  border-radius: 7px;
  margin-top: 14.77px;
  padding: 7px;
  background: #35353f;

  .main_left_3_gz {
    display: flex;
    flex: 1;
    align-items: center;
    height: 25px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-color: rgb(38, 39, 44);
    font-size: 14px;
    justify-content: space-between;
    padding: 0 5px;
    color: #4eb8c2;

    &>.main_left_3_gz_user {
      display: flex;
      align-items: center;

      i {
        min-width: 16.62px;
        height: 17px;
        background: #d8d8d8;
      }

      img {
        width: 14px;
        height: 14px;
      }
    }
  }

  .main_left_3_icon {
    margin-top: 7px;
    display: flex;
    flex-wrap: wrap;

    .main_left_3_icon_item {
      min-width: 40.62px;
      height: 44px;
      cursor: pointer;
      margin: 0px 0 0 3px;
    }

    span {
      width: 100%;
      color: #46b3bd;
      text-align: center;
    }
  }
}

.main_left_5 {
  margin-top: 14.77px;
  width: 188.31px;
  height: 381px;
  border-radius: 7px;
  // background: url("~@/assets/imgs/guanggao.png") no-repeat center/cover;
}

.level {
  position: absolute;
  top: 0;
  right: 0;
  width: 75px;
  height: 26px;
  overflow: hidden;

  .main_left_1_photo_level {
    position: absolute;
    right: 0;
    top: 0;
    width: 29px;
    height: 26px;
    line-height: 26px;
    padding-right: 5px;
    font-weight: bold;
    background-color: #494c4c;
    color: #4eb8c2;
    border-top-right-radius: 7px;
    opacity: 0.9;
    z-index: 999;
  }

  .triangle {
    position: absolute;
    top: -26px;
    right: 34px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid #494c4c;
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    opacity: 0.9;
    z-index: 9;
  }
}

// @media screen and (max-width: 1700px) {
.main_left {
  // position: fixed;
  width: 189px;
  border-radius: 7px;
  position: sticky;
  top: 0;
  .main_left_1 {
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 355px;
    background-color: rgb(55, 52, 67);

    .main_left_1_photo {
      position: relative;
      border-radius: 7px;
      margin-top: 8px;
      width: 173px;
      height: 188px;
      background: no-repeat center/contain;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .main_left_1_nickname {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      text-align: center;
      font-size: 22px;
      color: white;
      // width: 173px;
      // height: 45px;

      div {
        overflow: hidden;
        max-width: 173px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

// }

// @media screen and (min-width: 1700px) {
//   .main_left {
//     width: 189px;
//     border-radius: 7px;
//     .main_left_1 {
//       border-radius: 7px;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       width: 100%;
//       min-height: 428px;
//       background-color: rgb(55, 52, 67);
//       .main_left_1_photo {
//         position: relative;
//         border-radius: 7px;
//         margin-top: 8px;
//         width: 173px;
//         height: 188px;
//         background: no-repeat center/contain;
//         img {
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//           object-position: center;
//         }
//       }
//       .main_left_1_nickname {
//         cursor: pointer;
//         display: flex;
//         flex-direction: column;
//         padding-top: 10px;
//         text-align: center;
//         font-size: 40px;
//         color: white;
//         div {
//           overflow: hidden;
//           max-width: 133px;
//           text-overflow: ellipsis;
//           white-space: nowrap;
//         }
//       }
//     }
//   }
// }

.progressBar img {
  float: left;
  width: 20px;
  padding-right: 10px;
}

.progressBar2 img {
  float: left;
  width: 16px;
  padding-right: 13px;
}

/deep/.el-progress-bar__outer {
  background-color: #26272c;
}

/deep/.el-progress-bar__outer {
  border-radius: 2px;
}

/deep/.el-progress-bar__inner {
  border-radius: 2px;
}

/deep/.el-progress--without-text .el-progress-bar {
  margin: 15px 0;
  padding-top: 2px;
}

.money {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-top: 13px;
  color: #46b3bd;
  font-size: 14px;

  &_button {
    width: 59px;
    height: 23px;
    border-radius: 5px;
    border: 1px solid #4eb8c2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.afterLink {
  position: relative;

  .afe_link {
    position: absolute;
    right: -45px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: white;
    background: red !important;
    display: block;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
  }
}
</style>
