<template>
  <div class="main">
    <div class="main_center">
      <!-- 顶部 -->
      <div class="center_banner">
        <!-- <div class="banner_banner"></div> -->
        <div class="banner_desc">
          <span>{{ data.title }}</span>
          <div v-html="data.detail"></div>
          <!-- <img :src="getUrl(data.file_path)"> -->
          <p>活动标签：{{ data.tag_name }}></p>
        </div>
      </div>
      <!-- 投稿 -->
      <div class="center_contribute">
        <div class="contribute_title">大家的投稿</div>
        <div class="contribute_items">
          <div class="items_item" v-for="(item, index) in activity" :key="index" @click="goWorkDetail(item)">
            <div class="item_img">
              <img :src="item.coverimg" :title="item.title" alt="" />
            </div>
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
      <!-- 浏览 -->
      <div class="center_msg">
        <!--  <div class="msg_title">大家的留言</div>
        <div class="msg_allmsg">
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu1.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu2.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu1.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu2.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu5.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu4.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu1.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu2.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu5.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu4.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu1.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu2.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu5.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
          <div class="allmsg_item">
            <img src="~@/assets/img/home/yonghu4.png" alt="" />
            <div class="item_desc">
              <div class="desc_userName">吴彦祖 5小时前</div>
              <div class="desc_msg">
                <span>大大我来了 占楼</span>
                <span>回复</span>
              </div>
            </div>
          </div>
        </div>-->
        <!-- 发送消息 -->
        <!-- <div class="msg_sendmsg">
          <div class="sendmsg_input"><input type="text" /></div>
          <div class="sendmsg_btn">发送</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "zuan2",
  data() {
    return {
      allWorksList: [], //全部作品
      activity: [], // 专题活动
      data: "",
    };
  },
  mounted() {
    this.data = JSON.parse(this.$route.query.data);
    console.log(this.data);
    this.getActivity();
    // this.getAllWorks();
  },
  methods: {
    // 获取全部作品
    // async getAllWorks() {
    //   let res = await this.$netWork.getAllWorks({
    //     url: "/works/getWorksList",
    //     method: "post",
    //     data: {
    //       type: "new",
    //       page: "1",
    //       works_type:"所有"
    //     },
    //   });
    //   console.log(res,'获取全部作品')
    //   if (res.code === 0) {
    //     this.allWorksList = res.data.list;
    //   }
    // },
    async getActivity() {
      // 专题活动
      const activity = await this.$netWork.getActivity({
        method: "post",
        url: "/works/getWorksList",
        data: {
          type: "tag",
          page: 1,
          works_type: "所有",
          tag: this.data.tag_id + "",
        },
      });
      this.activity = activity.data.list;
      console.log(activity, "活动活动");
    },

    //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    // 去作品详情1
    goWorkDetail(data) {
      let url = this.$router.resolve({
        name: "workDetail1",
        query: {
          work_id: data.id,
          uid: data.uid,
        },
      });
      window.open(url.href,'_blank')

    },
  },
};
</script>
<style lang="less" scoped>
.main {
  margin-top: 14.77px;
}

.center_msg,
.main_center {
  border-radius: 7px;
}

.main_center {
  color: #4eb8c2;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: #34373f !important;
  max-height: max-content;
}

.main_center .center_banner {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.main_center .center_banner .banner_banner {
  width: 100%;
  height: 200px;
  border-radius: 7px;
  background: url(~@/assets/img/home/zhuantidatu.png) no-repeat center / cover;
}

.main_center .center_banner .banner_desc {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 50px 0 50px;
  // height: 90px;
  border-radius: 5px;
  background-color: #28262e;
  padding: 10px;
  font-size: 14px;
}

.main_center .center_banner .banner_desc p {
  flex: 1;
  padding: 10px;
  width: 100%;
  text-align: end;
  font-size: 1rem;
}

.main_center .center_banner .banner_desc span {
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 0 0 20px;
  font-size: 1.5rem;
}

// .main_center .center_banner .banner_desc img {
// 	padding: 10px;
// 	max-width: 300px;
// 	max-height: 200px;
// }

.main_center .center_contribute {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
}

.main_center .center_contribute .contribute_title {
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  background-color: #28262e;
}

.main_center .center_contribute .contribute_items {
  max-width: 1200px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 10px;
  font-size: 14px;
}

.main_center .center_contribute .contribute_items .items_item {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-right: 30px;
}

.main_center .center_contribute .contribute_items .items_item .item_img {
  // height: 170px;
  cursor: pointer;
}

.main_center .center_contribute .contribute_items .items_item img {
  width: 200px;
  height: 200px;
  cursor: pointer;
  object-fit: cover;
  // max-height: 170px;
}

.main_center .center_msg {
  margin-top: 50px;
  padding: 15px;
}

.main_center .center_msg .msg_title {
  line-height: 30px;
  background-color: #28262e;
  text-align: center;
  font-size: 14px;
}

.main_center .center_msg .msg_allmsg {
  margin-top: 15px;
  height: 650px;
  overflow-y: auto;
  padding-right: 30px;
}

.main_center .center_msg .msg_allmsg::-webkit-scrollbar {
  width: 15px;
}

.main_center .center_msg .msg_allmsg::-webkit-scrollbar-track {
  background: #353641;
  border-radius: 2px;
  border: 1px solid #4eb8c2;
}

.main_center .center_msg .msg_allmsg::-webkit-scrollbar-thumb {
  background: #4eb8c2;
  border-radius: 2px;
}

.main_center .center_msg .msg_allmsg .allmsg_item {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #344249;
}

.main_center .center_msg .msg_allmsg .allmsg_item .item_desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-left: 15px;
}

.main_center .center_msg .msg_allmsg .allmsg_item .item_desc .desc_msg {
  color: white;
  display: flex;
  justify-content: space-between;
}

.main_center .center_msg .msg_allmsg .allmsg_item .item_desc .desc_msg span:last-child {
  color: #4eb8c2;
}

.main_center .center_msg .msg_allmsg .allmsg_item:not(:first-child) {
  margin-top: 15px;
}

.main_center .center_msg .msg_sendmsg {
  margin-top: 40px;
  display: flex;
}

.main_center .center_msg .msg_sendmsg .sendmsg_input {
  flex: 1;
  margin-right: 70px;
}

.main_center .center_msg .msg_sendmsg .sendmsg_input input {
  padding: 0 15px;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #28262e;
  box-sizing: border-box;
  color: #4eb8c2;
}

.main_center .center_msg .msg_sendmsg .sendmsg_btn {
  font-size: 14px;
  width: 80px;
  line-height: 30px;
  text-align: center;
  color: black;
  border-radius: 5px;
  background-color: #4eb8c2;
}
</style>
