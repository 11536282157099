import { render, staticRenderFns } from "./zuanti.vue?vue&type=template&id=032b3420&scoped=true&"
import script from "./zuanti.vue?vue&type=script&lang=js&"
export * from "./zuanti.vue?vue&type=script&lang=js&"
import style0 from "./zuanti.vue?vue&type=style&index=0&id=032b3420&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "032b3420",
  null
  
)

export default component.exports