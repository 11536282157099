<template>
  <!-- 中间内容 -->
  <div class="content_middle">
    <common-left1></common-left1>
    <!-- 右边 -->
    <div class="content_middleR">
      <div class="frame">
        <div class="BiaoTi">任务列表</div>
        <div class="list-frame test test-1">
          <div class="list" v-for="l in list" :key="l.index">
            <img :src="l.url" class="list-img" alt="" />
            <div class="list-layout">
              <div class="list-name">{{ l.name }}</div>
              <div class="list-explain">{{ l.explain }}</div>
            </div>
            <div class="DeDao">{{ l.finish }}</div>
          </div>

          <div class="scrollbar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonLeft1 from "../../components/common_left1.vue";
export default {
  name: "task",
  components: { commonLeft1 },
  data() {
    return {
      list: [
        {
          url: require("../../assets/img/task/renwu1.png"),
          name: "第一次投稿！",
          explain: "用户第一次在任何板块投稿成功",
          finish: "完成！",
        },
        {
          url: require("../../assets/img/task/renwu2.png"),
          name: "博爱",
          explain: "用户关注数量达到100人",
          finish: "完成！",
        },
        {
          url: require("../../assets/img/task/renwu3.png"),
          name: "好习惯！",
          explain: "连续7天登录网站",
          finish: "完成！",
        },
        {
          url: require("../../assets/img/task/renwu4.png"),
          name: "持之以恒",
          explain: "连续30天登录网站",
          finish: "50%",
        },
      ],
    };
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.content_middle {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
}

/* 右边 */
.content_middleR {
  display: flex;
  flex-direction: column;
  width: 996px;
}
/* 标题 */
.frame {
  /* flex: 1; */
  background-color: #353641;
  border-radius: 0.4rem;
  padding: 8px 8px 2rem 8px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
  height: 736px;
}
.BiaoTi {
  color: #4db9c2;
  background-color: #26272e;
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.4rem;
}
.list-frame {
  box-sizing: border-box;
  overflow: hidden;
  /* overflow-y: visible; */
  /* height: 12rem; */
  min-height: 680px;
}
.list {
  display: flex;
  background-color: #26282c;
  margin: 1rem 2rem;
  padding: 1rem;
  border-radius: 0.2rem;
}

.list-img {
  width: 4rem;
  /* height: 3rem; */
  /* border-radius: 50%; */
  /* background-color: red; */
  margin-right: 1rem;
}

.list-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}

.list-name {
  color: #4db9c2;
  font-size: 14px;
}

.list-explain {
  color: #cdced4;
  font-size: 14px;
}

.DeDao {
  color: #4db9c2;
  font-size: 14px;
  margin-top: 1rem;
}
/* 滚动条 */
.test {
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  height: 100px;
  overflow: auto;
  border: none;
}

.scrollbar {
  height: 736px;
  margin: 0 auto;
}

.test-1::-webkit-scrollbar {
  /*滚动条整体样式*/
  /* width: 10px; */
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  border-radius: 0.2rem;
}

.test-1::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  background: #4bbac0;
  border-radius: 0.2rem;
}

.test-1::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border: 1px solid #4bbac0;
  border-radius: 0.2rem;
}
</style>