import instance from "./index";
const netWork = {
	// 首页公告
	getNoticelist(config) {
		return instance(config);
	},
	//登录
	login(config) {
		return instance(config);
	},
	//注册
	register(config) {
		return instance(config)
	},
	//上传头像
	uploadAvatar(config) {
		return instance(config)
	},
	//获取短信验证码
	getMac(config) {
		return instance(config)
	},
	//获取用户信息
	getUserInfo(config) {
		return instance(config);
	},
	// 首页轮播图
	getSlide(config) {
		return instance(config);
	},
	// 专题活动
	getActivity(config) {
		return instance(config);
	},
	// 获取标签
	getTag(config) {
		return instance(config);
	},
	// 最新投稿
	getWorksList(config) {
		return instance(config);
	},
	// 用户关注列表
	getMyAttention(config) {
		return instance(config);
	},

	//忘记密码
	forgotPassword(config) {
		return instance(config)
	},
	// 搜索
	getSearchInfo(config) {
		return instance(config)
	},
	// 友情链接
	getLink(config) {
		return instance(config)
	},
	// 法律
	getLaw(config) {
		return instance(config)
	},
	// 联系我们
	getConcatUS(config) {
		return instance(config)
	},
	// 帮助中心
	getHelpCenter(config) {
		return instance(config)
	},
	getAbout(config){
		return instance(config)
	},
	//意见反馈
	getFeedBack(config) {
		return instance(config)
	},
	// 公告详情
	getnoticeDetails(config) {
		return instance(config)
	},
	// 点击标签
	getTagContent(config) {
		return instance(config)
	},
	//本日排行榜
	getThisDayList(config) {
		return instance(config)
	},
	//本周排行榜
	getThisWeekList(config) {
		return instance(config)
	},
	//本月排行榜
	getThisMonthList(config) {
		return instance(config)
	},
	//根据作评id获取评论
	getCommentByWorks(config) {
		return instance(config)
	},
	// 获取本人作品目录
	getUserWorkList(config) {
		return instance(config)
	},
	// 申请版权托管全部作品
	getCopyrightMangerAllWorks(config) {
		return instance(config)
	},
	// 申请版权保护
	getCopyRightProtection(config) {
		return instance(config)
	},
	// 版权查询
	getCopyrightQuery(config) {
		return instance(config)
	},
	// 获取版权保护列表
	getCopyRightProtectionWorks(config) {
		return instance(config)
	},
	// 托管
	getManangeWork(config) {
		return instance(config)
	},
	// 获取作品详情
	getWorkDetail(config) {
		return instance(config)
	},
	// 收藏作品
	getCollectOrCancelWork(config) {
		return instance(config)
	},
	// 获取用户收藏作品
	getUserCollectWorks(config) {
		return instance(config)
	},
	// 获取作品评论
	getCommentWorks(config) {
		return instance(config)
	},
	// 作品评论
	commentWorks(config) {
		return instance(config)
	},
	// 根据评论ID和作品ID获取评论
	getCommentByCidAndWid(config) {
		return instance(config)
	},
	// 获取用户对作品收藏点赞信息
	getDetail(config) {
		return instance(config)
	},
	//点赞
	like(config) {
		return instance(config)
	},
	// 上传图片
	uploadImg(config) {
		return instance(config)
	},
	// 上传作品
	uploadWorks(config) {
		return instance(config)
	},
	// 获取全部作品
	getAllWorks(config) {
		return instance(config)
	},
	// 获取日、周、月排行榜
	getTodayOrWeekOrMonthRankingList(config) {
		return instance(config)
	},
	// 评分
	score(config) {
		return instance(config)
	},
	//关注
	attention(config) {
		return instance(config)
	},
	//获取标签id
	getLabelId(config) {
		return instance(config)
	},
	//修改自我介绍
	descChange(config) {
		return instance(config)
	},
	//提交实名认证
	realName(config) {
		return instance(config)
	},
	//修改实名认证
	reviseName(config) {
		return instance(config)
	},
	//验证验证码
	VerifyCode(config) {
		return instance(config)
	},
	//获取实名信息
	getIdentificationData(config) {
		return instance(config)
	},
	//获取推荐用户
	getUser(config) {
		return instance(config)
	},
	//获取自己的标签列表
	getMyTag(config) {
		return instance(config)
	},
	//通过标签id获取自己对应的作品列表
	getMyWorksListByTagId(config) {
		return instance(config)
	},
	//获取聊天列表
	getMessageList(config) {
		return instance(config)
	},
	//修改个人中心名称
	setUserName(config) {
		return instance(config)
	},
	//获取聊天详情
	getMessageDetail(config) {
		return instance(config)
	},
	//根据userid获取用户信息
	getUserInfoById(config) {
		return instance(config)
	},
	//发送私信
	sendMessage(config) {
		return instance(config)
	},
	//获取用户成就
	getUserAchievementListByID(config) {
		return instance(config)
	},
	//获取所有成就
	getCjList(config){
		return instance(config)
	},
	//根据id获取关注列表
	getAttentionByID(config){
		return instance(config)
	},
	//获取自己的粉丝
	getUserFans(config){
		return instance(config)
	},
	// 获取充值金额列表
	getRechargeList(config){
		return instance(config)
	},
	// 获取充值二维码
	getRechargeQR(config){
		return instance(config)
	},
	// 获取交易记录
	getTransactionRecords(config){
		return instance(config)
	},
      // 版权保护价格
	getDCIPrice(config){
		return instance(config)
	},
	// 询共同创作人并检查是否已注册
	getsearchUser(config){
		return instance(config)
	},
	// 查询是否支付
	checkOrderStatus(config){
		return instance(config)
	},
	// 删除作品
	closeMyzp(config){
		return instance(config)
	},
	// 查询作品是否已申请版权保护
	getMyzp(config){
		return instance(config)
	},
	// 查询dci申领详情
	getDciInfo(config){
		return instance(config)
	}
};
export default netWork;
