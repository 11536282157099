<template>
	<div class="wrapper">
		<h6 class="title" v-if="query=='所有'">最新投稿列表</h6>
		<h6 class="title" v-if="query=='漫画'">漫画邦投稿列表</h6>
		<h6 class="title" v-if="query=='原画'">绘画邦投稿列表</h6>
		<!-- nav -->
		<ul class="nav" v-if="query == '所有'">
			<li :class="{ active: route == '所有' }" @click="changeRoute('所有')" style="cursor: pointer">
				<p>所有</p>
				<span class="triangle"></span>
			</li>
			<li :class="{ active: route == '漫画' }" @click="changeRoute('漫画')" style="cursor: pointer">
				<p>漫画</p>
				<span class="triangle"></span>
			</li>
			<li :class="{ active: route == '原画' }" @click="changeRoute('原画')" style="cursor: pointer">
				<p>绘画</p>
				<span class="triangle"></span>
			</li>
		</ul>
		<div class="contribution">
			<!-- 作品 -->
			<ul class="works" v-if="this.$route.query.type=='所有'">
				<li class="item" v-for="item in worksListAll" :key="item.id">
					<a @click="goWorkDetail(item)" style="cursor: pointer">
						<img :src="item.fData[0].file_path" alt="" :title="item.title" />
						<span class="name">{{ item.title }}</span>
					</a>
				</li>
			</ul>
			<ul class="works" v-if="this.$route.query.type=='漫画'">
				<li class="item" v-for="item in comicListAll" :key="item.id">
					<a @click="goWorkDetail(item)" style="cursor: pointer">
						<img :src="item.fData[0].file_path" alt="" :title="item.title" />
						<span class="name">{{ item.title }}</span>
					</a>
				</li>
			</ul>
			<ul class="works" v-if="this.$route.query.type=='原画'">
				<li class="item" v-for="item in originalListAll" :key="item.id">
					<a @click="goWorkDetail(item)" style="cursor: pointer">
						<img :src="item.fData[0].file_path" alt="" :title="item.title" />
						<span class="name">{{ item.title }}</span>
					</a>
				</li>
			</ul>
			<!-- 分页 -->
			<!-- <ul v-if="totalPage > 1" class="pages bottomPages">
        <li class="prev" v-if="totalPage > 1" @click="prev"></li>
        <li
          v-for="item in totalPage"
          :key="item"
          :class="{ active: item == currPage }"
          @click="refash(item)"
        >
          {{ item }}
        </li>
        <li class="next" @click="next" v-if="totalPage > 1"></li>
      </ul> -->
		</div>
	</div>
</template>
<script>
	let timer = null; //滚动条定时器
	let time = null;//提示定时器
	export default {
		name: "newContribution",
		data() {
			return {
				isActive: true,
				// number : 0,
				worksList: [], //作品列表
				worksListAll: [], //作品所有列表
				comicListAll: [], //作品漫画列表
				originalListAll: [], //作品原画列表
				totalPage: "",
				currPage: "1",
				currPage1: "1",
				currPage2: "1",
				currPage3: "1",
				route: "", //页面
				query:'',
			};
		},
		created() {
			// nav 样式
			//   sessionStorage.setItem("route",'所有')
			sessionStorage.setItem("route", this.$route.query.type);
			this.route = sessionStorage.getItem("route");
			this.query = sessionStorage.getItem("route");
		},
		mounted() {
			this.getNewWorks();
			//创建滚动条监听
			window.addEventListener("scroll", this.scrollFn)
			console.log("hello");
		},
		destroyed() {
			window.removeEventListener("scroll", this.scrollFn)
		},
		methods: {
			//监听滚动条，预加载图片
			scrollFn() {
				//获取滚动时的高度window-HTML
				let st = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				//浏览器可视高度window（固定）
				let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body
					.clientHeight;
				//文档高度HTML（不固定）
				let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

				if (timer) {
					clearTimeout(timer)
				}
				timer = setTimeout(() => {
					if (st + windowHeight >= scrollHeight) {
						this.getlist();
					}
					if(this.currPage1 == "2" || this.currPage2 == "2" || this.currPage3 == "2"){
						// document.documentElement.scrollTop=st-10
					}
				}, 300)
			},
			getlist() {
				if (this.worksList.length > 0) {
					if (this.$route.query.type == "所有" && this.currPage1 <= this.totalPage) {
						this.currPage1++;
						this.currPage = this.currPage1;
						this.getNewWorks();
						if (this.worksList != this.worksListAll) {
							this.worksList.forEach(data => {
								this.worksListAll.push(data)
							})
						}
					}
					if (this.$route.query.type == "漫画" && this.currPage2 <= this.totalPage) {
						this.currPage2++;
						this.currPage = this.currPage2;
						this.getNewWorks();
						if (this.worksList != this.comicListAll) {
							this.worksList.forEach(data => {
								this.comicListAll.push(data)
							})
							}
					}
					if (this.$route.query.type == "原画" && this.currPage3 <= this.totalPage) {
						this.currPage3++;
						this.currPage = this.currPage3;
						this.getNewWorks();
						if (this.worksList != this.originalListAll) {
							this.worksList.forEach(data => {
								this.originalListAll.push(data)
							})
						}
					}
					if ((this.$route.query.type == "所有" && this.currPage1 > this.totalPage) ||
						(this.$route.query.type == "漫画" && this.currPage2 > this.totalPage) ||
						(this.$route.query.type == "原画" && this.currPage3 > this.totalPage)) {
						if (time) {
							clearTimeout(time)
						}
						time = setTimeout(() => {
							this.$message({
								message:'已经到底了',
								duration:1000
							});
						}, 500)
					}
				}
			},
			changeRoute(value) {
				let route = (this.route = value);
				// console.log(route);
				sessionStorage.setItem("route", route);
				//导航跳转
				let routePath = "/newContribution";
				this.$router.push({
					path: routePath,
					query: {
						type: route
					}
				});
				console.log(this.route);
				this.getNewWorks();
			},
			// refash(item) {
			//   this.currPage = item;
			//   this.getNewWorks();
			// },
			// //上一页
			// prev() {
			//   if (this.currPage == 1) {
			//     this.$message.error("已经是第一页了");
			//     return false;
			//   }
			//   this.currPage--;
			//   this.getNewWorks();
			// },
			// //下一页
			// next() {
			//   if (this.currPage >= this.totalPage) {
			//     this.$message.error("已经是最后一页了");
			//     return false;
			//   }
			//   this.currPage++;
			//   this.getNewWorks();
			// },
			// 获取最新投稿
			async getNewWorks() {
				if ((this.$route.query.type == "所有" && this.currPage1 == 1) ||
					(this.$route.query.type == "漫画" && this.currPage2 == 1) ||
					(this.$route.query.type == "原画" && this.currPage3 == 1)) {
					this.currPage = "1"
				}
				let res = await this.$netWork.getWorksList({
					url: "/works/getWorksList",
					method: "post",
					data: {
						type: "new",
						page: "1",
						page: this.currPage,
						works_type:this.$route.query.type|| "所有",
					},
				});
				this.totalPage = res.data.totalPage;
				if (res.code === 0) {
					if (res.data.list.length > 0) {
						this.worksList = res.data.list
						if(this.worksListAll.length==0&&this.$route.query.type == "所有"){
							this.worksListAll = res.data.list
							this.currPage1 == "2"
						}
						if(this.comicListAll.length==0&&this.$route.query.type == "漫画"){
							this.comicListAll = res.data.list
							this.currPage2 == "2"
						}
						if(this.originalListAll.length==0&&this.$route.query.type == "原画"){
							this.originalListAll = res.data.list
							this.currPage3 == "2"
						}
					}
				}
			},
			//处理 url
			getUrl(url) {
				return "http://api.aboom.net" + url;
			},
			// 去作品详情1
			goWorkDetail(data) {
				let url = this.$router.resolve({
					name: "workDetail1",
					query: {
						work_id: data.id,
						uid: data.uid,
					},
				});
				window.open(url.href,'_blank')
			},
		},
	};
</script>
<style lang="less" scoped>
	.clearfix:after {
		content: "";
		display: block;
		clear: both;
		height: 0;
		visibility: hidden;
	}

	.clearfix {
		*zoom: 1;
	}

	* {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
		color: #5dbbbf;
	}

	li {
		list-style: none;
		color: #5dbbbf;
	}

	.wrapper {
		margin: 14.77px auto;
		// max-height: 3646px;
		background: #35363f;
		border-radius: 7px;
	}

	.wrapper .title {
		padding: 30px 0 25px;
		text-align: center;
		font-size: 20px;
		font-weight: lighter;
		color: #5dbbbf;
	}

	.wrapper .nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 46px;
		padding: 0 400px;
		color: #5dbbbf;
		font-weight: lighter;
		background: #28262e;
	}

	.wrapper .nav .active {
		position: relative;
		width: 96px;
		height: 38px;
		line-height: 36px;
		text-align: center;
		border-radius: 7px;
		background: #5accd8;
	}

	.wrapper .nav .active p {
		color: #0c424f;
	}

	.wrapper .nav .active .triangle {
		position: absolute;
		left: 50%;
		bottom: -10px;
		transform: translateX(-50%);
		border: 5px solid transparent;
		border-top-color: #5accd8;
	}

	.wrapper .nav li {
		position: relative;
		width: 96px;
		height: 38px;
		line-height: 36px;
		text-align: center;
		border-radius: 7px;
	}

	.wrapper .nav li:hover {
		background: #5accd8;
	}

	.wrapper .nav li:hover p {
		color: #0c424f;
	}

	.wrapper .nav li:hover .triangle {
		position: absolute;
		left: 50%;
		bottom: -10px;
		transform: translateX(-50%);
		border: 5px solid transparent;
		border-top-color: #5accd8;
		cursor: pointer;
	}

	.wrapper .contribution .pages {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 40px 0;
	}

	.wrapper .contribution .pages li {
		margin-right: 16px;
		cursor: pointer;
	}

	.wrapper .contribution .pages li:hover {
		color: #ffffff;
	}

	.wrapper .contribution .pages .active {
		color: #ffffff;
	}

	.wrapper .contribution .pages .next {
		width: 48px;
		height: 25px;
		background: #5accd8;
		background-image: url("../../assets/img/home/fanye3.png");
		background-repeat: no-repeat;
		background-size: 14px;
		background-position: center;
		border-radius: 3px;
	}

	.wrapper .contribution .works {
		display: grid;
		grid-template-columns: repeat(auto-fill,200px);
		justify-content: space-between;
		grid-gap: 40px 16px;
		padding: 35px 20px;
		border-top: 1px solid #46535a;
	}

	.wrapper .contribution .works .item {
		width: 200px;
	}

	.wrapper .contribution .works .item a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.wrapper .contribution .works .item a img {
		width: 200px;
		height: 200px;
		object-fit: cover;
	}

	.wrapper .contribution .works .item a .name {
		margin-top: 10px;
	}

	.wrapper .contribution .works .narrow {
		width: 200px;
	}

	.wrapper .contribution .works .narrow a img {
		width: 150px;
	}

	.wrapper .contribution .bottomPages {
		border-top: 1px solid #46535a;
		padding-top: 50px;
	}
</style>
