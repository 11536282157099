<template>
  <div class="wrapper">
    <!-- 侧边栏 -->
    <components_left2 v-if="this.$route.query.uid"></components_left2>
    <common-left1 v-else></common-left1>
    <!-- 内容 -->
    <div class="centerContent">
      <div class="title" v-if="isMy">标签</div>
      <div class="title" v-else-if="this.$route.query.uid">他的标签</div>
      <div class="title" v-else>全部标签</div>
      <ul v-if="isMy" class="hotKeywordContent">
        <li class="name" v-for="li in titleList" :key="li.id">
          <a href="javascript:;" @click="changeWorkList(li.id)">
            {{ li.tag_name }}<span class="num">{{ li.haveWorks }}</span>
          </a>
        </li>
      </ul>
      <ul v-else-if="uid && isMy == false" class="hotKeywordContent">
        <li class="name" v-for="li in titleList" :key="li.tag_name" @click="changeSearch(li.tag_name)"
          v-show="uid == li.uid">
          <a href="javascript:;">
            {{ li.tag_name }}<span class="num">{{ li.num }}</span>
          </a>
        </li>
      </ul>
      <ul v-else class="hotKeywordContent">
        <li class="name" v-for="li in titleList" :key="li.tag_name" @click="changeSearch(li.tag_name)">
          <a href="javascript:;">
            {{ li.tag_name }}<span class="num">{{ li.num }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import commonLeft1 from "../../components/common_left1.vue";
import components_left2 from '@/components/components_left2'
export default {
  name: "hotKeyword",
  components: { commonLeft1, components_left2 },
  data() {
    return {
      titleList: [],
      isMy: false,
      uid: ""
    };
  },
  async created() {
    if (this.$route?.query?.msg) {
      // console.log(this.titleList,"进入")
      let parameter = this.$route.query
      if (parameter.uid) {
        // console.log(parameter.uid)

        this.uid = parameter.uid
        let res = await this.$netWork.getTag({
          url: "/Activity/getTag",
          method: "post"
        })
        this.titleList = res.data
        console.log(res.data, "res")
        // for (let index = 0; index < res.data.length; index++) {
        //   if (res.data[index].uid == this.uid) {
        //     _this.titleList[num] = res.data[index]
        //     num++
        //   }
        // }

      } else {
        this.isMy = true;
        this.getLabellist();
      }
    } else {
      let res = await this.$netWork.getTag({
        url: "/Activity/getTag",
        method: "post"
      })
      this.titleList = res.data
      this.uid = ''
      console.log(this.titleList, "第二", this.isMy)
    }
  },
  methods: {
    //点击标签搜索
    changeSearch(parameter) {
      let url = this.$router.resolve({
        path: '/search',
        query: { search: parameter }
      })
      window.open(url.href, '_blank')
    },
    //查找自己的作品
    async changeWorkList(id) {
      let token = localStorage.getItem("token");
      let res = await this.$netWork.getMyWorksListByTagId({
        url: "/works/getMyWorksListByTagId",
        method: "post",
        data: {
          token: token,
          tag_id: id,
          page: "1",
        }
      });
      if (res.code == 0) {
        this.$router.push({ name: "userProfile", query: { workList: JSON.stringify(res.data.list) } })
      }
    },
    async getLabellist() {
      let token = localStorage.getItem("token");
      let res = await this.$netWork.getMyTag({
        url: "/user/getMyTag",
        method: "post",
        data: {
          token: token,
        },
      });
      if (res.code == 0) {
        this.titleList = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: @fsColor;
}

li {
  color: @fsColor;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;

  .centerContent {
    border-radius: 7px;
    background: @bg1Color;
    flex: 1;
    margin-left: 20px;

    .title {
      height: 38px;
      margin: 8px 8px 0px 8px;
      padding-left: 5px;
      line-height: 38px;
      color: @fsColor;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: @bgColor;
    }

    // 关键字
    .hotKeywordContent {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 20px 40px;

      .name {
        margin-right: 45px;
        margin-bottom: 16px;
        white-space: nowrap;

        .num {
          font-size: 13px;
          font-weight: lighter;
          color: @iptfsColor;
        }
      }
    }
  }
}
</style>