<template>
  <div class="content_middle">
    <common-left1></common-left1>

    <!-- 右边 -->
    <div class="content_middleR">
      <div class="frame">
        <div class="BiaoTi">私信</div>
        <!-- <div class="shoujian">收信箱</div> -->
        <div class="shoujian_XX test test-1">
          <!-- <table>
            <tr>
              <td>发件人</td>
              <td>邮件名</td>
              <td>时间</td>
            </tr>
            <tr v-for="(item, index) in messageList" :key="index">
              <td>{{ item.sender }}</td>
              <td>{{ item.mailname }}</td>
              <td>{{ item.time }}</td>
            </tr>
          </table> -->
          <div class="scrollbar message myMessage">
            <div class="item" v-for="(item, index) in messageList" :key="item.id">
              <div class="right">
                <img class="avatar" :src="item.avatar" alt="" />
                <div class="info">
                  <p class="name">
                    <span style="color: green">{{
                      item.user_id == userid ? "发给" : "收到"
                    }}</span>
                    {{ item.nickname }}
                    <span class="time">{{ item.send_time }}</span>
                  </p>
                  <p class="text">{{ message_content_List[index] }}</p>
                </div>
              </div>
              <div class="reply" @click="
                toMyEmail2(item.talk_id, item.send_user_id, item.friend_id)
                " v-show="item.nickname != '客服'">
                回复
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="choice_div">
					<p class="choice_p" @click="chooseAll">全选</p>
					<p class="choice_p" @click="cancel">取消</p>
				</div>
				<div class="choice_button">删除</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import commonLeft1 from "@/components/common_left1.vue";
export default {
  data() {
    return {
      selectAll: [false, false, false],
      checkboxlist: [
        {
          sender: "官方邮件",
          mailname: "恭喜您的作品获得本周排行榜第一",
          time: "2015年2月1日 12:00",
          ischeck: false,
        },
        {
          sender: "官方邮件",
          mailname: "恭喜您的作品获得本周排行榜第二",
          time: "2016年2月1日 12:00",
          ischeck: false,
        },
        {
          sender: "官方邮件",
          mailname: "恭喜您的作品获得本周排行榜第四",
          time: "2017年2月1日 12:00",
          ischeck: false,
        },
      ],
      messageList: [],
      message_content_List: [],
      userid: ""
    };
  },
  computed: {},
  created() {
    this.getMessageList();
    this.userid = JSON.parse(localStorage.getItem("userInfo")).id
  },
  methods: {
    toMyEmail2(talk_id, send_user_id, friend_id) {
      this.$router.push({ name: "myEmail2", query: { talk_id: talk_id, uid: send_user_id, friend_id: friend_id } })
    },
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    async getMessageList() {
      let token = localStorage.getItem("token");
      let res = await this.$netWork.getMessageList({
        url: "/message/getList",
        method: "post",
        data: {
          token: token,
        },
      });
      localStorage.setItem('messContent',res.messageCount)
      console.log(res);
      if (res.code == 0) {
        this.messageList = res.data;
        let talkIdList = [];
        let message_content_List = [];
        talkIdList.push(res.data.talk_id);
        for (var i = 0; i < res.data.length; i++) {
          let result = await this.$netWork.getMessageDetail({
            url: "/message/getDetail",
            method: "post",
            data: {
              token: token,
              talk_id: res.data[i].talk_id,
              send_user_id: res.data[i].send_user_id,
            },
          });
          if (result.code == 0) {
            message_content_List.push(
              result.data[result.data.length - 1].message_content
            );
          }
        };
        this.message_content_List = message_content_List;
      }
    },
    chooseAll() {
      // if (!this.checkboxlist.every(item=>item.ischeck==false)) {
      this.checkboxlist.forEach(
        (item, i, array) => (this.checkboxlist[i].ischeck = true)
      );
      this.checkboxlist.forEach((item, i, array) =>
        console.log(this.checkboxlist[i].ischeck)
      );
      // }
    },
    cancel() {
      // if (!this.checkboxlist.every(item => item.ischeck == true)) {
      this.checkboxlist.forEach(
        (item, i, array) => (this.checkboxlist[i].ischeck = false)
      );
      this.checkboxlist.forEach((item, i, array) =>
        console.log(this.checkboxlist[i].ischeck)
      );
      // }
    },
    changes(index) {
      console.log(this.checkboxlist[index].ischeck);
    },
  },
  components: {
    commonLeft1,
  },
};
</script>
<style lang="less" scoped>
// * {
//   -ms-user-select: none;
//   -moz-user-select: none;
//   -webkit-user-select: none;
// }

.content_middle {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
}

/* 右边 */
.content_middleR {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex:1;
}

.frame {
  /* flex: 1; */
  background-color: #353641;
  border-radius: 0.4rem;
  padding: 8px 8px 2rem 8px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
  min-height: 736px;
  display: flex;
  flex-direction: column;
}

.BiaoTi {
  color: #4db9c2;
  font-size: 14px;
  background-color: #26272e;
  border-radius: 0.2rem;
  padding: 0.4rem;
}

/* 收件箱 */
.shoujian {
  color: #4db9c2;
  font-size: 16px;
  margin-top: 60px;
  padding-left: 0.4em;
}

.shoujian_XX {
  flex: 1;
  background-color: rgb(38, 39, 46);
  padding: 0 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
  background-color: rgb(38, 39, 46);
}

th,
td {
  padding: 10px;
  text-align: center;
  border-bottom: 0.01rem solid rgb(47, 66, 68);
  color: #4db9c2;
  font-size: 14px;
}

.radio_my {
  color: #4db9c2;
}

/* 单选按钮 */
/* 先将按钮原来的样式隐藏 */
/* input[type=radio] {
    opacity: 0;
    display: inline-block;
    position: relative;
    left: 19px;
} */
/* 选择 */
.choice_div {
  display: flex;
  cursor: pointer;
}

.choice_p {
  color: #4db9c2;
  font-size: 14px;
  margin: 10px;
}

.choice_button {
  background-color: #4db9c2;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 0.3rem;
  outline: none;
  width: 80px;
  line-height: 25px;
  text-align: center;
}

/* 滚轴 */
.test {
  /* width: 50px; */
  margin-right: 0.5rem;
  margin-top: 10px;
  height: 500px;
  overflow: auto;
  border: none;
}

.scrollbar {
  /* width: 30px; */
  height: 1000px;
  margin: 0 auto;
}

.test-1::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 15px;
  // margin: 10px 0;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  border-radius: 0.2rem;
}

.test-1::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  background: #4bbac0;
  border-radius: 0.2rem;
}

.test-1::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border: 1px solid #4bbac0;
  border-radius: 0.2rem;
}

/* 邮箱页面二级 */
.email_message {
  min-height: 300px;
  background-color: rgb(38, 39, 46);
  margin-bottom: 30px;
}

.email_message_title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(51, 62, 66);
}

.email_message_img {
  width: 50px;
  margin: 20px;
}

.email_message_name {
  color: #4db9c2;
  font-size: 14px;
}

/* 邮件内容 */
.email_message_content {
  padding: 20px;
}

.email_message_content1 {
  color: #bbbdbe;
  font-size: 14px;
  margin-bottom: 20px;
}

.email_message_content2 {
  color: #4bbac0;
  font-size: 14px;
}

/* 回复 */
.reply {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reply_p {
  color: #4bbac0;
  font-size: 14px;
  margin: 40px;
}

.reply_txt {
  width: 700px;
  height: 120px;
  border: none;
  resize: none;
  /* 去掉右下角图标 */
  border-radius: 0.5rem;
  background-color: #28262e;
  color: #4bbac0;
  font-size: 14px;
  padding: 10px;
}

.reply_txt:hover,
.reply_txt:visited {
  border: 1px solid #4bbac0;
}

.reply_button {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #4bbac0;
  border-radius: 0.3rem;
  margin: 35px;
}

.scrollBar {
  overflow-y: auto;
}

/*滚动条整体样式*/
.scrollBar::-webkit-scrollbar {
  width: 18px;
  height: 10px;
  background: #35363f;
}

/*滚动条滑块*/
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #5accd8;
}

/*滚动条轨道*/
.scrollBar::-webkit-scrollbar-track {
  border: 1px solid #5accd8;
  border-radius: 4px;
}

.message {
  position: relative;
  overflow-y: auto;
  height: 312px;
  margin: 10px 10px 0 0;
  padding: 10px 50px 20px 20px;
  color: #5dbbbf;
}

.message .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid #46535a;
}

.message .item .right {
  display: flex;
}

.message .item .right .avatar {
  width: 40px;
  height: 40px;
  border-radius: 7px;
}

.message .item .right .info {
  margin-left: 20px;
}

.message .item .right .info .name {
  font-size: 13px;
  font-weight: lighter;
}

.message .item .right .info .name .time {
  font-size: 12px;
  padding-left: 20px;
}

.message .item .right .info .text {
  font-size: 13px;
  font-weight: lighter;
  color: #ffffff;
}

.message .item .right .info .text .replyName {
  color: #5dbbbf;
}

.message .item .reply {
  font-size: 14px;
  font-weight: lighter;
  cursor: pointer;
}

.myMessage {
  height: 100% !important;
  box-sizing: border-box;
  margin: 0;
}
</style>
