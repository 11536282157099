<template>
	<div class="wrapper">
		<!-- 侧边栏 -->
		<common-left1></common-left1>
		<!-- 内容 -->
		<div class="centerContent">
			<div class="title">版权查询</div>
			<div class="copyrightQueryContent">
				<!-- 搜索 -->
				<div class="search">
					<div class="keyword">关键字</div>
					<div class="inputBox">
						<input class="input" type="text" placeholder="仅限搜索自己作品的版权状态" v-model="keyword" />
						<img class="icon" src="../../../assets/img/copyright/sousuo.png" alt="" @click="search" />
					</div>
				</div>
				<!-- 结果 -->
				<div class="result">
					<div class="queryResult">查询结果</div>
					<div class="showResult scrollBar">
						<div class="worksBox"  v-if="queryList.length!=0">
							<div class="item" v-for="(item, index) in queryList" :key="index" @click="showBig(index)">
								<img v-if="item.cover" :src="item.cover" alt="" :title="item.title"/>
								<img v-else src="../../../assets/imgs/icontouxiang2.png"  :title="item.title"/>
								<p class="name">{{ item.title }}
								</p>
							</div>
						</div>
						<p class="info" v-else>暂无结果</p> 
						<ul v-if="totalPage > 1" class="pages bottomPages">
							<li class="prev" v-if="totalPage > 1" @click="prev"></li>
							<li v-for="item in totalPage" :key="item" :class="{ active: item == currPage }"
								@click="refash(item)">
								{{ item }}
							</li>
							<li class="next" @click="next" v-if="totalPage > 1"></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import commonLeft1 from "../../../components/common_left1.vue";
	export default {
		name: "copyrightQuery",
		components: {
			commonLeft1
		},
		data() {
			return {
				keyword: "", //输入的关键字
				queryList: [], //查询结果
				isShowTip: false, //是否显示查询提示
				totalPage: "",
				currPage: "1",
			};
		},
		computed: {
			
		},
		methods: {
			// 版权保护状态
			auditStatus2(status) {
				console.log('status',status);
				if (status == 0) {
					return "审核未通过";
				}
				if (status == 1) {
					return "审核通过";
				}
				if (status == 2||3||4||5) {
					return "审核通过";
				}
				// if (status == 3) {
				// 	return "托管审核";
				// }
				// if (status == 4) {
				// 	return "拒绝托管";
				// }
				// if (status == 5) {
				// 	return "托管中";
				// }
			},
			// 托管状态
			auditStatus(type,status) {
				console.log('type',type,'status',status);
				if(type != 0 && (status ==  0 ||status ==  1 ||status ==  2)){
					return "未申请";
				}
				if(type != 0 && status == 3){
					return "审核中";
				}
				if(type != 0 && status == 4){
					return "已拒绝";
				}
				if (type == 1 && status == 5) {
					return "维权（已托管）";
				}
				if (type == 2 && status == 5) {
					return "商业化（已托管）";
				}
			},
			showBig(index) {
				// console.log(this.queryList[index].type, index);
				let type = this.auditStatus(this.queryList[index].type,this.queryList[index].status)
				let status = this.auditStatus2(this.queryList[index].status)
				this.html = `<div class="useForm">
							<p class="agreement">版权保护状态 : ${status}</p>
							<p class="agreement">托管状态 : ${type}</p>
							</div>`
				this.$alert(this.html, '此作品全部状态信息', {
					center: true,
					dangerouslyUseHTMLString: true
				});
			},
			//处理 url
			getUrl(url) {
				return "http://api.aboom.net" + url;
			},
			// 版权的状态
			getStatus() {
				return function(status) {
					switch (status) {
						case 0:
							return "审核未通过";
						case 1:
							return "审核中";
						case 2:
							return "审核通过";
						case 3:
							return "托管";
					}
				};
			},
			//上一页
			prev() {
				if (this.currPage == 1) {
					this.$message.error("已经是第一页了")
					return false
				}
				this.currPage--
				this.getCopyRightProtectionWorks()
			},
			//下一页
			next() {
				if (this.currPage >= this.totalPage) {
					this.$message.error("已经是最后一页了")
					return false
				}
				this.currPage++
				this.getCopyRightProtectionWorks()
			},
			// 版权查询
			async search() {
				let res = await this.$netWork.getCopyrightQuery({
					url: "/protection/getdetail",
					method: "post",
					data: {
						token: localStorage.getItem("token"),
						search: this.keyword,
					},
				});
				if (res.code === 0) {
					this.queryList = res.data;
					console.log(this.queryList);
					// console.log(this.queryList[0].nickname);
					// if (res.data.length === 0) {
					// 	this.isShowTip = true;
					// 	return false;
					// }
					// this.isShowTip = false;
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	* {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
	}

	input::-webkit-input-placeholder {
		color: #919191;
	}

	.scrollBar {
		overflow-y: auto;
	}

	/*滚动条整体样式*/
	.scrollBar::-webkit-scrollbar {
		width: 18px;
		height: 10px;
		background: @bgColor;
	}

	/*滚动条滑块*/
	.scrollBar::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: @btnColor;
	}

	/*滚动条轨道*/
	.scrollBar::-webkit-scrollbar-track {
		border: 1px solid @btnColor;
		border-radius: 4px;
	}

	@fsColor: #5dbbbf;
	@bgColor: #28262e;
	@btnColor: #5accd8;
	@btnfsColor: #0c424f;
	@bg1Color: #35363f;
	@iptfsColor: #ffffff;

	.wrapper {
		display: flex;
		justify-content: space-between;
		margin: 14.77px auto;

		.centerContent {
			margin-left: 20px;
			flex:1;
			// height: 764px;
			min-height: 736px;
			border-radius: 7px;
			background: @bg1Color;

			.title {
				height: 38px;
				margin: 8px 8px 0px 8px;
				padding-left: 5px;
				line-height: 38px;
				color: @fsColor;
				border-top-left-radius: 7px;
				border-top-right-radius: 7px;
				background: @bgColor;
			}

			.copyrightQueryContent {
				margin: 55px;

				// 搜索
				.search {
					display: flex;
					align-items: center;
					color: @fsColor;

					.keyword {
						width: 80px;
						text-align: center;
					}

					.inputBox {
						width: 100%;
						overflow: hidden;
						display: flex;
						align-items: center;
						margin-left: 30px;
						background: @bgColor;
						border-radius: 7px;

						.input {
							width: 728px;
							height: 40px;
							padding-left: 10px;
							color: @iptfsColor;
							background: @bgColor;
							border: none;
							outline: none;
							font-size: 18px;
						}

						.icon {
							width: 18px;
							height: 21px;
							padding-left: 20px;
							cursor: pointer;
						}
					}
				}

				// 结果
				.result {
					display: flex;
					margin-top: 20px;

					.queryResult {
						width: 80px;
						margin-top: 6px;
						color: @fsColor;
						text-align: center;
					}

					.showResult {
						position: relative;
						width: 100%;
						height: 540px;
						overflow-y: auto;
						margin-left: 30px;
						border-radius: 7px;
						background: @bgColor;

						.info {
							padding: 10px 10px 2px 10px;
							color: @fsColor;

							.name {
								color: @iptfsColor;
							}
						}

						.pages {
							position: absolute;
							left: 50%;
							transform: translateX(-50%);
							display: flex;
							align-items: center;

							li {
								margin-right: 16px;
								cursor: pointer;
							}

							.active {
								color: @iptfsColor;

								&:hover {
									color: @iptfsColor;
								}
							}

							.next {
								width: 48px;
								height: 25px;
								background: @btnColor;
								background-image: url(../../../assets/img/copyright/fanye3.png);
								background-repeat: no-repeat;
								background-size: 14px;
								background-position: center;
								border-radius: 3px;
							}
						}

						.worksBox {
							display: flex;
							justify-content: flex-start;
							flex-wrap: wrap;
							padding-left: 40px;

							.item {
								display: flex;
								flex-direction: column;
								align-items: center;
								position: relative;
								width: 180px;
								cursor: pointer;
								border-radius: 7px;
								margin: 25px;

								img {
									width: 180px;
									height: 180px;
									border-radius: 7px;
									object-fit: cover;
								}

								.name {
									margin-top: 10px;
									color: @fsColor;
								}

								.gou {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									width: 100px;
									height: 76px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
