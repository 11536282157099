<template>
  <div class="main">
    <common-left1></common-left1>
    <div class="main_center">
      <div class="main_center_top">绘画教师目录</div>
      <div class="main_center_itemss">
        <div class="main_center_items">
          <div class="item">
            <img src="~@/assets/img/home/jiaoxue1.png" alt="" />
            <div class="item_desc">
              <div class="item_desc_text">台湾知名画师</div>
              <div class="item_desc_btn">
                <div class="in">进入</div>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="~@/assets/img/home/jiaoxue1.png" alt="" />
            <div class="item_desc">
              <div class="item_desc_text">台湾知名画师</div>
              <div class="item_desc_btn">
                <div class="in">进入</div>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="~@/assets/img/home/jiaoxue1.png" alt="" />
            <div class="item_desc">
              <div class="item_desc_text">台湾知名画师</div>
              <div class="item_desc_btn">
                <div class="in">进入</div>
              </div>
            </div>
          </div>
          <div class="item">
            <img src="~@/assets/img/home/jiaoxue1.png" alt="" />
            <div class="item_desc">
              <div class="item_desc_text">台湾知名画师</div>
              <div class="item_desc_btn">
                <div class="in">进入</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonLeft1 from "../../components/common_left1.vue";
export default {
  name: "motif",
  components: { commonLeft1 },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
  border-radius: 7px;
  min-height: calc(100vh - 200px - 14.77px);
  background-color: rgb(113, 113, 125);
}
.main_center {
  width: 996px;
  margin-right: 0 !important;
  background-color: #373443;
  border-radius: 7px;
}
.main_center_top {
  border-radius: 7px;
  color: #4eb8c2;
  background-color: #26282c;
  margin: 8px 8px 0 8px;
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
}
.main_center_itemss {
  padding: 5px;
}
.main_center_items {
  max-height: 680px;
  padding: 15px 20px 0 20px;
  overflow-y: scroll;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-track {
    background: #353641;
    border-radius: 2px;
    border: 1px solid #4eb8c2;
  }
  &::-webkit-scrollbar-thumb {
    background: #4eb8c2;
    border-radius: 2px;
  }
  .item {
    display: flex;
    border-radius: 7px;
    height: 180px;
    background-color: #28262e;
    padding: 15px;
    box-sizing: border-box;
    img {
      max-height: 100%;
    }
    .item_desc {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      color: #4eb8c2;
      padding-left: 15px;
      .item_desc_btn {
        display: flex;
        justify-content: right;
        width: 100%;
        text-align: right;
        div {
          color: black;
          width: 90px;
          height: 30px;
          line-height: 30px;
          border-radius: 7px;
          text-align: center;
          background-color: #4eb8c2;
        }
      }
    }
  }
  .item:not(:first-child) {
    margin-top: 20px;
  }
}
</style>