<template>
  <div class="wrapper clearfix">
    <!-- 侧边栏 -->
    <common-left1></common-left1>
    <!-- 内容 -->
    <div class="centerContent">
      <div class="title">申请版权保护</div>
      <div class="copyrightManageOnecontent">
        <div class="explain">版权保护说明</div>
        <p class="text">
		&emsp;&emsp;平台对采集的信息如您的注册信息、已投稿作品已使用加密技术（例如，TLS、SSL）进行加密；
		版权保护服务是基于保护您的作品著作权不受侵犯而提供的服务，在您勾选同意本项服务后，您需要上传您的作品著作权证或作品数字创作证明，平台将采集这两项信息用于保护您的作品著作权不受侵害；
		如发生版权侵权行为，平台将就受委托保护的作品著作权进行司法维权的工作。
        </p>
        <a class="go" @click="goWorkPage">进入作品页面</a>
      </div>
    </div>
  </div>
</template>

<script>
import commonLeft1 from "../../../../components/common_left1.vue";
export default {
  name: "copyrightManageOne",
  components: { commonLeft1 },
  methods: {
    // 判断是否登录，登录后才能进入作品页面
    goWorkPage() {
      if (!localStorage.getItem("token")) {
        this.$message.warning("登录后才能进入作品页面");
        return false;
      }
      this.$router.push({ name: "protectionWork" });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
  .centerContent {
    margin-left: 20px;
    flex:1;
    min-height: 736px;
    border-radius: 7px;
    background: @bg1Color;
    .title {
      height: 38px;
      margin: 8px 8px 0px 8px;
      padding-left: 5px;
      line-height: 38px;
      color: @fsColor;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: @bgColor;
    }
    .copyrightManageOnecontent {
      color: @fsColor;
      .explain {
        padding: 20px 40px;
        font-size: 20px;
      }
      .text {
        padding: 0px 40px;
        line-height: 30px;
      }
      .go {
        display: inline-block;
        height: 50px;
        width: 250px;
        margin-top: 500px;
        margin-left: 50%;
        transform: translateX(-50%);
        text-align: center;
        line-height: 50px;
        color: @btnfsColor;
        cursor: pointer;
        border-radius: 7px;
        background: @btnColor;
      }
    }
  }
}
</style>