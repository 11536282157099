<template>
	<div class="home">
		<header>
			<img src="../assets/imgs/haed.jpg">
			<div>
			</div>
		</header>
		<main>
			<div class="content">
				<div class="navbar">
					<ul @click="changeRoute">
						<li :class="{ bgcolor: route == '首页' }">首页</li>
						<li :class="{ bgcolor: route == '漫画邦' }">漫画邦</li>
						<!-- <li :class="{ bgcolor: route == '动画邦' }">动画邦</li> -->
						<li :class="{ bgcolor: route == '绘画邦' }">绘画邦</li> <!-- 原画邦改名绘画邦 -->
						<!-- <li :class="{ bgcolor: route == '竞技领' }">竞技领</li> -->
						<li :class="{ bgcolor: route == '个人中心' }">个人中心</li>
						<li :class="{ bgcolor: route == '版权中心' }">版权中心</li>
						<li class="search" v-if="$route.path != '/search'">
							<el-autocomplete popper-class="my-autocomplete" v-model="search" size="mini"
								:fetch-suggestions="querySearch" @select="handleSelect"
								@keydown.enter.native="changeRoute_2">

								<img src="@/assets/icon/serch.png" slot="suffix" @click="handleIconClick">
								<template slot-scope="{ item }">
									<div class="name">{{ item.value }}</div>
								</template>
							</el-autocomplete>
						</li>
					</ul>
				</div>
				<keep-alive include="Login,register">
					<router-view></router-view>
				</keep-alive>
				<footer>
					<ul class="ul_left">
						<li @click="pathClick('about')">关于我们</li>
						<li @click="pathClick('link')">{{ bottomInfo.linkTitle }}</li>
						<li @click="pathClick('law')">法律声明</li>
						<li @click="pathClick('contact')">联系我们</li>
						<li @click="pathClick('feed')">意见反馈</li>
						<li @click="pathClick('helpcenter')">帮助中心</li>
					</ul>
					<ul class="ul_right">
						<li style="font-size: 0.7rem">Copyright 2021-2022 aboom.net 版权所有
							<a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备19034282号-2</a>
						</li>
					</ul>
				</footer>
				<!-- <ul class="ul_right">
					<li style="font-size: 0.7rem">Copyright 2021-2022 aboom.net 版权所有
						<a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备19034282号-2</a>
					</li>
				</ul> -->
			</div>
		</main>
		<el-backtop :right="300" :bottom="100">
			<i class="el-icon-arrow-up"></i>
		</el-backtop>
	</div>
</template>

<script>
export default {
	name: "index",
	data() {
		return {
			route: "首页", //页面
			bottomInfo: {
				linkTitle: "",
				lawTitle: "",
			},
			restaurants: [],
			search: '',
			routeMap: {
				首页: "/",
				漫画邦: "/cartoon",
				// "动画邦":'/home',
				绘画邦: "/original",
				// "竞技领":'/home',
				个人中心: "/infoMessage",
				版权中心: "/copyrightCenter",
				搜索: "/search",
			},
		};
	},
	created() {
		// nav 样式
		this.route = sessionStorage.getItem("route");
	},
	mounted() {
		this.getLink();
		this.getLaw();
		this.getConcatUS();
		this.getHelpCenter();
		// var elBacktop = document.querySelector('.el-backtop')
		// this.$refs.elb._props.right = "17%"
		// console.log(this.$refs.elb._props.right="333",'191151515')
		// window.addEventListener('scroll', this.a, true)
	},
	beforeUpdate() {
		let loginTime = localStorage.getItem("loginTime")
		if (loginTime) {
			let date = new Date()
			let time = date.getTime()
			if (time - loginTime >= 1000 * 60 * 60 * 24) { //
				localStorage.removeItem("token")
				localStorage.removeItem("userInfo")
				localStorage.removeItem("avatar")
				localStorage.removeItem("loginTime")
				this.$router.push({
					path: "/"
				})
				window.location.reload()
				this.$message("登录信息过期,请从新登录!")
			}
		}
	},
	watch:{
		$route(to,from){
			if(to.path != '/link' && to.path != '/about' && to.path != '/law' && to.path != '/contact' && to.path != '/feed' && to.path != '/helpcenter'){
				sessionStorage.removeItem('tnmbsmdx')
			}
		}
	},
	methods: {
		// a() {
		//   // console.log(this.$refs.view)
		//   console.log(window.innerWidth);
		//   if((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop)>300){
		//     this.$refs.elb.right = (window.innerWidth*0.16)+'px'
		//   }
		// },
		querySearch(queryString, cb) {
			var restaurants = localStorage.getItem("selectMsg") ? JSON.parse(localStorage.getItem("selectMsg"))
				.reverse().slice(0, 10) : []
			var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		handleSelect(item) {
			console.log(item);
		},
		handleIconClick(ev) {
			console.log(ev, this.search);
			var selectMsg = JSON.parse(localStorage.getItem("selectMsg"))
			if (selectMsg) {
				selectMsg.push({
					"value": this.search
				})
			} else {
				selectMsg = []
				selectMsg.push({
					"value": this.search
				})
			}
			localStorage.setItem("selectMsg", JSON.stringify(selectMsg))
			let routePath = this.routeMap;
			this.$router.push({
				path: routePath.搜索,
				query: {
					search: this.search
				}
			});
		},
		pathClick(str) {
			let tnmbsmdx = sessionStorage.getItem('tnmbsmdx')
			if (tnmbsmdx) {
				this.$router.push({path:`/${str}`})
			} else {
				sessionStorage.setItem('tnmbsmdx',20)
				window.open(this.$router.resolve({
					path: `/${str}`
				}).href, '_blank')
			}
		},
		changeRoute(event) {
			let route = (this.route = event.target.innerText);
			let routes = route ? route : "搜索";
			// console.log(route);
			sessionStorage.setItem("route", routes);
			//导航跳转
			// let routePath = this.routeMap[route];
			// console.log(routePath);
			// this.$router.push({ path: routePath });
			if (route == '个人中心' || route == "版权中心") {
				if (!this.$isToken()) {
					return
				}
			}
			let routePath = this.routeMap[route];
			this.$router.push({
				path: routePath
			});
		},
		changeRoute_2() {
			// sessionStorage.setItem("search", this.search);
			var selectMsg = JSON.parse(localStorage.getItem("selectMsg"))
			if (selectMsg) {
				selectMsg.push({
					"value": this.search
				})
			} else {
				selectMsg = []
				selectMsg.push({
					"value": this.search
				})
			}
			localStorage.setItem("selectMsg", JSON.stringify(selectMsg))
			let routePath = this.routeMap;
			this.$router.push({
				path: routePath.搜索,
				query: {
					search: this.search
				}
			});
		},
		// 友情链接
		async getLink() {
			let res = await this.$netWork.getLink({
				url: "/banner/lianjie",
				method: "post",
			});
			// console.log('链接',res)
			this.bottomInfo.linkTitle = res.data[0].title;
		},
		// 法律
		async getLaw() {
			let res = await this.$netWork.getLaw({
				url: "/banner/falv",
				method: "post",
			});
			// console.log('法律',res)
			// this.falv = res.data
		},
		// 联系我们
		async getConcatUS() {
			let res = await this.$netWork.getConcatUS({
				url: "/banner/lianxi",
				method: "post",
			});
			// console.log('联系',res)
		},
		// 帮助中心
		async getHelpCenter() {
			let res = await this.$netWork.getHelpCenter({
				url: "/banner/help",
				method: "post",
			});
		},
	},
};
</script>
<style lang="less" scoped>
/deep/ .el-backtop {
	border-radius: 5px !important;
	right: 50% !important;
	background-color: #5ce2ed;
	transform: translate(650px);

	.el-icon-arrow-up:before {
		content: "\e6e1";
		font-size: 22px;
		color: #35353f;
	}
}

/deep/ .el-icon-search:before {
	color: #35353f;
}

.el-backtop:hover {
	background-color: #4eb8c2;
}

/deep/ .el-input--mini .el-input__icon {
	margin-left: 4.5px;
	margin-top: -2px;
}

.search /deep/ .el-input__inner {
	padding: 0px;
	border: none;
	width: 170px;
	color: #fff;
	background-color: #35353f;
}

.search /deep/ .el-input__suffix {
	font-size: 22px;
	right: -5px;
	width: 20px;
	top: -1px;
	height: auto;
	background-color: #5ce2ed;
}

.search /deep/ .my-autocomplete {
	li {
		line-height: normal;
		padding: 7px;

		.name {
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.addr {
			font-size: 12px;
			color: #b4b4b4;
		}

		.highlighted .addr {
			color: #ddd;
		}
	}
}

.home {
	display: flex;
	flex-direction: column;
	// flex-wrap: wrap;
	justify-content: space-between;
	// justify-content: center;
	width: 100%;
	min-height: 100vh;
	min-width: 1300px;
}

header {
	width: 100%;
	height: 150px;

	img {
		width: 100%;
		height: 100%; 
		object-fit: cover;
	}

	// div {
	// 	background-image: url(../assets/imgs/header1.jpg); //设置背景图片
	// 	background-repeat: no-repeat; //背景图像将仅显示一次。
	// 	background-attachment: fixed; //
	// 	background-position: 0 0; //设置背景图片的的偏移量，这个-50相当于背景整体向左偏移50，就可以显示图片的中心
	// 	background-size: contain; ////设置背景图片的大小，相当于图片实际宽高等比例饿缩放的
	// 	width: 100%; //
	// 	height: 150px; //
	// 	border: none;
	// 	padding: 0;
	// 	margin: 0;
	// }
}

main {
	width: 100%;
	background-color: rgb(112, 115, 124);
	flex: 1;
	display: flex;
}

.content {
	width: 90%;
	margin: 20px auto 0 auto;

	.navbar {
		background-color: rgb(53, 53, 63);
		width: 100%;
		height: 50px;
		border-radius: 7px;
		color: #4eb8c2;

		ul {
			display: flex;
			align-items: center;
			height: 100%;
			list-style: none;
			padding-right: 15px;
			box-sizing: border-box;

			li:not(.search) {
				display: flex;
				padding: 0 48px;
				cursor: pointer;
				height: 100%;
				align-items: center;
				border-top-left-radius: 7px;
				border-bottom-left-radius: 7px;
			}

			li:not(.search):hover {
				background-color: rgb(38, 39, 44);
			}

			.bgcolor {
				background-color: rgb(38, 39, 44);
			}

			.search {
				padding: 1px 0px 1px 2px;
				border-radius: 3px;
				width: 190px;
				display: flex;
				justify-content: space-between;
				background-color: #5ce2ed;
				align-items: center;
				height: 31px;
				margin-left: auto;

				input {
					height: 24px;
					width: 150px;
					border: none;
					outline: none;
					background-color: rgb(53, 53, 63);
					border-radius: 3px;
					color: #ffff;
				}

				/deep/.el-input__suffix {
					height: 100%;
					top: 0;

					.el-input__suffix-inner {
						height: 100%;
						display: flex;
						align-items: center;

						img {
							width: 20px;
							height: 20px;
							margin-left: 5px;
							cursor: pointer;
						}
					}
				}

				// i {
				//   width: 27px;
				//   height: 23px;
				//   background: url("../assets/img/home/search2.png") no-repeat center/contain;
				// }
			}
		}
	}
}

// @media screen and (max-width: 1700px) {

footer {
	margin: 14.77px 0 50px 0 !important;
	background-color: rgb(53, 53, 63);
	height: 50px;
	border-radius: 7px;
	color: #4eb8c2;
	display: flex;
	flex-wrap: wrap;

	.ul_left {
		width: 100%;
		display: flex;
		height: 50px;
		align-items: center;
		justify-content: center;
		font-size: 14px;

		li {
			text-align: center;
			cursor: pointer;
			width: 15%;
			// margin-left: 50px;
		}
	}

	.ul_right {
		width: 100%;
		display: flex;
		height: 50px;
		align-items: center;
		justify-content: center;
		font-size: 14px;

		li {
			min-width: 120px;

			a {
				color: #4eb8c2;
				cursor: pointer;
			}
		}
	}
}

.ul_right {
	width: 100%;
	display: flex;
	height: 20px;
	margin-bottom: 10px;
	justify-content: center;
	font-size: 14px;
	color: #4eb8c2;

	li {
		min-width: 120px;

		a {
			color: #4eb8c2;
			cursor: pointer;
		}
	}
}

// }

// @media screen and (min-width: 1700px) {
//   .content {
//     min-width: 1200px;
//   }
//   footer {
//     margin: 14.77px 0 40px 0 !important;
//     width: 1200px;
//     margin: 0 auto;
//     background-color: rgb(53, 53, 63);
//     height: 50px;
//     border-radius: 7px;
//     color: #4eb8c2;
//     ul {
//       width: 100%;
//       display: flex;
//       height: 100%;
//       align-items: center;
//       font-size: 14px;
//       li {
//         cursor: pointer;
//         min-width: 80px;
//         margin-left: 50px;
//       }
//     }
//   }
// }</style>
