<template>
	<div class="main">
		<common-left></common-left>
		<div class="main_center">
			<div class="main_center_1">
				<el-carousel class="main_center_1_banner" trigger="click">
					<el-carousel-item class="itemImg" v-for="item in slides" :key="item.id">
						<div class="picItem">
							<a :href="`${item.href}`" target="_blank">
								<img class="sliderImg" :src="item.file_path" alt="" />
								<div class="title">
									{{ item.title }}<span class="name">Krenz</span>
								</div>
							</a>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div id="padding" class="main_center_2 public">
				<div id="paddingnone" class="public_1">
					<div class="public_1_1">
						<img class="icon" style="margin-left: 5px" src="~@/assets/icon/zuixintougao.png" alt="" />
						<span>&nbsp;最新投稿</span>
					</div>
				</div>
				<div class="main_center_2_item">
					<div id="padding0" class="item" v-for="item in worksList.slice(0, 20)" :key="item.id">
						<router-link target="_blank"
							:to="{ name: 'workDetail1', query: { work_id: item.id, uid: item.uid } }"
							style="text-decoration: none">
							<img class="item_img" :src="item.coverimg" alt="" :title="item.title" />
							<p style="
                  height: 20px;
                  color: #4eb8c2;
                  text-align: center;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  margin-bottom: 10px;
                ">
								{{ item.title }}
							</p>
						</router-link>
					</div>
				</div>
				<div @click="toNewContribution" class="moRe">
					>>更多
				</div>
			</div>
			<div id="padding" class="main_center_3 public">
				<div class="public_1">
					<div class="public_1_1">
						<img class="icon" src="~@/assets/icon/zhuantihuodong.png" alt="" />
						<span class="huodong">&nbsp;专题活动</span>
					</div>
					<!-- <div @click="toZuanti">>>更多</div> -->
				</div>

				<div class="main_center_3_item special">
					<div class="item">
						<div v-for="item in activity" :key="item.id" class="item_div">
							<img id="margin_left0" class="item_img" :src="item.file_path" alt="" :title="item.title"
								@click="goDetail(item)" />
							<!-- <p>{{ item.title }}</p> -->
						</div>
					</div>
					<div @click="toZuanti" class="More">>>更多</div>
				</div>
			</div>
			<div id="padding" class="main_center_2 public">
				<div id="paddingnone" class="public_1">
					<div class="public_1_1">
						<img class="icon" style="margin-left: 5px" src="~@/assets/icon/zuixintougao.png" alt="" />
						<span>&nbsp;漫画邦</span>
					</div>
				</div>
				<div class="main_center_2_item">
					<div id="padding0" class="item" v-for="item in originalList.slice(0, 20)" :key="item.id">
						<router-link :to="{ name: 'workDetail1', query: { work_id: item.id, uid: item.uid } }"
							style="text-decoration: none">
							<img class="item_img" :src="item.coverimg" alt="" :title="item.title" />
							<p style="
                  height: 20px;
                  color: #4eb8c2;
                  text-align: center;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  margin-bottom: 10px;
                ">
								{{ item.title }}
							</p>
						</router-link>
					</div>
				</div>
				<div @click="toYuan" class="moRe">
					>>更多
				</div>
			</div>
			<div id="padding" class="main_center_7 public">
				<div class="public_1">
					<div class="public_1_1">
						<img class="icon" src="~@/assets/icon/remenbiaoqian.png" alt="" />
						<span>&nbsp;热门标签</span>
					</div>
					<div @click="toHot">更多</div>
				</div>
				<div class="main_center_6_item paddingL0">
					<ul>
						<li v-for="item in tags.slice(0, 20)" :key="item.id" @click="clickTag(item.id)">
							• {{ item.tag_name }}
						</li>
					</ul>
				</div>
				<div class="split"></div>
				<!-- <div class="fav_tag" @click="toHot">
					<span class="huodong">设置喜欢的标签</span>
					<span class="huodong">[标签设置]</span>
				</div> -->
			</div>
		</div>

		<!-- 右边 -->
		<div class="main_right down" ref="main_right">
			<!-- 公告 -->
			<div class="main_right_1 index" style="margin-bottom: 8px">
				<div class="public" style="width: 189px; margin-bottom: 8px">
					<div class="public_left">
						<img class="icon" src="~@/assets/icon/gonggao.png" alt="" />
						<span>&nbsp;站内公告</span>
					</div>
					<span @click="more" class="gonggao"> 更多</span>
				</div>
				<div class="main_right_1_text">
					<ul style="margin-left: 19px">
						<li style="overflow: hidden" v-for="item in noticelist.slice(0, 5)" :key="item.id"
							@click="jumpNoticeDetils(item.id)">
							• {{ item.title }}
						</li>
					</ul>
				</div>
			</div>
			<!-- 排行榜 -->
			<!-- 公告 -->
			<div class="main_right_2 index">
				<div class="public">
					<div class="public_left">
						<img class="icon" src="~@/assets/icon/paihangbang.png" alt="" />
						<span>&nbsp;本日排行榜</span>
					</div>
					<span @click="goTodayOrWeekOrMonthRankingList(1)" style="cursor: pointer">
						更多</span>
				</div>
				<div class="main_right_2_item" v-for="item in thisDayList" :key="item.id">
					<!-- <div v-for="j in item.fData" :key="j.id"> -->
					<img class="main_right_2_item_img" @click="goWorkDetail(item.id, item.uid)"
						:src="item.fData[0].file_path" alt="!" :title="item.title" />
					<div class="main_right_2_item_desc">
						<span>{{ item.title }}</span>
						<div>
							<span>by&nbsp;</span>
							<span>{{ item.nickname }}</span>
						</div>
					</div>
					<!-- </div> -->
				</div>

				<!-- 编辑部的日常 -->
				<div class="main_right_2_bj" v-if="true">
					<div class="main_right_2_bj_item" v-for="(item, index) in thisDayList" :key="item.id"
						@click="goWorkDetail(item.id)">
						<i>{{ index + 4 }}</i>
						<span>{{ item.title }}</span>
					</div>
					<!-- <div class="moreInfo">>>更多</div> -->
				</div>
			</div>
			<div class="main_right_3 index">
				<div class="public">
					<div class="public_left">
						<!-- <i></i> -->
						<img class="icon" src="~@/assets/icon/paihangbang.png" alt="" />
						<span>&nbsp;本周排行榜</span>
					</div>
					<span @click="goTodayOrWeekOrMonthRankingList(2)" style="cursor: pointer">
						更多</span>
				</div>
				<div class="main_right_2_item" v-for="item in thisWeekList" :key="item.data">
					<!-- <div v-for="j in item.fData" :key="j.id"> -->
					<img class="main_right_2_item_img" @click="goWorkDetail(item.id, item.uid)"
						:src="item.fData[0].file_path" alt="!" :title="item.title" />
					<div class="main_right_2_item_desc">
						<span>{{ item.title }}</span>
						<div>
							<span>by&nbsp;</span>
							<span>{{ item.nickname }}</span>
						</div>
					</div>
					<!-- </div> -->
				</div>

				<!-- 编辑部的日常 -->
				<div class="main_right_2_bj" v-if="true">
					<div class="main_right_2_bj_item" v-for="(item, index) in thisWeekList" :key="item.id"
						@click="goWorkDetail(item.id)">
						<i>{{ index + 4 }}</i>
						<span>{{ item.title }}</span>
					</div>
					<!-- <div class="moreInfo">>>更多</div> -->
				</div>
			</div>
			<div class="main_right_4 index">
				<div class="public">
					<div class="public_left">
						<!-- <i></i> -->
						<img class="icon" src="~@/assets/icon/paihangbang.png" alt="" />
						<span>&nbsp;本月排行榜</span>
					</div>
					<span @click="goTodayOrWeekOrMonthRankingList(3)" style="cursor: pointer">
						更多</span>
				</div>
				<div class="main_right_2_item" v-for="item in thisMonthList" :key="item.id">
					<!-- <div v-for="j in item.fData" :key="j.id"> -->
					<img class="main_right_2_item_img" @click="goWorkDetail(item.id, item.uid)"
						:src="item.fData[0].file_path" alt="!" :title="item.title" />
					<div class="main_right_2_item_desc">
						<span>{{ item.title }}</span>
						<div>
							<span>by&nbsp;</span>
							<span>{{ item.nickname }}</span>
						</div>
					</div>
					<!-- </div> -->
				</div>

				<!-- 编辑部的日常 -->
				<div class="main_right_2_bj" v-if="true">
					<div class="main_right_2_bj_item" v-for="(item, index) in thisMonthList" :key="item.id"
						@click="goWorkDetail(item.id)">
						<i>{{ index + 4 }}</i>
						<span>{{ item.title }}</span>
					</div>
					<!-- <div class="moreInfo">>>更多</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import commonLeft from "@/components/common_left.vue";
export default {
	name: "homeMain",
	data() {
		return {
			noticelist: [], // 公告列表
			slides: [], // 轮播图数据
			activity: [], // 专题活动
			tags: [], // 热门标签
			worksList: [], // 最新投稿
			originalList: [], //原画列表
			thisDayList: [], //本日排行榜
			thisWeekList: [], //本周排行榜
			thisMonthList: [], //本月排行榜
			type: "漫画",
			oldScrollTop: 0, //
			top: 0, //
			topstop: 0,
			topmax: 0,
		};
	},
	components: {
		commonLeft
	},
	created() {
		this.getOriginal();
		this.getNoticeList();
		this.getSlides();
		this.getActivity();
		this.getTag();
		this.getWorksList();
		this.getThisDayList();
		this.getThisWeekList();
		this.getThisMonthList();
	},
	mounted() {
		window.addEventListener('scroll', this.scrollEvent, false);
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollEvent, false);
	},
	methods: {
		goDetail(item) {
			let abcd = this.$router.resolve({
				name: "zuanti2",
				query: {
					data: JSON.stringify(item)
				}
			});
			window.open(abcd.href, '_blank')
		},
		scrollEvent() {
			let offsetTop = this.$refs.main_right.offsetTop; //main_right顶部高度
			let scrollHeight = this.$refs.main_right.scrollHeight; //main_right元素高度
			let scrollTop = document.documentElement.scrollTop; //网页被卷起来的高度
			let innerHeight = window.innerHeight; //视口高度
			let bodyscrollHeight = document.body.scrollHeight;
			let tope = scrollTop + innerHeight - 215 - scrollHeight;
			this.top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			this.topstop = this.top - this.oldScrollTop;
			this.oldScrollTop = this.top;
			if (this.topstop < 0) { //向上滚动
				if (this.topmax + 215 > scrollTop) {
					this.$refs.main_right.style.top = 0 + "px";
				} else {
					this.$refs.main_right.style.top = this.topmax + "px";
				}
			} else { //向下滚动
				if (scrollTop + innerHeight >= 215 + scrollHeight && scrollTop + innerHeight < bodyscrollHeight -
					110) {
					this.$refs.main_right.style.top = tope + "px";
					this.topmax = tope;
				}
			}
		},
		//原画帮列表
		async getOriginal() {
			const originalList = await this.$netWork.getWorksList({
				method: "post",
				url: "/works/getWorksList",
				data: {
					type: "new", // 类型
					page: 1, // 页码
					works_type: "漫画",
				},
			});
			console.log(originalList, "originalList");
			this.originalList = originalList.data.list;
		},
		toHot() {
			let url = this.$router.resolve({
				path: "/hotKeyWord",
				query: {
					type: this.type
				}
			});
			window.open(url.href, '_blank')
		},
		more() {
			this.$router.push({
				path: "/announcement",
				query: {
					type: this.type
				}
			});
		},
		async getNoticeList() {
			// 公告
			const res = await this.$netWork.getNoticelist({
				method: "post",
				url: "/notice/getnoticelist",
			});
			this.noticelist = res;
			console.log(res, 2224);
		},
		async getSlides() {
			// 轮播图
			let data = { type: 1 }
			const slides = await this.$netWork.getSlide({
				method: "post",
				url: "/notice/getSlide",
				data
			});
			this.slides = slides;
			console.log(this.slides, 1111111111);
		},
		async getActivity() {
			// 专题活动
			let data = { type: 1 }
			const activity = await this.$netWork.getActivity({
				method: "post",
				url: "/activity/getActivity",
				data
			});
			this.activity = activity.data;
			console.log(activity, "活动活动");
		},
		async getTag() {
			// 热门标签
			const tags = await this.$netWork.getTag({
				method: "post",
				url: "/Activity/getTag",
			});
			console.log('--漫画帮热门标签--', tags.data);
			this.tags = tags.data;
		},
		async getWorksList() {
			// 最新投稿
			const worksList = await this.$netWork.getWorksList({
				method: "post",
				url: "/works/getWorksList",
				data: {
					type: "new", // 类型
					page: 1, // 页码
					works_type: "漫画",
				},
			});
			console.log(worksList, 1111122222);
			this.worksList = worksList.data.list;
		},
		async getThisDayList() {
			const thisDayList = await this.$netWork.getThisDayList({
				method: "post",
				url: "/works/getWorksList",
				data: {
					type: "today", // 类型
					page: 1, // 页码
					works_type: "漫画",
				},
			});
			console.log(thisDayList + "++++++++++++++++++++++++++++");
			this.thisDayList = thisDayList.data.list;
			this.thisDayList = this.thisDayList.slice(0, 3);
			console.log(this.thisDayList, "thisDayList");
		},
		async getThisWeekList() {
			const thisWeekList = await this.$netWork.getThisDayList({
				method: "post",
				url: "/works/getWorksList",
				data: {
					type: "thisWeek", // 类型
					page: 1, // 页码
					works_type: "漫画",
				},
			});
			this.thisWeekList = thisWeekList.data.list;
			this.thisWeekList = this.thisWeekList.slice(0, 3);
			console.log(thisWeekList, "thisWeekList");
		},
		async getThisMonthList() {
			const thisMonthList = await this.$netWork.getThisDayList({
				method: "post",
				url: "/works/getWorksList",
				data: {
					type: "thisMonth", // 类型
					page: 1, // 页码
					works_type: "漫画",
				},
			});
			this.thisMonthList = thisMonthList.data.list;
			this.thisMonthList = this.thisMonthList.slice(0, 3);
			console.log(thisMonthList, "thisMonthList");
		},

		//处理 url
		getUrl(url) {
			return "http://api.aboom.net" + url;
		},
		// 跳转公告详情
		jumpNoticeDetils(id) {
			this.$router.push({
				name: "announcement",
				query: {
					id,
					type: this.type
				},
			});
		},
		// 点击标签
		async clickTag(id) {
			let res = await this.$netWork.getTagContent({
				url: "/tag/clickTag",
				method: "post",
				data: {
					tid: `${id}`
				},
			});
		},
		//去最新投稿
		toNewContribution() {
			this.$router.push({
				path: "/newContribution",
				query: {
					type: this.type
				},
			});
		},
		toYuan() {
			this.$router.push({
				path: "/newContribution",
				query: {
					type: "原画"
				},
			});
		},
		//去专题活动
		toZuanti() {
			this.$router.push({
				path: "/zuanti",
				query: {
					type: 1
				}
			});
		},
		//去作品详情
		// toWorkDetail(data) {
		//   this.$router.push({ name: 'workDetail', params: { data: data } })
		// },
		// 去作品详情1
		goWorkDetail(id, uid) {
			this.$router.push({
				name: "workDetail1",
				query: {
					work_id: id,
					uid: uid
				},
			});
		},
		// 去日、周、月排行榜
		goTodayOrWeekOrMonthRankingList(num) {
			this.$router.push({
				name: "seniority",
				query: {
					sort: num,
					type: this.type
				},
			});
		},
	},
};
</script>

<style scoped lang="less">
.down {
	position: relative;
}

img {
	object-fit: cover;
}

.huodong {
	cursor: pointer;
}

.gonggao {
	cursor: pointer;
}

.morE {
	color: #4eb8c2;
	margin-top: 250px;
	margin-left: 529px;
}

.mOre {
	margin-top: 250px;
	margin-left: 529px;
	color: #4eb8c2;
}

.More {
	// margin-top: 80px;
	color: #4eb8c2;
	margin-left: 90%;
	cursor: pointer;
}

.moRe {
	color: #4eb8c2;
	margin-left: 90%;
	cursor: pointer;
}

.moreInfo {
	color: #4eb8c2;
	margin-top: 210px;
	margin-left: 150px;
	margin-bottom: 12px;
	position: absolute;
}

.icon {
	width: 26px;
	height: 26px;
}

.flex {
	display: flex;
}

.main {
	display: flex;
	border-radius: 7px;
	min-height: calc(100vh - 200px - 14.77px);
	// background-color: rgb(113, 113, 125);

	.main_right {
		.index {
			width: 218.77px;
			border-radius: 7px;
			background: #35353f;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 8px;

			.public {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 189px;
				height: 26px;
				border-top-left-radius: 7px;
				border-top-right-radius: 7px;
				background: #26272c;
				color: #4eb8c2;
				padding: 0 7px;

				.public_left {
					display: flex;
					align-items: center;

					i {
						min-width: 16.62px;
						height: 17px;
						background: #d8d8d8;
					}

					img {
						width: 16px;
						height: 16px;
					}
				}
			}
		}

		.padding {
			padding: 0 !important;
		}

		.index:not(:first-child) {
			margin-top: 14.77px;
		}

		.main_right_1 {
			min-height: 191px;

			.main_right_1_text {
				width: 100%;
			}

			ul {
				margin-left: 30px;
				line-height: 30px;
				list-style: none;
				color: whiter;
				color: white;
				font-size: 14px;
				text-overflow: ellipsis;

				li {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					cursor: pointer;
				}
			}
		}

		.main_right_2,
		.main_right_3,
		.main_right_4 {
			min-height: 590px;

			.main_right_2_item {
				margin-top: 7px;
				cursor: pointer;

				.main_right_2_item_img {
					width: 200px;
					height: 104px;
					cursor: pointer;

					border-radius: 7px;
					// background: #d8d8d8 url("~@/assets/imgs//tuijian.png") no-repeat
					//   center/cover;
				}

				.main_right_2_item_desc {
					display: flex;
					margin-top: 7px;
					justify-content: space-between;
					color: #4eb8c2;
					font-size: 14px;

					div {
						display: flex;
						justify-content: space-between;

						span:first-child {
							color: white;
						}
					}
				}
			}

			.main_right_2_bj {
				width: 100%;
				cursor: pointer;

				.main_right_2_bj_item {
					padding-left: 7px;
					height: 60px;
					display: flex;
					align-items: center;
					font-size: 14px;
					color: #4eb8c2;
					border-bottom: 1px solid #46b3bd;

					i {
						margin-right: 11px;
						width: 36px;
						height: 18px;
						line-height: 18px;
						color: #353641;
						text-align: center;
						font-style: normal;
						border-radius: 4px;
						background: #46b3bd;
					}
				}

				:last-child {
					border-bottom: none;
				}
			}
		}

		.main_right_3 {
			.main_right_3_item_img2 {
				background-image: url("~@/assets/imgs//di21.png") !important;
				cursor: pointer;
			}
		}

		.main_right_4 {
			.main_right_4_item_img2 {
				background-image: url("~@/assets/imgs/di19.png") !important;
				cursor: poniter;
			}
		}
	}

	.main_center {
		.main_center_1 {
			height: 322px;
			border-radius: 7px;
			background: #35353f;
			padding: 8px;
			box-sizing: border-box;

			.main_center_1_banner {
				overflow: hidden;
				border-radius: 7px;

				.itemImg {
					.picItem {
						position: relative;
						height: 322px;

						.sliderImg {
							width: 100%;
							height: 100%;
						}

						.title {
							width: calc(100% - 16px);
							height: 40px;
							position: absolute;
							bottom: 17px;
							color: #fff;
							font-size: 18px;
							line-height: 40px;
							padding-left: 16px;
							background: rgba(38, 39, 46, 0.3);

							.name {
								padding-left: 20px;
								color: #72e1e5;
								font-size: 16px;
							}
						}
					}
				}
			}
		}

		.public {
			margin-top: 14.77px;
			min-height: 300px;
			border-radius: 7px;
			padding: 14px;
			// padding-right: 0;
			background: #35353f;
			box-sizing: border-box;

			.public_1 {
				display: flex;
				align-items: center;
				height: 34px;
				border-top-left-radius: 7px;
				border-top-right-radius: 7px;
				background-color: rgb(38, 39, 44);
				font-size: 14px;
				justify-content: space-between;
				color: #4eb8c2;
				padding: 0 7px;

				// margin: 0 -9px;
				&>.public_1_1 {
					display: flex;
					align-items: center;

					img {
						width: 14px;
						height: 14px;
					}
				}

				div {
					cursor: pointer;
				}
			}
		}

		.main_center_2_item {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
			grid-gap: 5px 20px;
			grid-template-rows: 230px 230px;
			height: 470px;
			overflow: hidden;
			padding: 0 8px;

			.item {
				margin-top: 10px;
				font-size: 14px;

				.item_div {
					box-sizing: border-box;
					width: 138px;
					height: 228px;
				}

				.item_img {
					box-sizing: border-box;
					border-radius: 5px;
					width: 100%;
					height: 90%;
					object-fit: cover;
					object-position: center;
					cursor: pointer;
				}

				i {
					display: none;
					position: absolute;
					bottom: 20px;
					padding: 5px 10px;
					background-color: rgba(112, 115, 124, 0.5);
				}

				span {
					max-width: 115px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				span:hover+i {
					display: block;
				}
			}

			.item:nth-child(5n) {
				margin-right: 0;
			}
		}

		.main_center_4_item,
		.main_center_5_item,
		.main_center_6_item {
			display: flex;
			flex-wrap: wrap;

			// justify-content: space-between;
			.item {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				// border-radius: 7px;
				overflow: hidden;
				font-size: 14px;
				color: #4eb8c2;
				margin-top: 13px;

				// margin-right: 13px;
				// margin: 13px;
				.item_img {
					cursor: pointer;
					border-radius: 5px;
					margin-bottom: 8px;
					width: 180px;
					height: 180px;
					margin-right: 13px;
					object-fit: cover;
					object-position: center;
				}

				i {
					width: 184px;
					height: 24px;
					display: none;
					position: absolute;
					bottom: 20px;
					padding: 5px 10px;
					background-color: rgba(112, 115, 124, 0.5);
				}

				span {
					max-width: 115px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				span:hover+i {
					display: block;
				}
			}

			.item:nth-child(5n) {
				margin-right: 0;
			}
		}

		.main_center_3 {
			.main_center_3_item {
				.item {
					display: grid;
					grid-template-columns: repeat(auto-fill, minmax(359px,1fr));
					grid-template-rows: 165px 165px;
					grid-gap: 10px 20px;
					padding: 14px 8px;

					.item_div {
						box-sizing: border-box;
					}

					.item_img {
						width: 100%;
						height: 136px;
						border-radius: 7px;
						cursor: pointer;
					}

				}
			}
		}

		.main_center_7 {
			position: relative;
			min-height: 100px;
			padding-bottom: 0;

			.main_center_6_item {
				ul {
					display: flex;
					list-style: none;
					flex-wrap: wrap;

					li {
						margin-top: 5px;
						margin-right: 12px;
						color: #4eb8c2;
						font-size: 14px;
						cursor: pointer;
					}
				}
			}

			.split {
				margin: 20px -8px 0 -8px;
				height: 1px;
				background-color: #4eb8c2;
			}

			.fav_tag {
				line-height: 40px;
				font-size: 12px;
				color: #4eb8c2;
			}
		}
	}
}

// @media screen and (max-width: 1700px) {
.main {
	margin-top: 14.77px;

	.main_center {
		margin: 0 14.77px;
		flex: 1;
		border-radius: 7px;
	}

	.main_right {
		width: 218.77px;
		height: 100%;
		border-radius: 7px;
	}
}

// }

// @media screen and (min-width: 1700px) {
//   .main {
//     margin-top: 14.77px;

//     .main_center {
//       margin: 0 14.77px;
//       flex: 1;
//       border-radius: 7px;
//     }
//     .main_right {
//       width: 218.77px;
//       border-radius: 7px;
//     }
//   }
// }

/deep/.el-carousel__container {
	height: 305px;
}

/deep/.el-carousel__indicators--horizontal {
	left: unset;
	right: 2%;
	bottom: 1px;
	transform: translateX(0%);
}

/deep/ .el-carousel__button {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 2px solid #72e1e5;
	background-color: rgba(0, 0, 0, 0);
}

/deep/ .is-active .el-carousel__button {
	background: #72e1e5;
}

#margin_left0 {
	margin-left: 0px;
}

#padding {
	padding: 8px;
}

#padding0 {
	padding: 0px;
}

#paddingnone {
	padding: 0;
}

.flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}
</style>
