<template>
  <div class="main_center">
    <div class="loginForm">
      <form action="" class="login-form">
        <input type="text" placeholder="邮箱或手机号" v-model.trim="form.name" class="username" />
        <input type="password" placeholder="密码" v-model.trim="form.pwd" class="password" />
        <div class="flex">
          <input type="text" placeholder="请输入验证码" v-model.trim="form.verifycode" class="verifycode" />
          <img @click="refresh" class="pointer" :src="src" alt="!" />
        </div>
        <div class="user-select">
          <!-- <input type="checkbox" id="yespwd" />
        <label class="fz12 pointer" for="yespwd">记住账号</label>
        <input type="checkbox" id="agreement" value="agreement" v-model="form.agreement" />
        <label for="agreement" class="fz12 agreement pointer"
          >已阅读并同意<a href="javascript:;" @click="toUserAgreement">
            《用户协议》</a
          ></label
        > -->
          <a href="javascript:;" @click="toForgetPwd" class="fz12 forget">忘记密码</a>
        </div>
        <div class="btn">
          <button class="btn-login" type="button" @click="login">登录</button>
          <button type="button" class="btn-register" @click="toRegister">
            注册
          </button>
        </div>
        <div class="wxn">
          <img src="../../assets/imgs/weixingniang.png" />
        </div>
        <div class="tuceng">
          <img src="../../assets/imgs/tuceng19.png" />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        name: "",
        pwd: "",
        verifycode: "",
        agreement: this.$route.params.isAgree,
      },
      src: "http://api.aboom.net/captcha?a=" + Math.random(),
    };
  },
  computed: {
    agreement() {
      return this.$route.params.isAgree
    }
  },
  activated() {
    console.log("activated")
    console.log(this.$route.params)
  },
  mounted() {
    // 
  },
  methods: {
    //图片验证码刷新
    refresh() {
      this.src = "http://api.aboom.net/captcha?a=" + Math.random();
    },
    login() {
      // console.log("提交表单");
      // if (this.form.agreement != true) {
      //   alert("请同意用户协议");
      //   return false;
      // }
      this.$netWork
        .login({
          method: "post",
          url: "/user/login",
          data: {
            account: this.form.name,
            password: this.form.pwd,
            captcha: this.form.verifycode,
          },
        })
        .then(async (result) => {
          if (result.code !== 0) {
            alert(result.msg);
            return;
          }
          // console.log(result);
          localStorage.setItem("token", result?.token);
          let date = new Date()
          let timer = date.getTime()
          console.log(timer);
          localStorage.setItem("loginTime", timer)
          let token = localStorage.getItem("token");
          await this.$netWork
            .getUserInfo({
              method: "post",
              url: "/user/getUserInfo",
              data: {
                token: token,
              },
            })
            .then((result) => {
              if (result.code != 0) {
                return Promise.reject(result.msg);
              }
              localStorage.setItem("userInfo", JSON.stringify(result?.data));
              this.$router.push({ path: "/" });
            })
            .catch((arr) => {
              console.log(arr);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //到注册
    toRegister() {
      this.$router.push({ path: "/register" });
    },
    //到忘记密码
    toForgetPwd() {
      this.$router.push({ path: "/forgetPwd" });
    },
    //到用户协议
    toUserAgreement() {
      this.$router.push({ path: "/userAgreement" });
    },
  },
};
</script>
<style scoped lang="less">
.loginForm {
  // border: 2px solid red;
  background: #35353f;
  border-radius: 20px;
  width: 500px;
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px auto;
}

.pointer {
  cursor: pointer;
}

main,
.main {
  background-color: #08072a !important;
}

.login-form {
  width: 300px;
  height: 200px;
  position: relative;
  // margin: 200px auto;
}

input {
  border-radius: 8px;
  border: none;
}

.username,
.password {
  box-sizing: border-box;
  width: 300px;
  height: 28px;
  text-indent: 1em;
}

.password {
  margin: 18px 0;
}

.verifycode {
  width: 70%;
  height: 28px;
  text-indent: 10px;
}

.login-form>input:nth-child(3)::-webkit-input-placeholder {
  color: #b4b8bd;
}

// .code {
//   display: inline-block;
//   line-height: 24px;
//   width: 78px;
//   height: 24px;
//   background-color: aliceblue;
//   color: green;
//   text-align: center;
// }
img {
  width: 140px;
  height: 60px;
}

.fz12 {
  font-size: 12px;
}

.user-select {
  margin: 8px 0 16px 0;
  // display: flex;
  // justify-content: right;
  align-items: center;
  color: white;
}

.user-select .agreement {
  text-decoration: none;
  color: white;

  a {
    text-decoration: none;
  }
}

.user-select .forget {
  color: #bb3805;
  margin-left: 250px;
}

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn .btn-login {
  background-color: #46b3bd;
}

.btn .btn-register {
  background-color: #35353f;
  border: 1px solid white;
}

.btn-register,
.btn-login {
  border: none;
  width: 140px;
  height: 28px;
  color: white;
  border-radius: 8px;

  cursor: pointer;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.wxn img {
  // background-image: url('../../assets/imgs/卫星娘.png');
  position: absolute;
  left: 350px;
  bottom: -30px;
  width: 140px;
  height: 140px;
  z-index: 458;
}

.tuceng img {
  // background-image: url('../../assets/imgs/图层19.png');
  position: absolute;
  left: -200px;
  top: -70px;
  width: 160px;
  height: 150px;
  z-index: 457;
}

.flex {
  align-items: center;
  img {
    margin-left: 10px;
  }
}
</style>
