<template>
  <div class="wrapper">
    <!-- 侧边栏 -->
    <!-- <div class="leftContent"></div> -->

    <!-- <common-left1 ref="child" @changeWorkList="changeWorkList"></common-left1> -->
    <common-left2 :userid="userid"></common-left2>
    <!-- 内容 -->
    <div class="centerContent">
      <!-- 自我介绍 -->
      <div class="nav1">
        <div class="head">
          <div class="title">自我介绍</div>
          <!-- <a class="change">{{ isEdit ? "取消更改" : "更改" }}</a> -->
        </div>
        <div class="introduce">
          <!-- <p class="text">
						画画爱好者，某某站签约绘师。喜欢的东西是甜品，不喜欢的东西是大蒜。
					</p>
					<p class="text">本着结交朋友的精神，欢迎大家多来交流。</p>
					<p class="text">个人微博：www.baidu.com</p>
					<p class="text">以上</p> -->
          <p class="text">{{ desc }}</p>
        </div>
      </div>
      <!-- 作品 -->
      <div class="nav2">
        <div class="head">
          <div class="title">作品目录</div>
          <!-- <a class="more" @click="more"> >>更多 </a> -->
        </div>
        <div class="worksBox">
          <div class="item" v-for="(item, index) in work" :key="index" @click="goWorkDetail(item)">
            <img :src="item.coverimg" alt="" :title="item.title" />
            <p class="name" style="height: 20px; overflow: hidden; text-overflow: ellipsis">
              {{ item.title }}
              <!-- <span
                style="fontsize: 14px"
                :class="{
                  red: item.wstatus == 3,
                  green: item.wstatus == 2,
                  org: item.wstatus == 1,
                }"
                >{{ auditStatus(item.wstatus) }}</span
              > -->
            </p>
          </div>
        </div>
        <!-- 分页 -->
        <ul v-if="totalPage > 1" class="pages">
          <li class="prev" v-if="totalPage > 1" @click="prev"></li>
          <li v-for="item in totalPage" :key="item" :class="{ active: item == currPage }" @click="refash(item)">
            {{ item }}
          </li>
          <li class="next" @click="next" v-if="totalPage > 1"></li>
        </ul>
      </div>
      <div class="nav3" style="display: none;">
        <div class="head">
          <div class="title">作品留言板</div>
        </div>
        <div class="message scrollBar">
          <div v-for="(item, index) in MessageList" :key="index">
            <div class="item">
              <div class="right">
                <img class="avatar" :src="getUrl(item.avatar)" alt="" />
                <div class="info">
                  <p class="name">
                    {{ item.nickname
                    }}<span class="time">{{ item.send_time }}</span>
                  </p>
                  <p class="text">{{ item.message_content }}</p>
                </div>
              </div>
              <!-- <div class="reply" @click="reply(item.id, item.nickname)">
                回复
              </div> -->
            </div>
            <!-- <div
              class="item"
              v-for="replyItem in item.reply"
              :key="replyItem.id"
            >
              <div class="right">
                <img
                  class="avatar"
                  :src="getUrl(replyItem.userAvatar)"
                  alt=""
                />
                <div class="info">
                  <p class="name">
                    {{ replyItem.nickname
                    }}<span class="time">{{ replyItem.create_time }}</span>
                  </p>
                  <p class="text">
                    回复<span class="replyName">{{ item.nickname }}</span
                    >{{ replyItem.content }}
                  </p>
                </div>
              </div>
              <div class="reply" @click="reply(item.id, replyItem.nickname)">
                回复
              </div>
            </div> -->
          </div>
        </div>
        <!-- <form class="formInfo">
          <input
            class="inputInfo"
            type="text"
            :placeholder="replyNickname"
            v-model="commentContent"
            ref="ipt"
          />
            
          <div class="send" @click="send">发送</div>
        </form> -->
      </div>
    </div>
  </div>
</template>
<script>
import commonLeft2 from "@/components/components_left2.vue";
export default {
  components: {
    commonLeft2,
  },
  data() {
    return {
      worksList: [], //作品
      desc: "", //自我介绍
      newDesc: "",
      isEdit: false, //是否是编辑
      userid: 0,
      introduce: "",
      MessageList: [],
      work: [],
      totalPage: "",
      currPage: "1",
    };
  },
  computed: {
    // imgurl() { //全部作品图片
    // 	this.worksList.forEach((item,index,data)=>{
    // 		if (this.worksList) {
    // 		}
    // 	})
    // }
    // desc(){
    // 	return localStorage.getItem('userInfo').introduction?localStorage.getItem('userInfo').introduction:"暂无信息"
    // }
  },
  created() {
    this.desc = JSON.parse(localStorage.getItem("userInfo")).introduction;
    this.newDesc = this.desc;
    this.userid = this.$route.query.uid;
    this.getUserWorkList();
    this.getOtherUser();
    // this.getMessageList();
  },
  methods: {
    refash(item) {
      this.currPage = item;
      this.getUserWorkList();
    },
    //上一页
    prev() {
      if (this.currPage == 1) {
        this.$message.error("已经是第一页了");
        return false;
      }
      this.currPage--;
      this.getUserWorkList();
    },
    //下一页
    next() {
      if (this.currPage >= this.totalPage) {
        this.$message.error("已经是最后一页了");
        return false;
      }
      this.currPage++;
      this.getUserWorkList();
    },
    //获取聊天列表
    async getMessageList() {
      let token = localStorage.getItem("token");
      let res = await this.$netWork.getMessageList({
        url: "/message/getList",
        method: "post",
        data: {
          token: token,
        },
      });
      // if(res.code == 0 ){
      this.MessageList = res.data;
      // }
    },
    async getOtherUser() {
      let res = await this.$netWork.attention({
        method: "post",
        url: "/user/getUserInfoById",
        data: {
          uid: this.userid,
        },
      });
      if (res.code == 0) {
        this.desc = res.data.introduction;
      }
    },
    auditStatus(wstatus) {
      if (wstatus == 1) {
        return "待审核";
      }
      if (wstatus == 2) {
        return "审核通过";
      }
      if (wstatus == 3) {
        return "审核未通过";
      }
    },
    async bcxg() {
      let introduction = this.$refs.edit.value;
      this.desc = introduction;
      let token = localStorage.getItem("token");
      if (!token) {
        return false;
      }
      let res = await this.$netWork.descChange({
        url: "/user/editIntroduction",
        method: "post",
        data: {
          token: token,
          introduction: introduction,
        },
      });
      if (res.code == 0) {
        this.$message.success("修改成功");
        this.isEdit = false;
        await this.$netWork
          .getUserInfo({
            method: "post",
            url: "/user/getUserInfo",
            data: {
              token: token,
            },
          })
          .then((result) => {
            if (result.code != 0) {
              return Promise.reject(result.msg);
            }
            localStorage.setItem("userInfo", JSON.stringify(result?.data));
          })
          .catch((arr) => {
            console.log(arr);
          });
      }
    },
    changeEdit() {
      if (this.isEdit) {
        this.isEdit = false;
        return false;
      }
      this.isEdit = true;
      setTimeout(() => {
        let a = this.$refs.edit.value.length;
        this.$refs.edit.focus();
        this.$refs.edit.setSelectionRange(a, a);
      }, 0);
    },
    more() {
      this.$router.push({
        path: "./userWorkOne",
      });
    },
    // 获取本人作品目录
    async getUserWorkList() {
      let page1 = 0;
      let res = await this.$netWork.getUserWorkList({
        url: "/works/getWorksList",
        method: "post",
        data: {
          type: "my",
          // page: "1",
          page: this.currPage,
          id: this.userid,
          works_type: "所有",
          status: '2'
        },
      });
      //筛选
      this.work = res.data.list.filter((item) => {
        return item.wstatus == 2;
      });
      console.log(this.work.length, '789987')
      console.log(res, "推翻78丰饶iaeh");
      this.totalPage = res.data.totalPage;
      console.log(res, "resresresresresres");
      if (res.code === 0) {
        // if (res.data.list.length > 3) {
        //   this.worksList = res.data.list.slice(0, 6);
        //   console.log("123");
        //   console.log(res.data.list);
        //   return false;
        // }
        // this.worksList = res.data.list;
        console.log("456");
      }
      console.log(res.data.list);
    },
    //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    // 去作品详情
    goWorkDetail(data) {
      let url = this.$router.resolve({
        name: "workDetail1",
        query: {
          work_id: data.id,
          uid: data.uid,
        },
      });
      window.open(url.href, '_blank')
    },
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.pages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  color: #4db9c2;
}

.pages li {
  margin-right: 16px;
  cursor: pointer;
}

.pages li:hover {
  color: #ffffff;
}

.pages .active {
  color: #ffffff;
}

.pages .prev {
  transform: rotate(180deg);
  width: 48px;
  height: 25px;
  background: #5accd8;
  background-image: url("../../assets/img/home/fanye3.png");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  border-radius: 3px;
}

.pages .next {
  width: 48px;
  height: 25px;
  background: #5accd8;
  background-image: url("../../assets/img/home/fanye3.png");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  border-radius: 3px;
}

a {
  text-decoration: none;
  color: #5dbbbf;
}

li {
  color: #5dbbbf;
  list-style: none;
}

.scrollBar {
  overflow-y: auto;
}

.bcxg {
  width: 116px;
  height: 36px;
  margin-left: 15px;
  line-height: 36px;
  text-align: center;
  color: #0c424f;
  cursor: pointer;
  background: #5accd8;
  border: none;
  border-radius: 7px;
}

/*滚动条整体样式*/
.scrollBar::-webkit-scrollbar {
  width: 18px;
  height: 10px;
  background: #35363f;
}

/*滚动条滑块*/
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #5accd8;
}

/*滚动条轨道*/
.scrollBar::-webkit-scrollbar-track {
  border: 1px solid #5accd8;
  border-radius: 4px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
}

.wrapper .centerContent {
  flex: 1;
  margin-left: 20px;
  // height: 1065px;
}

.wrapper .centerContent .nav1 {
  overflow: hidden;
  height: 197px;
  border-radius: 7px;
  background: #35363f;
}

.wrapper .centerContent .nav1 .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  margin: 8px 8px 0px 8px;
  padding: 0 5px;
  color: #5dbbbf;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: #28262e;
}

.wrapper .centerContent .nav1 .head .change {
  cursor: pointer;
}

.wrapper .centerContent .nav1 .introduce {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: lighter;
  color: #5dbbbf;
}

.wrapper .centerContent .nav1 .introduce .text {
  line-height: 28px;
}

.wrapper .centerContent .nav2 {
  overflow: hidden;
  // height: 375px;
  margin-top: 14.77px;
  border-radius: 7px;
  background: #35363f;
}

.wrapper .centerContent .nav2 .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  margin: 8px 8px 0px 8px;
  padding: 0 5px;
  color: #5dbbbf;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: #28262e;
}

.wrapper .centerContent .nav2 .worksBox {
  display: flex;
  justify-content: flex-start;
  // justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 1rem;
}

.wrapper .centerContent .nav2 .worksBox .item {
  display: flex;
  padding: 0.2rem;
  padding-left: 14px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // margin-bottom: 26px;
  // width: 246px;
  // height: 246px;
  // margin: 1rem;
  width: 180px;
  height: 246px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.wrapper .centerContent .nav2 .worksBox .item img {
  // width: 100%;
  // height: 100%;
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 7px;
}

.wrapper .centerContent .nav2 .worksBox .item .name {
  padding-top: 10px;
  color: #5dbbbf;
}

.wrapper .centerContent .nav2 .more {
  display: flex;
  justify-content: flex-end;
  // padding: 0 20px 0 0;
  color: #5dbbbf;
  font-size: 14px;
  font-weight: lighter;
  cursor: pointer;
}

.wrapper .centerContent .nav3 {
  overflow: hidden;
  height: 459px;
  margin-top: 14.77px;
  border-radius: 7px;
  background: #35363f;
}

.wrapper .centerContent .nav3 .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  margin: 8px 8px 0px 8px;
  padding: 0 5px;
  color: #5dbbbf;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: #28262e;
}

.wrapper .centerContent .nav3 .message {
  position: relative;
  overflow-y: auto;
  height: 312px;
  margin: 10px 10px 0 0;
  padding: 10px 50px 20px 20px;
  color: #5dbbbf;
}

.wrapper .centerContent .nav3 .message .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid #46535a;
}

.wrapper .centerContent .nav3 .message .item .right {
  display: flex;
}

.wrapper .centerContent .nav3 .message .item .right .avatar {
  width: 40px;
  height: 40px;
  border-radius: 7px;
}

.wrapper .centerContent .nav3 .message .item .right .info {
  margin-left: 20px;
}

.wrapper .centerContent .nav3 .message .item .right .info .name {
  font-size: 13px;
  font-weight: lighter;
}

.wrapper .centerContent .nav3 .message .item .right .info .name .time {
  font-size: 12px;
  padding-left: 20px;
}

.wrapper .centerContent .nav3 .message .item .right .info .text {
  font-size: 13px;
  font-weight: lighter;
  color: #ffffff;
}

.wrapper .centerContent .nav3 .message .item .right .info .text .replyName {
  color: #5dbbbf;
}

.wrapper .centerContent .nav3 .message .item .reply {
  font-size: 14px;
  font-weight: lighter;
  cursor: pointer;
}

.wrapper .centerContent .nav3 .formInfo {
  display: flex;
  align-items: center;
  margin: 14px 15px 0px 15px;
}

.wrapper .centerContent .nav3 .formInfo .inputInfo {
  width: 100%;
  height: 32px;
  padding-left: 10px;
  color: #ffffff;
  background: #28262e;
  border-radius: 7px;
  border: none;
}

.wrapper .centerContent .nav3 .formInfo .send {
  width: 116px;
  height: 36px;
  margin-left: 15px;
  line-height: 36px;
  text-align: center;
  color: #0c424f;
  cursor: pointer;
  background: #5accd8;
  border: none;
  border-radius: 7px;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;

.nav2 {
  overflow: hidden;
  // height: 459px;
  margin-top: 14.77px;
  border-radius: 7px;
  background: @bg1Color;

  .head {
    height: 38px;
    margin: 8px 8px 0 8px;
    padding: 0 5px;
    line-height: 38px;
    color: @fsColor;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background: @bgColor;
  }

  .message {
    position: relative;
    overflow-y: auto;
    height: 312px;
    margin: 10px 10px 0 0;
    padding: 10px 50px 20px 20px;
    color: @fsColor;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      padding-bottom: 12px;
      border-bottom: 1px solid @lineColor;

      .right {
        display: flex;

        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 7px;
        }

        .info {
          margin-left: 20px;

          .name {
            font-size: 13px;
            font-weight: lighter;

            .time {
              font-size: 12px;
              padding-left: 20px;
            }
          }

          .text {
            font-size: 13px;
            font-weight: lighter;
            color: @iptfsColor;

            .replyName {
              color: @fsColor;
            }
          }
        }
      }

      .reply {
        font-size: 14px;
        font-weight: lighter;
        cursor: pointer;
      }
    }
  }

  .formInfo {
    display: flex;
    align-items: center;
    margin: 14px 15px 0px 15px;

    .inputInfo {
      width: 100%;
      height: 32px;
      padding-left: 10px;
      color: @iptfsColor;
      background: @bgColor;
      border-radius: 7px;
      border: none;
    }

    .send {
      width: 116px;
      height: 36px;
      margin-left: 15px;
      line-height: 36px;
      text-align: center;
      color: @btnfsColor;
      cursor: pointer;
      background: @btnColor;
      border: none;
      border-radius: 7px;
    }
  }
}

.red {
  color: red !important;
}

.green {
  color: green !important;
}

.org {
  color: orange !important;
}
</style>
