<template>
  <div>
    <div class="pf" ref="pf" @mouseleave="hide">
      <div v-for="(item, index) in 10" id="dl" :class="a(index)" @mouseenter="show(index)" @mouseleave="hide(index)"
        @click="pf(index)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rate",
  props:['sc'],
  inject:['score'],
  data() {
    return {
      bgcMap: {
        0: "#d3f1f5",
        1: "#b0dde3",
        2: "#7acdda",
        3: "#5cccdc",
        4: "#26c5dc",
        5: "#03bbd6",
        6: "#02abc1",
        7: "#0098a6",
        8: "#017080",
        9: "#015d67"
      },
      ispf: false,
    }
  },
  mounted() {
    // this.getWorkDetail();
    // this.views++
    console.log(this.score,"inj");
    console.log(this.sc);
    // var score = this.$parent.score
    // console.log(score,"pf");
    // if(score){
    //   this.show(score)
    //   this.ispf = true
    // }
  },
  methods: {
    a(index) {
      return {
        [this.bgcMap[index]]: true
      }
    },
    pf(idx) {
      this.$parent.toScore(idx + 1)
      // let isScore = JSON.parse(sessionStorage.getItem("isScore"));
      // console.log(isScore);
      if (this.$parent.score) {
        this.ispf = true
      }
    },
    show(idx) {
      if (this.ispf) {
        return
      }
      for (var i = 0; i <= idx; i++) {
        this.$refs.pf.children[i].style.backgroundColor = this.bgcMap[i]
      }
    },
    hide(idx) {
      if (this.ispf) {
        return
      }
      console.log(idx, "6666", typeof idx);
      if (typeof idx == "object") {
        for (var i = 0; i < 10; i++) {
          if (this.$refs.pf.children[i]?.style) {
            this.$refs.pf.children[i].style.backgroundColor = ""
          }
        }
        return
      }
      if (idx) {
        if (this.$refs.pf.children[idx]?.style) {
          this.$refs.pf.children[idx].style.backgroundColor = ""

        }
      } else {
        console.log(12321);
        for (var i = 0; i < 10; i++) {
          if (this.$refs.pf.children[i]?.style) {
            this.$refs.pf.children[i].style.backgroundColor = ""

          }
        }
      }

    }
  }
}
</script>

<style>
.pf {
  width: 300px;
  height: 44px;
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  margin: 20px auto;
  border: 2px solid #5ce2ed;
  border-radius: 5px;
}

.pf::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 20px;
  border-radius: 2px;
  background-color: #5ce2ed;
  right: -12px;
}

#dl {
  box-sizing: border-box;
  width: 28px;
  height: 40px;
}
</style>