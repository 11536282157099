import axios from "axios";
//dev开发环境  pro 生产环境
// const baseURL = process.env.NODE_ENV === "production" ? "http://api.aboom.net" : "http://api.aboom.net";
const baseURL = process.env.NODE_ENV === "production" ? "https://api.aboom.net" : "https://api.aboom.net";
let instance = axios.create({
  baseURL,
  timeout: 10000,
});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
instance.interceptors.response.use(
  (res) => {
    // if(res.data.code==0){
    //   // this.$message(res.data.msg)
    //   console.log(res.data.msg,"666666666963258741");
    // }
    return res.data;
  },
  (err) => {
    
    return Promise.reject(err);
  }
);
export default instance;
