<template>
    <div class="link">
        <p class="title">友情链接</p>
        <p class="content">原画邦的小伙伴,可以在这里点击交换友情链接。</p>
        <div class="linkAddress">
            <div>
                <a href="http://www.tiangushan.cn/" target="_blank"><img src="../../assets/imgs/tencent.png" /></a>
            </div>
            <div>
                <a href="http://comic.sina.com.cn/" target="_blank"><img src="../../assets/imgs/sina.png" /></a>
            </div>
            <div>
                <a href="https://www.bilibili.com/" target="_blank"><img src="../../assets/imgs/bilibili.png" /></a>
            </div>
            <div>
                <a href="https://weibo.com/" target="_blank"><img src="../../assets/imgs/weibo.png" /></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Link"
}
</script>

<style scoped>
/* 背景 */
.link {
    height: 400px;
    background: #353640;
    margin: 30px auto;
    border-radius: 10px;
    cursor: auto;
    padding: 14px 11px;
}

/* 大标题 */
.title {
    background: #26272D;
    border-radius: 10px;
    color: #5CE2ED;
    padding: 5px 12px;
    margin-bottom: 16px;
}

.content {
    text-align: center;
    margin: 100px 0 44px 0;
    color: #5CE2ED;
}

.linkAddress {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkAddress div {
    width: 180px;
    height: 70px;
    margin-right: 100px;
}
.linkAddress div:last-child{
    margin-right: 0;
}
</style>