<template>
  <div class="wrapper">
    <!-- 侧边栏 -->
    <common-left1></common-left1>
    <!-- 内容 -->
    <div class="centerContent">
      <div class="title">推荐用户目录</div>
      <div class="userBox scrollBar">
        <div
          class="userInfo"
          v-for="item in user"
          :key="item.id"
          @click="otherUser(item.id)"
        >
          <img
            class="avatar"
            :src="`http://api.aboom.net${item.avatar_path}`"
            alt=""
          />
          <span class="name">{{ item.nickname }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonLeft1 from "../../components/common_left1.vue";
export default {
  name: "copyrightProtection",
  components: { commonLeft1 },
  data() {
    return {
      list: [
        { url: require("../../assets/img/home/yonghu1.png"), name: "吴彦祖" },
        { url: require("../../assets/img/home/yonghu2.png"), name: "金城武" },
        { url: require("../../assets/img/home/yonghu5.png"), name: "金三胖" },
        { url: require("../../assets/img/home/yonghu4.png"), name: "梁朝伟" },
        { url: require("../../assets/img/home/yonghu5.png"), name: "范冰冰" },
      ],
      user: [], //推荐用户列表
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    //为什么这个函数放在getUser函数后面就会报错？？？
    otherUser(uid) {
      this.$router.push({
        name: "otherUserProfile",
        query: { uid: uid },
      });
    },
    async getUser() {
      let id = JSON.parse(localStorage.getItem("userInfo")).id;
      let res = await this.$netWork.getUser({
        url: "/user/getUser",
        method: "post",
        data: {
          id: id,
        },
      });
      console.log(res,'推荐用户')
      if (res.code == 0) {
        this.user = res.data.filter((item) => {
          return item.id != null;
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.scrollBar {
  overflow-y: auto;
}

/*滚动条整体样式*/
.scrollBar::-webkit-scrollbar {
  width: 18px;
  height: 10px;
  background: @bgColor;
}
/*滚动条滑块*/
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: @btnColor;
}

/*滚动条轨道*/
.scrollBar::-webkit-scrollbar-track {
  border: 1px solid @btnColor;
  border-radius: 4px;
}
@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
  .centerContent {
    margin-left: 14.77px;
    height: 736px;
    border-radius: 7px;
    background: @bg1Color;
    .title {
      height: 38px;
      margin: 8px 8px 0px 8px;
      padding-left: 5px;
      line-height: 38px;
      color: @fsColor;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: @bgColor;
    }
    .userBox {
      height: 670px;
      margin: 28px 20px 30px 30px;
      .userInfo {
        display: inline-block;
        width: 300px;
        margin-bottom: 40px;
        .avatar {
          width: 40px;
          height: 40px;
          vertical-align: middle;
          border-radius: 7px;
        }
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0 20px;
          color: @fsColor;
        }
      }
    }
  }
}
</style>