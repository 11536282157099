<template>
	<div class="useForm">
		<p class="title">原画邦隐私政策</p>
<!-- 		<p class="agreement">更新时间:2022年【5】月【7】日</p>
		<p class="agreement1">生效时间:2022年【5】月【10】日</p> -->
		<p class="head">【引言】</p>
		<div class="textarea">
			&emsp;&emsp;原画邦非常重视用户信息安全与隐私保护，秉承“用户至上”的理念，致力于提升信息处理透明度，增强您对信息管理的便捷性，保障您的信息及通信安全。
			原画邦严格遵守法律法规，遵循以下隐私保护原则，为您提供更加安全、可靠的服务：
			1、安全可靠：我们竭尽全力通过合理有效的信息安全技术及管理流程，防止您的信息泄露、损毁、丢失。
			2、自主选择：我们为您提供便利的信息管理选项，以便您做出合适的选择，管理您的个人信息。
			3、保护通信秘密：我们严格遵照法律法规，保护您的通信秘密，为您提供安全的通信服务。
			4、合理必要：为了向您和其他用户提供更好的服务，我们仅收集必要的信息。
			5、清晰透明：我们努力使用简明易懂的表述，向您介绍隐私政策，以便您清晰地了解我们的信息处理方式。
			6、将隐私保护融入产品设计：我们在产品或服务开发的各个环节，综合法律、产品、设计等多方因素，融入隐私保护的理念。
			本《隐私政策》主要向您说明：
			我们收集哪些信息；
			我们收集信息的用途；
			您所享有的权利。
			希望您仔细阅读《隐私政策》（以下简称“本政策”），详细了解我们对信息的收集、使用方式，以便您更好地了解我们的服务并作出适当的选择。
			若您使用原画邦服务，即表示您认同我们在本政策中所述内容。除另有约定外，本政策所用术语与《原画邦免责声明》中的术语具有相同的涵义。
			如您有问题，请联系我们。
		</div>
		<div class="textarea">
			&emsp;&emsp;一、我们收集的信息
			我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。
			1.1 您在使用我们服务时主动提供的信息
			1.1.1 您在注册帐户时填写的信息。
			例如，您在注册时所填写的昵称、手机号码。
			1.1.2 您在使用服务时上传的信息。
			例如，您在使用时，上传的头像、分享的图片。
			1.1.3 您通过我们的客服或参加我们举办的活动时所提交的信息。
			例如，您参与我们线上活动时填写的调查问卷中可能包含您的姓名、电话、家庭地址等信息。
			我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。
			1.2 我们在您使用服务时获取的信息
			1.2.1 日志信息。当您使用我们的服务时，我们可能会自动收集相关信息并存储为服务日志信息。
			(1) 设备信息。
			例如，设备型号、操作系统版本、唯一设备标识符、电池、信号强度等信息。
			(2) 软件信息。
			例如，软件的版本号、浏览器类型。为确保操作环境的安全或提供服务所需，我们会收集有关您使用的移动应用和其他软件的信息。
			(3) IP地址。
			(4) 服务日志信息。
			例如，您在使用我们服务时搜索、查看的信息、服务故障信息、引荐网址等信息。
			(5) 通讯日志信息。例如，您在使用我们服务时曾经通讯的账户、通讯时间和时长。
			1.2.2 位置信息。当您使用与位置有关的服务时，我们可能会记录您设备所在的位置信息，以便为您提供相关服务。
			(1) 在您使用服务时，我们可能会通过IP地址、GPS、WiFi或基站等途径获取您的地理位置信息；
			(2) 您或其他用户在使用服务时提供的信息中可能包含您所在地理位置信息。
			例如您提供的帐号信息中可能包含的您所在地区信息，您或其他人共享的照片包含的地理标记信息；
			1.2.3 其他相关信息。为了帮助您更好地使用我们的产品或服务，我们会收集相关信息。
			例如，我们收集的好友列表、群列表信息、声纹特征值信息。为确保您使用我们服务时能与您认识的人进行联系，如您选择开启导入通讯录功能，我们可能对您联系人的姓名和电话号码进行加密，并仅收集加密后的信息。
			1.3 其他用户分享的信息中含有您的信息。
			例如，其他用户发布的图片可能包含您的信息。
			1.4
			从第三方合作伙伴获取的信息我们可能会获得您在使用第三方合作伙伴服务时所产生或分享的信息。例如，您使用微信或QQ帐户登录第三方合作伙伴服务时，我们会获得您登录第三方合作伙伴服务的名称、登录时间，方便您进行授权管理。请您仔细阅读第三方合作伙伴服务的用户协议或隐私政策。
		</div>
		<div class="textarea">
			&emsp;&emsp;二、我们如何使用收集的信息
			我们严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途。若我们超出以下用途使用您的信息，我们将再次向您进行说明，并征得您的同意。
			2.1 向您提供服务。
			2.2 满足您的个性化需求。例如，语言设定、位置设定、个性化的帮助服务。
			2.3 产品开发和服务优化。例如，当我们的系统发生故障时，我们会记录和分析系统故障时产生的信息，优化我们的服务。
			2.4 安全保障。例如，我们会将您的信息用于身份验证、安全防范、反诈骗监测、存档备份、客户的安全服务等用途。例如，您下载或安装的安全软件会对恶意程序或病毒进行检测，或为您识别诈骗信息。
			2.5 向您推荐您可能感兴趣的广告、资讯等。
			2.6 评估、改善我们的广告投放和其他促销及推广活动的效果。
			2.7 管理软件。例如，进行软件认证、软件升级等。
			2.8 邀请您参与有关我们服务的调查。
			为了让您有更好的体验、改善我们的服务或经您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某些服务所收集的信息用于我们的其他服务。例如，将您在使用我们某项服务时的信息，用于另一项服务中向您展示个性化的内容或广告、用于用户研究分析与统计等服务。
			为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息，例如，您使用应用程序的频率、故障信息、总体使用情况、性能数据以及应用程序的来源。我们不会将我们存储在分析软件中的信息与您在应用程序中提供的个人身份信息相结合。
		</div>
		<div class="textarea">
			&emsp;&emsp;三、我们如何使用Cookie及相关技术
			我们或我们的第三方合作伙伴，可能通过放置安全的Cookie及相关技术收集您的信息，目的是为您提供更个性化的用户体验和服务。我们会严格要求第三方合作伙伴遵守本政策的相关规定。
			您也可以通过浏览器设置管理Cookie。但请注意，如果停用Cookie，您可能无法享受最佳的服务体验，某些服务也可能无法正常使用。若您希望了解更多Cookie的安全性等信息，可参见《Cookie政策说明》。
		</div>
		<div class="textarea">
			&emsp;&emsp;四、您分享的信息
			您可以通过我们的服务与您的好友、家人及其他用户分享您的相关信息。例如，您在微信朋友圈中公开分享的文字和照片。请注意，这其中可能包含您的个人身份信息、个人财产信息等敏感信息。请您谨慎考虑披露您的相关个人敏感信息。
			您可通过我们服务中的隐私设置来控制您分享信息的范围，也可通过服务中的设置或我们提供的指引删除您公开分享的信息。但请您注意，这些信息仍可能由其他用户或不受我们控制的非关联第三方独立地保存。
		</div>
		<div class="textarea">
			&emsp;&emsp;五、您如何管理自己的信息
			5.1
			您可以在使用我们服务的过程中，访问、修改和删除您提供的注册信息和其他个人信息，也可按照通知指引与我们联系。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。例如，若您在使用地理位置相关服务时，希望停止分享您的地理位置信息，您可通过手机定位关闭功能、软硬件服务商及通讯服务提供商的关闭方式停止分享，建议您仔细阅读相关指引。
			5.2
			我们将按照本政策所述，仅为实现我们产品或服务的功能，收集、使用您的信息。如您发现我们违反法律、行政法规的规定或者双方的约定收集、使用您的个人信息，您可以要求我们删除。如您发现我们收集、存储的您的个人信息有错误的，您也可以要求我们更正。请通过本政策列明的联系方式与我们联系。
			5.3 在您访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障帐号的安全。
			5.4 请您理解，由于技术所限、法律或监管要求，我们可能无法满足您的所有要求，我们会在合理的期限内答复您的请求。
		</div>
		<div class="textarea">
			&emsp;&emsp;六、我们分享的信息
			我们遵照法律法规的规定，对信息的分享进行严格的限制，例如：
			6.1 经您事先同意，我们可能与第三方分享您的个人信息；
			6.2
			仅为实现外部处理的目的，我们可能会与第三方合作伙伴（第三方服务供应商、承包商、代理、广告合作伙伴、应用开发者等，例如，代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置服务的地图服务供应商）（他们可能并非位于您所在的法域）分享您的个人信息，让他们按照我们的说明、隐私政策以及其他相关的保密和安全措施来为我们处理上述信息，并用于以下用途：
			6.2.1 向您提供我们的服务；
			6.2.2 实现“我们如何使用收集的信息”部分所述目的；
			6.2.3 履行我们在《原画邦免责声明》或本政策中的义务和行使我们的权利；
			6.2.4 理解、维护和改善我们的服务。
			如我们与上述第三方分享您的信息，我们将会采用加密、匿名化处理等手段保障您的信息安全。
			6.3
			随着我们业务的持续发展，当发生合并、收购、资产转让等交易导致向第三方分享您的个人信息时，我们将通过推送通知、公告等形式告知您相关情形，按照法律法规及不低于本政策所要求的标准继续保护或要求新的管理者继续保护您的个人信息。
			6.4
			我们会将所收集到的信息用于大数据分析。例如，我们将收集到的信息用于分析形成不包含任何个人信息的城市热力图或行业洞察报告。我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解用户如何使用我们服务或让公众了解我们服务的总体使用趋势。
			6.5 我们可能基于以下目的披露您的个人信息：
			6.5.1 遵守适用的法律法规等有关规定；
			6.5.2 遵守法院判决、裁定或其他法律程序的规定；
			6.5.3 遵守相关政府机关或其他法定授权组织的要求；
			6.5.4 我们有理由确信需要遵守法律法规等有关规定；
			6.5.5 为执行相关服务协议或本政策、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途。
		</div>
		<div class="textarea">
			&emsp;&emsp;七、我们可能向您发送的信息
			7.1 信息推送
			您在使用我们的服务时，我们可能向您发送电子邮件、短信、资讯或推送通知。您可以按照我们的相关提示，在设备上选择取消订阅。
			7.2 与服务有关的公告
			我们可能在必要时（例如，因系统维护而暂停某一项服务时）向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于广告的公告。
		</div>
		<div class="textarea">
			&emsp;&emsp;八、存储信息的地点和期限
			8.1 存储信息的地点
			我们遵守法律法规的规定，将境内收集的用户个人信息存储于境内。
			8.2 存储信息的期限
			一般而言，我们仅为实现目的所必需的最短时间保留您的个人信息。但在下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：
			8.2.1 为遵守适用的法律法规等有关规定；
			8.2.2 为遵守法院判决、裁定或其他法律程序的规定；
			8.2.3 为遵守相关政府机关或法定授权组织的要求；
			8.2.4 我们有理由确信需要遵守法律法规等有关规定；
			8.2.5 为执行相关服务协议或本政策、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。
			当我们的产品或服务发生停止运营的情形时，我们将采取例如，推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。
		</div>
		<div class="textarea">
			&emsp;&emsp;九、信息安全
			我们为您的信息提供相应的安全保障，以防止信息的丢失、不当使用、未经授权访问或披露。
			9.1 我们严格遵守法律法规保护用户的通信秘密。
			9.2 我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们使用加密技术（例如，TLS、SSL）、匿名化处理等手段来保护您的个人信息。
			9.3 我们建立专门的管理制度、流程和组织确保信息安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审查。
			9.4 若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。
			我们可能使用您的相关信息，在相关网站、应用及其他渠道向您提供与您更加相关的广告。您可以在关于广告页面中了解更多。
		</div>
		<div class="textarea">
			&emsp;&emsp;十一、未成年人保护
			我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定，若您是18周岁以下的未成年人，在使用原画邦的服务前，应事先取得您的家长或法定监护人的同意。若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过第十三部分中的联系方式与我们联系。
		</div>
		<div class="textarea">
			&emsp;&emsp;十二、适用范围
			我们的所有服务均适用本政策。但某些服务有其特定的隐私指引/声明，该特定隐私指引/声明更具体地说明我们在该服务中如何处理您的信息。如本政策与特定服务的隐私指引/声明有不一致之处，请以该特定隐私指引/声明为准。
			请您注意，本政策不适用由其他公司或个人提供的服务。例如，您通过使用微信帐号登录其他公司或个人提供的服务。
			您使用该等第三方服务须受其隐私政策（而非本政策）约束，您需要仔细阅读其政策内容。
		</div>
		<div class="textarea">
			&emsp;&emsp;十三、联系我们
			如您对本政策或其他相关事宜有疑问，请过 http://AnimeBoom.com/about/contact/
			与我们联系。您也可根据我们提供的指引，填写相关资料，将您的问题发送至 animeboom@sakudora.com我们将尽快审核所涉问题，并在验证您的用户身份后的三十天内予以回复。
		</div>
		<div class="textarea">
			&emsp;&emsp;十四、变更
			我们可能适时修订本政策内容。如该等变更会导致您在本政策项下权利的实质减损，我们将在变更生效前，通过在页面显著位置提示、向您发送电子邮件等方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的政策约束。
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				isAgree: false,
			};
		},
		mounted() {},
		methods: {
			toLogin() {
				this.$router.push({
					name: "login",
					params: {
						isAgree: this.isAgree
					}
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.useForm {
		width: 800px;
		min-height: 550px;
		background: white;
		margin: 30px auto;
		padding: 10px 0;
		position: relative;
		border-radius: 10px;
	}

	// .agreement {
	// 	font-size: 14px;
	// 	color: black;
	// 	margin: 30px 0 20px 30px;
	// }

	// .agreement1 {
	// 	font-size: 14px;
	// 	color: black;
	// 	margin-left: 30px;
	// 	margin-top: -18px;
	// }

	.head {
		font-size: 14px;
		color: black;
		margin-left: 30px;
		margin-top: 15px;
	}

	.title {
		font-size: 25px;
		font-weight: 700;
		padding-top: 20px;
		color: #4eb8c2;
		text-align: center;
	}

	.textarea {
		margin-left: 30px;
		font-size: 14px;
		color: black;
		margin-right: 30px;
		margin-top: 15px;
	}
</style>
