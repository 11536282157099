<template>
  <div class="main">
    <div class="main_center">
      <div class="center_title">
        {{ query.type == 0 ? '首页' : query.type == 1 ? '漫画邦' : '绘画邦'}}
        专题活动一览
      </div>
      <div class="center_items">
        <div class="items_item" v-for="(item, index) in activityList" :key="index">
          <div class="item_left">
            <img :src="item.file_path" :title="item.title" alt="" />
          </div>
          <div class="item_right">
            <a href="#">{{ item.title }}</a>
            <div class="right_enter" @click="goDetail(item)">进入</div>
          </div>
        </div>
        <!-- <div class="items_item">
          <div class="item_left">
            <img src="~@/assets/img/home/zhuanti1.png" alt="" />
          </div>
          <div class="item_right">
            <a href="#">【企划】夏日少女特辑</a>
            <div class="right_enter">进入</div>
          </div>
        </div>
        <div class="items_item">
          <div class="item_left">
            <img src="~@/assets/img/home/zhuanti1.png" alt="" />
          </div>
          <div class="item_right">
            <a href="#">【企划】夏日少女特辑</a>
            <div class="right_enter">进入</div>
          </div>
        </div> -->
        <ul class="pages bottomPages">
          <li class="active">1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
          <li>6</li>
          <li class="next"></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "zuanti",
  data() {
    return {
      query: {
        type: 0
      },
      activityList: [], //专题列表
    };
  },
  mounted() {
    if (JSON.stringify(this.$route.query) != '{}') {
      this.query = this.$route.query;
    }
    console.log('---', this.$route);
    this.getActivity();
  },
  methods: {
    // 专题活动
    async getActivity() {
      const activity = await this.$netWork.getActivity({
        method: "post",
        url: "/activity/getActivity",
        data: {
          type: this.query.type
        }
      });
      this.activityList = activity.data;
    },
    //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    // 进入专题
    goDetail(item) {
      let url = this.$router.resolve({ name: "zuanti2", query: { data: JSON.stringify(item) } });
      window.open(url.href, '_blank')
    },
  },
};
</script>
<style lang="less" scoped>
.main_center,
.center_title {
  border-radius: 7px;
}

.main_center {
  color: #4eb8c2;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: #34373f !important;
}

.main_center .center_title {
  margin-top: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #26272e;
}

.main_center .center_items {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.main_center .center_items .items_item {
  display: flex;
  align-items: center;
  width: 700px;
  height: 200px;
  border-top: 1px solid #4eb8c2;
}

.main_center .center_items .items_item .item_left {
  margin-right: 15px;
}

.main_center .center_items .items_item .item_left img {
  width: 130px;
  height: 140px;
  object-fit: cover;
}

.main_center .center_items .items_item .item_right {
  display: flex;
  align-items: center;
}

.main_center .center_items .items_item .item_right a {
  font-size: 14px;
  color: #4eb8c2;
}

.main_center .center_items .items_item .right_enter {
  text-align: center;
  margin-left: 90px;
  width: 100px;
  line-height: 40px;
  color: black;
  border-radius: 3px;
  font-size: 12px;
  background-color: #4eb8c2;
  cursor: pointer;
}

.main_center .center_items .items_item {
  border-bottom: 1px solid #4eb8c2;
}

.main_center .center_paging {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.pages {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 20px;
}

.pages li {
  margin-right: 16px;
  cursor: pointer;
  list-style: none;
}

.pages li:hover {
  color: #ffffff;
}

.pages .active {
  color: #ffffff;
}

.pages .next {
  width: 48px;
  height: 25px;
  background: #5accd8;
  background-image: url("../../assets/img/home/fanye3.png");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  border-radius: 3px;
}

.bottomPages {
  padding-top: 15px;
}
</style>