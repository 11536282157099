import { render, staticRenderFns } from "./BindEmail.vue?vue&type=template&id=a1e4e202&scoped=true&"
import script from "./BindEmail.vue?vue&type=script&lang=js&"
export * from "./BindEmail.vue?vue&type=script&lang=js&"
import style0 from "./BindEmail.vue?vue&type=style&index=0&id=a1e4e202&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1e4e202",
  null
  
)

export default component.exports