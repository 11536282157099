<template>
	<div class="wrapper clearfix">
		<!-- 侧边栏 -->
		<common-left1></common-left1>
		<!-- 内容 -->
		<div class="centerContent">
			<div class="title">申请版权托管</div>
			<div class="copyrightManageOnecontent">
				<div class="explain">版权托管说明</div>
				<p class="text">
					&emsp;&emsp;版权托管是一项将您已经登记完成的作品版权委托给原画邦平台进行版权运营的管家式服务，您托管的作品版权可以有更多的发展机会，与众多的渠道、资源进行合作。
					平台将积极的向各方推荐您托管的作品版权，您有机会将您托管的版权制成实体商品、数字虚拟商品。
					进行版权托管需要您和平台达成一致的共识，您需要签订版权托管协议，协议将以电子邮件的形式先发送到您提供的电子邮箱中，签署完成后平台将在14个工作日内通过电子邮件的形式告知您审核结果。
				</p>
				<a class="go1" @click="goHost">进入已托管作品页面</a>
				<a class="go" @click="goWorkPage">前往托管</a>
			</div>
		</div>
	</div>
</template>

<script>
	import commonLeft1 from "../../../components/common_left1.vue";
	export default {
		name: "copyrightManageOne",
		components: {
			commonLeft1
		},
		methods: {
			// 判断是否登录，登录后才能进入作品页面
			goWorkPage() {
				if (this.$isToken())
					this.$router.push({
						name: "manageWork"
					});
			},
			goHost() {
				this.$router.push('/host')
			}
		},
	};
</script>

<style lang="less" scoped>
	* {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
	}

	@fsColor: #5dbbbf;
	@bgColor: #28262e;
	@btnColor: #5accd8;
	@btnfsColor: #0c424f;
	@iptfsColor: #ffffff;
	@bg1Color: #35363f;

	.wrapper {
		display: flex;
		justify-content: space-between;
		margin: 14.77px auto;

		.centerContent {
			margin-left: 20px;
			flex:1;
			min-height: 736px;
			border-radius: 7px;
			background: @bg1Color;

			.title {
				height: 38px;
				margin: 8px 8px 0px 8px;
				padding-left: 5px;
				line-height: 38px;
				color: @fsColor;
				border-top-left-radius: 7px;
				border-top-right-radius: 7px;
				background: @bgColor;
			}

			.copyrightManageOnecontent {
				color: @fsColor;

				.explain {
					padding: 20px 40px;
					font-size: 20px;
				}

				.text {
					padding: 0px 40px;
					line-height: 30px;
				}

				.go {
					display: inline-block;
					height: 50px;
					width: 250px;
					margin-top: 15px;
					margin-left: 50%;
					transform: translateX(-50%);
					text-align: center;
					line-height: 50px;
					color: @btnfsColor;
					cursor: pointer;
					border-radius: 7px;
					background: @btnColor;
				}
			}
		}
	}

	.go1 {
		display: inline-block;
		height: 50px;
		width: 250px;
		margin-top: 400px;
		margin-left: 50%;
		transform: translateX(-50%);
		text-align: center;
		line-height: 50px;
		color: @btnfsColor;
		cursor: pointer;
		border-radius: 7px;
		background: @btnColor;
	}
</style>
