<template>
	<div class="wrapper clearfix">
		<!-- 侧边栏 -->
		<common-left1></common-left1>
		<!-- 内容 -->
		<div class="centerContent">
			<div class="title">申请版权托管</div>
			<div class="copyrightWorkContent">
				<!-- <div class="explain">未托管作品</div> -->
				<!-- 作品 -->
				<!-- <div class="worksBox">
					<div class="item" v-for="(item, index) in allWorkList" :key="index" @click="select(item.works_id)">
						<img :src="getUrl(item.cover)" alt="" />
						<p class="name">
							{{ item.title }}
						</p>
						<div class="state">
							<span class="sj"></span>
							<span style="fontsize: 14px;background-color: #35363f;color: #5dbbbf" class="revisespan">
								{{ auditStatus(item.status) }}
							</span>
						</div>
					</div>
				</div> -->
				<div class="worksBox">
					<div class="item" v-for="(item, index) in allWorkList.slice((currPage - 1) * 8, (currPage * 8))"
						:key="index" @click="goCopyrightProtection({
							id: item.id,
							pstatus: item.pstatus
						})">
						<img :src="item.coverimg" :title="item.title" alt="" />
						<p class="name">
							{{ item.title }}
						</p>
						<!-- TY -->
						<div class="state">
							<span class="sj"></span>
							<span style="fontsize: 14px;background-color: #35363f;color: #5dbbbf" class="revisespan">
								{{ auditStatus(item.pstatus) }}
							</span>
						</div>
					</div>
				</div>
				<!-- 分页 -->
				<ul v-if="totalPage > 1" class="pages bottomPages">
					<li class="prev" v-if="totalPage > 1" @click="prev"></li>
					<li v-for="item in totalPage" :key="item" :class="{ active: item == currPage }" @click="refash(item)">
						{{ item }}
					</li>
					<li class="next" @click="next" v-if="totalPage > 1"></li>
				</ul>
				<!-- 按钮 -->
				<div class="btn">
					<!-- <div class="submit" @click="submit">提交</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import commonLeft1 from "../../../components/common_left1.vue";
export default {
	name: "copyrightWork",
	components: {
		commonLeft1
	},
	data() {
		return {
			allWorkList: [], //作品
			isSelect: false, //是否选中,
			selectWorkId: "", //选中作品id
			totalPage: "",
			currPage: "1",
			list: [],
			list_two: []
		};
	},
	created() {
		// this.getCopyRightProtectionWorks();
		this.getCopyrightMangerAllWorks();
		// this.getHostList();
	},
	mounted() {
		setTimeout(() => {
			this.istuoguan();
		}, 500)
	},
	methods: {
		istuoguan() {
			console.log(this.allWorkList.length);
			if (this.allWorkList.length == 0) {
				this.$alert('请先将作品进行保护登记', '注意', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/protectionWork");//版权保护页面
						// this.$router.push("/copyrightCenter");//版权中心页面
					}
				})
			}
		},
		refash(item) {
			this.currPage = item;
			// this.getCopyRightProtectionWorks();
		},
		//上一页
		prev() {
			if (this.currPage == 1) {
				this.$message.error("已经是第一页了");
				return false;
			}
			this.currPage--;
			// this.getCopyRightProtectionWorks();
		},
		//下一页
		next() {
			if (this.currPage >= this.totalPage) {
				this.$message.error("已经是最后一页了");
				return false;
			}
			this.currPage++;
			// this.getCopyRightProtectionWorks();
		},
		// 获取全部作品
		async getCopyrightMangerAllWorks() {
			if (!localStorage.getItem("userInfo")) {
				this.$message.warning("用户登录过期");
				return false;
			}
			let { id } = JSON.parse(localStorage.getItem("userInfo"));
			let res = await this.$netWork.getCopyrightMangerAllWorks({
				url: "/works/getWorksList",
				method: "post",
				data: {
					type: "my",
					page: this.currPage,
					id: `${id}`,
					works_type: "所有",
					status: '2'
				},
			});
			this.allWorkList = res.data.list
			this.allWorkList = this.allWorkList.filter(v => {
				return v.pstatus == 1 || v.pstatus == 3 || v.pstatus == 4
			})
			// .filter((item) => {
			// 	return item.pstatus != 3 && item.pstatus != 4 && item.pstatus != 5
			// })
			// this.totalPage = res.data.totalPage;
			if (this.allWorkList.length > 8) {
				for (let i = 2; i <= this.totalPage; i++) {
					console.log(i, this.totalPage, "666");
					let res = await this.$netWork.getCopyrightMangerAllWorks({
						url: "/works/getWorksList",
						method: "post",
						data: {
							type: "my",
							page: i,
							id: `${id}`,
							works_type: "所有",
							status: '2'
						},
					});
					if (res.code == 0) {
						res.data.list.forEach(element => {
							this.allWorkList.push(element)
						});
						this.allWorkList = this.allWorkList.filter(v => {
							return v.pstatus == 1 || v.pstatus == 3 || v.pstatus == 4
						})
					}
					console.log(this.allWorkList);
				}
			}
			console.log('---作品列表---', this.allWorkList);
			// this.allWorkList = this.allWorkList
			// .filter((item) => {
			// 	return item.pstatus != 3
			// })
			// if (!this.allWorkList[0]) {
			// 	this.$message("您暂时没有作品可申版权保护")
			// }
			if (this.allWorkList.length > 8) {
				// console.log(this.allWorkList.length,parseInt(this.allWorkList.length/8)+1,"totalpage");
				this.totalPage = parseInt(this.allWorkList.length / 8) + 1
			}
			// this.allWorkListCopy = this.allWorkList
			// if (res.code === 0) {
			//   this.allWorkList = res.data.list
			//   console.log(this.allWorkList, "这是全部作品2")
			// }
		},
		// 获取版权保护列表
		// async getCopyRightProtectionWorks() {
		// 	console.log(localStorage.getItem("token"), "这是token")
		// 	let id = JSON.parse(localStorage.getItem("userInfo")).id;
		// 	let res = await this.$netWork.getCopyRightProtectionWorks({
		// 		url: "/protection/getprotection",
		// 		method: "post",
		// 		data: {
		// 			token: localStorage.getItem("token")
		// 		},
		// 	});
		// 	this.totalPage = res.data.totalPage;
		// 	if (res.code === 0) {
		// 		// this.allWorkList = res.data.list.filter((item) => {
		// 		//   return item.wstatus == 2 && item.pstatus == 2;
		// 		// });

		// 		this.allWorkList = res.data;
		// 	}
		// 	if (res.code === 201 || res.code === 200) {
		// 		this.$message.warning("用户登录过期");
		// 		this.$router.push("/");
		// 		localStorage.removeItem("token");
		// 		localStorage.removeItem("userInfo");
		// 	}

		// },
		//处理 url
		getUrl(url) {
			return "http://api.aboom.net" + url;
		},
		// 选取托管作品
		select(id) {
			sessionStorage.setItem("workId", id);
			this.selectWorkId = id;
			this.$router.push({
				name: "copyrightEscrow",
				query: {
					works_id: id
				},
			});
			// if (id == sessionStorage.getItem("workId")) {
			//   this.isSelect = !this.isSelect;
			// } else {
			//   this.isSelect = true;
			// }
		},
		//获取自己托管列表
		// async getHostList() {
		//   let res = await this.$netWork.getCopyRightProtectionWorks({
		//     url: "/protection/getDeposit",
		//     method: "post",
		//     data: {
		//       token: localStorage.getItem("token"),
		//     },
		//   });
		//   console.log(res.data,"托管作品")
		//   this.list = res.data;
		// },
		auditStatus(status) {
			if (status == null) {
				return "未保护";
			}
			if (status == 1) {
				return "待申请托管";
			}
			if (status == 3) {
				return "审核中";
			}
			if (status == 4) {
				return "审核未通过";
			}
			if (status == 5) {
				return "已托管";
			}
		},
		goCopyrightProtection(obj) {
			// console.log(obj);
			if (obj.pstatus == null) {
				this.$confirm("此作品没有版权保护，点击确定进入申请版权保护页面", "注意", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
					center: true,
				})
					.then(() => {
						this.$router.push("/protectionWork");
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消跳转",
						});
					});
			}
			else if (obj.pstatus == 1) {
				this.select(obj.id)
			}
			else if (obj.pstatus == 2) {
				this.select(obj.id)
			} else if (obj.pstatus == 3) {
				this.$alert('作品托管审核中请稍后查看', '注意', {
					confirmButtonText: '确定',
				})
			} else if (obj.pstatus == 4) {
				this.$alert('作品审核未通过', '注意', {
					confirmButtonText: '确定',
				})
			} else if (obj.pstatus == 5) {
				this.$alert('作品已托管', '注意', {
					confirmButtonText: '确定',
				})
			}
		},
	},
};
</script>

<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;

.wrapper {
	display: flex;
	justify-content: space-between;
	margin: 14.77px auto;

	.centerContent {
		margin-left: 20px;
		flex: 1;
		// height: 1100px;
		border-radius: 7px;
		background: @bg1Color;

		.title {
			height: 38px;
			margin: 8px 8px 0px 8px;
			padding-left: 5px;
			line-height: 38px;
			color: @fsColor;
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
			background: @bgColor;
		}

		.copyrightWorkContent {
			position: relative;
			color: @fsColor;

			.explain {
				padding: 20px 40px;
				font-size: 20px;
			}

			// 作品
			.worksBox {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				padding-left: 40px;
				padding-top: 20px;

				.item {
					display: flex;
					flex-direction: column;
					align-items: center;
					position: relative;
					width: 180px;
					margin-bottom: 30px;
					cursor: pointer;
					border-radius: 7px;
					margin-right: 38px;

					.state {
						position: absolute;
						right: 0;
						top: -4px;

						.sj {
							height: 0;
							width: 0;
							float: left;
							left: -20px;
							border-top: solid 12px rgb(53, 54, 63);
							border-left: solid 12px transparent;
							border-right: solid 12px rgb(53, 54, 63);
							border-bottom: solid 12px transparent;
						}

						.revisespan {
							padding: 0 0 4px 1px;
							font-size: 1rem;
							height: 1.25rem;
						}
					}

					img {
						width: 180px;
						height: 180px;
						border-radius: 7px;
						object-fit: cover;
					}

					.name {
						margin-top: 10px;
					}

					.gou {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100px;
						height: 76px;
					}
				}
			}

			// 分页
			.pages {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				display: flex;
				align-items: center;

				li {
					margin-right: 16px;
					cursor: pointer;
				}

				.active {
					color: @iptfsColor;

					&:hover {
						color: @iptfsColor;
					}
				}

				.next {
					width: 48px;
					height: 25px;
					background: @btnColor;
					background-image: url(../../../assets/img/copyright/fanye3.png);
					background-repeat: no-repeat;
					background-size: 14px;
					background-position: center;
					border-radius: 3px;
				}
			}

			// 按钮
			.btn {
				margin: 30px;
				// padding-top: 20px;
				border-top: 1px solid @lineColor;

				.submit {
					width: 116px;
					height: 36px;
					margin-top: 30px;
					margin-left: 50%;
					transform: translate(-50%);
					line-height: 36px;
					text-align: center;
					color: @btnfsColor;
					cursor: pointer;
					border-radius: 7px;
					background: @btnColor;
				}
			}
		}
	}
}

.red {
	color: red !important;
}

.green {
	color: green !important;
}

.org {
	color: orange !important;
}
</style>
