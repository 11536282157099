<template>
	<div class="wrapper clearfix">
		<!-- 侧边栏 -->
		<common-left1></common-left1>
		<!-- 内容 -->
		<div class="centerContent">
			<div class="title">申请版权托管</div>
			<div class="copyrightWorkContent">
				<div class="explain">已托管的作品</div>
				<!-- 作品 -->
				<div class="worksBox">
					<div class="item" v-for="(item, index) in allWorkList.slice((currPage - 1) * 8, (currPage * 8))" :key="index"
						@click="showBig(index)">
						<img :src="item.cover" alt="" :title="item.title" />
						<p class="name">{{ item.title }}
						</p>
					</div>
				</div>
				<!-- 分页 -->
				<ul v-if="totalPage > 1" class="pages bottomPages">
					<li class="prev" v-if="totalPage > 1" @click="prev"></li>
					<li v-for="item in totalPage" :key="item" :class="{ active: item == currPage }" @click="refash(item)">
						{{ item }}
					</li>
					<li class="next" @click="next" v-if="totalPage > 1"></li>
				</ul>
				<!-- 按钮 -->
				<div class="btn">
					<div class="submit" @click="submit">返回</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import commonLeft1 from "../../components/common_left1.vue";
export default {
	name: "copyrightWork",
	components: {
		commonLeft1
	},
	data() {
		return {
			allWorkList: [], //作品
			isSelect: false, //是否选中,
			selectWorkId: "", //选中作品id
			totalPage: "",
			currPage: "1",
			html: ""
		};
	},
	created() {
		// this.getCopyRightProtectionWorks();
		this.getHostList();
	},
	mounted() {
		setTimeout(() => {
			this.istuoguan();
		}, 500);
	},
	methods: {
		istuoguan() {
			console.log(this.allWorkList.length);
			if (this.allWorkList.length == 0) {
				this.$alert('暂时没有已托管的作品，点击确定进入申请版权托管页面', '注意', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/manageWork");
					}
				})
			}
		},
		showBig(index) {
			console.log(this.allWorkList[index].type, index);
			let type = this.auditStatus(this.allWorkList[index].type)
			this.html = `<div class="useForm">
				<p class="agreement">版权保护状态 : 是</p>
				<p class="agreement">托管状态 : ${type}</p>
				</div>`
			this.$alert(this.html, '此作品全部状态信息', {
				center: true,
				dangerouslyUseHTMLString: true
			});
		},
		refash(item) {
			this.currPage = item
			// this.getCopyRightProtectionWorks()
		},
		//上一页
		prev() {
			if (this.currPage == 1) {
				this.$message.error("已经是第一页了")
				return false
			}
			this.currPage--
			// this.getCopyRightProtectionWorks()
		},
		//下一页
		next() {
			if (this.currPage >= this.totalPage) {
				this.$message.error("已经是最后一页了")
				return false
			}
			this.currPage++
			// this.getCopyRightProtectionWorks()
		},
		// 获取版权保护列表
		// async getCopyRightProtectionWorks() {
		// 	let id = JSON.parse(localStorage.getItem("userInfo")).id;
		// 	let res = await this.$netWork.getWorksList({
		// 		url: "/works/getWorksList",
		// 		method: "post",
		// 		data: {
		// 			type: "my",
		// 			page: this.currPage,
		// 			id: `${id}`,
		// 			works_type: "所有",
		// 		},
		// 	});
		// 	this.totalPage = res.data.totalPage
		// 	if (res.code === 0) {
		// 		// this.allWorkList = res.data.list.filter((item) => {
		// 		//   return item.wstatus == 2 && item.pstatus == 2;
		// 		// });

		// 		// this.allWorkList = res.data.list
		// 		console.log(this.allWorkList, "这是版权保护rrr")
		// 	}
		// 	if (res.code === 201 || res.code === 200) {
		// 		this.$message.warning("用户登录过期");
		// 		this.$router.push("/");
		// 		localStorage.removeItem("token");
		// 		localStorage.removeItem("userInfo");
		// 	}
		// },
		//处理 url
		getUrl(url) {
			return "http://api.aboom.net" + url;
		},
		// 提交托管
		async submit() {
			// if (!this.isSelect) {
			//   return false;
			// }
			// let res = await this.$netWork.getManangeWork({
			//   url: "/protection/deposit",
			//   method: "post",
			//   data: {
			//     token: localStorage.getItem("token"),
			//     works_id: this.selectWorkId,
			//   },
			// });
			// if (res.code === 0) {
			//   this.$message.success(res.msg);
			//   this.getCopyRightProtectionWorks();
			//   this.isSelect = false;
			// }
			// if (res.code === 201 || res.code === 200) {
			//   this.$message.warning("用户登录过期");
			//   this.$router.push("/");
			//   localStorage.removeItem("token");
			//   localStorage.removeItem("userInfo");
			// }

			this.$router.push('/manageExplain')
		},

		//获取自己托管列表
		async getHostList() {
			let res = await this.$netWork.getCopyRightProtectionWorks({
				url: "/protection/getDeposit",
				method: "post",
				data: {
					token: localStorage.getItem("token")
				}
			})
			this.allWorkList = res.data
			console.log(res, '这是托管列表')
			if (this.allWorkList.length > 8) {
				this.totalPage = parseInt(this.allWorkList.length / 8) + 1
			}
		},
		auditStatus(type) {
			if (type == 1) {
				return "维权（已托管）";
			}
			if (type == 2) {
				return "商业化（已托管）";
			}
		},
	},
};
</script>

<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;

.wrapper {
	display: flex;
	justify-content: space-between;
	margin: 14.77px auto;

	.centerContent {
		margin-left: 20px;
		flex: 1;
		// height: 1100px;
		border-radius: 7px;
		background: @bg1Color;

		.title {
			height: 38px;
			margin: 8px 8px 0px 8px;
			padding-left: 5px;
			line-height: 38px;
			color: @fsColor;
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
			background: @bgColor;
		}

		.copyrightWorkContent {
			position: relative;
			color: @fsColor;

			.explain {
				padding: 20px 40px;
				font-size: 20px;
			}

			// 作品
			.worksBox {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				padding-left: 40px;

				.item {
					display: flex;
					flex-direction: column;
					align-items: center;
					position: relative;
					width: 180px;
					margin-bottom: 30px;
					cursor: pointer;
					border-radius: 7px;
					margin-right: 38px;

					img {
						width: 180px;
						height: 180px;
						border-radius: 7px;
						object-fit: cover;
					}

					.name {
						margin-top: 10px;
					}

					.gou {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100px;
						height: 76px;
					}
				}
			}

			// 分页
			.pages {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				display: flex;
				align-items: center;

				li {
					margin-right: 16px;
					cursor: pointer;
				}

				.active {
					color: @iptfsColor;

					&:hover {
						color: @iptfsColor;
					}
				}

				.next {
					width: 48px;
					height: 25px;
					background: @btnColor;
					background-image: url(../../assets/img/copyright/fanye3.png);
					background-repeat: no-repeat;
					background-size: 14px;
					background-position: center;
					border-radius: 3px;
				}
			}

			// 按钮
			.btn {
				margin: 30px;
				// padding-top: 20px;
				border-top: 1px solid @lineColor;

				.submit {
					width: 116px;
					height: 36px;
					margin-top: 30px;
					margin-left: 50%;
					transform: translate(-50%);
					line-height: 36px;
					text-align: center;
					color: @btnfsColor;
					cursor: pointer;
					border-radius: 7px;
					background: @btnColor;
				}
			}
		}
	}
}



.red {
	color: red !important;
}

.green {
	color: green !important;
}

.org {
	color: orange !important;
}
</style>
