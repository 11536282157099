<template>
	<div>
		<div class="suggest">
			<p class="title">意见反馈</p>
			<div class="xdsmgs">
				<div class="first">
					<div class="qType">问题类型:</div>
					<el-radio-group v-model="radio4" class="el_ra_gr" text-color="#5CE2ED">
						<el-radio label="我想提建议">我想提建议</el-radio>
						<el-radio label="发现问题/BUG">发现问题/BUG</el-radio>
						<el-radio label="表示感谢">表示感谢</el-radio>
						<el-radio label="功能疑问">功能疑问</el-radio>
					</el-radio-group>
				</div>
				<div class="describe">
					<textarea placeholder="请具体描述你的问题" v-model="desc"> </textarea>
				</div>
				<div class="upload">
					<p class="up_title">请将有问题的截图发给我们，方便分析及时解决问题(仅限png、jpg)格式</p>
					<el-upload action="https://api.aboom.net/File/uploadFile" list-type="picture-card"
						:auto-upload="false">
						<i slot="default" class="el-icon-plus"></i>
						<div slot="file" slot-scope="{ file }">
							<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span v-if="!disabled" class="el-upload-list__item-delete"
									@click="handleDownload(file)">
									<i class="el-icon-download"></i>
								</span>
								<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="" />
					</el-dialog>
				</div>
				<div class="tijiao" @click="submit">提交</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "feedBack",
	data() {
		return {
			dialogImageUrl: "",
			dialogVisible: false,
			disabled: false,
			radio4: 0,
			desc: ""
		};
	},
	methods: {
		async submit() {
			if (this.$isToken())
				console.log(this.radio4)
			let res = await this.$netWork.getFeedBack({
				url: "/banner/fankui",
				method: "post",
				data: {
					title: this.desc,
					token: localStorage.getItem("token")
				}
			})
			console.log(res, '反馈反馈反馈反馈')
			if (res.code === 0) {
				this.$message.success(res.msg)
				window.location.reload();
			}
		},
		handleRemove(file) {
			console.log(file);
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleDownload(file) {
			console.log(file);
		},
	},
};
</script>

<style lang="less" scoped>
.tijiao {
	width: 120px;
	height: 35px;
	border-radius: 5px;
	background: #5CE2ED;
	color: #333;
	font-size: 14px;
	text-align: center;
	line-height: 35px;
	margin-top: 33px;
}

.suggest {
	background: #353640;
	margin: 30px auto;
	border-radius: 10px;
	color: #5CE2ED;
	padding: 14px 11px 20px 11px;
}

/* 大标题 */
.title {
	background: #26272D;
	border-radius: 10px;
	color: #5CE2ED;
	padding: 5px 12px;
}

// .qType div {
//   width: 110px;
//   text-align: center;
//   line-height: 40px;
//   height: 40px;
//   border: 1px solid rgb(154, 150, 150);
//   color: rgb(154, 150, 150);
//   border-radius: 3px;
//   cursor: pointer;
// }
.second {
	width: 550px;
	height: 40px;
	margin: auto;
	display: flex;
	justify-content: left;
	cursor: pointer;
}

.second div {
	margin-top: 30px;
	width: 110px;
	text-align: center;
	line-height: 40px;
	height: 40px;
	border: 1px solid rgb(154, 150, 150);
	color: rgb(154, 150, 150);
	border-radius: 3px;
	margin-left: 12px;
	cursor: pointer;
}
.describe{
	margin: 22px 0;
}
textarea {
	width: 700px;
	height: 215px;
	border-radius: 5px;
	background: #4d4f5a;
	color: #5CE2ED;
	padding: 14px 16px;
	border: 0;
	resize: none;
	font-size: 14px;
}
textarea::placeholder{
	color: #5CE2ED;
	font-size: 14px;
}
.up_title{
	margin-bottom: 16px;
	font-size: 14px;
}
/deep/.el-upload--picture-card{
	background: transparent;
	border: 1px dashed #5CE2ED;
}
.el-upload {
	margin-top: 10px;
}
/deep/.el-upload--picture-card i{
	color: #5CE2ED;
}
.xdsmgs{
	padding: 20px 40px;
	width: 1000px;
	margin: 20px auto 0 auto;
	box-sizing: border-box;
	background: #26272D;
	border-radius: 12px;
}
.el_ra_gr{
	margin: 20px 0;
}
/deep/.el-radio{
	color: #5CE2ED;
}

/deep/.el-radio__input.is-checked .el-radio__inner{
	background: #000;
	border: 4px solid #5CE2ED;
}
/deep/.el-radio__input.is-checked .el-radio__inner::after{
	background: transparent;
}
/deep/.el-radio__input.is-checked+.el-radio__label{
	color: #5CE2ED;
}
</style>
