<template>
  <div class="content">
    <div class="content_middle">
      <common-left1></common-left1>
      <!-- 右边 -->
      <div class="content_middleR">
        <div class="frame">
          <div class="BiaoTi">站内搜索</div>
          <!-- 搜索框 -->
          <div class="search">
            <div class="search-title">关键字</div>
            <div class="search-frame">
              <input
                type="text"
                placeholder="新番 同人"
                v-model="searchKeyword"
                @keydown.enter="getSearchInfo"
              />
              <img
                src="../../assets/img/copyright/sousuo.png"
                class="search-img"
                @click="getSearchInfo"
              />
            </div>
          </div>
        </div>
        <!-- 搜索结果 -->
        <div class="search-enter">
          <!-- <div class="search-enter-title">搜索结果</div> -->
          <div class="contribution" v-if="searchInfo.length > 0">
            <ul class="works">
              <li class="item" v-for="item in searchInfo" :key="item.id">
                <a @click="goWorkDetail(item.id, item.uid)">
                  <img :src="item.coverimg" alt="" />
                  <span class="name">{{ item.title }}</span>
                </a>
              </li>
            </ul>
            <!-- 分页 -->
            <ul v-if="totalPage > 1" class="pages bottomPages">
              <li class="prev" v-if="totalPage > 1" @click="prev"></li>
              <li
                v-for="item in totalPage"
                :key="item"
                :class="{ active: item == currPage }"
                @click="refash(item)"
              >
                {{ item }}
              </li>
              <li class="next" @click="next" v-if="totalPage > 1"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonLeft1 from "../../components/common_left1.vue";
export default {
  name: "serach",
  components: { commonLeft1 },
  data() {
    return {
      searchKeyword: this.$route.query.search,
      totalPage: "",
      currPage: "1",
      searchInfo: [],
    };
  },
  async created(){
    let res = await this.$netWork.getSearchInfo({
      url:"/works/getSearchList",
      method:"post",
      data:{
        keyword: this.searchKeyword,
        page: this.currPage,
      }
    })
      this.totalPage = res.data.totalPage;
      console.log(this.totalPage,"搜索作品")
      if (res.data.list.length < 1) {
        this.$message("暂无内容");
      }
      if(this.totalPage > 1){
        this.totalPage = this.totalPage-1
      }
      this.searchInfo = res.data.list;
  },
  watch: {
    searchKeyword(a, b) {
      if (a != b) {
        this.currPage = "1";
      }
    },
  },
  mounted() {},
  methods: {
    refash(item) {
      this.currPage = item;
      this.getSearchInfo();
    },
    //上一页
    prev() {
      if (this.currPage == 1) {
        this.$message.error("已经是第一页了");
        return false;
      }
      this.currPage--;
      this.getSearchInfo();
    },
    //下一页
    next() {
      if (this.currPage >= this.totalPage) {
        this.$message.error("已经是最后一页了");
        return false;
      }
      this.currPage++;
      this.getSearchInfo();
    },
    // 搜索
    async getSearchInfo() {
      let res = await this.$netWork.getSearchInfo({
        url: "/works/getSearchList",
        method: "post",
        data: {
          keyword: this.searchKeyword,
          // pageSize:8,
          page: this.currPage,
        },
      });
      console.log(res,"搜索作品")
      this.totalPage = res.data.totalPage;
      console.log(this.totalPage,"搜索作品")
      if (res.data.list.length < 1) {
        this.$message("暂无内容");
      }
      if(this.totalPage > 1){
        this.totalPage = this.totalPage-1
      }
      this.searchInfo = res.data.list;
    }, //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    // 去作品详情1
    goWorkDetail(id, uid) {
      this.$router.push({
        name: "workDetail1",
        query: { work_id: id, uid: uid },
      });
    },
  },
};
</script>
<style scoped lang="less">
.content{
  width: 100%;
}
* {
  margin: 0;
  padding: 0;
}
input::-webkit-input-placeholder {
  color: #4db9c2;
}
.content_middle {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
}

/* 右边 */
.content_middleR {
  display: flex;
  flex-direction: column;
  flex:1;
  margin-left: 20px;
}
/* 标题 */
.frame {
  background-color: #353641;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
}
.BiaoTi {
  color: #4db9c2;
  background-color: #26272e;
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.4rem;
  margin: 8px;
}
/*搜索框  */
.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 6rem;
  margin: 0 70px 0 50px;
  color: #4db9c2;
}
.search-title {
  color: #4db9c2;
  font-size: 14px;
  margin-right: 0.4rem;
}
.search-frame {
  display: flex;
  align-items: center;
  width: 90%;
  height: 36px;
  background-color: #26282c;
  border-radius: 7px;
  border: 1px solid #26282c;
}
.search-frame:hover {
  border: 1px solid #4db9c2;
  color: #4db9c2;
}
input {
  background-color: #26282c;
  width: 100%;
  border-style: none;
  color: #4db9c2;
  padding: 0.3rem;
  padding: 0.3rem 0.8rem;
  border-radius: 0.2rem;
  outline: none;
}

.search-img {
  width: 18px;
  height: 21px;
  padding: 2px 12px 0 12px;
  cursor: pointer;
}

/* 搜索结果 */
ul {
  list-style: none;
}
.search-enter {
  background-color: #26272e;
  padding-bottom: 1rem;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  padding: 10px;
  min-height: 609px;
}
.search-enter-title {
  color: #4db9c2;
  font-size: 14px;
}
.contribution .pages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  color: #4db9c2;
}
.contribution .pages li {
  margin-right: 16px;
  cursor: pointer;
}
.contribution .pages li:hover {
  color: #ffffff;
}
.contribution .pages .active {
  color: #ffffff;
}
.contribution .pages .prev {
  transform: rotate(180deg);
  width: 48px;
  height: 25px;
  background: #5accd8;
  background-image: url("../../assets/img/home/fanye3.png");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  border-radius: 3px;
}
.contribution .pages .next {
  width: 48px;
  height: 25px;
  background: #5accd8;
  background-image: url("../../assets/img/home/fanye3.png");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  border-radius: 3px;
}
.contribution .works {
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px 35px 20px;
  padding-top: 35px;
  // border-top: 1px solid #46535a;
}
.contribution .works .item {
  width: 150px;
  padding: 0 16px;
  margin-bottom: 20px;
}
.contribution .works .item a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contribution .works .item a img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.contribution .works .item a .name {
  margin-top: 10px;
  color: #4db9c2;
}
.contribution .works .narrow {
  width: 200px;
}
.contribution .works .narrow a img {
  width: 150px;
}
.contribution .bottomPages {
  border-top: 1px solid #46535a;
  padding-top: 50px;
}
</style>