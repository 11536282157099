<template>
  <div class="bind">
    <el-steps :active="active" finish-status="success">
      <el-step title="验证邮箱"></el-step>
      <el-step title="绑定手机"></el-step>
      <el-step title="绑定成功"></el-step>
    </el-steps>
    <!-- 验证邮箱 -->
    <el-input
      v-if="Email"
      placeholder="请输入正确的邮箱地址"
      v-model="email"
      clearable
    >
    </el-input>
    <el-input v-if="Email" placeholder="请输入验证码" v-model="mac" clearable>
    </el-input>
    <!-- 绑定手机号 -->
    <el-input
      v-if="Phone"
      placeholder="请输入正确格式的手机号码"
      v-model="telephone"
      clearable
    >
    </el-input>
    <el-input v-if="Phone" placeholder="请输入验证码" v-model="mac" clearable>
    </el-input>
    <div class="verify" v-if="cancel" @click="getCode" ref="btn">
      {{ msg > 0 ? msg : "获取验证码" }}
    </div>
    <!-- 设置成功 -->
    <div class="success" v-if="set">
      <p class="title">设置手机号成功！</p>
      <img src="../../assets/imgs/right.png"  class="right">
      <div class="self" @click="backSelf">返回个人中心</div>
    </div>
    <el-button type="primary" @click="next" v-if="cancel">下一步</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 1,
      email: "", //邮箱
      mac: "", //验证码
      telephone: "", //手机号
      Email: true,
      Phone: false,
      set: false,
      cancel: true,
      msg: 0,
      number : 0 //判断
    };
  },

  methods: {
    getCode() {
        // if(!this.email || !this.telephone){
        //     this.$message.warning("请输入.....")
        // }
      let type;
      //判断输入的是邮箱还是手机号
    //   1：手机号  2：邮箱
      if (this.email.includes("@")) {
        type = "1";
        console.log(type);
      } else {
        type = "2";
        this.email = this.telephone
        console.log(type);
      }
      //获取验证码
      this.$netWork
        .getMac({
          method: "post",
          url: "/verify/getVerify",
          data: {
            account: this.email,
            template: "register",
            type: type,
          },
        })
        .then((result) => {
          console.log(result);
          if (result.code == 0) {
            this.$refs.btn.disabled = true;
            this.$message.success("验证码发送成功,请注意查收");
            this.msg = 60;
            let time = setInterval(() => {
              this.msg--;
            }, 1000);
            setTimeout(() => {
              clearInterval(time);
              this.$refs.btn.disabled = false;
            }, 60 * 1000);
          } else {
            // this.$message.error(result.msg);
            this.$message.error("请输入对应的值.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    backSelf() {
      this.$router.push({ path: "/infoMessage" });
    },
    next() {
      this.number++
      if(this.number === 1){
        console.log(this.number)
        this.$netWork
        .getMac({
          method: "post",
          url: "/verify/VerifyCode",
          data: {
            account: this.email,
            code: this.mac,
          },
        })
        .then((result) => {
          console.log(result);
          if (result.code == 0) {
            this.$refs.btn.disabled = true;
            this.$message.success("验证成功.");
            this.email = "";
            this.mac = "";
            this.msg = 0;
            setTimeout(() => {
              this.active++;
              if (this.active === 2) {
                this.Email = false;
                this.Phone = true;
                return false;
              }
              if (this.active === 3) {
                this.Email = false;
                this.Phone = false;
                this.set = true;
                this.cancel = false;
              }
            },100);
          } else {
            this.$message.error("验证失败,请重试.");
          }
        });
      }
      if(this.number === 2){
        console.log(this.number)
        this.$netWork
        .getMac({
          method: "post",
          url: "/user/bindAccount",
          data: {
            token : localStorage.getItem("token"),
            account: this.email,
            code: this.mac,
          },
        })
        .then((result) => {
          console.log(result);
          if (result.code == 0) {
            this.$refs.btn.disabled = true;
            this.$message.success("成功.");
            this.email = "";
            this.mac = "";
            this.msg = 0;
            setTimeout(() => {
              this.active++;
              if (this.active === 2) {
                this.Email = true;
                this.Phone = false;
                return false;
              }
              if (this.active === 3) {
                this.Email = false;
                this.Phone = false;
                this.set = true;
                this.cancel = false;
              }
            },100);
          } else if(result.code == 1){
            this.$message.warning("该账号已绑定")
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-step__title.is-process {
    color: black;
    font-weight: normal;
}
/deep/.el-step__title.is-wait {
    color: black;
}
/deep/.el-step__icon.is-text {
    border-radius: 50%;
    border: 1px solid;
    // border-color: inherit;
}
/deep/.el-step__title.is-success {
    // color: #67C23A;
    color: skyblue;
    margin-left: -30px;
    font-size: 20px;
}
/deep/.el-step__title.is-process {
    color: black;
    // font-weight: normal;
    margin-left: -25px;
    font-size: 20px;
}
/deep/.el-step__title.is-wait {
    color: black;
     margin-left: -25px;
     font-size: 20px;
}
/deep/.el-step__head.is-success {
    color: skyblue;
    border-color: skyblue;
}
.right {
    margin-left: 327px;
    margin-top: 40px;
}
.bind {
  margin: 50px auto;
  width: 700px;
  height: 500px;
  background: rgb(219, 217, 217);
  border-radius: 10px;
}
.el-step {
  margin-top: 70px;
}
.el-steps {
  width: 500px;
  margin: auto;
}

.el-button {
  margin-top: 50px;
  width: 340px;
  margin-left: 175px;
  /* position: relative; */
}
.el-input {
  width: 340px;
  margin-left: 175px;
  margin-top: 80px;
}
.el-input:nth-child(3) {
  width: 240px;
  margin-top: 30px;
}
.verify {
  width: 80px;
  height: 36px;
  background: orangered;
  font-size: 14px;
  line-height: 36px;
  margin-left: 433px;
  margin-top: -39px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  color: white;
}
.title {
  font-size: 26px;
  color: skyblue;
  margin-top: 80px;
  margin-left: 260px;
}
.self {
  width: 150px;
  height: 50px;
  background: skyblue;
  color: white;
  font-size: 18px;
  text-align: center;
  line-height: 50px;
  border-radius: 4px;
  margin-top: 50px;
  margin-left: 280px;
  cursor: pointer;
} 
</style>