<template>
  <div class="content">
    <div class="content_middle">
      <common-left1></common-left1>
      <!-- 右边 -->
      <div class="content_middleR">
        <div class="frame">
          <div class="BiaoTi">公告</div>
          <el-collapse v-model="id" @change="handleChange" accordion>
            <el-collapse-item v-for="item in noticelist" :key="item.id" :title="item.title" :name="item.id">
              <div style="color:pink;text-indent:2rem;" v-html="item.content"></div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonLeft1 from "../../components/common_left1.vue";
export default {
  name: "announcement",
  components: {
    commonLeft1,
  },
  data() {
    return {
      noticeContent: [],
      noticelist: [], //公告列表
      activeNames: "1",
      isshow: true,
      id: "0"
    };
  },
  created() {
    console.log(this.$route.query.id, "666");
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    this.getNoticeList();
  },
  mounted() {
    
  },
  methods: {
    handleChange(val) {

    },
    // 获取公告详情
    async getnoticeDetails(id) {
      let res = await this.$netWork.getnoticeDetails({
        url: "/notice/getnoticedetails",
        method: "post",
        params: {
          id: this.$route.query.id ? this.$route.query.id : id,
        },
      });
      console.log(res, '详情');

      // res.content = res.content.replace(
      //   "/upload",
      //   `http://api.aboom.net/upload`
      // );
      // console.log(item.content, "1111111");

      this.noticeContent.push(res);
      // console.log(this.noticeContent)
    },

    // 跳转公告详情
    jumpNoticeDetils(id) {
      this.$router.push({
        name: "noticeDetails",
        query: {
          id,
        },
      });
    },
    // 获取更多公告
    async getNoticeList() {
      // 公告
      const res = await this.$netWork.getNoticelist({
        method: "post",
        url: "/notice/getnoticelistS",
      });
      this.noticelist = res;
      console.log(res, 2224);
    },
    isshows() {
      this.isshow = !this.isshow;
    },
  },
};
</script>
<style scoped lang="less">
.content {
  width: 100%;
}

.content_middle {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
}

/* 右边 */
.content_middleR {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
}

/* 标题 */
.frame {
  /* flex: 1; */
  background-color: #353641;
  border-radius: 0.4rem;
  padding: 8px 8px 2rem 8px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
  min-height: 736px;
}

.BiaoTi {
  color: #4db9c2;
  background-color: #26272e;
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.4rem;
}

ul {
  padding: 1.5rem 4rem;
}

li {
  color: #4db9c2;
  font-size: 14px;
  line-height: 1.8rem;
  cursor: pointer;
}

//饿了么UI手风琴 .TY

.el-collapse {
  border: none;
  margin: 2rem;
}

// .el-collapse-item{
// 	background-color: #4db9c2;
// }
// #el-collapse-head-4496{
// 	background-color: #4db9c2;
// }
/deep/.el-collapse-item__header {
  display: flex;
  height: 2rem;
  border: none;
  margin: 1rem;
  padding: 0 1rem;
  font-size: 0.8rem;
  font-family: "microsoft sans serif";
  font-weight: 550;
  border-radius: 0.2rem;
  background-color: #26272c !important;
  color: #4db9c2;
}

/deep/.el-collapse-item__wrap {
  border: none;
  background-color: #353641 !important;
}

.el-collapse-item-div {
  border: none;
  margin: 0 1rem;
  padding: 0 1rem;
  font-size: 0.8rem;
  font-family: "microsoft sans serif";
  font-weight: 550;
  color: #4db9c2;
  background-color: #353641 !important;
}

.righttitle {
  flex: 1;
  text-align: right;
}


</style>
