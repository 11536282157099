<template>
  <div class="content">
    <div class="content_middle">
      <common-left1></common-left1>
      <!-- 右边 -->
      <div class="content_middleR">
        <div class="frame">
          <div class="BiaoTi">公告</div>
          <ul>
            <li
              v-for="(item, index) in noticeContent"
              v-html="item.content"
              :key="index"
            ></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonLeft1 from "../../components/common_left1.vue";
export default {
  name: "announcement",
  components: { commonLeft1 },
  data() {
    return {
      noticeContent: [],
    };
  },
  created() {
    console.log(this.$route.query.id, "666");
    let id = this.$route.query.id;
    if (id) {
      this.getnoticeDetails(id);
    } else {
      this.getNoticeList();
    }
  },
  mounted() {
    // this.noticeContent.forEach((item) => {
    //   item.content = item.content.replace(
    //     "/upload",
    //     `http://api.aboom.net/upload`
    //   );
    //   console.log(item.content, "1111111");
    // });
  },
  methods: {
    // 获取公告详情
    async getnoticeDetails(id) {
      let res = await this.$netWork.getnoticeDetails({
        url: "/notice/getnoticedetails",
        method: "post",
        params: { id: this.$route.query.id ? this.$route.query.id : id },
      });
      console.log(res)

        // res.content = res.content.replace(
        //   "/upload",
        //   `http://api.aboom.net/upload`
        // );
        // console.log(item.content, "1111111");

      this.noticeContent.push(res);
      // console.log(this.noticeContent)
    },
    // 获取更多公告
    async getNoticeList() {
      const res1 = await this.$netWork.getNoticelist({
        method: "post",
        url: "/notice/getnoticelist",
      });
      for (let i = 0; i < res1.length; i++) {
        await this.getnoticeDetails(res1[i].id);
      }
    },
  },
};
</script>
<style scoped lang="less">
.content_middle {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
}

/* 右边 */
.content_middleR {
  display: flex;
  flex-direction: column;
  width: 996px;
}
/* 标题 */
.frame {
  /* flex: 1; */
  background-color: #353641;
  border-radius: 0.4rem;
  padding: 8px 8px 2rem 8px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
  min-height: 736px;
}

.BiaoTi {
  color: #4db9c2;
  background-color: #26272e;
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.4rem;
}

ul {
  padding: 1.5rem 4rem;
}

li {
  color: #4db9c2;
  font-size: 14px;
  line-height: 1.8rem;
  cursor: pointer;
}
</style>