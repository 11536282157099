<template>
  <div class="content_middle">
    <div class="frame">
      <div class="BiaoTI">作品排行榜</div>
      <div class="page_navigation">
        <a class="page_navigation_a" :class="{ active: sort == 1 }" @click="changeSort(1)">
          <p>日排行</p>
          <span class="triangle" :class="{ trangle1: sort == 1 }"></span>
        </a>
        <a class="page_navigation_a" :class="{ active: sort == 2 }" @click="changeSort(2)">
          <p>周排行</p>
          <span class="triangle" :class="{ trangle1: sort == 2 }"></span>
        </a>
        <a class="page_navigation_a" :class="{ active: sort == 3 }" @click="changeSort(3)">
          <p>月排行</p>
          <span class="triangle" :class="{ trangle1: sort == 3 }"></span>
        </a>
      </div>
      <div class="LorR">
        <img src="~@/assets/img/home/zuo.png" class="LorR_img" alt="" />
        <p class="LorR_zhong">2015年1月1日</p>
        <img src="~@/assets/img/home/you.png" class="LorR_img" alt="" />
      </div>
      <div class="rankingScope">
        <a href="#" class="rankingScope_a">
          <p>1-10</p>
        </a>
        <a href="#" class="rankingScope_a">
          <p>11-30</p>
        </a>
        <a href="#" class="rankingScope_a">
          <p>31-50</p>
        </a>
      </div>
      <!-- 排行信息 -->
      <div class="seniority">
        <div class="seniority_layout" v-for="(item, index) in rankingList" :key="index">
          <div class="seniority_detail">
            <p class="seniority_MingCi1">
              <img v-if="index == 0" src="~@/assets/imgs/di1.png" alt="">
              <img v-if="index == 1" src="~@/assets/imgs/di2.png" alt="">
              <img v-if="index == 2" src="~@/assets/imgs/di3.png" alt="">
              <span v-if="index != 0 && index != 1 && index != 2">{{ index + 1 }}名</span>
            </p>
            <!-- <p class="seniority_MingCi2">初登场</p> -->
          </div>
          <div class="seniority_detail">
            <p class="seniority_p" style="font-size: 22px;font-weight: 600;">{{ item.title }}</p>
            <img :src="item.avatar" class="seniority_Img2" alt="" />
            <p class="seniority_p">{{ item.nickname }}</p>

          </div>
          <!-- {{item.fData[1]["file_path"]}} -->
          <div style="width: 580px;display:flex" @click="goWorkDetail(item.id,item.uid)">
            <img v-for="items in item.fData.slice(0,3)" :src="items.file_path" class="seniority_Img1" alt="" :title="item.title" />
            <!-- <img :src="getUrl(item.fData[1].file_path)" alt=""> -->
          </div>
            <!-- <img :src="getUrl(item.fData[1].file_path)" class="seniority_Img1" alt="" :title="item.title" />
            <img :src="getUrl(item.fData[2].file_path)" class="seniority_Img1" alt="" :title="item.title" /> -->
          
        </div>
        <!-- <div class="seniority_layout">
          <div class="seniority_detail">
            <p class="seniority_MingCi1">2名</p>
            <p class="seniority_MingCi2">
              <img
                src="~@/assets/img/home/shang.png"
                class="seniority_Img3"
                alt=""
              />
              昨日第三名
            </p>
          </div>
          <div class="seniority_detail">
            <img
              src="~@/assets/img/home/di2.png"
              class="seniority_Img1"
              alt=""
            />
            <p class="seniority_p">乐园</p>
          </div>
          <div class="seniority_detail">
            <img
              src="~@/assets/img/home/yonghu2.png"
              class="seniority_Img2"
              alt=""
            />
            <p class="seniority_p">madoka</p>
          </div>
        </div> -->
      </div>
      <!-- 排行角标 -->
      <div class="cornerMark">
        <div>11-30</div>
        <div>&nbsp;>></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "seniority",
  data() {
    return {
      rankingList: [],
      sort: this.$route.query.sort,
    };
  },
  computed: {
    // 计算是日、周、月
    typeSort() {
      switch (parseInt(this.sort)) {
        case 1:
          return "today";
        case 2:
          return "thisWeek";
        case 3:
          return "thisMonth";
      }
    },
  },
  mounted() {
    this.getRankingList();
  },
  methods: {
    // 获取排行榜数据
    async getRankingList() {
      const res = await this.$netWork.getTodayOrWeekOrMonthRankingList({
        method: "post",
        url: "/works/getWorksList",
        data: {
          type: this.typeSort, // 类型
          page: 1, // 页码
          works_type: this.$route.query.type || "所有"
        },
      });
      this.rankingList = res.data.list;
      // console.log(res.data.list[0].fData[1]);
      // console.log(this.rankingList[0].fData);
    },
    //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    // 改变排行榜
    changeSort(num) {
      this.sort = num;
      this.getRankingList();
    },
    // 去作品详情1
    goWorkDetail(id,uid) {
      this.$router.push({ name: "workDetail1", query: { work_id: id,uid:uid } });
    },
  },
};
</script>
<style lang="less" scoped>
.seniority img {
  object-fit: cover;
}
.content_middle {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.frame {
  flex:1;
  background-color: #353641;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
  border-radius: 0.4rem;
}

.BiaoTI {
  line-height: 7rem;
  text-align: center;
  font-size: 20px;
  color: #58b5c0;
  cursor: pointer;
}

/* 周日月排行 */
.page_navigation {
  height: 45px;
  background-color: #26282c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.page_navigation_a {
  position: relative;
  margin: 0 8rem;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.page_navigation_a:hover {
  color: #233e45;
  background-color: #58b5c0;
  /* padding:0 1.5rem; */
  width: 100px;
  height: 40px;
  line-height: 40px;
  border-radius: 0.2rem;
  text-align: center;
}

.page_navigation_a:hover .triangle {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  border: 5px solid transparent;
  border-top-color: #58b5c0;
  
}

.active {
  color: #233e45;
  background-color: #58b5c0;
  width: 100px;
  height: 40px;
  line-height: 40px;
  border-radius: 0.2rem;
  text-align: center;
  cursor: pointer;
}

.trangle1 {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  border: 5px solid transparent;
  border-top-color: #58b5c0;
  cursor: pointer;
}

/* 去掉a标签样式 */
a {
  text-decoration: none;
  color: #58b5c0;
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none;
}

/* 左右选择 */
.LorR {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.LorR_zhong {
  color: #cbccd5;
  margin: 2rem 4rem;
  cursor: pointer;
}

.LorR_img {
  width: 12px;
  cursor: pointer;
}

/* 排行范围 */
.rankingScope {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.rankingScope_a {
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-bottom: none;
  /* padding: 0.3rem 1.8rem; */
  background-color: rgb(53, 54, 65);
}

.rankingScope_a:hover

/* .rankingScope_a:visited,
.rankingScope_a:active */
  {
  color: #cfcdda;
  /* padding: 0.3rem 1.8rem; */
  border: 0.15rem solid #37424a;
  border-bottom: none;
}

/* 信息排行 */
.seniority {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.seniority_layout {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  border-top: 0.15rem solid #37424a;
  padding: 60px 10px;
}

// .seniority_layout:last-child {
//   border-bottom: 0.15rem solid #37424a;
// }

/*信息排行 文字  */
.seniority_detail {
  display: flex;
  width: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
  cursor: pointer;
}

.seniority_MingCi1 {
  word-break: keep-all;
  white-space: nowrap;
  color: #5db1b8;
  font-size: 30px;
}

.seniority_MingCi2 {
  word-break: keep-all;
  white-space: nowrap;
  color: #c03a40;
  font-size: 14px;
}

.seniority_p {
  word-break: keep-all;
  white-space: nowrap;
  color: #5db1b8;
  font-size: 14px;
  padding-top: 10px;
}

/* 信息排行 图片 */
.seniority_Img1 {
  // width: 70%;
  width: 180px;
  height: 180px;
  margin: 0 10px;
}

.seniority_Img2 {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-top: 40px;
}

.seniority_Img3 {
  width: 20%;
}

/* 排行角标 */
.cornerMark {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5db1b8;
  font-size: 14px;
  line-height: 60px;
  text-align: center;
}

/* 页面排行11-50 */
.ranking {
  float: left;
  box-sizing: border-box;
  padding: 0 100px 30px 100px;
}

.ranking_layout {
  text-align: center;
  border-bottom: 0.15rem solid #37424a;
  padding-bottom: 50px;
  float: left;
}

.ranking_detail {
  width: 250px;
  margin: 1rem 0;
  text-align: center;
}

/* 排行11-50 图片信息 */
.ranking_Img1 {
  height: 150px;
}

.ranking_Img2 {
  width: 40px;
}

.ranking_Img3 {
  width: 12px;
}

/* 排行11-50 文字信息 */
.ranking_MingCi1 {
  word-break: keep-all;
  white-space: nowrap;
  color: #5db1b8;
  font-size: 30px;
}

.ranking_MingCi2 {
  word-break: keep-all;
  white-space: nowrap;
  color: #c03a40;
  font-size: 14px;
}

.ranking_p {
  word-break: keep-all;
  white-space: nowrap;
  color: #5db1b8;
  font-size: 14px;
}
</style>