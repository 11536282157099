<template>
  <div class="wrapper">
    <!-- 侧边栏 -->
    <common-left1></common-left1>
    <!-- 内容 -->
    <div class="centerContent">
      <div class="title">作品目录</div>
      <!-- nav -->
      <ul class="nav">
        <li class="active">
          <a href="">插画</a>
          <span class="triangle"></span>
        </li>
        <li>
          <a href="">漫画</a>
          <span class="triangle"></span>
        </li>
        <li>
          <a href="">动画</a>
          <span class="triangle"></span>
        </li>
      </ul>
      <!-- 作品 -->
      <div class="works">
        <!-- 分页 -->
        <ul class="pages">
          <li class="active">1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
          <li>6</li>
          <li class="next"></li>
        </ul>
        <!-- 作品展示 -->
        <ul class="showWork">
          <li class="item" v-for="(item) in worksList" :key="item.id">
            <a @click="goWorkDetail(item.id)">
              <img :src="item.fData[0].file_path" alt="" />
              <span class="name">{{ item.nickname }}</span>
            </a>
            <div class="info">
              <div class="one">
                <p>点赞数：<span class="num">211</span></p>
                <p>推介数：<span class="num">69</span></p>
              </div>
              <div class="two">
                <p>收藏数：<span class="num">77</span></p>
                <p>点赞数：<span class="num">80</span></p>
              </div>
            </div>
          </li>
        </ul>
        <!-- 分页 -->
        <ul class="pages bottomPages">
          <li class="active">1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
          <li>6</li>
          <li class="next"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import commonLeft1 from "../../../../components/common_left1.vue";
export default {
  name: "userWorkOne",
  components: { commonLeft1 },
  data() {
    return {
      worksList: [], //作品
    };
  },
  mounted() {
    this.getUserWorkList();
  },
  methods: {
    // 获取本人作品目录
    async getUserWorkList() {
      let { id } = JSON.parse(localStorage.getItem("userInfo"));
      let res = await this.$netWork.getUserWorkList({
        url: "/works/getWorksList",
        method: "post",
        data: {
          type: "my",
          page: "1",
          id: `${id}`,
        },
      });
      if (res.code === 0) {
        this.worksList = res.data;
      }
    },
    //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    // 去作品详情
    goWorkDetail(id) {
      this.$router.push({ name: "workDetail1", query: { work_id: id } });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: @fsColor;
}
li {
  color: @fsColor;
  list-style: none;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;
.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
  width: 1200px;
  .centerContent {
    margin-left: 14.77px;
    width: 996px;
    max-height: 1320px;
    border-radius: 7px;
    background: @bg1Color;
    .title {
      height: 38px;
      margin: 8px 8px 0px 8px;
      padding-left: 5px;
      line-height: 38px;
      color: @fsColor;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: @bgColor;
    }
    //nav
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46px;
      padding: 35px 310px 0;
      color: @fsColor;
      font-weight: lighter;
      border-bottom: 1px solid @lineColor;
      .active {
        position: relative;
        width: 96px;
        height: 38px;
        line-height: 36px;
        text-align: center;
        border-radius: 7px;
        background: @btnColor;
        a {
          color: @btnfsColor;
        }
        .triangle {
          position: absolute;
          left: 50%;
          bottom: -9px;
          transform: translateX(-50%);
          border: 5px solid transparent;
          border-top-color: @btnColor;
        }
      }
      li {
        position: relative;
        width: 96px;
        height: 38px;
        line-height: 36px;
        text-align: center;
        border-radius: 7px;
        &:hover {
          background: @btnColor;
          a {
            color: @btnfsColor;
          }
          .triangle {
            position: absolute;
            left: 50%;
            bottom: -9px;
            transform: translateX(-50%);
            border: 5px solid transparent;
            border-top-color: @btnColor;
          }
        }
      }
    }
    .works {
      // 分页
      .pages {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 42px 0;
        li {
          margin-right: 16px;
          cursor: pointer;
          &:hover {
            color: @iptfsColor;
          }
        }
        .active {
          color: @iptfsColor;
        }
        .next {
          width: 48px;
          height: 25px;
          background: @btnColor;
          background-image: url(../../../../assets/img/copyright/fanye3.png);
          background-repeat: no-repeat;
          background-size: 14px;
          background-position: center;
          border-radius: 3px;
        }
      }
      // 作品展示
      .showWork {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        padding: 0 18px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 280px;
          margin-bottom: 32px;
          padding: 0 20px;
          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 280px;
              height: 202px;
              border-radius: 7px;
            }
            .name {
              padding: 10px 0;
            }
          }
          .info {
            width: 74%;
            .one,
            .two {
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              font-weight: lighter;
              .num {
                color: @iptfsColor;
              }
            }
            .two {
              margin-top: 10px;
            }
          }
        }
      }
      // 分页
      .bottomPages {
        margin-top: 25px;
      }
    }
  }
}
</style>