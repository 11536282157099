<template>
    <div class="lawForm">
        <p class="title">法律声明</p>
        <div class="content" v-html="falv"></div>
    </div>
</template>

<script>
export default {
    name: "Law",
    data() {
        return {
            falv:[]
        }
    },
    methods: {
        async getLaw() {
            let res = await this.$netWork.getLaw({
                method: "post",
                url: "/banner/falv",
            });
            console.log(res, '声明')
            this.falv = res.data[0].content
        }
    },
    mounted() {
        this.getLaw();
    },
}
</script>

<style scoped>
/* 白色背景 */
.lawForm {
    background: #353640;
    margin: 30px auto;
    position: relative;
    border-radius: 10px;
    padding: 14px 11px;
    box-sizing: border-box;
}

/* 大标题 */
.title {
    background: #26272D;
    border-radius: 10px;
    color: #5CE2ED;
    padding: 5px 12px;
    margin-bottom: 16px;
}
.content{
    width: 1000px;
    background: #26272D;
    margin:0 auto 20px auto;
    font-size: 14px;
    padding: 33px 30px;
    box-sizing: border-box;
    border-radius: 12px;
}
/* 用户使用协议 */
.agreement {
    font-size: 18px;
    color: black;
    margin: 30px 0 20px 30px;
}

/* 文本 */
.textarea {
    /* width: 700px; */
    margin: 20px auto;
}

.accept {
    font-size: 13px;
    color: rgb(98, 96, 100);
}
</style>