<template>
  <div>
    <p class="title">上传头像</p>
    <el-upload class="avatar-uploader" action="https://api.aboom.net/File/uploadFile" :show-file-list="false"
      :on-success="handleAvatarSuccess">
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>

    <el-button type="primary" @click="uploadAvatar" class="uploadBtn">上传头像</el-button>

    <el-dialog append-to-body title="封面裁剪" ref="dialog" width="1200" class="img-cropper" :visible.sync="dialogVisible"
      :close-on-click-modal="false">
      <div class="cropper-w">
        <div class="cropper" :style="{ width: '780px', height: '500px' }">
          <cropper-image :Name="cropperName" :url="imageUrl" ref="child">
          </cropper-image>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sure">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CropperImage from "./propper/cropperImage.vue";
export default {
  components: { CropperImage },
  name: "Avatar",
  created() {
    // this.uploadAvatar()
  },
  mounted() {
    //创建时绑定
    this.$Bus.$on("getScreenshotUrl", this.getScreenshot)
  },
  beforeDestroy() {
    //销毁时解绑
    this.$Bus.$off("getScreenshotUrl")
  },
  data() {
    return {
      screenshotUrl: {}, //截图url
      dialogVisible: false,
      cropperName: '',
      imageUrl: "",
      Path: "",
    };
  },
  methods: {
    getScreenshot(value) {
      this.screenshotUrl = value
      // console.log('截图url', value);
    },
    // 上传头像
    async uploadAvatar() {
      let res = await this.$netWork.uploadAvatar({
        url: "/user/uploadAvatar",
        method: "post",
        data: {
          token: localStorage.getItem("token"),
          file_id: this.Path[0].file_id,
        },
      });
      if (res.code == 0) {
        this.$message.success("修改成功.");
        let token = localStorage.getItem("token");
        let result = await this.$netWork.getUserInfo({
          method: "post",
          url: "/user/getUserInfo",
          data: {
            token: token,
          },
        });
        if (result.code == 0) {
          if (result.code !== 0) {
            alert(result.msg);
            return;
          }
          console.log(result.data, "修改头像后获取的user info");
          localStorage.setItem("userInfo", JSON.stringify(result.data));
          setTimeout(() => {
            this.$router.push({ path: "/" });
          }, 1000);
        }
      }else{
        this.$message.error(res.msg)
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      // console.log(res.data[0].abs_path,'resresres')
      this.Path = res.data;
      this.dialogVisible = true;
      // localStorage.setItem("avatar",res.data[0].file_path)
    },
    async sure() {
      let files = new File([this.screenshotUrl], Date.now() + "TY.jpg", {
        type: "image/jpeg",
        lastModified: Date.now(),
      });
      files.uid = new Date().getTime()
      let datas = new FormData()
      datas.append('file', files)
      let res = await this.$netWork.uploadImg({
        url: "/File/uploadFile",
        method: "post",
        data: datas
      });
      if (res.code === 0) {
        this.imageUrl = res.data[0].file_path;
        this.Path = res.data;
      }
      console.log('上传结果', res.data);
      this.dialogVisible = false
    },
  },
};
</script>

<style scoped lang="less">
.title {
  font-size: 18px;
  color: white;
  margin-top: 20px;
  margin-left: 560px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  margin-left: 520px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
  margin-left: 500px;
}

.uploadBtn {
  margin: 20px 540px;
}

.avatar-uploader-icon {
  margin-left: 500px;
  font-size: 28px;
  color: #0267f4;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 180px;
  height: 180px;
  display: block;
  margin: 20px 0;
  margin-left: 500px;
}

/deep/.el-dialog__header {
  background: #70737c;
  width: 780px;
}

/deep/.el-dialog__headerbtn {
  position: inherit;
  float: right;
}

/deep/.el-dialog__body {
  background: #70737c;
  width: 780px;
}

/deep/.el-dialog__footer {
  background: #70737c;
  width: 820px;
}

.el-dialog__wrapper {
  top: -80px;
}
</style>