<template>
  <div class="content">
    <div class="content_middle">
      <common-left1></common-left1>
      <!-- 右边 -->
      <div class="content_middleR">
        <div class="frame">
          <div class="BiaoTi">称号列表</div>
          <div class="list-frame test test-1">
            <div class="list" v-for="item in list" :key="item.acc_id">
              <img :src="item.file_hd" class="list-img" alt="" />
              <div class="list-layout">
                <div class="list-name">{{ item.acc_name }}</div>
                <div class="list-explain">{{item.acc_describe}}</div>
              </div>
              {{item.isGet}}
              <div class="DeDao">{{isGet(item.acc_id)}}</div>
            </div>
            <!-- <div class="list">
              <img
                src="~@/assets/achievement/png/2.png"
                class="list-img"
                alt=""
              />
              <div class="list-layout">
                <div class="list-name">旧日支配者</div>
                <div class="list-explain">用户给其他人点赞超过500次</div>
              </div>
              <div class="DeDao">已获得</div>
            </div>
            <div class="list">
              <img
                src="~@/assets/achievement/png/3.png"
                class="list-img"
                alt=""
              />

              <div class="list-layout">
                <div class="list-name">泛教徒</div>
                <div class="list-explain">用户11-20级默认称号</div>
              </div>
              <div class="DeDao">已获得</div>
            </div>
            <div class="list">
              <img
                src="~@/assets/achievement/png/4.png"
                class="list-img"
                alt=""
              />

              <div class="list-layout">
                <div class="list-name">苏醒的旧神</div>
                <div class="list-explain">用户发表作品获得赞数达到10万次</div>
              </div>
              <div class="DeDao">50%</div>
            </div>
            <div class="list">
              <img
                src="~@/assets/achievement/png/10.png"
                class="list-img"
                alt=""
              />

              <div class="list-layout">
                <div class="list-name">克苏鲁之眼</div>
                <div class="list-explain">连续签到次数达到100次</div>
              </div>
              <div class="DeDao">20%</div>
            </div> -->
            <div class="scrollbar"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUrl } from "../../common/util";
import commonLeft1 from "../../components/common_left1.vue";
export default {
  name: "designation",
  components: { commonLeft1 },
  data() {
    return {
      list: [],//所有
      lists: []//已获得
    }
  },
  async created() {
    await this.getlistAll()
    await this.getCjList()
    // await this.Filter()
  },
  computed:{
    
  },
  // watch:{
  //   list:{
  //     handler(newName, oldName) {
  //        console.log(' changed');
  //     },
  //     immediate: true
  //   }
  // },
  methods: {
    isGet(id){
      let res = this.lists.filter((item,idx)=>{
        console.log(item,id);
        return item==id
      })
      console.log(res,"res");
      if(res[0]){
        return "已获得"
      }else{
        return "未获得"
      }
    },
    getUrl,
    async getlistAll() {
      let res = await this.$netWork.getCjList({
        method: "get",
        url: "/user/getAllAchievement",
      })
      if (res.code == 0) {
        this.list = res.data
      }
    },
    async getCjList() {
      let res = await this.$netWork.getUserAchievementListByID({
        url: "/user/getAchievementListByID",
        method: "post",
        data: {
          uid: JSON.parse(localStorage.getItem("userInfo")).id
        }
      })
      if (res.code == 0) {
        res.data.forEach(element => {
          this.lists.push(element.acc_id)
        });
      }
    },
    Filter() {
      for (let i = 0; i < this.list.length; i++) {
        for (let j = 0; j < this.lists.length; j++) {
          console.log(this.list[i].acc_id,this.lists[j]);
          console.log();
          if (this.list[i].acc_id == this.lists[j]) {
            console.log(666);
            this.list[i].isGet = true
          }
          // else{
          //   this.list[i].isGet = false
          // }
        }
      }
    }
  }
};
</script>
<style scoped lang="less">
.content_middle {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
}

/* 右边 */
.content_middleR {
  display: flex;
  flex-direction: column;
  width: 996px;
}

/* 标题 */
.frame {
  /* flex: 1; */
  background-color: #353641;
  border-radius: 0.4rem;
  padding: 8px 8px 2rem 8px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
  height: 736px;
}

.BiaoTi {
  color: #4db9c2;
  background-color: #26272e;
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.4rem;
}

.list-frame {
  box-sizing: border-box;
  overflow: hidden;
  /* overflow-y: visible; */
  /* height: 12rem; */
  min-height: 680px;
}

.list {
  display: flex;
  background-color: #26282c;
  margin: 1rem 2rem;
  padding: 1rem;
  border-radius: 0.5rem;
}

.list-img {
  width: 4rem;
  /* height: 3rem; */
  /* border-radius: 50%; */
  /* background-color: red; */
  margin-right: 1rem;
}

.list-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}

.list-name {
  color: #4db9c2;
  font-size: 14px;
}

.list-explain {
  color: #cdced4;
  font-size: 14px;
}

.DeDao {
  color: #4db9c2;
  font-size: 14px;
  margin-top: 1rem;
}

/* 滚动条 */
.test {
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  height: 100px;
  overflow: auto;
  border: none;
}

.scrollbar {
  height: 764px;
  margin: 0 auto;
}

.test-1::-webkit-scrollbar {
  /*滚动条整体样式*/
  /* width: 10px; */
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  border-radius: 0.2rem;
}

.test-1::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  /* -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  background: #4bbac0;
  border-radius: 0.2rem;
}

.test-1::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border: 1px solid #4bbac0;
  border-radius: 0.2rem;
}
</style>