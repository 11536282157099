<template>
  <div class="wrapper clearfix">
    <!-- 侧边栏 -->
    <common-left1 v-if="bool"></common-left1>
    <common-left2 v-else :userid="userid"></common-left2>
    <!-- 内容 -->
    <div class="centerContent">
      <!-- 作品目录 -->
      <div class="nav1">
        <div class="head">
          <div class="title">作品说明</div>
          <ul>
            <li>
              浏览数：<span class="num">{{ views }}</span>
            </li>
            <li>
              评分次数：<span class="num">{{ sTimes }}</span>
            </li>
            <li>
              平均分：<span class="num">{{ avgScore ? parseInt(avgScore) : 0 }}.0</span>
            </li>
          </ul>
        </div>
        <!-- 介绍 -->
        <div class="introduce">
          <div class="one">
            <div class="time">{{ times }}</div>
          </div>
          <div class="two">
            <h2 class="title">{{ workTitle }}</h2>
          </div>
          <div class="explain">
            <!-- <p>
              这里是作品描述，这张图是来自P站一个很出名的画师画的，是初音的同人，登上过P站排行的第一位，非常大人气的一张同人画。后来根据此图还出了手办。谢谢观赏。
            </p>
            <p>微博地址：www.baidu.com</p> -->
            <p>{{ detail }}</p>
          </div>
        </div>
        <div class="tag">
          <ul>
            <li class="item" v-for="(item, index) in toDate" :key="index" @click="changeSearch(item.tag_name)">
              {{ item.tag_name }}
            </li>
            <!-- <li class="add">
              <img src="../../assets/imgs/tianjia.png" alt="" />
            </li> -->
          </ul>

          <div class="workImg">
            <el-carousel ref="carousel" trigger="click" height="700px" :autoplay="false" indicator-position="none">
              <el-carousel-item v-for="(item, index) in workImgList" :key="item.fid">
                <el-image @click="showMask(index)" style="width: 780px; height: 700px" :src="item.file_path" :fit="fit">
                </el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <ul class="ulImg">
            <li v-for="(item, idx) in workImgList" :class="{ active: idx == index }"
              @click="changUrl(idx, item.file_path)" :key="idx">
              <img width="80px" height="80px" :src="item.file_path" alt="" />
            </li>
          </ul>
          <div style="
              display: flex;
              height: 100px;
              justify-content: space-between;
              align-items: center;
              margin: 0px 20px;
              border-top: 1px solid #5dbbbf;
              padding-left: 65px;
            ">
            <!-- <div style="position: absolute;top: 30px;right: 300px;"> -->
            <div class="pf" ref="pf" @mouseleave="hide">
              <div class="pf_ts" v-if="score"></div>
              <div v-for="(item, index) in 10" id="dl" :key="(item, index)" :class="a(index)"
                @mouseenter="show(index + 1)" @mouseleave="hide(index)" @click="toScore(index + 1)"
                style="cursor: pointer"></div>
            </div>
            <!-- </div> -->
            <div style="display: flex; margin-left: 340px">
              <div class="like" @click="like(isLike)">
                <img width="30px" style="vertical-align: middle" :src="isLike
                  ? require('../../assets/imgs/dz.png')
                  : require('../../assets/imgs/dz01.png')
                  " alt="" />
                {{ isLike ? "" : "点赞" }}
              </div>
              <div class="collect" @click="collectOrCancel">
                <img width="30px" height="30px" style="vertical-align: middle; cursor: pointer" :src="isCollect
                  ? require('../../assets/imgs/sc01.png')
                  : require('../../assets/imgs/sc.png')
                  " alt="" />
                {{ isCollect ? "" : "收藏" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 作品留言板 -->
      <div class="nav2">
        <div class="head">
          <div class="title">作品留言板</div>
        </div>
        <div class="message scrollBar">
          <div v-for="(item, index) in commentList" :key="index">
            <div class="item">
              <div class="right">
                <img class="avatar" :src="item.userAvatar" alt="" />
                <div class="info">
                  <p class="name">
                    {{ item.nickname
                    }}<span class="time">{{ item.create_time }}</span>
                  </p>
                  <p class="text">{{ item.content }}</p>
                </div>
              </div>
              <div class="reply" @click="reply(item.id, item.nickname)">
                回复
              </div>
            </div>
            <div class="item replyhuifu" v-for="replyItem in item.reply" :key="replyItem.id">
              <div class="right">
                <img class="avatar" :src="replyItem.userAvatar" alt="" />
                <div class="info">
                  <p class="name">
                    {{ replyItem.nickname
                    }}<span class="time">{{ replyItem.create_time }}</span>
                  </p>
                  <p class="text">
                    回复<span class="replyName">{{ item.nickname }}</span>{{ replyItem.content }}
                  </p>
                </div>
              </div>
              <div class="reply" @click="reply(item.id, replyItem.nickname)">
                回复
              </div>
            </div>
          </div>
        </div>
        <form class="formInfo">
          <input class="inputInfo" type="text" :placeholder="replyNickname" v-model="commentContent" ref="ipt"
            @keydown.enter="send" />
          <!-- @blur="cancelReply" -->
          <div class="send" @click="send">发送</div>
        </form>
      </div>
    </div>
    <div class="mask" v-show="showimage" ref="mask"  @mousewheel="mousImg">
      <div class="left" @click="prev">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="img" :style="abab">
        <img :src="workImgListURL[imgIdx]" alt="">
      </div>
      <div class="right" @click="next">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import commonLeft1 from "../../components/common_left1.vue";
import commonLeft2 from "../../components/components_left2.vue";
import rate from "../../components/rate";
export default {
  name: "otherUserWorkTwo",
  components: {
    commonLeft2,
    commonLeft1,
    rate,
  },

  data() {
    return {
      imgSca: 1,
      abab: {
        transform: `scale(1)`
      },
      showimage: false,
      dz: "",
      bgcMap: {
        0: "#d3f1f5",
        1: "#b0dde3",
        2: "#7acdda",
        3: "#5cccdc",
        4: "#26c5dc",
        5: "#03bbd6",
        6: "#02abc1",
        7: "#0098a6",
        8: "#017080",
        9: "#015d67",
      },
      ispf: false,
      value: 6,
      workTitle: "", //作品题目
      workImgList: [], //作品图片
      workImgListURL: [], //作品图片url
      imgSrc: "", //图片路径
      index: 0, //图片索引
      imgIdx: 0,
      isCollect: 0, //是否收藏
      isLike: 0, //是否点赞
      commentList: [], //评论内容列表
      commentContent: "", //评论的内容
      replyNickname: "", //被回复人呢称
      commentId: "", //评论id
      timer: null, //定时器
      score: "", //分数
      userid: 0, //参数用户id
      detail: "",
      toDate: "", //当前作品标签
      times: "", //作品发布时间
      bool: false, //判断是否为自己账号
      views: "", //浏览次数
      sTimes: "", //评分次数
      avgScore: "", //平均分
      fit: "contain",
    };
  },
  // provide() {
  // 	return {
  // 		score: this.score
  // 	}

  // },
  async created() {
    sessionStorage.setItem("workId", this.$route.query.work_id);
    this.getWorkDetail();
    this.getCommentWorks();
    await this.getDetail();
    sessionStorage.removeItem("isScore");
    // this.getquery();
    this.userid = this.$route.query.uid;
    let userInfo = eval("(" + localStorage.getItem("userInfo") + ")");
    if (this.userid == userInfo?.id) {
      this.bool = true;
    }
    console.log(this.score, "created");
    if (this.score) {
      this.show(this.score);
      this.ispf = true;
    }
  },
  mounted() {
    console.log(this.$refs);
    this.$refs.mask.addEventListener("click", (e) => {
      console.log(e.target, e.target.className);
      if (e.target.className == "mask") {
        this.showimage = false
        let m = function (e) { e.preventDefault(); };
        document.body.style.overflow = '';//出现滚动条
        document.removeEventListener("touchmove", m, { passive: true });
        this.abab.transform = 'scale(1)';
      }
    })
    // this.getWorkDetail();
    // this.views++
    // setTimeout(() => {
    console.log(this.score, "mounted");

    // }, 500)
  },
  methods: {
    mousImg(e) {
      // deltaY 滚动距离 小于0向下缩小 大于0向上扩大
      if (e.deltaY > 0) {
        if (this.imgSca > 2.5) return
        this.imgSca += 0.1;
      } else {
        if (this.imgSca < 0.5) return
        this.imgSca -= 0.1;
      }
      this.abab.transform = `scale(${this.imgSca})`
    },
    prev() {
      if (this.imgIdx == 0) {
        this.showimage = false
        let m = function (e) { e.preventDefault(); };
        document.body.style.overflow = '';//出现滚动条
        document.removeEventListener("touchmove", m, { passive: true });
        this.abab.transform = 'scale(1)';
      } else {
        this.imgIdx--
      }
    },
    next() {
      if (this.imgIdx == this.workImgListURL.length - 1) {
        this.showimage = false
        let m = function (e) { e.preventDefault(); };
        document.body.style.overflow = '';//出现滚动条
        document.removeEventListener("touchmove", m, { passive: true });
        this.abab.transform = 'scale(1)';
      } else {
        this.imgIdx++
      }
    },
    showMask(index) {
      this.showimage = true
      this.imgIdx = index
      let m = function (e) { e.preventDefault(); };
      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove", m, { passive: false });//禁止页面滑动
    },
    //判断是否登陆过期.TY
    istoken(value) {
      if (!localStorage.getItem("token")) {
        // this.$message.warning("登录后才能进行评论");
        this.$confirm(`登录后才能进行${value},请先登录`, "登录提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "跳转到登陆页面",
            });
            this.$router.push("/login");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消跳转",
            });
          });
        return false;
      }
    },
    a(index) {
      return {
        [this.bgcMap[index]]: true,
      };
    },
    // pf(idx) {
    // 	this.toScore(idx + 1)
    // 	// let isScore = JSON.parse(sessionStorage.getItem("isScore"));
    // 	// console.log(isScore);
    // 	if (this.score) {
    // 		this.ispf = true
    // 	}
    // },
    show(idx) {
      if (this.ispf) {
        return;
      }
      for (var i = 0; i < idx; i++) {
        if (this.score) {
          this.$refs.pf.children[i + 1].style.backgroundColor = this.bgcMap[i];
        } else {
          this.$refs.pf.children[i].style.backgroundColor = this.bgcMap[i];
        }
      }
    },
    hide(idx) {
      console.log(this.ispf);
      if (this.ispf) {
        return;
      }
      console.log(idx, "6666", typeof idx);
      if (typeof idx == "object") {
        for (var i = 0; i < 10; i++) {
          if (this.$refs.pf.children[i]?.style) {
            this.$refs.pf.children[i].style.backgroundColor = "";
          }
        }
        return;
      }
      if (idx) {
        if (this.$refs.pf.children[idx]?.style) {
          this.$refs.pf.children[idx].style.backgroundColor = "";
        }
      } else {
        console.log(12321);
        for (var i = 0; i < 10; i++) {
          if (this.$refs.pf.children[i]?.style) {
            this.$refs.pf.children[i].style.backgroundColor = "";
          }
        }
      }
    },
    //获取路由参数
    getquery() {
      this.userid = this.$route.query.uid;
      console.log(this.userid, "userid");
    },
    //获取作品详情
    async getWorkDetail() {
      let res = await this.$netWork.getWorkDetail({
        url: "/works/getDetail",
        method: "post",
        data: {
          work_id: sessionStorage.getItem("workId"),
          page: "1",
          uid: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo"))?.id : ''
        },
      });
      this.workTitle = res.title;
      this.workImgList = res.fData;
      this.detail = res.detail;
      res.fData.forEach((data) => {
        this.workImgListURL.push(data.file_path);
      });
      this.imgSrc = this.workImgList[0].file_path;
      this.toDate = res.tData;
      this.times = res.publish_time;
      this.views = res.views;
      this.sTimes = res.score_times;
      this.avgScore = res.avg_score;
      console.log(this.toDate, "作品标签");
      console.log(res, "作品详情");
    },
    //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    // 切换图片
    changUrl(index, url) {
      // this.imgSrc = url;
      this.$refs.carousel.setActiveItem(index);
      this.index = index;
    },
    // 收藏作品
    async collectOrCancel() {
      this.istoken("收藏");
      let res = await this.$netWork.getCollectOrCancelWork({
        url: "/scoring/collection",
        method: "post",
        params: {
          works_id: parseInt(sessionStorage.getItem("workId")),
        },
        data: {
          token: localStorage.getItem("token"),
        },
      });
      if (res.code === 0) {
        if (!this.isCollect) {
          this.$message.success("收藏成功");
          this.isCollect = true;
        } else {
          this.$message.success("取消收藏成功");
          this.isCollect = false;
        }
      }
      if (res.code === 201) {
        this.$message.warning("用户登录过期");
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
      }
    },
    // 点赞
    async like(isLike) {
      this.istoken("点赞");
      if (isLike == 0) {
        let res = await this.$netWork.like({
          url: "/scoring/thumbs_up",
          method: "post",
          data: {
            works_id: sessionStorage.getItem("workId"),
            token: localStorage.getItem("token"),
          },
        });
        if (res.code == 0) {
          if (this.isLike) {
            this.isLike = 0;
          } else {
            this.isLike = 1;
          }
          this.$message.success(res.msg + "点赞");
        }
      }
    },
    // 获取评论
    async getCommentWorks() {
      let res = await this.$netWork.getCommentWorks({
        url: "/comment/getCommentByWorks",
        method: "post",
        data: {
          works_id: sessionStorage.getItem("workId"),
          page: "1",
        },
      });
      if (res.code === 0) {
        for (let i = 0; i < res.data.length; i++) {
          // 根据评论ID和作品ID获取评论
          let res1 = await this.$netWork.getCommentByCidAndWid({
            url: "/comment/getCommentByComment",
            method: "post",
            data: {
              works_id: sessionStorage.getItem("workId"),
              page: "1",
              comment_id: res.data[i].id,
            },
          });
          if (res1.code === 0) {
            res.data[i].reply = res1.data;
          }
        }
        this.commentList = res.data;
      }
    },
    // 作品评论
    async send() {
      this.istoken("评论");
      if (this.commentContent.trim() === "") {
        this.$message("评论不能为空");
        return false;
      }
      if (this.commentId) {
        let res = await this.$netWork.commentWorks({
          url: "/comment/commentWorks",
          method: "post",
          data: {
            token: localStorage.getItem("token"),
            works_id: sessionStorage.getItem("workId"),
            comment_id: this.commentId,
            content: this.commentContent,
          },
        });
        if (res.code === 0) {
          this.commentContent = "";
          this.commentId = "";
          this.replyNickname = "";
          this.getCommentWorks();
        }
        return false;
      }
      let res = await this.$netWork.commentWorks({
        url: "/comment/commentWorks",
        method: "post",
        data: {
          token: localStorage.getItem("token"),
          works_id: sessionStorage.getItem("workId"),
          content: this.commentContent,
        },
      });
      if (res.code === 0) {
        this.commentContent = "";
        this.getCommentWorks();
      }
    },
    // 回复
    reply(id, nickname) {
      this.$refs.ipt.focus();
      this.replyNickname = "@" + nickname;
      this.commentId = id;
    },
    // 取消回复
    cancelReply() {
      this.replyNickname = "";
      this.commentContent = "";
      this.timer = setTimeout(() => {
        this.commentId = "";
      }, 1000);
    },
    // 获取用户对作品收藏点赞信息
    async getDetail() {
      let res = await this.$netWork.getDetail({
        url: "/scoring/getDetail",
        method: "post",
        data: {
          token: localStorage.getItem("token"),
          works_id: sessionStorage.getItem("workId"),
        },
      });
      console.log(res, "点赞");
      if (res.code === 0) {
        if (res.data) {
          this.isCollect = res.data.collection;
          console.log(res.data, "9999999999");
          this.isLike = res.data.thumbs_up;
          sessionStorage.setItem("isScore", res.data.score);
          this.score = res.data.score;
        }
      }
    },
    // 评分
    toScore(score) {
      if (this.score) {
        this.$message("该作品已评分");
        return;
      }
      this.ispf = true;
      if (!localStorage.getItem("token")) {
        // this.$message.warning("登录后才能进行评分");
        this.$confirm("登录后才能进行评分,请先登录", "登录提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "跳转到登陆页面",
            });
            this.$router.push("/login");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消跳转",
            });
            console.log("12321312313212312132");
            this.ispf = false;
            this.hide();
          });
        return false;
      }
      let isScore = JSON.parse(sessionStorage.getItem("isScore"));
      if (isScore) {
        this.$message("该作品已评分");
        this.getDetail();
        return false;
      }
      this.$confirm(`此次评分分数：${score}, 是否评分?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        // this.ispf = false
        .then(async () => {
          let res = await this.$netWork.score({
            url: "/scoring/score",
            method: "post",
            params: {
              works_id: sessionStorage.getItem("workId"),
              score: score,
            },
            data: {
              token: localStorage.getItem("token"),
            },
          });
          console.log(res, "resresreresrsd");
          if (res.code === 0) {
            this.$message.success("评分成功");
            this.getDetail();
            this.show(score);
            this.ispf = true;
          }
        })
        .catch(() => {
          this.score = null;
          this.$message({
            type: "info",
            message: "已取消评分",
          });
          this.ispf = false;
          this.hide();
        });
    },
    //作品标签点击搜索
    changeSearch(parameter) {
      console.log(parameter);
      this.$router.push({
        path: "/search",
        query: {
          search: parameter,
        },
      });
    },
  },
  beforeDestroy() {
    this.timer = null;
  },
};
</script>

<style lang="less" scoped>
.left,
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 400px;

  i {
    width: 50px;
    height: 50px;
    font-size: 50px;
  }
}

.mask {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(53, 54, 63, .7);
  // opacity: 0.7;
  z-index: 9999999;

  .img {
    // opacity: none;
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto
    }
  }
}

@import "../../assets/iconfont/iconfont.css";

* {
  margin: 0;
  padding: 0;
}

/deep/ .el-image-viewer__next,
/deep/ .el-image-viewer__prev {
  height: 100vh !important;
}



/deep/ span.el-image-viewer__prev {
  height: 180px !important;
}

// /deep/ .el-carousel__arrow {
//   height: 100%;
//   width: 50px;
//   border-radius:0px;
//   .el-carousel__arrow--left {
//     left: 0px;
//   }
// }

.pf_ts {
  width: 220px;
  height: 32px;
  line-height: 32px;
  position: absolute;
  text-align: center;
  font-size: 16px;
  color: #fff;
  z-index: -1;
}

.pf {
  width: 220px;
  height: 32px;
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  // margin: 20px auto;
  border: 2px solid #5ce2ed;
  border-radius: 5px;
}

.pf::after {
  content: "评分:";
  position: absolute;
  width: 60px;
  height: 38px;
  line-height: 38px;
  font-size: 22px;
  // height: 20px;
  // border-radius: 2px;
  left: -66px;
  color: #5ce2ed;
}

.pf::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 20px;
  border-radius: 2px;
  background-color: #5ce2ed;
  right: -12px;
}

#dl {
  box-sizing: border-box;
  width: 20px;
  height: 28px;
}

a {
  text-decoration: none;
  color: @fsColor;
}

li {
  color: @fsColor;
  list-style: none;
}

.scrollBar {
  overflow-y: auto;
}

/*滚动条整体样式*/
.scrollBar::-webkit-scrollbar {
  width: 18px;
  height: 10px;
  background: @bg1Color;
}

/*滚动条滑块*/
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: @btnColor;
}

/*滚动条轨道*/
.scrollBar::-webkit-scrollbar-track {
  border: 1px solid @btnColor;
  border-radius: 4px;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;

  .centerContent {
    margin-left: 20px;
    flex: 1;

    // 高度塌陷
    .nav1::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      clear: both;
      overflow: hidden;
      visibility: hidden;
      zoom: 1;
    }

    // 作品目录
    .nav1 {
      overflow: hidden;
      // height: 1108px;
      border-radius: 7px;
      background: @bg1Color;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 38px;
        margin: 8px 8px 0 8px;
        padding: 0 5px;
        color: @fsColor;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background: @bgColor;

        ul {
          display: flex;

          li {
            margin-left: 50px;
            font-size: 14px;

            .num {
              font-weight: lighter;
              color: @iptfsColor;
            }
          }
        }
      }

      // 介绍
      .introduce {
        padding: 10px 10px 0 15px;

        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .time {
            font-size: 14px;
            font-weight: lighter;
            color: @iptfsColor;
          }
        }

        .two {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 7px;

          .title {
            font-weight: normal;
            color: @fsColor;
          }

          ul {
            display: flex;
          }
        }

        .explain {
          margin: 30px 0 25px;
          font-size: 15px;
          font-weight: lighter;
          color: @fsColor;

          p {
            line-height: 24px;
          }
        }
      }

      .tag {
        position: relative;

        ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          // height: 40px;
          background: @bgColor;

          .item {
            position: relative;
            padding: 0 30px;
            cursor: pointer;

            &::before {
              content: ".";
              position: absolute;
              top: -4px;
              left: 20px;
              padding-right: 4px;
              font-weight: bold;
            }
          }

          .add {
            width: 18px;
            height: 18px;
            padding: 3px 0 0 25px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .collect {
          // position: absolute;
          // top: 56px;
          // right: 30px;
          // width: 120px;
          padding: 0 10px 0 10px;
          height: 36px;
          line-height: 32px;
          text-align: center;
          font-weight: lighter;
          color: @btnfsColor;
          cursor: pointer;
          border-radius: 7px;
          background: @btnColor;
        }

        .like {
          // position: absolute;
          // top: 56px;
          // right: 160px;
          // width: 120px;
          padding: 0 10px 0 10px;
          height: 36px;
          margin-right: 40px;
          line-height: 36px;
          text-align: center;
          font-weight: lighter;
          color: @btnfsColor;
          cursor: pointer;
          border-radius: 7px;
          background: @btnColor;
        }

        .workImg {
          width: 780px;
          margin: 100px auto 0;
        }

        // 高度塌陷
        .ulImg::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          clear: both;
          overflow: hidden;
          visibility: hidden;
          zoom: 1;
        }

        .ulImg {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 620px;
          margin: 20px 0 10px 188px;
          background: none;
          list-style: none;

          li {
            margin: 0 17px 14px;
            box-sizing: border-box;
            width: 90px;

            // height: 90px;
            img {
              width: 100%;
              height: 90px;
              object-fit: cover;
              vertical-align: middle;
            }
          }

          .active {
            // box-shadow: 0 0 4px #5accd8;
            border: 1px solid @btnColor;
          }
        }
      }
    }

    // 作品留言板
    .nav2 {
      overflow: hidden;
      height: 459px;
      margin-top: 14.77px;
      border-radius: 7px;
      background: @bg1Color;

      .head {
        height: 38px;
        margin: 8px 8px 0 8px;
        padding: 0 5px;
        line-height: 38px;
        color: @fsColor;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background: @bgColor;
      }

      .message {
        position: relative;
        overflow-y: auto;
        height: 312px;
        margin: 10px 10px 0 0;
        padding: 10px 50px 20px 20px;
        color: @fsColor;

        .replyhuifu {
          //回复评论样式
          padding-left: 20px;
        }

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          padding-bottom: 12px;
          border-bottom: 1px solid @lineColor;

          .right {
            display: flex;

            .avatar {
              width: 40px;
              height: 40px;
              border-radius: 7px;
            }

            .info {
              margin-left: 20px;

              .name {
                font-size: 13px;
                font-weight: lighter;

                .time {
                  font-size: 12px;
                  padding-left: 20px;
                }
              }

              .text {
                font-size: 13px;
                font-weight: lighter;
                color: @iptfsColor;

                .replyName {
                  color: @fsColor;
                }
              }
            }
          }

          .reply {
            font-size: 14px;
            font-weight: lighter;
            cursor: pointer;
          }
        }
      }

      .formInfo {
        display: flex;
        align-items: center;
        margin: 14px 15px 0px 15px;

        .inputInfo {
          width: 100%;
          height: 32px;
          padding-left: 10px;
          color: @iptfsColor;
          background: @bgColor;
          border-radius: 7px;
          border: none;
        }

        .send {
          width: 116px;
          height: 36px;
          margin-left: 15px;
          line-height: 36px;
          text-align: center;
          color: @btnfsColor;
          cursor: pointer;
          background: @btnColor;
          border: none;
          border-radius: 7px;
        }
      }
    }
  }
}

// @font-face {
// 	font-family: "iconfont";
// 	src: url("../../assets/iconfont/iconfont.woff2?t=1649991164315") format("woff2"),
// 		url("../../assets/iconfont/iconfont.woff?t=1649991164315") format("woff"),
// 		url("../../assets/iconfont/iconfont.ttf?t=1649991164315") format("truetype");
// }

// /deep/.el-icon-star-on:before {
// 	font-family: "iconfont" !important;
// 	font-size: 16px;
// 	font-style: normal;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// 	content: "\e71e";
// }

// /deep/.el-icon-star-off:before {
// 	font-family: "iconfont" !important;
// 	font-size: 16px;
// 	font-style: normal;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// 	content: "\e71f";
// }

/deep/ .el-rate__icon {
  // color: #5accd8 !important;
  font-size: 34px;
}
</style>
