<!--
 * @Author: your name
 * @Date: 2022-04-26 10:39:06
 * @LastEditTime: 2022-04-28 14:44:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \aboom-vue\src\views\new\realName.vue
-->
<template>
  <div style="width: 1200px">
    <h2>填写信息</h2>
    <div class="flex" style="width: 500px">
      <p style="width: 100px; margin-top: 6px">真实姓名</p>
      <el-input
        type="text"
        v-model="name"
        size="small"
        placeholder="请输入姓名"
        @blur="bb"
      ></el-input>
    </div>
    <p v-if="isShow == 2" style="margin-left: 82px; color: red">
      请输入正确的名字
    </p>
    <div class="flex" style="width: 500px">
      <p style="width: 100px; margin-top: 6px">证件号码</p>
      <el-input
        ref="sfz"
        type="text"
        size="small"
        placeholder="请输入身份证号码"
        v-model="sfz"
        @blur="aa"
      ></el-input>
    </div>
    <p v-if="isShow == 1" style="margin-left: 82px; color: red">
      请输入正确的身份证号码
    </p>
    <div class="flex" style="width: 500px">
      <p style="width: 100px; margin-top: 6px">通讯地址</p>
      <el-input
        ref="sfz"
        type="text"
        size="small"
        placeholder="请输入通讯地址"
        v-model="address"
        @blur="aa"
      ></el-input>
    </div>
    <div class="flex">
      <el-upload
        class="avatar-uploader"
        action="https://api.aboom.net/File/uploadFile"
        :show-file-list="false"
        :on-success="handleAvatarSuccess1"
        :before-upload="beforeAvatarUpload1"
        list-type="picture"
        accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
      >
        <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon bg1"></i>
        <p>上传手持证件照</p>
      </el-upload>
      <el-upload
        class="avatar-uploader"
        action="https://api.aboom.net/File/uploadFile"
        :show-file-list="false"
        :on-success="handleAvatarSuccess2"
        :before-upload="beforeAvatarUpload2"
        list-type="picture"
        accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
      >
        <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon bg2"></i>
        <p>上传证件正面照</p>
      </el-upload>
      <el-upload
        class="avatar-uploader"
        action="https://api.aboom.net/File/uploadFile"
        :show-file-list="false"
        :on-success="handleAvatarSuccess3"
        :before-upload="beforeAvatarUpload3"
        list-type="picture"
        accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
      >
        <img v-if="imageUrl3" :src="imageUrl3" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon bg3"></i>
        <p>上传证件被面照</p>
      </el-upload>
    </div>
    <div class="flex2">
      <h2 style="margin-bottom: 20px">验证手机</h2>
      <p>
        手机 <input v-if="!showm" type="text" v-model="userMobile" /><span
          v-else
          >{{ userMobile }}</span
        >
      </p>
      <div class="flex" style="width: 400px">
        <p style="width: 90px; margin-top: 6px">手机验证码</p>
        <el-input
          v-model="phone_code"
          size="small"
          type="text"
          style="width: 120px"
        ></el-input>
        <button
          ref="btn2"
          :disabled="msg2 > 0"
          class="btn"
          @click="getCode(false)"
        >
          {{ msg2 > 0 ? msg2 : "点击获取" }}
        </button>
      </div>
    </div>
    <div class="flex2">
      <h2 style="margin-bottom: 20px">验证邮箱</h2>
      <p>
        邮箱 <input v-if="!showe" type="text" v-model="userEmail" /><span
          v-else
          >{{ userEmail }}</span
        >
      </p>
      <div class="flex" style="width: 400px">
        <p style="width: 90px; margin-top: 6px">邮箱验证码</p>
        <el-input
          v-model="email_code"
          size="small"
          type="text"
          style="width: 120px"
        ></el-input>
        <button
          ref="btn"
          :disabled="msg > 0"
          class="btn"
          @click="getCode(true)"
        >
          {{ msg > 0 ? msg : "点击获取" }}
        </button>
      </div>
    </div>
    <div class="flex" style="width: 100%; justify-content: center">
      <p style="margin-top: 30px">
        <button type="button" @click="submit" class="sub">提交认证</button>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userEmail = userInfo.email;
    this.userMobile = userInfo.mobile;
    if (userInfo.mobile) {
      this.showm = true;
    }
    if (userInfo.email) {
      this.showe = true;
    }
  },
  data() {
    return {
      isShow: 0,
      msg: 0,
      msg2: 0,
      showm: false,
      showe: false,
      userMobile: "",
      userEmail: "",
      imageUrl1: "",
      imageUrl2: "",
      imageUrl3: "",
      id1: "",
      id2: "",
      id3: "",
      name: "",
      sfz: "",
      email_code: "",
      phone_code: "",
      address: "", //通讯地址
      userinfo: {}, //用户实名认证信息
      card_front_path: "", //正面地址
      card_back_path: "", //反面地址
      card_front_file_name: "", //正面文件名
      card_back_file_name: "", //反面文件名
    };
  },
  computed: {},
  mounted() {
    this.getIdentificationData();
    this.filter();
  },
  methods: {
    filter() {
      if (this.userinfo.status == "1") {
        this.$message.success("实名认证审核中,请勿重复提交！");
      }
    },
    aa() {
      if (
        this.sfz.length == "18" &&
        /([0-9]{18})|([0-9]{17}x)/i.test(this.sfz)
      ) {
        this.isShow = 0;
      } else {
        this.isShow = 1;
      }
      console.log("ffsd55555");
    },
    bb() {
      if (/^[\u4e00-\u9fa5]+$/g.test(this.name)) {
        this.isShow = 0;
      } else {
        this.isShow = 2;
      }
      console.log("name");
    },
    async getIdentificationData() {
      let res = await this.$netWork.getIdentificationData({
        url: "/Identification/getIdentificationData",
        method: "post",
        data: {
          token: localStorage.getItem("token"),
        },
      });
      this.userinfo = res;
      this.name = res.realname;
      this.sfz = res.id_card;
      this.address = res.address;
      console.log(this.userinfo);
    },
    async submit() {
      let token = localStorage.getItem("token");
      let res = {};
      if (this.userinfo.status == 0) {
        res = await this.$netWork.realName({
          url: "/identification/uploaddata",
          method: "post",
          data: {
            token: token,
            id: this.userinfo.id.toString(),
            realname: this.name,
            id_card: this.sfz,
            address: this.address,
            card_front: this.id2.toString(),
            card_back: this.id3.toString(),
            card_hand: this.id1.toString(),
            phone_code: this.phone_code,
            email_code: this.email_code,
            phone: this.userMobile,
            email: this.userEmail,
            card_front_path: this.imageUrl2,
            card_back_path: this.imageUrl3,
            card_front_file_name: this.card_front_file_name,
            card_back_file_name: this.card_back_file_name,
          },
        });
        console.log("修改");
        if (res.code == 1) {
          switch (res.code) {
            case "1":
              this.$message.error("上传错误！");
          }
        }
      } else {
        res = await this.$netWork.realName({
          url: "/identification/uploaddata",
          method: "post",
          data: {
            token: token,
            realname: this.name,
            id_card: this.sfz,
            address: this.address,
            card_front: this.id2.toString(),
            card_back: this.id3.toString(),
            card_hand: this.id1.toString(),
            phone_code: this.phone_code,
            email_code: this.email_code,
            phone: this.userMobile,
            email: this.userEmail,
            code: this.code,
            card_front_path: this.imageUrl2,
            card_back_path: this.imageUrl3,
            card_front_file_name: this.card_front_file_name,
            card_back_file_name: this.card_back_file_name,
          },
        });
        console.log("提交");
        if (res.code == 1) {
          switch (res.msg) {
            case "realname不能为空":
              this.$message.error("姓名不能未空");
              break;
            case "card_back不能为空":
              this.$message.error("身份证背面图片未上传");
              break;
            case "card_hand不能为空":
              this.$message.error("手持身份证图片未上传");
              break;
            case "card_front不能为空":
              this.$message.error("身份证正面图片未上传");
              break;
            case "card_front不能为空":
              this.$message.error("身份证图片未上传");
              break;
            case "验证码错误！":
              this.$message.error("验证码错误");
              break;
            case "验证码已过期！":
              this.$message.error("验证码已过期");
              break;
            case "id_card规则错误":
              this.$message.error("身份证格式验证格式错误或不正确");
              break;
            case "手机已被绑定。":
              this.$message.error("手机已被绑定。");
              break;
          }
		  if (res.msg.includes('网络繁忙...')) {
			this.$message.error("身份证图片上传失败！！！");
		  }
        }
      }
      console.log(res);
      if (res.code == 0) {
        this.$message.success("实名认证成功");
        setTimeout(() => {
          this.$router.push("/");
        }, 1000);
      }
    },
    async getCode(value) {
      let res = await this.$netWork.getMac({
        url: "/verify/getVerify",
        method: "post",
        data: {
          account: value ? this.userEmail : this.userMobile,
          template: "register",
          type: value ? "1" : "2", //邮箱1，手机2
        },
      });
      if (res.code == 0) {
        // this.$refs.btn.disabled = value?true:false;
        // this.$refs.btn2.disabled = value?false:true;
        this.$message.success("验证码发送成功,请注意查收");
        if (value && this.msg <= 0) {
          this.msg = 60;
          let time = setInterval(() => {
            this.msg--;
          }, 1000);
          setTimeout(() => {
            clearInterval(time);
            // this.$refs.btn.disabled = false;
          }, 61 * 1000);
        }
        if (!value && this.msg2 <= 0) {
          this.msg2 = 60;
          let time2 = setInterval(() => {
            this.msg2--;
          }, 1000);
          setTimeout(() => {
            clearInterval(time2);
            // this.$refs.btn2.disabled = false;
          }, 61 * 1000);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess1(res, file) {
      this.imageUrl1 = URL.createObjectURL(file.raw);
      this.id1 = res.data[0].file_id;
      console.log("id1:", res, file);
    },
    beforeAvatarUpload1(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("手持证件照大小不能超过 2MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccess2(res, file) {
      this.id2 = res.data[0].file_id;
      this.card_front_file_name = file.name;
      this.imageUrl2 = res.data[0].file_path;
      console.log(this.imageUrl2);
    },
    beforeAvatarUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("证件正面照大小不能超过 2MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccess3(res, file) {
		console.log('id3',res,file)
      this.id3 = res.data[0].file_id;
      this.card_back_file_name = file.name;
      this.imageUrl3 = res.data[0].file_path;
      console.log(this.imageUrl3);
    },
    beforeAvatarUpload3(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("证件背面照大小不能超过 2MB!");
      }
      return isLt2M;
    },
  },
};
</script>
<style>
.sub {
  width: 100px;
  height: 32px;
  background-color: cyan;
  border-radius: 2px;
  border: none;
}

.btn {
  width: 70px;
  height: 28px;
  background-color: orange;
  color: white;
  margin: 2px 0 0 18px;
  border-radius: 7px;
  border: none;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.flex2 {
  width: 50%;
  float: left;
}

.avatar-uploader .el-upload {
  /* border: 1px dashed #d9d9d9; */
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 412px;
  height: 256px;
  line-height: 265px;
  text-align: center;
}

.avatar {
  width: 367px;
  height: 227px;
  margin: 15px 22px;
  display: block;
}

.bg1 {
  background: url("~@/assets/imgs/sfz1.png") no-repeat center/contain;
}

.bg2 {
  background: url("~@/assets/imgs/sfz3.png") no-repeat center/contain;
}

.bg3 {
  background: url("~@/assets/imgs/sfz2.png") no-repeat center/contain;
}
</style>
