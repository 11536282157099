<template>
  <div class="wrapper clearfix">
    <!-- 侧边栏 -->
    <common-left></common-left>
    <!-- 内容 -->
    <div class="centerContent">
      <!-- 作品目录 -->
      <div class="nav1">
        <div class="head">
          <div class="title">作品目录</div>
        </div>
        <!-- 介绍 -->
        <div class="introduce">
          <div class="one">
            <div class="time">2015年12月25日&emsp;20:05</div>
            <ul>
              <li>浏览数：<span class="num">361</span></li>
              <li>评分次数：<span class="num">233</span></li>
              <li>综合分：<span class="num">1204</span></li>
            </ul>
          </div>
          <div class="two">
            <h2 class="title">世界第一公主殿下</h2>
            <ul>
              <li class="radio">
                <input type="radio" checked />
                <label class="radio-label"></label>
              </li>
              <li class="radio">
                <input type="radio" checked />
                <label class="radio-label"></label>
              </li>
              <li class="radio">
                <input type="radio" />
                <label class="radio-label"></label>
              </li>
              <li class="radio">
                <input type="radio" />
                <label class="radio-label"></label>
              </li>
              <li class="radio">
                <input type="radio" />
                <label class="radio-label"></label>
              </li>
              <li class="radio">
                <input type="radio" />
                <label class="radio-label"></label>
              </li>
              <li class="radio">
                <input type="radio" />
                <label class="radio-label"></label>
              </li>
              <li class="radio">
                <input type="radio" />
                <label class="radio-label"></label>
              </li>
              <li class="radio">
                <input type="radio" />
                <label class="radio-label"></label>
              </li>
              <li class="radio">
                <input type="radio" />
                <label class="radio-label"></label>
              </li>
            </ul>
          </div>
          <div class="explain">
            <p>
              这里是作品描述，这张图是来自P站一个很出名的画师画的，是初音的同人，登上过P站排行的第一位，非常大人气的一张同人画。后来根据此图还出了手办。谢谢观赏。
            </p>
            <p>微博地址：www.baidu.com</p>
          </div>
        </div>
        <div class="tag">
          <ul>
            <li class="item">初音</li>
            <li class="item">同人</li>
            <li class="item">公主</li>
            <li class="add">
              <img src="../../../../assets/imgs/tianjia.png" alt="" />
            </li>
          </ul>
          <div class="collect">收藏</div>
          <div class="workImg">
            <img src="../../../../assets/imgs/zuopindatu.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 作品留言板 -->
      <div class="nav2">
        <div class="head">
          <div class="title">作品留言板</div>
        </div>
        <div class="message scrollBar">
          <div class="item">
            <div class="right">
              <img
                class="avatar"
                src="../../../../assets/imgs/yonghu1.png"
                alt=""
              />
              <div class="info">
                <p class="name">吴彦祖<text class="time">5小时前</text></p>
                <p class="text">大大我来啦，留个瓜先</p>
              </div>
            </div>
            <div class="reply">回复</div>
          </div>
          <div class="item">
            <div class="right">
              <img
                class="avatar"
                src="../../../../assets/imgs/yonghu2.png"
                alt=""
              />
              <div class="info">
                <p class="name">流川枫<text class="time">2小时前</text></p>
                <p class="text">
                  回复<text class="replyName">金城武</text>谢谢夸奖，已互粉啦
                </p>
              </div>
            </div>
            <div class="reply">回复</div>
          </div>
          <div class="item">
            <div class="right">
              <img
                class="avatar"
                src="../../../../assets/imgs/yonghu4.png"
                alt=""
              />
              <div class="info">
                <p class="name">金城武<text class="time">1小时前</text></p>
                <p class="text">好漂亮啊，这里是金城武，求互粉</p>
              </div>
            </div>
            <div class="reply">回复</div>
          </div>
          <div class="item">
            <div class="right">
              <img
                class="avatar"
                src="../../../../assets/imgs/yonghu5.png"
                alt=""
              />
              <div class="info">
                <p class="name">巴洛克<text class="time">30分钟前</text></p>
                <p class="text">这张太美了，沙发。</p>
              </div>
            </div>
            <div class="reply">回复</div>
          </div>
          <div class="item">
            <div class="right">
              <img
                class="avatar"
                src="../../../../assets/imgs/yonghu1.png"
                alt=""
              />
              <div class="info">
                <p class="name">吴彦祖<text class="time">5小时前</text></p>
                <p class="text">大大我来啦，留个瓜先</p>
              </div>
            </div>
            <div class="reply">回复</div>
          </div>
          <div class="item">
            <div class="right">
              <img
                class="avatar"
                src="../../../../assets/imgs/yonghu2.png"
                alt=""
              />
              <div class="info">
                <p class="name">流川枫<text class="time">2小时前</text></p>
                <p class="text">
                  回复<text class="replyName">金城武</text>谢谢夸奖，已互粉啦
                </p>
              </div>
            </div>
            <div class="reply">回复</div>
          </div>
          <div class="item">
            <div class="right">
              <img
                class="avatar"
                src="../../../../assets/imgs/yonghu4.png"
                alt=""
              />
              <div class="info">
                <p class="name">金城武<text class="time">1小时前</text></p>
                <p class="text">好漂亮啊，这里是金城武，求互粉</p>
              </div>
            </div>
            <div class="reply">回复</div>
          </div>
          <div class="item">
            <div class="right">
              <img
                class="avatar"
                src="../../../../assets/imgs/yonghu5.png"
                alt=""
              />
              <div class="info">
                <p class="name">巴洛克<text class="time">30分钟前</text></p>
                <p class="text">这张太美了，沙发。</p>
              </div>
            </div>
            <div class="reply">回复</div>
          </div>
        </div>
        <form class="formInfo">
          <input class="inputInfo" type="text" />
          <input class="send" type="submit" value="发送" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import commonLeft from "../../../../components/common_left.vue";
export default {
  name: "otherUserWorkTwo",
  components: { commonLeft },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: @fsColor;
}
li {
  color: @fsColor;
  list-style: none;
}
.radio {
  margin-left: 10px;
}

.radio input[type="radio"] {
  position: absolute;
  margin: 5px 0 0 4px;
  opacity: 0;
}
.radio input[type="radio"] + .radio-label:before {
  content: "";
  border-radius: 100%;
  border: 1px solid @fsColor;
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  margin-right: 1em;
  vertical-align: bottom;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: @fsColor;
  box-shadow: inset 0 0 0 3px @bg1Color;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: @fsColor;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
.scrollBar {
  overflow-y: auto;
}

/*滚动条整体样式*/
.scrollBar::-webkit-scrollbar {
  width: 18px;
  height: 10px;
  background: @bg1Color;
}
/*滚动条滑块*/
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: @btnColor;
}

/*滚动条轨道*/
.scrollBar::-webkit-scrollbar-track {
  border: 1px solid @btnColor;
  border-radius: 4px;
}
@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;
.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
  width: 1200px;
  .centerContent {
    width: 996px;
    // 作品目录
    .nav1 {
      overflow: hidden;
      width: 996px;
      height: 1038px;
      border-radius: 7px;
      background: @bg1Color;
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 38px;
        margin: 8px 8px 0 8px;
        padding: 0 5px;
        color: @fsColor;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background: @bgColor;
      }
      // 介绍
      .introduce {
        padding: 10px 10px 0 15px;
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .time {
            font-size: 14px;
            font-weight: lighter;
            color: @iptfsColor;
          }
          ul {
            display: flex;
            li {
              margin-left: 50px;
              font-size: 14px;
              .num {
                font-weight: lighter;
                color: @iptfsColor;
              }
            }
          }
        }
        .two {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 7px;
          .title {
            font-weight: normal;
            color: @fsColor;
          }
          ul {
            display: flex;
          }
        }
        .explain {
          margin: 30px 0 25px;
          font-size: 15px;
          font-weight: lighter;
          color: @fsColor;
          p {
            line-height: 24px;
          }
        }
      }
      .tag {
        position: relative;
        ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          height: 40px;
          background: @bgColor;
          .item {
            position: relative;
            padding: 0 30px;
            &::before {
              content: ".";
              position: absolute;
              top: -4px;
              left: 20px;
              padding-right: 4px;
              font-weight: bold;
            }
          }
          .add {
            width: 18px;
            height: 18px;
            padding: 3px 0 0 25px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .collect {
          position: absolute;
          top: 56px;
          right: 30px;
          width: 100px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          font-weight: lighter;
          color: @btnfsColor;
          cursor: pointer;
          border-radius: 7px;
          background: @btnColor;
        }
        .workImg {
          width: 620px;
          height: 532px;
          margin-top: 100px;
          margin-left: 50%;
          transform: translateX(-54%);
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    // 作品留言板
    .nav2 {
      overflow: hidden;
      width: 996px;
      height: 459px;
      margin-top: 14.77px;
      border-radius: 7px;
      background: @bg1Color;
      .head {
        height: 38px;
        margin: 8px 8px 0 8px;
        padding: 0 5px;
        line-height: 38px;
        color: @fsColor;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background: @bgColor;
      }
      .message {
        position: relative;
        overflow-y: auto;
        height: 312px;
        margin: 10px 10px 0 0;
        padding: 10px 50px 20px 20px;
        color: @fsColor;
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          padding-bottom: 12px;
          border-bottom: 1px solid @lineColor;
          .right {
            display: flex;
            .avatar {
              width: 40px;
              height: 40px;
              border-radius: 7px;
            }
            .info {
              margin-left: 20px;
              .name {
                font-size: 13px;
                font-weight: lighter;
                .time {
                  font-size: 12px;
                  padding-left: 20px;
                }
              }
              .text {
                font-size: 13px;
                font-weight: lighter;
                color: @iptfsColor;
                .replyName {
                  color: @fsColor;
                }
              }
            }
          }
          .reply {
            font-size: 14px;
            font-weight: lighter;
            cursor: pointer;
          }
        }
      }
      .formInfo {
        display: flex;
        align-items: center;
        margin: 14px 15px 0px 15px;
        .inputInfo {
          width: 100%;
          height: 32px;
          padding-left: 10px;
          color: @iptfsColor;
          background: @bgColor;
          border-radius: 7px;
          border: none;
        }
        .send {
          width: 116px;
          height: 36px;
          margin-left: 15px;
          line-height: 36px;
          text-align: center;
          color: @btnfsColor;
          cursor: pointer;
          background: @btnColor;
          border: none;
          border-radius: 7px;
        }
      }
    }
  }
}
</style>