<template>
  <div class="wrapper">
    <!-- 侧边栏 -->
    <common-left1></common-left1>
    <!-- 内容 -->
    <div class="centerContent">
      <div class="title">申请版权保护</div>
      <div class="tit_2">
        保版权保护申领说明：请上传与作品相同内容，否则会申领失败，申领失败原画币不予退还。
      </div>
      <!-- 表单 -->
      <form class="formInfo">
        <div class="item">
          <label>上传作品</label>
          <el-upload action="https://api.aboom.net/File/uploadFile" :show-file-list="true" :on-success="uploadSuccessTwo"
            :before-upload="beforeAvatarUpload" :before-remove="removeFail" :limit="1" :on-exceed="handleExceed"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF" :file-list="fileList">
            <div class="upload2">
              <i class="el-icon-picture"></i>
              <span>上传作品</span>
            </div>
          </el-upload>
          <div class="item_tips">
            <div>版权保护规则说明:</div>
            <div>图片(限制jpg、jpeg、png类型)(限制文件大小5M)</div>
            <div>系列图(限制pdf类型)(限制文件大小10M)(限制子图数量2-30)</div>
          </div>
        </div>
        <div class="item">
          <label>文件类型</label>
          <div class="item_radio">
            <el-radio v-model="fileType" label="IMAGE" @change="chanFT('IMAGE')" border>图片</el-radio>
            <el-radio v-model="fileType" label="PDF_IMAGE" @change="chanFT('PDF_IMAGE')" border>系列图</el-radio>
          </div>
        </div>
        <div class="item">
          <label>标&emsp;&emsp;&emsp;题</label>
          <input type="text" v-model="workInfo2.workName" placeholder="" @input="isCopyRightProtection = false" />
        </div>
        <div class="item">
          <label>作品封面</label>
          <div class="WorkCover">
            <img :src="work_url" alt="" srcset="">
          </div>
        </div>
        <div class="item">
          <label>作品创作概述</label>
          <!-- <input type="text" v-model="workInfo.content" placeholder="请简单说明您创作主题内容,请控制在30个字符以内"
						@input="isCopyRightProtection = false" /> -->
          <textarea name="" id="" cols="30" rows="10" v-model="workInfo2.content" @input="isCopyRightProtection = false"
            placeholder="请简单说明您创作主题内容,请控制在30个字符以内。"></textarea>
        </div>
        <div class="item">
          <label>创作开始时间</label>
          <el-date-picker v-model="workInfo2.start_time" placeholder="创作开始时间不能低于创作结束时间，否则会申领失败，申领失败原画币不予退还。" type="date"
            format="yyyy-MM-dd" value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
        <div class="item">
          <label>创作结束时间</label>
          <el-date-picker v-model="workInfo2.creationCompletionDate" placeholder="请选择作品结束创作的时间" type="date"
            format="yyyy-MM-dd" value-format="yyyy-MM-dd">
          </el-date-picker>
          <!-- </div> -->
        </div>

        <div class="item">
          <label>作品创作地点</label>
          <div class="address" style="display: flex; align-items: center">
            <div id="address" ref="address"></div>
          </div>
        </div>
        <div></div>
        <div class="item">
          <label>首次发表地点</label>
          <div class="address" style="display: flex; align-items: center">
            <div id="address2" ref="address"></div>

          </div>
        </div>

        <div class="item">
          <label>首次发表日期</label>
          <el-date-picker v-model="workInfo2.firstPublicationDate" placeholder="请选择作品首次发表的日期" type="date"
            format="yyyy-MM-dd" value-format="yyyy-MM-dd">
          </el-date-picker>
          <!-- </div> -->
        </div>
        <div class="item">
          <label>作者</label>
          <div class="common">
            <input v-model="workInfo2.authorName" type="text" placeholder="输入作者姓名" @input="search" name="" id=""
              readonly />
          </div>
        </div>
        <div class="item">
          <label>共同创作者</label>
          <div class="common">
            <div class="common_people" v-if="common_input_list.length">
              <div class="people_one" v-for="(item, index) in common_input_list" :key="index">
                {{ item.nickname }}
                <i v-if="false" @click="removeCommon(item)" class="el-icon-close"></i>
              </div>
            </div>
            <div>
              <input v-if="false" v-model="common_input" type="text" placeholder="输入关键字搜索共同创作人" @input="search" name=""
                id="" />
              <div ref="common" class="common_list" style="display: none">
                <div @click="select_common()" class="common_list_one">
                  {{ common_list.nickname }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="com_tips">请填写共同创造者的网站昵称，如没有共同创作者请填写自己昵称(当前DCI申领只支持一个著作权人)</div>
        <div class="item">
          <label>创&nbsp;作&nbsp;过&nbsp;程
          </label>
          <div class="item_upload">
            <!-- 上传内容图片 -->
            <div class="main_center_fodder_item2 flex1" v-if="imgUrlArr.length">
              <div class="item2_img" v-for="(img, index) in imgUrlArr" :key="index">
                <img :src="img" />
                <div class="item2_i ishow">
                  <i class="el-icon-close" @click="deletework(index, img)"></i>
                </div>
              </div>
              <div class="item2_add">
                <div class="item2_add_w"></div>
                <div class="item2_add_h"></div>
                <el-upload class="avatar-uploader" action="https://api.aboom.net/File/uploadFile"
                  :on-success="uploadSuccess" :show-file-list="false" :before-upload="beforeAvatarUpload"
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF">
                </el-upload>
              </div>
            </div>
            <span v-if="!imgUrlArr.length" style="margin-left: 10px; width: 100%; color: #808080">
              内容主要反映创作过程，包括但不限于：PS图层截图的草图、线稿、色块图层、特效图层、构思草图。文件大小不能大于3M，绘画邦图片数量不少于10张，漫画邦图片数量不少于3张，所有图片不超过20张。
            </span>
            <!-- <input v-if="!imgUrlArr.length" disabled="disabled" style="margin: 0;width: 100%;"
							placeholder="内容主要反映创作过程，包括但不限于：PS图层截图的草图、线稿、色块图层、特效图层、构思草图。文件大小不能大于3M，图片数量不少于10张，不超过10张。" /> -->
            <!-- 上传内容图片 -->
            <el-upload v-if="!imgUrlArr.length" class="upload-demo upload" action="https://api.aboom.net/File/uploadFile"
              :show-file-list="false" :on-success="uploadSuccess" :before-upload="beforeAvatarUpload"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF">
              <el-button size="small" type="primary">上传过程</el-button>
            </el-upload>
          </div>
        </div>

        <div class="workInfo">
          <label> 作&nbsp;品&nbsp;信&nbsp;息
          </label>
          <textarea name="" id="" cols="30" rows="10" v-model="workInfo2.work_info" maxlength="500"
            @input="isCopyRightProtection = false"
            placeholder="请详细描述您的创作开始时间、完成时间、创作及完成的城市等作品信息，便于作品在产生版权纠纷时提供创作证明。"></textarea>
        </div>
        <!-- <div class="item">
          <label>版权保护类型</label>
          <div class="版权保护类型">
            <el-radio-group v-model="版权保护类型">
        	    <el-radio  label="数字存证" border>登记数字存证（付费）</el-radio>
        	    <el-radio label="著作权证" border>申请代理登记著作权证（免费）</el-radio>
              </el-radio-group>
            <div>
              <div
                class="版权保护类型-选项 版权保护类型-数字存证"
                :class="[版权保护类型_选中 == 0 ? '选中' : '默认']"
                @click="选择版权保护类型(0)"
              >
                <p class="图标"></p>
                <p>登记数字存证</p>
                <p>(免费)</p>
              </div>
              <div class="提示">
                漫画邦作品需要不低于3话且不更名的同部作品，选择累计满3个作品投稿（包含单投稿内的作品内容）系统将自动提交申请。
              </div>
            </div>
            <div>
              <div
                class="版权保护类型-选项 版权保护类型-著作权证"
                :class="[版权保护类型_选中 == 1 ? '选中' : '默认']"
                @click="选择版权保护类型(1)"
              >
                <p class="图标"></p>
                <p>申请代理登记著作权证</p>
                <p>(付费)</p>
              </div>
              <div class="提示">
                仅限一张完成绘画作品使用，详细收费价格<a
                  target="_blank"
                  href="#helpcenter"
                  >点击此处»</a
                >
                。
              </div>
            </div>
          </div>
        </div> -->
      </form>
      <!-- 协议 -->
      <div class="agreement">
        <div class="scrollBar" ref="scrollBar">
          <h2 class="title">原画邦版权保护意向协议</h2>
          <div class="areementContent" v-html="html"></div>
        </div>
        <div class="fullView" @click="showBig">
          <span>全屏查看</span>
          <img src="../../../assets/img/copyright/quanping.png" alt="" />
        </div>
        <el-dialog title="原画邦版权保护意向协议" :visible.sync="centerDialogVisible" width="60%" center>
          <div class="areementContent" v-html="html"></div>
          <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
            <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
        <div class="item">
          <label>用户作品保护协议</label>
          <div class="item_radio">
            <el-radio v-model="radio3" label="3" @change="isSures(3)" border>同意</el-radio>
            <el-radio v-model="radio3" label="0" @change="isSures(0)" border>不同意</el-radio>
          </div>
        </div>
      </div>
      <div class="price">
        <div class="price_one">
          本次消费：<span>{{ price.price }}</span>原画币
        </div>
        <div class="price_two">
          （原{{ price.balance }}原画币，扣除后剩余{{ price.after_balance }}）
        </div>
      </div>
      <!-- 按钮 -->
      <div class="btnBox">
        <div :class="{ btn: isSure, btn2: !isSure }" @click="getCopyRightProtection">{{ istrue ? '保存' : '确认' }}</div>
        <div class="btn" @click="cancel">取消</div>
      </div>
    </div>

    <!-- 图片预加载 -->
    <div style="display: none">
      <img src="../../../assets/img/copyright/shucun_2.png" alt="" />
      <img src="../../../assets/img/copyright/cunzheng_2.png" alt="" />
    </div>
  </div>
</template>

<script>
import BuildCitySelect from "../../../common/area_format_js.level3.js";
import commonLeft1 from "../../../components/common_left1.vue";
export default {
  name: "copyrightProtection",
  components: {
    commonLeft1,
  },
  data() {
    return {
      common_input_list: [],
      common_input: "",
      common_list: "",
      works_upload_list: [],
      price: "", //价格
      版权保护类型_选中: 1,
      centerDialogVisible: false,
      istrue: false,
      work_id: "", //申请保护作品总id
      work_url: "", //申请作品封面图
      imgUrlArr: [], //全部图片地址
      imgurllist: [], //待传入组件的图片地址集合
      files: [], //json文件ID数组
      radio3: "-1",
      fileType: "",
      fileList: [],
      isSure: false, //是否同意协议
      isLook: false, //是否观看协议
      workInfo: {
        token: "",
        works_id: "", //作品id
        title: "", //作品标题
        content: "", //作品内容
        verify_files: "", //作品创作流程图片
        start_time: "", //创作作品开始的时间
        publish_time: "", //创作作品结束的时间
        detail: "", //发布到站内作品的内容
        work_info: "", //作品信息
      },
      workInfo2: {
        verify_files: '',
        start_time: "",
        detail: "", //发布到站内作品的内容
        work_info: "", //作品信息
        content: "", //作品内容
        workName: "", //作品名
        workFileName: "", //作品文件名
        workFileUrl: "", //作品文件url
        fileType: "", //文件类型
        workCategory: "ART", //ART:美术作品，支持文件类型IMAGE ARCHITECTURE:建筑作品，支持文件类型IMAGE PHOTOGRAPHY:摄影作品，支持文件类型IMAGE DESIGN:工程设计图、产品设计图，支持文件类型IMAGE DIAGRAM:示意图，支持文件类型IMAGE MODEL:模型，支持文件类型IMAGE
        firstPublicationCode: "", //首次发表地点地区编码
        firstPublicationDate: "", //首次发表日期
        creationNature: "ORIGINAL", //作品创作性质
        firstPublicationDate: "", //作品创作地点地区编码
        creationCompletionDate: "", //创作完成日期
        ownershipWay: "PERSON", //权利归属方式:个人或法人
        authorName: "", //作者姓名
        authorSignature: "", //作者署名
        preRegistrationTrueWill: "PASSWORD", //用户进行申领意愿表达的方式，PASSWORD 密码验证 CONFIRM_SMS 用户短信验证 LIVE_SMS 指纹或视频人脸验证 CERTIFICATE 电子签名验证
        copyrightOwnerIds: [], //著作权人用户id列表[ 著作权人用户id ]
        token: "",
        workId: '',
      },
      isCopyRightProtection: true, //判断是否填入信息
      html: `<div class="useForm">
    <p class="head">【总则】</p>
    <div class="textarea">
      &emsp;&emsp;用户在接受原画邦（Anime Boom.com） 版权保护服务之前，请务必仔细阅读本协议，用户同意协议后，都将被视作已无条件接受本协议所涉全部内容；若用户对本协议的任何条款有异议，请停止使用原画邦所提供的版权保护服务。
    </div>
    <div class="textarea">
<p class="head">第一条 作品著作权说明</p>
&emsp;&emsp;1.著作人身权
（1）发表权发表权：即决定作品是否公之于众的权利。发表权只能行使一次，除特殊情况外，仅能由作者行使。
（2）署名权：署名权，即表明作者身份，在作品上署名的权利。它包括作者决定是否署名，署真名、假名、笔名，禁止或允许他人署名等权利。
（3）修改权：修改权，即修改或者授权他人修改作品的权利。
（4）保护作品完整权：保护作品完整权，即保护作品不受歪曲、篡改的权利。
2.著作财产权
（1）复制权：即以印刷、复印、拓印、录音、录像、翻录、翻拍、数字化等方式将作品制作一份或者多份的权利；
（2）发行权：即以出售或者赠与方式向公众提供作品的原件或者复制件的权利；
（3）出租权：即有偿许可他人临时使用视听作品、计算机软件的原件或者复制件的权利，计算机软件不是出租的主要标的的除外；
（4）展览权：即公开陈列美术作品、摄影作品的原件或者复制件的权利；
（5）表演权：即公开表演作品，以及用各种手段公开播送作品的表演的权利；
（6）放映权：即通过放映机、幻灯机等技术设备公开再现美术、摄影、视听作品等的权利；
（7）广播权：即以有线或者无线方式公开传播或者转播作品，以及通过扩音器或者其他传送符号、声音、图像的类似工具向公众传播广播的作品的权利，但不包括本款第十二项规定的权利；
（8）信息网络传播权：即以有线或者无线方式向公众提供，使公众可以在其选定的时间和地点获得作品的权利；
（9）摄制权：即以摄制视听作品的方法将作品固定在载体上的权利；
（10）改编权：即改变作品，创作出具有独创性的新作品的权利；
（11）翻译权：即将作品从一种语言文字转换成另一种语言文字的权利；
（12）汇编权：即将作品或者作品的片段通过选择或者编排，汇集成新作品的权利；
（13）应当由著作权人享有的其他权利。
</div>
    <div class="textarea">
<p class="head">第二条 版权保护服务说明</p>
&emsp;&emsp;平台在服务中尊重每一个版权的完整性，用户勾选同意委托平台进行版权维护工作，服务一旦生效后，平台将针对侵犯已委托作品的著作权利益的行为进行司法维权工作，平台将出具已采集的用户信息、作品信息用于司法维权工作中去，此外平台不额外进行商业化运营管理工作；
针对版权保护服务，用户必须持有作品著作权证、作品数字创作证明，并按服务流程提交平台留档，作品著作权证与作品数字创作证明是具有法律效应的官方证明文件，如用户未持有上述证明文件，平台将视作审核不通过；
此项服务中作品著作权是指用户所拥有的全部17项权力。
</div>
    <div class="textarea">
<p class="head">第三条 托管服务流程</p>
&emsp;&emsp;用户自愿托管→勾选版权保护服务项→提交资料→平台审核→签订托管服务协议→开展版权保护服务→发生侵权→司法诉讼→收集证据→用户配合→提交证据→结案
为确保用户真实、自愿的意愿，用户需要签署纸质托管服务协议，签署完成后需通过扫描电子档发送至平台电子邮箱（animeboom@sakudora.com）、邮寄（上海市长宁区虹桥路996弄71号V19室）的形式提交给平台管理公司；
平台审核周期约为14个工作日，审核结果会以电子邮件（animeboom@sakudora.com）的形式发送至用户在平台登记的电子邮箱中。

    </div>
  </div>`,
    };
  },
  computed: {
    scrollBar() {
      return this.$refs.scrollBar;
    },
  },
  created() {
    this.workInfo2.workId = this.$route.query?.works_id;
    this.work_id = this.$route.query?.works_id;
    this.work_url = this.$route.query?.url;
    let istrue = Boolean(this.$route.query?.istrue);
    if (istrue) {
      this.istrue = istrue;
    } else {
      if (localStorage.getItem('saveInfoArr')) {
        let arr = JSON.parse(localStorage.getItem('saveInfoArr'));
        console.log('---', arr);
        let obj = arr.find(v => {
          return v.workId == this.work_id
        })
        if (obj != undefined) {
          this.workInfo2 = obj
          // 替换第一个上传
          let fileObj = {
            name: this.workInfo2.workFileName,
            url: this.workInfo2.workFileUrl
          }
          this.fileList.push(fileObj);
          // 替换第二个上传
          this.imgUrlArr = JSON.parse(this.workInfo2.verify_files);
          // 选择文件类型
          this.fileType = this.workInfo2.fileType;
        }
      }
    }
    if (this.work_id) {
      this.getWorkDetail();
      console.log(this.work_id);
    }
    // 版权保护价格
    this.$netWork
      .getDCIPrice({
        method: "post",
        url: "/order/getDCIPrice",
        data: {
          token: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.price = res.data;
      });
  },
  mounted() {
    new BuildCitySelect("#address", "", this.changeCall);
    new BuildCitySelect("#address2", "", this.changeCall2);
    if (!this.istrue) {
      // 如果有缓存 再把数组里最后一个value赋予
      if (localStorage.getItem('saveInfoArr')) {
        new BuildCitySelect("#address", this.workInfo2.czddArr.slice(-1)[0].value, this.changeCall);
        new BuildCitySelect("#address2", this.workInfo2.fbddArr.slice(-1)[0].value, this.changeCall2);
      }
    }
    this.$nextTick(() => {
      let scrollBar = this.scrollBar;
      let scrollTop, scrollHeight, offsetHeight;
      scrollBar.onscroll = () => {
        [scrollTop, scrollHeight, offsetHeight] = [
          scrollBar.scrollTop,
          scrollBar.scrollHeight,
          scrollBar.offsetHeight,
        ];
        this.isLook = true;
        if (scrollTop >= scrollHeight - offsetHeight) {
          this.isLook = true;
        }
      };
    });
    let userInfo1 = JSON.parse(localStorage.getItem('userInfo'))
    this.getUserSMname()
    let Newdata = {
      dci_uid: userInfo1.dci_uid,
      id: userInfo1.id,
      nickname: userInfo1.nickname
    }
    this.common_input_list.push(Newdata)
    this.workInfo2.copyrightOwnerIds.push(userInfo1.dci_uid);
  },
  methods: {
    handleExceed() {
      this.$message.warning('只可上传一张图片,如需更换请先删除已上传图片')
    },
    removeCommon(item) {
      this.common_input_list = this.common_input_list.filter(
        (i) => i.id != item.id
      );
      this.workInfo2.copyrightOwnerIds =
        this.workInfo2.copyrightOwnerIds.filter((i) => i != item.dci_uid);
    },
    select_common() {
      let bloon = true;
      for (let index = 0; index < this.common_input_list.length; index++) {
        if (this.common_list.id == this.common_input_list[index].id) {
          bloon = false;
          break;
        }
      }
      if (bloon) {
        this.workInfo2.copyrightOwnerIds.push(this.common_list.dci_uid);
        this.common_input_list.push(this.common_list);
      } else {
        this.$message('已添加此人')
      }
      this.common_input = "";
      this.$refs.common.style.display = "none";
    },
    // 搜索共同创作人
    search(item) {
      if (this.common_input) {
        this.$netWork
          .getsearchUser({
            url: "/user/searchUserAndCheck?keyword=" + this.common_input,
            method: "get",
          })
          .then((res) => {
            if (res.code == 0) {
              console.log(res.data);
              this.$refs.common.style.display = "block";
              this.common_list = res.data;
            } else {
              this.$message(res.msg);
              this.$refs.common.style.display = "none";
              this.common_list = "";
            }
          });
      } else {
        console.log(this.common_list);
        this.common_list = "";
        this.$refs.common.style.display = "none";
      }
    },
    // 文件上传
    // 上传成功
    uploadSuccessTwo(response, file) {
      this.$message(`已上传${file.name}`)
      console.log('---上传结果---', file.response);
      this.workInfo2.workFileName = this.workInfo2.workFileName + file.name;
      this.workInfo2.workFileUrl = file.response.data[0].file_path;
      //
    },
    // 删除文件
    removeFail(file, fileList) {
      this.$message(`已删除${file.name}`)
      this.fileList = fileList
      this.workInfo2.workFileName = ""
      this.workInfo2.workFileUrl = ""
    },
    // 上传失败
    uploadFailed(response, file, fileList) {
      this.$message.error("上传文件失败");
    },
    // 文件上传结束
    changeCall2(id, hasChild, cityData) {
      console.log("2:", id);
      this.workInfo2.creationCompletionCode = id;
    },
    changeCall(id, hasChild, cityData) {
      console.log("1:", id);
      this.workInfo2.firstPublicationCode = id;
    },
    选择版权保护类型(e) {
      this.版权保护类型_选中 = e;
    },
    isSures(value) {
      switch (value) {
        case 0:
          this.isSure = false;
          return false;
        case 3:
          this.isSure = true;
          return false;
      }
    },
    chanFT(value) {
      this.fileType = value;
    },
    // 图片上传前的判断3M
    beforeAvatarUpload(file) {
      const isLt1M = file.size / 1024 / 1024;
      if (
        file.type.includes("jpg") ||
        file.type.includes("jpeg") ||
        file.type.includes("png")
      ) {
        this.workInfo2.fileType = "IMAGE";
        if (isLt1M > 5) {
          this.$message.error("上传图片大小不能超过5MB");
          return false;
        }
      } else if (file.type.includes("pdf")) {
        this.workInfo2.fileType = "PDF_IMAGE";
        if (isLt1M > 10) {
          this.$message.error("上传PDF文件大小不能超过10MB");
          return false;
        }
      } else if (file.type.includes("mp3") || file.type.includes("wav")) {
        this.workInfo2.fileType = " AUDIO";

        if (isLt1M > 50) {
          this.$message.error("上传音频文件大小不能超过50MB");
          return false;
        }
      } else if (
        file.type.includes("mp4") ||
        file.type.includes("avi") ||
        file.type.includes("wmv")
      ) {
        this.workInfo2.fileType = "VIDEO";
        if (isLt1M > 200) {
          this.$message.error("上传视频文件大小不能超过50MB");
          return false;
        }
      }
      return isLt1M;
    },
    // 作品过程上传成功
    uploadSuccess(response, file) {
      this.imgurllist.push(URL.createObjectURL(file.raw));
      this.imgUrlArr.push(response.data[0].file_path);
      this.files.push(response.data[0].file_id);
      this.workInfo2.verify_files = JSON.stringify(this.imgUrlArr)
    },
    // 删除作品过程图片id,删除作品
    deletework(index, item) {
      this.files.splice(index, 1);
      this.imgUrlArr.splice(this.imgUrlArr.indexOf(item), 1);
      // console.log(this.files);
      // console.log(this.imgUrlArr);
    },
    //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    async getWorkDetail() {
      let res = await this.$netWork.getWorkDetail({
        url: "/works/getDetail",
        method: "post",
        data: {
          work_id: this.work_id,
          page: "1",
          uid: "",
        },
      });
      console.log('--获取作品详情--', res);
      this.workInfo2.workName = res.title;
      //   this.workInfo.content = res.detail;
      this.workInfo2.content = res.detail;
      //   this.workInfo.publish_time = res.publish_time;
      // this.workInfo2.creationCompletionDate = res.publish_time.split(" ")[0];
      // this.workInfo2.start_time = res.start_time;
      //   this.workInfo.start_time = res.start_time;
      //   this.workInfo.works_type = res.works_type;
    },
    showBig() {
      // this.$alert(this.html, '原画邦版权保护意向协议', {
      // 	center: true,
      // 	dangerouslyUseHTMLString: true
      // });
      this.centerDialogVisible = true;
      this.isLook = true;
      // console.log(4444);
      // this.$router.push({path:"/userAgreement"})
    },
    // 申请版权保护
    async getCopyRightProtection() {
      switch (true) {
        case !this.workInfo2.workName:
          this.$message("作品标题不能为空");
          return false;
        case !this.workInfo2.content:
          this.$message("作品内容不能为空");
          return false;
        case !this.workInfo2.creationCompletionDate:
          this.$message("发布到站内作品开始时间不能为空");
          return false;
        case !this.workInfo2.start_time:
          this.$message("完成作品结束时间不能为空");
          return false;
        case !this.workInfo2.work_info:
          this.$message("作品信息不能为空");
          return false;
        case this.版权保护类型_选中 < 0:
          this.$message("请选择版权保护类型");
          return false;
        case !this.workInfo2.workFileUrl:
          this.$message('请上传作品')
          return
        case !this.workInfo2.firstPublicationCode:
          console.log('首次发表地点', this.workInfo2.firstPublicationCode)
          this.$message('请选择创作地点')
          return
        case !this.workInfo2.firstPublicationDate:
          this.$message('请选择首次发表日期')
          return
        case !this.workInfo2.firstPublicationDate:
          this.$message('请选择首次发表地点')
          return
        case !this.workInfo2.creationCompletionDate:
          this.$message('请选择创作完成日期')
          return
        case !this.workInfo2.authorName:
          this.$message('请输入作者姓名')
          return
        case !this.workInfo2.copyrightOwnerIds.length:
          this.$message('请输入共同创作人')
          return
        case !this.workInfo2.verify_files:
          this.$message('创作过程不能为空')
          return
      }
      if (this.fileType == '') {
        this.$message("请选择文件类型");
        return
      }
      if (!this.isLook) {
        this.$message({
          showClose: true,
          message: "请阅读完用户协议再确定保护！",
          type: "warning",
          offset: 300,
        });
        return false;
      }
      this.workInfo2.token = localStorage.getItem("token");
      this.workInfo2.fileType = this.fileType;
      // let { dci_uid } = JSON.parse(localStorage.getItem('userInfo'))
      // if(!this.workInfo2.copyrightOwnerIds.includes(dci_uid)){
      //   this.workInfo2.copyrightOwnerIds.unshift(dci_uid)
      // }
      this.workInfo2.copyrightOwnerIds = Array.from(new Set(this.workInfo2.copyrightOwnerIds))
      if (this.istrue) {
        // czdd 作品创作地点 fbdd 首次发表地点
        let czdd = document.querySelector('#address')
        let fbdd = document.querySelector('#address2')
        let czddArr = []
        czdd.querySelectorAll('select').forEach(v => {
          czddArr.push({ value: v.value, text: v.options[v.selectedIndex].text })
        })
        let fbddArr = []
        fbdd.querySelectorAll('select').forEach(v => {
          fbddArr.push({ value: v.value, text: v.options[v.selectedIndex].text })
        })
        this.workInfo2.czddArr = czddArr
        this.workInfo2.fbddArr = fbddArr
        if (localStorage.getItem('saveInfoArr')) {
          let arr = JSON.parse(localStorage.getItem('saveInfoArr'));
          arr.push(this.workInfo2)
          localStorage.setItem('saveInfoArr', JSON.stringify(arr))
        } else {
          let arr = [];
          arr.push(this.workInfo2)
          localStorage.setItem('saveInfoArr', JSON.stringify(arr))
        }
        this.$message.success('版权保护申请资料已保存，请等待作品审核之后重新提交')
        setTimeout(() => {
          this.$router.push({
            path: "/userProfile",
          });
        }, 100);
        return
      } else {
        if ("czddArr" in this.workInfo2) {
          delete this.workInfo2.czddArr
          delete this.workInfo2.fbddArr
        }
      }
      let res = await this.$netWork.getCopyRightProtection({
        url: "/protection/protectionDCI",
        method: "post",
        data: this.workInfo2,
      });
      if (res.code == 0) {
        // 提交成功后 判断  删除掉对应id的数据
        if (localStorage.getItem('saveInfoArr')) {
          let arr = JSON.parse(localStorage.getItem('saveInfoArr'));
          arr = arr.filter(v => {
            return v.workId != this.work_id
          })
          // 筛选后如果为0 直接去除
          if (arr.length == 0) {
            localStorage.removeItem('saveInfoArr')
          } else {
            localStorage.setItem('saveInfoArr', JSON.stringify(arr))
          }
        }
        this.$message.success(res.msg);
        this.imgUrlArr = [];
        this.files = [];
        this.workInfo = {
          title: "",
          content: "",
          publish_time: "",
          start_time: "",
          verify_files: "",
          detail: "",
          work_info: "",
        };
        this.fileType = "";
        // 发布后直接跳转到该页面的判断
        if (this.istrue) {
          this.$router.push({
            path: "/userProfile",
          });
        } else {
          this.$router.push({
            path: "/protectionWork",
          });
        }
      }
      else if (res.code == 3) {
        this.$message.warning(res.msg);
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      }
      else if (res.code == 1) {
        this.$message.warning("申请失败,此作品已申请保护，不需要重复申请");
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      }
      else if (res.code == 201 || res.code == 200) {
        this.$message.warning("用户登录过期");
        this.$router.push("/");
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
      }
      else {
        this.$message.warning("申请失败");
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      }
    },
    // 取消申请版权保护
    cancel() {
      if (this.istrue) {
        this.$router.push({
          path: "/userProfile",
        });
      } else {
        this.$router.push("/protectionExplain");
      }
      if (this.isCopyRightProtection) {
        return false;
      }
      this.isCopyRightProtection = true;
      this.$message("取消申请版权保护");
      this.imgUrlArr = [];
      this.files = [];
      this.isSure = false; //是否同意协议
      this.isLook = false; //是否看完协议
      this.radio3 = "";
      this.workInfo = {
        title: "",
        content: "",
        publish_time: "",
        start_time: "",
        verify_files: "",
        detail: "",
        work_info: "",
      };
    },
    // 获取实名信息
    async getUserSMname() {
      let token = localStorage.getItem("token")
      let res = await this.$netWork.getIdentificationData({
        url: "Identification/getIdentificationData",
        method: "post",
        data: {
          token: token
        }
      })
      this.workInfo2.authorName = res.realname
    }
  },
};
</script>

<style>
.el-radio.is-bordered.is-checked {
  border-color: #5accd8;
}

.el-radio__input.is-checked+.el-radio__label {
  color: #5accd8 !important;
}

.el-radio__input.is-checked .el-radio__inner {
  background: #5accd8 !important;
  border-color: #5accd8 !important;
}

.el-radio__label {
  color: #fff;
}
</style>
<style lang="less" scoped>
.版权保护类型 {
  font-size: 11px;
  display: flex;

  &>div {
    position: relative;
    width: 200px;
    margin-right: 20px;

    .提示 {
      z-index: 1;
      background-color: #555;
      position: absolute;
      top: 0;
      box-sizing: border-box;
      padding: 15px 9px 5px;
      font-size: 13px;
      text-align: left;
      left: 0;
      width: 202px;
      border-radius: 0 0 8px 8px;
      transition: top 0.5s;
      color: #fff;

      a {
        color: aqua;
        text-decoration: underline;
      }
    }
  }

  &>div:hover {
    .提示 {
      top: 90px;
    }
  }
}

.版权保护类型-选项 {
  background-color: #35363f;
  position: relative;
  z-index: 2;
  height: 68px;
  padding: 14px 0;
  border-radius: 8px;
  width: 200px;
  text-align: center;
  border: 1px solid #fff;
  color: #fff;
  line-height: 1em;

  &:hover {
    cursor: pointer;
  }

  &.选中 {
    &:hover {
      cursor: default;
    }

    border: 1px solid #5accd8;
    color: #5accd8;
  }

  &>p {
    margin-bottom: 4px;
  }

  .图标 {
    height: 36px;
    width: 100%;
    background: url("") no-repeat center / contain;
    margin-bottom: 10px;
  }
}

.版权保护类型-数字存证 .图标 {
  background-image: url(../../../assets/img/copyright/shucun_1.png);
}

.版权保护类型-数字存证.选中 .图标 {
  background-image: url(../../../assets/img/copyright/shucun_2.png);
}

.版权保护类型-著作权证 .图标 {
  background-image: url(../../../assets/img/copyright/cunzheng_1.png);
}

.版权保护类型-著作权证.选中 .图标 {
  background-image: url(../../../assets/img/copyright/cunzheng_2.png);
}

.br {
  border-radius: 7px;
}

.color {
  color: #4eb8c2;
}

.bgColor {
  background-color: rgb(38, 40, 44);
}

.flex1 {
  flex: 1;
}

.ishow {
  display: none;
}

.item2_img:hover {
  .ishow {
    display: block;
  }
}

.main_center_fodder_item2 {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;

  .main_border_right1 {
    // padding-right: 1rem;
    border-right: 1px solid rgb(67, 80, 87);
  }

  .main_border_right2 {
    // margin-right: 1rem;
    border-right: 1px solid rgb(67, 80, 87);
  }

  // padding: 14.77px;
  // padding-bottom: 20px;

  .item2_img,
  .item2_add {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    // margin:14.77px;
    margin: 0.5rem;
    background-color: #222222;
    .br();

    .item2_i {
      width: 100%;
      height: 100%;
      position: absolute;

      .item2_div {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
      }

      .z-index {
        position: relative;
        z-index: 50;
      }
    }

    img {
      max-width: 150px;
      max-height: 150px;
      border-radius: 7px;
    }

    i {
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      text-align: center;
      line-height: 1rem;
      background-color: #4eb8c2;
      color: #000000;
      top: -0.4rem;
      right: -0.4rem;
      cursor: pointer;
      z-index: 50;
    }
  }

  .item2_add {
    position: relative;
    cursor: pointer;

    .item2_add_w {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 5px;
      border-radius: 5px;
      background-color: #4eb8c2;
    }

    .item2_add_h {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 50px;
      border-radius: 5px;
      background-color: #4eb8c2;
    }
  }
}

.useForm {
  width: 800px;
  height: 550px;
  background: white;
  margin: 30px auto;
  position: relative;
  border-radius: 10px;
}

.head {
  font-size: 14px;
  color: black;
  margin-left: 30px;
  margin-top: 15px;
}

// .title {
//   font-size: 25px;
//   font-weight: 700;
//   padding-top: 20px;
//   color: #4eb8c2;
//   text-align: center;
// }
.textarea {
  margin-left: 30px;
  font-size: 14px;
  color: black;
  margin-right: 30px;
  margin-top: 15px;
}

/deep/.el-input--prefix .el-input__inner {
  width: 755px;
  height: 40px;
  margin-left: 15px;
  padding-left: 10px;
  color: #ffffff;
  background: #28262e;
  border-radius: 7px;
  border: none;
}

/deep/.el-input__icon {
  height: 100%;
  width: 0px;
  text-align: center;
  transition: all 0.3s;
  line-height: 40px;
  display: none;
}

/deep/ .el-button--small,
.el-button--small.is-round {
  padding: 7px 10px;
}

/deep/ .el-button--primary {
  background-color: #4eb8c2;
  border-color: #4eb8c2;
  color: #000;
  font-size: 16px;
}

/deep/.avatar-uploader {
  width: 150px;
  height: 150px;
  display: flex;
}

/deep/.avatar-uploader .el-upload {
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding: 30px;
}

* {
  margin: 0;
  // padding: 0;
}

.scrollBar {
  overflow-y: auto;
}

/*滚动条整体样式*/
.scrollBar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: @bgColor;
}

/*滚动条滑块*/
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: @bgColor;
  border: 2px solid @iptfsColor;
}

/*滚动条轨道*/
.scrollBar::-webkit-scrollbar-track {
  border-radius: 5px;
  background: @iptfsColor;
}

@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btn2Color: #c2c2c2;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;

  .centerContent {
    flex:1;
    margin-left: 20px;
    // height: 736px;
    border-radius: 7px;
    background: @bg1Color;

    .title {
      height: 38px;
      margin: 8px 8px 0px 8px;
      padding-left: 5px;
      line-height: 38px;
      color: @fsColor;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: @bgColor;
    }

    // 表单
    .formInfo {
      display: flex;
      flex-direction: column;
      margin: 40px 55px 0 55px;

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        color: @fsColor;

        .版权保护类型 {
          width: 100%;
          margin-left: 28px;

          label {
            width: auto;
            margin-right: 10px;
            // &.el-radio.is-bordered.is-checked{
            //   border-color: #5accd8;
            //   .el-radio__label{
            //     color: #5accd8 !important;
            //   }
            // }
          }
        }

        textarea {
          width: 100%;
          height: 90px;
          margin-left: 30px;
          padding: 10px;
          color: @iptfsColor;
          background: @bgColor;
          border-radius: 7px;
          resize: none;
          border: none;
        }

        label {
          width: 120px;
          text-align: center;

          p {
            // display: inline-block;
            color: #9f9f9f;
            font-size: 0.8rem;
          }
        }

        input {
          width: 100%;
          height: 40px;
          margin-left: 28px;
          padding-left: 10px;
          color: @iptfsColor;
          background: @bgColor;
          border-radius: 7px;
          border: none;
        }

        .item_img {
          width: 100%;
          margin-left: 28px;

          img {
            width: 180px;
            height: 180px;
            border-radius: 7px;
            -o-object-fit: cover;
            object-fit: cover;
          }
        }

        .item_upload {
          width: 100%;
          min-height: 150px;
          margin-left: 28px;
          background: @bgColor;
          border-radius: 7px;
          border: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        }

        .address,
        .address2,
        .common {
          background: #28262d;
          width: 100%;
          height: 43px;
          border-radius: 8px;
          margin-left: 30px;
          padding-left: 10px;
        }
      }

      .common input {
        margin: 0px;
        padding-left: 0px;
      }

      .workInfo {
        display: flex;
        justify-content: flex-start;
        color: @fsColor;
        margin-bottom: 20px;

        label {
          p {
            // display: inline-block;
            font-size: 0.8rem;
            color: #9f9f9f;
          }

          width: 120px;
          text-align: center;
        }

        textarea {
          width: 100%;
          height: 90px;
          margin-left: 30px;
          padding: 10px;
          color: @iptfsColor;
          background: @bgColor;
          border-radius: 7px;
          resize: none;
          border: none;
        }
      }
    }

    // 协议
    .agreement {
      height: 200px;
      margin: 30px 55px 0 55px;
      background: @bgColor;
      border-radius: 7px;

      /deep/.el-dialog__wrapper {
        .el-dialog {
          .el-dialog__body {
            .areementContent {
              .useForm {
                // width: 800px;
                // height: 550px;
                background: white;
                margin: 30px auto;
                position: relative;
                border-radius: 10px;

                // text-align: center;
                p {
                  padding: 5px 30px;
                }

                .textarea {
                  padding: 10px;
                  margin-left: 30px;
                  font-size: 14px;
                  margin-right: 30px;
                  margin-top: 15px;

                  p {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        color: @fsColor;

        label {
          width: 200px;
          text-align: center;
        }

        .item_radio {
          width: 100%;
          margin-left: 30px;

          label {
            width: 100px;
            text-align: center;
          }
        }
      }

      .scrollBar {
        height: 200px;

        .title {
          color: @iptfsColor;
          font-weight: lighter;
          text-align: center;
        }

        .areementContent {
          margin: 0 20px;
          color: #fff;
        }
      }

      .fullView {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 6px;
        cursor: pointer;

        span {
          color: @fsColor;
          padding-right: 10px;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    // 按钮
    .btnBox {
      margin-top: 0px;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      color: @btnfsColor;

      :last-child {
        margin-left: 50px;
      }

      .btn {
        width: 116px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        background: @btnColor;
        border: none;
        pointer-events: auto;
        border-radius: 7px;
      }

      .btn2 {
        width: 116px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        background: @btn2Color;
        border: none;
        border-radius: 7px;
        pointer-events: none;
        cursor: default;
      }
    }
  }
}

.price {
  display: flex;
  justify-content: start;
  align-items: end;
  margin: 80px 66px 40px 66px;

  &_one {
    color: #5acbd8;
    font-size: 16px;

    span {
      font-size: 50px;
      margin: 0 8px;
    }
  }

  &_two {
    color: #999999;
    font-size: 16px;
    margin: 10px;
  }
}

.upload2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  background: #353641;
  box-sizing: border-box;

  i {
    font-size: 60px;
  }
}

.common {
  position: relative;
  display: flex;

  .common_list {
    position: absolute;
    width: 100%;
    height: 40px;
    background: #28262e;
    top: 45px;
    left: 0;
    border-radius: 5px;
    padding: 5px;

    &_one {
      display: flex;
      align-items: center;
      background: #35363f;
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      padding: 5px;
      border-radius: 5px;
    }

    &_one:hover {
      background: rgba(112, 115, 124, 0.5);
    }
  }

  .common_people {
    margin-right: 10px;
    max-width: 300px;
    overflow-x: auto;
    padding: 5px;
    display: flex;

    .people_one {
      flex-shrink: 0;
      margin-left: 5px;
      padding: 5px;
      background: #70737c;
      border-radius: 5px;
      font-size: 14px;
    }
  }
}

.common_people::-webkit-scrollbar {
  height: 5px;
}

.common_people::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.common_people::-webkit-scrollbar-thumb {
  background: #70737c;
}

.common_people::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.WorkCover {
  width: 90px;
  height: 90px;
  margin-left: 10px;

  img {
    width: 100%;
    height: 100%;
  }
}

.tit_2 {
  color: #5dbbbf;
  margin-top: 14px;
  padding: 0 13px;
}

.item_tips {
  flex: 1;
  margin-left: 20px;
}

.com_tips {
  color: #5dbbbf;
  font-size: 14px;
  padding-left: 140px;
  margin-bottom: 20px;
}
</style>
