<template>
  <!-- <el-autocomplete popper-class="my-autocomplete" v-model="state" size="mini" :fetch-suggestions="querySearch"
    @select="handleSelect" @keydown.enter.native="changeRoute_2">
    <i class="el-input__icon el-icon-search" slot="suffix" @click="handleIconClick">
    </i>
    <template slot-scope="{ item }">
      <div class="name">{{ item.value }}</div>
    </template>
  </el-autocomplete> -->
  <div>
    <!-- <div v-for="item in list">
      <img width="200px" height="200px" :src="item.fdata1[0].fdata1[0].file_path" alt="">
      <img width="200px" height="200px" :src="item.fdata1[0].fdata1[1].file_path" alt="">
      <div>
        <img width="200px" height="200px" :src="item.fdata1[1].fdata2[0].file_path" alt="">
        <img width="200px" height="200px" :src="item.fdata1[1].fdata2[1].file_path" alt="">
      </div>
    </div> -->

    <div class="dianchi">
      <span class="iconfont icon-changfangxingfangxingzhuozi"></span>
      <span class="iconfont icon-changfangxingfangxingzhuozi"></span>
      <span class="iconfont icon-changfangxingfangxingzhuozi"></span>
      <span class="iconfont icon-changfangxingfangxingzhuozi"></span>
      <span class="iconfont icon-changfangxingfangxingzhuozi"></span>
      <span class="iconfont icon-changfangxingfangxingzhuozi"></span>
      <span class="iconfont icon-changfangxingfangxingzhuozi"></span>

    </div>


    <div style="width: 500px;height: 300px;background-color: #35353f;">
      <div class="pf" ref="pf" @mouseleave="hide">
        <div v-for="(item, index) in 10" id="dl" :class="a(index)" @mouseenter="show(index)" @mouseleave="hide(index)" @click="pf(index)">
        </div>
      </div>
    </div>


  </div>

</template>

<script>
export default {
  data() {
    return {
      bgcMap: {
        0: "#d3f1f5",
        1: "#b0dde3",
        2: "#7acdda",
        3: "#5cccdc",
        4: "#26c5dc",
        5: "#03bbd6",
        6: "#02abc1",
        7: "#0098a6",
        8: "#017080",
        9: "#015d67"
      },
      ispf:false,
      // restaurants: [],
      // search: ''
      list: [
        {
          "fdata1": [
            {
              "fdata1": [
                { "file_path": "http://api.aboom.net/storage/image/png/20220507/e2cd86fa458e1ff740184f2d1c9fbc65.png" },
                { "file_path": "http://api.aboom.net/storage/image/png/20220429/2f7e3517a77cf9c071262af96ec2a5f4.png" },
              ]
            },
            {
              "fdata2": [
                { "file_path": "http://api.aboom.net/storage/image/png/20220507/e2cd86fa458e1ff740184f2d1c9fbc65.png" },
                { "file_path": "http://api.aboom.net/storage/image/png/20220429/2f7e3517a77cf9c071262af96ec2a5f4.png" },
              ],
            }
          ],
          "fdata2": [
            {
              "fdata2": [
                { "file_path": "http://api.aboom.net/storage/image/png/20220507/e2cd86fa458e1ff740184f2d1c9fbc65.png" },
                { "file_path": "http://api.aboom.net/storage/image/png/20220429/2f7e3517a77cf9c071262af96ec2a5f4.png" },
              ]
            }
          ]
        },
      ]
    };
  },
  computed: {
    // a(index){
    //   var b = {
    //     0:"#d3f1f5",
    //     1:"#b0dde3",
    //     2:"#7acdda",
    //     3:"#5cccdc",
    //     4:"#26c5dc",
    //     5:"#03bbd6",
    //     6:"#02abc1",
    //     7:"#0098a6",
    //     8:"#017080",
    //     9:"#015d67"
    //   }
    //   return {
    //     [b[index]]:true
    //   } 
    // }
  },
  methods: {
    a(index) {
      return {
        [this.bgcMap[index]]: true
      }
    },
    pf(idx){
      this.ispf = true
    },
    show(idx) {
      if(this.ispf){
        return
      }
      for (var i = 0; i <= idx; i++) {
        this.$refs.pf.children[i].style.backgroundColor = this.bgcMap[i]
      }
    },
    hide(idx) {
      if(this.ispf){
        return
      }
      console.log(idx,"6666",typeof idx);
      if(typeof idx=="object"){
        for (var i = 0; i < 10; i++) {
          if(this.$refs.pf.children[i]?.style){
            this.$refs.pf.children[i].style.backgroundColor = ""
          }
        }
        return
      }
      if (idx) {
        if(this.$refs.pf.children[idx]?.style){
          this.$refs.pf.children[idx].style.backgroundColor = ""

        }
      } else {
        console.log(12321);
        for (var i = 0; i < 10; i++) {
          if(this.$refs.pf.children[i]?.style){
            this.$refs.pf.children[i].style.backgroundColor = ""

          }
        }
      }

    }
    // changeRoute_2() {
    //   console.log("666")
    // },
    // querySearch(queryString, cb) {
    //   var restaurants = localStorage.getItem("selectMsg") ? JSON.parse(localStorage.getItem("selectMsg")) : []
    //   var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
    //   // 调用 callback 返回建议列表的数据
    //   cb(results);
    // },
    // createFilter(queryString) {
    //   return (restaurant) => {
    //     return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    //   };
    // },
    // handleSelect(item) {
    //   console.log(item);
    // },
    // handleIconClick(ev) {
    //   console.log(ev, this.search);
    //   var selectMsg = JSON.parse(localStorage.getItem("selectMsg"))
    //   if (selectMsg) {
    //     selectMsg.push({ "value": this.search })
    //   } else {
    //     selectMsg = []
    //     selectMsg.push({ "value": this.search })
    //   }
    //   localStorage.setItem("selectMsg", JSON.stringify(selectMsg))
    //   let routePath = this.routeMap;
    //   this.$router.push({
    //     path: routePath.搜索,
    //     query: { search: this.search }
    //   });
    // }
  },
  mounted() {
    console.log(this.$refs.pf.children);

  }
}
</script>
<style lang="less">
@import "../../assets/iconfont/iconfont.css";

.pf {
  width: 300px;
  height: 44px;
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  margin: 20px auto;
  border: 2px solid #5ce2ed;
  border-radius: 5px;
}
.pf::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 20px;
  border-radius: 2px;
  background-color: #5ce2ed;
  right: -10px;
}
#dl {
  box-sizing: border-box;
  width: 28px;
  height: 40px;
}
</style>
