import { render, staticRenderFns } from "./common_left.vue?vue&type=template&id=4a8419a2&scoped=true&"
import script from "./common_left.vue?vue&type=script&lang=js&"
export * from "./common_left.vue?vue&type=script&lang=js&"
import style0 from "./common_left.vue?vue&type=style&index=0&id=4a8419a2&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a8419a2",
  null
  
)

export default component.exports