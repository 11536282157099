<template>
  <div class="content_middle">
    <common-left1 v-if="bool"></common-left1>
    <common-left2 v-else :userid="userid"></common-left2>
    <!-- 右边 -->
    <div class="content_middleR">
      <div class="frame">
        <div class="BiaoTi">收集目录</div>
        <div class="collect_content">
          <div class="collect_message" v-for="(item, index) in collecWorks" :key="index" @click="goWorkDetail(item)">
            <img :src="item.fData[0].file_path" class="collect_img" alt="" />
            <p class="collect_p">{{ item.title }}</p>
            <p class="collect_p">作者：{{ item.nickname }}</p>
          </div>
        </div>
        <ul v-if="totalPage > 1" class="pages bottomPages">
          <li class="prev" v-if="totalPage > 1" @click="prev"></li>
          <li v-for="item in totalPage" :key="item" :class="{ active: item == currPage }" @click="refash(item)">
            {{ item }}
          </li>
          <li class="next" @click="next" v-if="totalPage > 1"></li>
        </ul>
      </div>
    </div>
    <!-- 分页 -->
  </div>
</template>

<script>
import commonLeft1 from "../../components/common_left1.vue";
import commonLeft2 from "../../components/components_left2.vue";
export default {
  name: "collect",
  components: { commonLeft1, commonLeft2 },
  data() {
    return {
      collecWorks: [],
      totalPage: "",
      currPage: "1",
      bool: false, //
      userInfo: {},//
      userid: "",
    };
  },
  mounted() {
    this.userid = this.$route.query.uid;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (this.userid == this.userInfo.id) {
      this.bool = true;
    }
    this.getUserCollectWorks();
  },
  methods: {
    refash(item) {
      this.currPage = item
      this.getSearchInfo()
    },
    //上一页
    prev() {
      if (this.currPage == 1) {
        this.$message.error("已经是第一页了")
        return false
      }
      this.currPage--
      this.getUserCollectWorks()
    },
    //下一页
    next() {
      if (this.currPage >= this.totalPage) {
        this.$message.error("已经是最后一页了")
        return false
      }
      this.currPage++
      this.getUserCollectWorks()
    },
    // 获取用户收藏作品
    async getUserCollectWorks() {
      let uid = this.$route.query.uid
      console.log(uid);
      let res = await this.$netWork.getUserCollectWorks({
        url: "/scoring/getCollection",
        method: "post",
        data: {
          // token: localStorage.getItem("token"),
          uid: uid,
        },
      });
      // this.totalPage = res.data.totalPage
      console.log("收藏",res.data);
      if (res.code === 0) {
        this.collecWorks = res.data;
      }
    },
    //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    // 去作品详情
    goWorkDetail(data) {
      let url = this.$router.resolve({
        path: "/workDetail1",
        query: { work_id: data.id, uid: data.uid },
      });
      window.open(url.href, '_blank')
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.pages {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  color: #5accd8;
}

.pages li {
  margin-right: 16px;
  cursor: pointer;
  list-style: none;
}

.pages li:hover {
  color: #ffffff;
}

.pages .active {
  color: #ffffff;
}

.pages .next {
  width: 48px;
  height: 25px;
  background: #5accd8;
  background-image: url("../../assets/img/home/fanye3.png");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  border-radius: 3px;
}

.bottomPages {
  margin: 0;
  margin-top: 100px;
}

.content_middle {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
}

/* 右边 */
.content_middleR {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex: 1;
}

/* 标题 */
.frame {
  /* flex: 1; */
  background-color: #353641;
  border-radius: 0.4rem;
  padding: 8px 8px 2rem 8px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #4b4b56;
  margin-bottom: 14.77px;
  min-height: 764px;
}

.BiaoTi {
  color: #4db9c2;
  background-color: #26272e;
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.4rem;
}

/* 收藏内容 */
.collect_content {
  /* float: left; */
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 16px;
}

.collect_message {
  /* float: left; */
  text-align: center;
  margin: 10px 18px;
  cursor: pointer;
}

/* 图片 */
.collect_img {
  width: 280px;
  height: 202px;
  border-radius: 7px;
  object-fit: cover;
  // width: 130px;
  // height: 120px;
  // border-radius: 3px;
  // padding-left: 20px;
}

/* 文字 */
.collect_p {
  color: #4db9c2;
  font-size: 14px;
}
</style>