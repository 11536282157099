<template>
  <div class="wrapper clearfix">
    <!-- 侧边栏 -->
    <!-- 内容 -->
    <div class="centerContent">
      <!-- 作品目录 -->
      <div class="nav1">
        <div class="head">
          <div class="title">作品详情</div>
        </div>
        <!-- 介绍 -->
        <div class="introduce">
          <div class="one">
            <div class="time">2015年12月25日&emsp;20:05</div>
            <ul>
              <li>浏览数：<span class="num">361</span></li>
              <li>评分次数：<span class="num">233</span></li>
              <li>综合分：<span class="num">1204</span></li>
            </ul>
          </div>
          <div class="two">
            <h2 class="title">作品</h2>
            <el-rate v-model="score" :max="10" @change="getScore"></el-rate>
          </div>
          <div class="explain">
            <p>
              这里是作品描述，这张图是来自P站一个很出名的画师画的，是初音的同人，登上过P站排行的第一位，非常大人气的一张同人画。后来根据此图还出了手办。谢谢观赏。
            </p>
            <p>微博地址：www.baidu.com</p>
          </div>
        </div>
        <div class="tag">
          <ul>
            <li class="item">初音</li>
            <li class="item">同人</li>
            <li class="item">公主</li>
            <li class="add">
              <img src="../../assets/imgs/tianjia.png" alt="" />
            </li>
          </ul>
          <div class="collect">收藏</div>
          <div class="workImg">
            <img :src="src" alt="" />
          </div>
          <ul class="ulimg">
            <li
              v-for="(item, idx) in $route.params.data.fData"
              :class="{ active: active == idx }"
              @click="isActive(idx), changUrl(item.file_path)"
              :key="idx"
            >
              <img
                width="80px"
                height="80px"
                :src="item.file_path"
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
      <!-- 作品留言板 -->
      <div class="nav2">
        <div class="head">
          <div class="title">作品留言板</div>
        </div>
        <div class="message scrollBar">
          <div class="item">
            <div class="right">
              <img class="avatar" src="../../assets/imgs/yonghu1.png" alt="" />
              <div class="info">
                <p class="name">吴彦祖<text class="time">5小时前</text></p>
                <p class="text">大大我来啦，留个瓜先</p>
              </div>
            </div>
            <div class="reply">回复</div>
          </div>
        </div>
        <form class="formInfo">
          <input class="inputInfo" type="text" />
          <input class="send" type="submit" value="发送" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getUrl } from "../../common/util";
export default {
  name: "workDetail",
  data() {
    return {
	  score:null,//分数
      src: "",
      id: "",
      nickname: "",
      active: "",
      commentList: "",
    };
  },
  methods: {
	  //评分
	  getScore(){
		  if(!localStorage.getItem("token")){
			  this.$message.warning('需要登陆后才可以评分哦~')
			  return false
		  }else{
			  this.$confirm(`您对该作品的评分为${this.score}`, '提示', {
			            confirmButtonText: '确定',
			            cancelButtonText: '取消',
			            type: 'success'
			          }).then(() => {
			            this.$message({
			              type: 'success',
			              message: '评分成功!'
			            });
			          }).catch(() => {
			            this.$message({
			              type: 'info',
			              message: '已取消评分'
			            });          
			          });
		  }
	  },
    getUrl,
    isActive(idx) {
      this.active = idx;
    },
    changUrl(url) {
      this.src = url;
    },
    async getCommentByWorks() {
      await this.$netWork
        .getCommentByWorks({
          method: "post",
          url: "/comment/getCommentByWorks",
          data: {
            works_id: this.id,
            page: "1",
          },
        })
        .then((result) => {
          console.log(result);
          this.commentList = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    console.log(getUrl);
    console.log(this.$route.params.data);
    this.src = this.$route.params.data.fData[0].file_path;
    this.id = this.$route.params.data.id;
    this.nickname = this.$route.params.data.nickname;
    this.getCommentByWorks();
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: @fsColor;
}
li {
  color: @fsColor;
  list-style: none;
}
.radio {
  margin-left: 10px;
}

.radio input[type="radio"] {
  position: absolute;
  margin: 5px 0 0 4px;
  opacity: 0;
}
.radio input[type="radio"] + .radio-label:before {
  content: "";
  border-radius: 100%;
  border: 1px solid @fsColor;
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  margin-right: 1em;
  vertical-align: bottom;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: @fsColor;
  box-shadow: inset 0 0 0 3px @bg1Color;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: @fsColor;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
.scrollBar {
  overflow-y: auto;
}

/*滚动条整体样式*/
.scrollBar::-webkit-scrollbar {
  width: 18px;
  height: 10px;
  background: @bg1Color;
}
/*滚动条滑块*/
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: @btnColor;
}

/*滚动条轨道*/
.scrollBar::-webkit-scrollbar-track {
  border: 1px solid @btnColor;
  border-radius: 4px;
}
@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;
.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
  width: 1200px;
  .centerContent {
    width: 1200px;
    // 作品目录
    .nav1 {
      overflow: hidden;
      width: 1200px;
      height: 1100px;
      border-radius: 7px;
      background: @bg1Color;
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 38px;
        margin: 5px 5px 0px 5px;
        padding: 0 5px;
        color: @fsColor;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background: @bgColor;
      }
      // 介绍
      .introduce {
        padding: 10px 10px 0 15px;
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .time {
            font-size: 14px;
            font-weight: lighter;
            color: @iptfsColor;
          }
          ul {
            display: flex;
            li {
              margin-left: 50px;
              font-size: 14px;
              .num {
                font-weight: lighter;
                color: @iptfsColor;
              }
            }
          }
        }
        .two {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 7px;
          .title {
            font-weight: normal;
            color: @fsColor;
          }
          ul {
            display: flex;
          }
        }
        .explain {
          margin: 30px 0 25px;
          font-size: 15px;
          font-weight: lighter;
          color: @fsColor;
          p {
            line-height: 24px;
          }
        }
      }
      .tag {
        position: relative;
        ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          height: 40px;
          background: @bgColor;
          .item {
            position: relative;
            padding: 0 30px;
            &::before {
              content: ".";
              position: absolute;
              top: -4px;
              left: 20px;
              padding-right: 4px;
              font-weight: bold;
            }
          }
          .add {
            width: 18px;
            height: 18px;
            padding: 3px 0 0 25px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .collect {
          position: absolute;
          top: 56px;
          right: 30px;
          width: 100px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          font-weight: lighter;
          color: @btnfsColor;
          cursor: pointer;
          border-radius: 7px;
          background: @btnColor;
        }
        .workImg {
          width: 620px;
          height: 532px;
          margin: 100px auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .ulimg {
          display: flex;
          justify-content: flex-start;
          width: 620px;
          margin: 30px auto 0;
          background: none;
          list-style: none;
          li {
            margin-right: 20px;
            box-sizing: border-box;
            img {
              vertical-align: middle;
            }
          }
          .active {
            // box-shadow: 0 0 4px #5accd8;
            border: 1px solid @btnColor;
          }
        }
      }
    }
    // 作品留言板
    .nav2 {
      overflow: hidden;
      width: 1200px;
      height: 459px;
      margin-top: 14.77px;
      border-radius: 7px;
      background: @bg1Color;
      .head {
        height: 38px;
        margin: 5px 5px 0px 5px;
        padding: 0 5px;
        color: @fsColor;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background: @bgColor;
      }
      .message {
        position: relative;
        overflow-y: auto;
        height: 312px;
        margin: 10px 10px 0 0;
        padding: 10px 50px 20px 20px;
        color: @fsColor;
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          padding-bottom: 12px;
          border-bottom: 1px solid @lineColor;
          .right {
            display: flex;
            .avatar {
              width: 40px;
              height: 40px;
              border-radius: 7px;
            }
            .info {
              margin-left: 20px;
              .name {
                font-size: 13px;
                font-weight: lighter;
                .time {
                  font-size: 12px;
                  padding-left: 20px;
                }
              }
              .text {
                font-size: 13px;
                font-weight: lighter;
                color: @iptfsColor;
                .replyName {
                  color: @fsColor;
                }
              }
            }
          }
          .reply {
            font-size: 14px;
            font-weight: lighter;
            cursor: pointer;
          }
        }
      }
      .formInfo {
        display: flex;
        align-items: center;
        margin: 14px 15px 0px 15px;
        .inputInfo {
          width: 100%;
          height: 32px;
          padding-left: 10px;
          color: @iptfsColor;
          background: @bgColor;
          border-radius: 7px;
          border: none;
        }
        .send {
          width: 116px;
          height: 36px;
          margin-left: 15px;
          line-height: 36px;
          text-align: center;
          color: @btnfsColor;
          cursor: pointer;
          background: @btnColor;
          border: none;
          border-radius: 7px;
        }
      }
    }
  }
}

@font-face {
  font-family: "iconfont";
  src: url("../../assets/iconfont/iconfont.woff2?t=1649991164315")
      format("woff2"),
    url("../../assets/iconfont/iconfont.woff?t=1649991164315") format("woff"),
    url("../../assets/iconfont/iconfont.ttf?t=1649991164315") format("truetype");
}

/deep/.el-icon-star-on:before {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e71e";
}

/deep/.el-icon-star-off:before {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e71f";
}

/deep/ .el-rate__icon {
  color: #5accd8 !important;
}
</style>