<template>
  <div class="wrapper clearfix">
    <!-- 侧边栏 -->
    <common-left1></common-left1>
    <!-- 内容 -->
    <div class="centerContent">
      <div class="title">申请版权经济</div>
      <!-- 上传 -->
      <div class="upload">
        <div class="uploadInfo">
          <img class="img" src="../../../assets/imgs/zuopinxiaotu.png" alt="" />
        </div>
        <div class="add">
          <img class="icon" src="../../../assets/imgs/tianjia2.png" alt="" />
          <input class="uploadfile" type="file" />
        </div>
      </div>
      <!-- 表单 -->
      <form class="formInfo">
        <div class="item">
          <label>作品介绍</label>
          <input type="text" />
        </div>
        <div class="item">
          <label>作者介绍</label>
          <input type="text" />
        </div>
        <div class="advise">
          <label>商业需求和期望</label>
          <div class="select">
            <div class="option1">
              <div class="radio">
                <input name="need" type="radio" checked />
                <label class="radio-label">需求需求1</label>
              </div>
              <div class="radio">
                <input name="need" type="radio" />
                <label class="radio-label">需求需求2</label>
              </div>
              <div class="radio">
                <input name="need" type="radio" />
                <label class="radio-label">需求需求2</label>
              </div>
            </div>
            <div class="option2">
              <div class="radio radio1">
                <input name="expect" type="radio" />
                <label class="radio-label">期望1</label>
              </div>
              <div class="radio radio1">
                <input name="expect" type="radio" />
                <label class="radio-label">期望2</label>
              </div>
              <div class="radio radio1">
                <input name="expect" type="radio" />
                <label class="radio-label">期望3</label>
              </div>
            </div>
            <textarea name="" id="" cols="30" rows="5"></textarea>
          </div>
        </div>
        <div class="protect">
          <label>在站内登记保护</label>
          <div class="select">
            <div class="radio">
              <input name="choice" type="radio" checked />
              <label class="radio-label">是</label>
            </div>
            <div class="radio">
              <input name="choice" type="radio" />
              <label class="radio-label">否</label>
            </div>
          </div>
        </div>
        <input class="submit" type="submit" />
      </form>
    </div>
  </div>
</template>

<script>
import commonLeft1 from "../../../components/common_left1.vue";
export default {
  name: "copyrightEconomy",
  components: { commonLeft1 },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.radio {
  margin: 3px 45px 8px 8px;
}
.radio1 {
  margin: 3px 77px 8px 8px;
}
.radio input[type="radio"] {
  position: absolute;
  margin: 5px 0 0 4px;
  opacity: 0;
}
.radio input[type="radio"] + .radio-label:before {
  content: "";
  border-radius: 100%;
  border: 1px solid @fsColor;
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  margin-right: 1em;
  vertical-align: bottom;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: @fsColor;
  box-shadow: inset 0 0 0 3px @bg1Color;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: @fsColor;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
  width: 1200px;
  .centerContent {
    margin-left: 14.77px;
    width: 996px;
    height: 736px;
    border-radius: 7px;
    background: @bg1Color;
    .title {
      height: 38px;
      margin: 8px 8px 0px 8px;
      padding-left: 5px;
      line-height: 38px;
      color: @fsColor;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: @bgColor;
    }
    // 上传
    .upload {
      display: flex;
      justify-content: flex-start;
      padding: 25px 30px;
      border-bottom: 1px solid @lineColor;

      .uploadInfo {
        width: 150px;
        height: 150px;
        margin-right: 20px;
        border-radius: 7px;
        background: @bgColor;
        .img {
          width: 100%;
          height: 90px;
          padding: 30px 0;
        }
      }
      .add {
        position: relative;
        width: 150px;
        height: 150px;
        margin-right: 20px;
        border-radius: 7px;
        background: @bgColor;
        .icon {
          width: 42px;
          height: 42px;
          margin-top: 50%;
          margin-left: 50%;
          transform: translate(-50%, -50%);
        }
        .uploadfile {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 42px;
          height: 42px;
          font-size: 10px;
          opacity: 0;
        }
      }
    }
    // 表单
    .formInfo {
      display: flex;
      flex-direction: column;
      margin: 0 50px 0 30px;
      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 25px;
        color: @fsColor;
        label {
          white-space: nowrap;
        }
        input {
          width: 100%;
          height: 40px;
          margin-left: 60px;
          padding-left: 10px;
          color: @iptfsColor;
          background: @bgColor;
          border-radius: 7px;
          border: none;
        }
      }
      .advise {
        display: flex;
        margin-top: 30px;
        color: @fsColor;
        label {
          white-space: nowrap;
        }
        .select {
          margin-left: 30px;
          .option1 {
            display: flex;
          }
          .option2 {
            display: flex;
            margin-top: 10px;
          }
          textarea {
            width: 784px;
            height: 120px;
            margin-top: 10px;
            margin-left: -19px;
            padding-left: 10px;
            color: @iptfsColor;
            background: @bgColor;
            border-radius: 7px;
            resize: none;
            border: none;
          }
        }
      }
      .protect {
        display: flex;
        margin-top: 25px;
        color: @fsColor;
        .select {
          display: flex;
          margin-left: 60px;
        }
        .radio {
          margin-top: 2px;
        }
      }
      .submit {
        width: 116px;
        height: 36px;
        margin-top: 7px;
        margin-left: 50%;
        transform: translateX(-50%);
        line-height: 36px;
        text-align: center;
        color: @btnfsColor;
        cursor: pointer;
        background: @btnColor;
        border: none;
        border-radius: 7px;
      }
    }
  }
}
</style>