<template>
	<div class="useForm">
		<p class="title">原画邦用户协议</p>
<!-- 		<p class="agreement">更新时间:2022年【5】月【7】日</p>
		<p class="agreement1">生效时间:2022年【5】月【10】日</p> -->
		<p class="head">【总则】</p>
		<div class="textarea">
			&emsp;&emsp;用户在接受原画邦（Anime
			Boom.com）服务之前，请务必仔细阅读本协议，用户直接或通过各类方式直接或间接使用原画邦服务和数据的行为，都将被视作已无条件接受本协议所涉全部内容；若用户对本协议的任何条款有异议，请停止使用原画邦所提供的全部服务。
		</div>
		<div class="textarea">
			&emsp;&emsp;第一条 用户承诺
			用户以各种方式使用原画邦服务和数据（包括但不限于发表、采集、宣传介绍绍、转载、浏览及利用原画邦或原画邦用户发布或采集的内容）的过程中，不得以任何方式利用原画邦直接或间接从事违反中国法律法规、以及社会公德的行为，且用户应得以任何方式利用原画邦直接或间接从事违反中国法律法规、以及社会公德的行为，应当恪守下述承诺：
			1. 发布、采集或提供的内容符合中国法律法规、社会公德；
			2. 发布、采集或提供的内容不得侵害正当权利人之权利；
			3. 不得干扰、损害和侵犯原画邦的各种合法权利与利益；
			4. 遵守原画邦以及与之相关的网络服务的协议、指导原则、管理细则等。
			5. 原画邦有权对违反上述承诺的内容予以删除。
		</div>
		<div class="textarea">
			&emsp;&emsp;第二条 免责说明
			原画邦仅为用户发表、采集的内容提供存储空间，原画邦不对用户发表、采集的内容提供任何形式的保证：不保证内容满足您的要求，不保证原画邦的服务不会中断（因网络状况、通讯线路、第三方网站或管理部门的要求等任何不可抗力、不可归责于原画邦的原因而导致用户不能正常使用原画邦，原画邦不承担任何法律责任。）
			用户在原画邦发表、采集的内容（包含但不限于原画邦现有各产品、功能里的内容），仅表明其个人的立场和观点，并不代表原画邦的立场和观点。作为内容的发表或采集者，需自行对所发表或采集的内容负责，因所发表或采集的内容引发的一切纠纷、损失，由该内容的发表或采集者承担全部直接或间接（连带）法律责任，原画邦不承担任何法律责任。
			用户在原画邦发表、采集的内容（包含但不限于原画邦现有各产品、功能中的内容），均系用户个人行为产生，任何个人、公司或其他主体使用上述内容从事盈利性或非盈利性行为导致的侵犯他人知识产权或其他合法权益而产生的一切纠纷、损失均由该个人、公司或其他主体承担全部直接或间接（连带）法律责任，原画邦不承担任何法律责任。
			用户在原画邦发布或采集侵犯他人知识产权或其他合法权益的内容时或者原画邦接到相关权利人投诉、举报时，原画邦有权对侵权内容予以删除或进行其他限制措施，并保留移交相关行政机关处理的权利，原画邦不承担因此产生的一切损失或其他法律责任。
			个人用户在申请注册用户时，不得使用已在工商局注册的企业或品牌名称作为用户名，经查实无法提供相关证明的，原画邦有权收回帐号。原画邦的删除或其他限制措施行为系履行网络服务提供者的审查、监管义务行为，不承担相关赔偿责任。原画邦作为网络服务提供者与用户及相关权利人之间的纠纷无关。
		</div>
		<div class="textarea">
			&emsp;&emsp;第三条 维权须知
			任何个人、公司或其他主体如认为原画邦上存在侵犯其自身合法权益的内容，应及时与原画邦取得联系同时向原画邦提交相关书面通知书，以便原画邦迅速做出处理。通知书应当包含下列内容：
			权利人的姓名(名称)、联系方式和地址；
			要求删除或者断开链接的具体侵权内容的名称、以及对应的采集或画板url地址；
			构成侵权的初步证明材料。
			权利人应当对通知书的真实性负责。权利人未提交通知书的原画邦有权在核实情况后再行处理，因此产生的一切损失由权利人承担。
			用户认为其在原画邦发布或采集的内容未侵犯他人权利的或者在接到原画邦侵权处理通知时可以向原画邦提交书面说明要求解除相关限制措施（不包括不可恢复性措施）。书面说明应当包含下列内容：
			1. 用户的姓名(名称)、联系方式和地址；
			2. 要求恢复的内容名称、对应的采集或画板url地址及要求解除的限制措施；
			3. 不构成侵权的初步证明材料。
			4. 用户应当对书面说明的真实性负责。用户不提交书面说明的，原画邦不自动恢复相关措施。
		</div>
		<div class="textarea">
			附则
			原画邦是一个美好的社区，我们承认个性，也请尊重他人，让自己大方得体。
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				isAgree: false,
			};
		},
		mounted() {},
		methods: {
			toLogin() {
				this.$router.push({
					name: "login",
					params: {
						isAgree: this.isAgree
					}
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.useForm {
		width: 800px;
		min-height: 550px;
		background: white;
		margin: 30px auto;
		padding: 30px 0;
		position: relative;
		border-radius: 10px;
	}

	// .agreement {
	// 	font-size: 14px;
	// 	color: black;
	// 	margin: 30px 0 20px 30px;
	// }

	// .agreement1 {
	// 	font-size: 14px;
	// 	color: black;
	// 	margin-left: 30px;
	// 	margin-top: -18px;
	// }

	.head {
		font-size: 14px;
		color: black;
		margin-left: 30px;
		margin-top: 15px;
	}

	.title {
		font-size: 25px;
		font-weight: 700;
		padding-top: 20px;
		color: #4eb8c2;
		text-align: center;
	}

	.textarea {
		margin-left: 30px;
		font-size: 14px;
		color: black;
		margin-right: 30px;
		margin-top: 15px;
	}
</style>
