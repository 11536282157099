<template>
  <div class="money">
    <common-left1></common-left1>
    <div class="money_right">
      <div class="money_right_title">钱包</div>
      <div class="money_right_ability">
        <div @click="selectItem(item)" style="cursor: pointer;" :class="select == item ? 'select' : ''" v-for="(item, index) in list" :key="index">
          {{ item }}
        </div>
      </div>
      <div v-if="select == '我的账户'" class="money_right_show">
        <div class="money_right_show_title">账户余额</div>
        <div class="money_right_show_data">
          <div class="data">
            <span class="number">{{ money }}</span><span class="text">原画币</span>
          </div>
          <div @click="select = '购买原画币'" class="button">购买原画币</div>
        </div>
      </div>
      <div v-if="select == '购买原画币'" class="money_right_recharge">
        <div class="money_right_recharge_select">
          <div class="one" @click="selectOne(item)" :class="selectRecharge.balance == item.balance ? 'selectRec' : ''"
            v-for="(item, index) in RechargeList" :key="index">
            <div class="left">
              <span class="left_one">{{ item.description }}</span>
              <span class="left_two">{{ item.tip }}</span>
            </div>
            <div class="right">
              <span class="right_one">{{ item.balance }}<span>原画币</span></span>
              <span class="right_two">￥{{ item.price }}</span>
            </div>
          </div>
        </div>
        <div class="money_right_recharge_money">
          <div class="money_one"><img :src="QRdata" alt="" srcset="" /></div>
          <div class="money_two">
            <div>
              ￥{{ selectRecharge.price ? selectRecharge.price : "0" }}
              <span>微信扫码付款</span>
            </div>
            <div>
              <img src="../../assets/img/home/weixin.png" alt="" srcset="" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="select == '使用记录'" class="money_right_record">
        <div class="money_right_record_title">
          <table class="money_right_record_title_table">
            <tr>
              <td style="width: 250px">时间</td>
              <td style="width: 200px">变化</td>
              <td style="width: 200px">操作</td>
            </tr>
          </table>
        </div>
        <div class="money_right_record_data">
          <table class="money_right_record_data_table">
            <tr v-for="(item, index) in recordListData" :key="index">
              <td style="width: 250px">{{ item.create_time }}</td>
              <td style="width: 200px" v-if="item.after_balance - item.before_balance > 0">
                +{{ item.after_balance - item.before_balance }}
              </td>
              <td style="width: 200px" v-else>
                {{ item.after_balance - item.before_balance }}
              </td>
              <td style="width: 200px">
                {{ item.description }} <span>{{ item.works_name }}</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="money_right_record_pagination">
          <el-pagination background layout="prev, pager, next" @prev-click="pagination" @next-click="pagination"
            prev-text="上一页" next-text="下一页" @current-change="pagination" :total="total" :page-size="1">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonLeft1 from "@/components/common_left1.vue";
export default {
  components: {
    commonLeft1,
  },
  data() {
    return {
      // 余额
      list: ["我的账户", "购买原画币", "使用记录"],
      select: "我的账户",
      money: "",
      //   充值
      RechargeList: "",
      selectRecharge: "",
      QRdata: require("../../assets/img/home/QR.png"),
      timer: "",
      // 交易记录
      recordList: "",
      recordListData: "",
      total: 0,
    };
  },
  created() {
    if (localStorage.getItem("userInfo")) {
      this.money = JSON.parse(localStorage.getItem("userInfo")).balance;
    }
    this.RechargeRequest();
    this.RecordRequest();
  },
  methods: {
    selectItem(item) {
      this.select = item;
      switch (item) {
        case "我的账户":
          break;
        case "购买原画币":
          this.RechargeRequest();
          break;
        case "使用记录":
          this.RecordRequest();
          break;
        default:
          break;
      }
      this.QRdata = require("../../assets/img/home/QR.png");
      this.selectRecharge = "";
    },
    RechargeRequest() {
      this.$netWork.getRechargeList({
        method: "get",
        url: "/order/getPriceList",
        data: {},
      }).then((res) => {
        this.RechargeList = res;
      });
    },
    RecordRequest() {
      this.$netWork.getTransactionRecords({
        method: "post",
        url: "/TransactionRecord/getMyTransactionRecord",
        data: {
          token: localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.recordList = res.data;
          if (this.recordList.length < 7) {
            this.recordListData = this.recordList;
            this.total = 1;
          } else {
            let number = 0;
            this.total = 0
            while (number - this.recordList.length < 0) {
              this.total++;
              number = this.total * 7;
            }
            this.recordList.reverse();
            this.pagination(1);
          }
        });
    },
    pagination(num) {
      let page = (num - 1) * 7;
      this.recordListData = [];
      if (num * 7 < this.recordList.length) {
        for (let index = page; index < num * 7; index++) {
          this.recordListData.push(this.recordList[index]);
        }
      } else {
        for (let index = page; index < this.recordList.length; index++) {
          this.recordListData.push(this.recordList[index]);
        }
      }
    },
    selectOne(item) {
      console.log(item.balance == this.selectRecharge.balance);
      this.selectRecharge = item;
      this.QR();
    },
    QR() {
      this.$netWork.getRechargeQR({
        method: "post",
        url: "/order/userBalanceRecharge",
        data: {
          token: localStorage.getItem("token"),
          recharge_amount: this.selectRecharge.balance,
          amount: this.selectRecharge.price,
        },
      }).then((res) => {
        this.QRdata = res.qrcode;
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = this.atRegularTime(res.data.order_id);
        this.money = JSON.parse(localStorage.getItem("userInfo")).balance;
      });
    },
    inspect(id) {
      return this.$netWork.checkOrderStatus({
        url: "/order/checkOrderStatus?order_id=" + id,
        method: "get",
      });
    },
    atRegularTime(orderId) {
      let intervalI = setInterval(() => {
        this.inspect(orderId).then((res) => {
          if (res.status) {
            clearImmediate(intervalI);
            this.$netWork.getUserInfo({
              url: "/user/getUserInfo",
              method: "post",
              data: {
                token: localStorage.getItem("token"),
              },
            }).then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data));
            });
            location.reload(true);
          }
        });
      }, 1000);
      return intervalI;
    },
  },
};
</script>

<style lang="less" scoped>
.money {
  margin: 14.77px auto;
  display: flex;
  align-items: flex-start;

  &_right {
    background: #353641;
    width: 100%;
    min-height: 292px;
    margin-left: 14.77px;
    padding: 12px;
    border-radius: 0.4rem;

    &_title {
      width: calc(100% - 24px);
      height: 35px;
      line-height: 35px;
      background-color: #26272d;
      font-size: 14px;
      color: #4eb8c2;
      padding: 0 10px;
      border-radius: 4px;
      box-sizing: border-box;
    }

    &_ability {
      display: flex;
      margin-top: 22px;
      border-bottom: 1px solid #535564;

      div {
        margin-right: 30px;
        padding-bottom: 15px;
        color: #4eb8c2;
        font-size: 14px;
      }

      .select {
        border-bottom: 2px solid #4eb8c2;
      }
    }

    &_show {
      margin-top: 32px;

      &_title {
        font-size: 14px;
        color: #4eb8c2;
        margin-bottom: 15px;
      }

      &_data {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .data {
          display: flex;
          align-items: end;
          color: #4eb8c2;
          font-size: 14px;

          .number {
            display: block;
            font-size: 50px;
            color: #4eb8c2;
            margin-right: 8px;
          }

          .text {
            margin-bottom: 15px;
          }
        }

        .button {
          width: 176px;
          height: 60px;
          border-radius: 10px;
          background: #4eb8c2;
          font-size: 20px;
          line-height: 60px;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    &_recharge {
      margin-top: 35px;

      &_select {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 18px 18px;

        .one {
          border-radius: 5px;
          background: #353641;
          box-sizing: border-box;
          border: 1px solid #d0d0d0;
          width: 100%;
          height: 97px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          color: #dddddd;
          cursor: pointer;

          .left,
          .right {
            display: flex;
            flex-direction: column;
          }

          .left_one {
            text-decoration: line-through;
            font-size: 14px;
          }

          .left_two {
            font-size: 12px;
          }

          .right_one {
            color: #ffffff;
            font-size: 30px;

            span {
              font-size: 14px;
            }
          }

          .right_two {
            color: #ffffff;
            margin-top: 12px;
          }
        }

        .selectRec {
          color: #4eb8c2 !important;
          border: 1px solid #4eb8c2 !important;

          .right_one {
            color: #4eb8c2;
            font-size: 30px;

            span {
              font-size: 14px;
            }
          }

          .right_two {
            color: #4eb8c2;
            margin-top: 12px;
          }
        }
      }

      &_money {
        display: flex;
        justify-content: center;
        align-content: center;
        padding-top: 111px;
        padding-bottom: 137px;

        .money_one {
          width: 144px;
          height: 142px;
          margin-right: 36px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .money_two {
          display: flex;
          flex-direction: column;

          div {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            font-size: 30px;
            color: #ffffff;

            span {
              margin-top: 6px;
              font-size: 14px;
              color: #bbb;
            }
          }

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    &_record {
      width: 100%;
      margin-top: 23px;

      &_title {
        width: 100%;
        background: #26272d;

        &_table {
          height: 39px;
          margin-left: 56px;
          color: #4eb8c2;
          font-size: 14px;
        }
      }

      &_data {
        width: 100%;
        margin: 28px 0;

        &_table {
          height: 39px;
          color: #4eb8c2;
          font-size: 14px;
          margin-left: 56px;

          td {
            height: 30px;
            width: calc(100% / 3);
            color: #e7e7e7;

            span {
              font-size: 14px;
              text-decoration: underline;
              color: #4eb8c2;
              margin-left: 14px;
            }
          }
        }
      }

      &_pagination {
        width: 100%;
        height: 39px;
        background: #26272d;
        display: flex;
        align-items: center;
      }
    }
  }
}
.money_right_title{
  width: 100%;
}
.money_right_ability{
  padding-left: 10px;
}
.money_right_show{
  padding-left: 10px;
}
.is-background {
  background-color: #26272d;

  /deep/ button,
  /deep/ .number,
  /deep/ .el-icon-more {
    background-color: #26272d !important;
    color: #e7e7e7;
  }
}
</style>
