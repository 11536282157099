<template>
	<div class="wrapper">
		<!-- 侧边栏 -->
		<!-- <div class="leftContent"></div> -->

		<common-left1 ref="child" @changeWorkList="changeWorkList"></common-left1>
		<!-- 内容 -->
		<div class="centerContent">
			<!-- 自我介绍 -->
			<div class="nav1">
				<div class="head">
					<div class="title">自我介绍</div>
					<a class="change" @click="changeEdit">{{ isEdit ? "取消更改" : "更改" }}</a>
				</div>
				<div class="introduce">
					<p class="text" v-if="!isEdit">{{ desc }}</p>
					<textarea ref="edit" class="text" style="
              width: 967px;
              height: 83px;
              backgroundcolor: gray;
              color: #5dbbbf;
              background-color: #35363f;
            " :value="desc" v-if="isEdit"></textarea>
				</div>
				<input class="bcxg" @click="bcxg" v-show="isEdit" type="button" value="保存修改" />
			</div>
			<!-- 作品 -->
			<div class="nav2" v-loading="loading" element-loading-text="操作中" element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)">
				<div class="head">
					<div class="title">作品目录</div>
				</div>
				<div class="worksBox">
					<div class="item" v-for="(item, index) in worksList" :key="index">
						<img :src="item.coverimg" @click="goWorkDetail(item)" alt="" />
						<p class="name">
							{{ item.title }}
						</p>
						<div class="w_p">
							<p style="color:orange ;" @click="revise(item)">修改</p>
							<p style="color:red ;" @click="close(item, index)">删除</p>
						</div>
						<div class="state">
							<span class="sj"></span>
							<span style="fontsize: 14px;background-color: #35363f;" class="revisespan color">
								{{ auditStatus(item.wstatus) }}
							</span>
							<!-- 	<span style="fontsize: 14px;background-color: #35363f;" :class="{
				  red: item.wstatus == 3|| item.wstatus == 0,
				  green: item.wstatus == 2,
				  org: item.wstatus == 1,
				  revisespan: true
				}">{{ auditStatus(item.wstatus) }}</span> -->
						</div>
					</div>
				</div>
				<!-- 分页 -->
				<ul v-if="totalPage > 1" class="pages">
					<li class="prev" v-if="totalPage > 1" @click="prev"></li>
					<li v-for="item in totalPage" :key="item" :class="{ active: item == currPage }" @click="refash(item)">
						{{ item }}
					</li>
					<li class="next" @click="next" v-if="totalPage > 1"></li>
				</ul>
			</div>
			<!-- 勾搭栏 -->
			<div class="nav3">
				<div class="head">
					<div class="title">勾搭栏</div>
				</div>
				<div class="message scrollBar">
					<div class="item" v-for="(item) in messageList" :key="item.id">
						<div class="right">
							<img class="avatar" :src="item.avatar" alt="" />
							<div class="info">
								<p class="name">
									<span style="color: green">{{
										item.user_id == userid ? "发给" : "收到"
									}}</span>
									{{ item.nickname }}
									<span class="time">{{ item.send_time }}</span>
								</p>
								<p class="text">{{ item.message_content }}</p>
							</div>
						</div>
						<div class="reply" @click="toMyEmail2(item.talk_id, item.send_user_id, item.friend_id)">回复</div>
					</div>
				</div>
				<!-- <form class="formInfo">
          <input class="inputInfo" type="text" />
          <input class="send" type="submit" value="发送" />
        </form> -->
			</div>
		</div>
	</div>
</template>
<script>
import commonLeft1 from "@/components/common_left1.vue";
export default {
	components: {
		commonLeft1,
	},
	data() {
		return {
			loading: false,
			work: [],
			worksList: [], //作品
			messageList: [], //聊天列表
			message_content_List: [],
			desc: "", //自我介绍
			newDesc: "",
			isEdit: false, //是否是编辑
			totalPage: "",
			currPage: "1",
			userid: "", //
		};
	},
	computed: {
		// imgurl() { //全部作品图片
		// 	this.worksList.forEach((item,index,data)=>{
		// 		if (this.worksList) {
		// 		}
		// 	})
		// }
		// desc(){
		// 	return localStorage.getItem('userInfo').introduction?localStorage.getItem('userInfo').introduction:"暂无信息"
		// }
	},
	created() {
		let userInfo = JSON.parse(localStorage.getItem("userInfo"));
		this.desc = userInfo.introduction;
		this.userid = userInfo.id;
		this.newDesc = this.desc;
		this.getMessageList();
		if (this.$route?.query?.workList) {
			console.log(JSON.parse(this.$route?.query?.workList), "66666666111111");
			this.worksList = JSON.parse(this.$route.query.workList);
			console.log("workList yes");
			return false;
		}
		this.getUserWorkList();
	},
	methods: {
		toMyEmail2(talk_id, send_user_id, friend_id) {
			this.$router.push({
				name: "myEmail2",
				query: {
					talk_id: talk_id,
					send_user_id: send_user_id,
					friend_id: friend_id
				}
			})
		},
		// 修改作品。TY
		revise(data) {
			if (this.$isToken())
				this.$router.push({
					path: "/contribute",
					query: {
						workid: data.id,
					},
				});
		},
		refash(item) {
			this.currPage = item;
			this.getUserWorkList();
		},
		//上一页
		prev() {
			if (this.currPage == 1) {
				this.$message.error("已经是第一页了");
				return false;
			}
			this.currPage--;
			this.getUserWorkList();
		},
		//下一页
		next() {
			if (this.currPage >= this.totalPage) {
				this.$message.error("已经是最后一页了");
				return false;
			}
			this.currPage++;
			this.getUserWorkList();
		},
		//获取聊天列表
		async getMessageList() {
			let token = localStorage.getItem("token");
			let res = await this.$netWork.getMessageList({
				url: "/message/getList",
				method: "post",
				data: {
					token: token,
				},
			});
			console.log('聊天列表',res);
			if (res.code == 0) {
				this.messageList = res.data;
				let talkIdList = [];
				let message_content_List = [];
				talkIdList.push(res.data.talk_id);
				res.data.forEach(async (element) => {
					// talkIdList.push({talk_id:element.talk_id,send_user_id:element.send_user_id})
					let result = await this.$netWork.getMessageDetail({
						url: "/message/getDetail",
						method: "post",
						data: {
							token: token,
							talk_id: element.talk_id,
							send_user_id: element.send_user_id,
						},
					});
					if (result.code == 0) {
						message_content_List.push(
							result.data[result.data.length - 1].message_content
						);
					}
				});
				this.message_content_List = message_content_List
			}
		},
		//获取子组件数据
		changeWorkList() {
			console.log("666666666");
			this.worksList = this.$refs.child.worksList;
		},
		auditStatus(wstatus) {
			if (wstatus == 1) {
				return "待审核";
			}
			if (wstatus == 2) {
				return "审核通过";
			}
			if (wstatus == 3) {
				return "审核未通过";
			}
			if (wstatus == 0) {
				return "已下架";
			}
		},
		async bcxg() {
			let introduction = this.$refs.edit.value;
			this.desc = introduction;
			let token = localStorage.getItem("token");
			if (!token) {
				return false;
			}
			let res = await this.$netWork.descChange({
				url: "/user/editIntroduction",
				method: "post",
				data: {
					token: token,
					introduction: introduction,
				},
			});
			if (res.code == 0) {
				this.$message.success("修改成功");
				this.isEdit = false;
				await this.$netWork
					.getUserInfo({
						method: "post",
						url: "/user/getUserInfo",
						data: {
							token: token,
						},
					})
					.then((result) => {
						if (result.code != 0) {
							return Promise.reject(result.msg);
						}
						localStorage.setItem("userInfo", JSON.stringify(result?.data));
					})
					.catch((arr) => {
						console.log(arr);
					});
			}
		},
		changeEdit() {
			if (this.isEdit) {
				this.isEdit = false;
				return false;
			}
			this.isEdit = true;
			setTimeout(() => {
				let a = this.$refs.edit.value.length;
				this.$refs.edit.focus();
				this.$refs.edit.setSelectionRange(a, a);
			}, 0);
		},
		more() {
			this.$router.push({
				path: "./userWorkOne",
			});
		},
		// 获取本人作品目录
		async getUserWorkList() {
			let { id } = JSON.parse(localStorage.getItem("userInfo"));
			let res = await this.$netWork.getUserWorkList({
				url: "/works/getWorksList",
				method: "post",
				data: {
					type: "my",
					// page: "3",
					page: this.currPage,
					id: `${id}`,
					works_type: "所有",
					status: '0'
				},
			});
			// this.work = res.data.list.filter(item => {
			//   return item.wstatus == 2
			// })
			this.totalPage = res.data.totalPage;
			if (res.code === 0) {
				// if (res.data.list.length > 3) {
				//   this.worksList = res.data.list.slice(0, 6);
				//   console.log("123");
				//   console.log(res.data.list);
				//   return false;
				// }
				this.worksList = res.data.list;
				// 筛选wstatus不为4的
				this.worksList = this.worksList.filter((v) => {
					return v.wstatus != 4
				})
			}
		},
		//处理 url
		getUrl(url) {
			return "http://api.aboom.net" + url;
		},
		// 去作品详情
		goWorkDetail(data) {
			let url = this.$router.resolve({
				path: "/workDetail1",
				query: {
					work_id: data.id,
					uid: data.uid,
				},
			});
			window.open(url.href,'_blank')
		},
		// 删除作品
		async close(v, i) {
			this.loading = true;
			// 查询是否有版权保护 0未申请 1已申请
			let getRes = await this.$netWork.getMyzp({
				url: '/works/checkProtection',
				method: 'get',
				data: {
					work_id: v.id
				}
			})
			console.log('查询结果', getRes);
			if (getRes.code == 0) {
				let text = '是否删除该作品?'
				if (getRes.status == 1) {
					text = '该作品已申请版权保护,是否删除?'
				}
				// 删除作品
				this.$confirm(text, '操作确认', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'warning'
				}).then(async () => {
					this.loading = false;
					let data = {
						work_id: v.id,
						token: localStorage.getItem('token')
					}
					let res = await this.$netWork.closeMyzp({
						url: '/works/delMyWorks',
						method: 'post',
						data
					})
					console.log('--删除结果--', res);
					if (res.code == 0) {
						v.wstatus = 4;
						this.$message.success('删除成功')
						this.worksList = this.worksList.filter((v) => {
							return v.wstatus != 4
						})
					} else {
						this.$message.error(res.msg)
					}
				}).catch(() => {
					this.loading = false;

				});
			} else {
				this.$message.error(res.msg)
			}
		}
	},
};
</script>
<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}

.pages {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 0;
	color: #4db9c2;
}

.pages li {
	margin-right: 16px;
	cursor: pointer;
}

.pages li:hover {
	color: #ffffff;
}

.pages .active {
	color: #ffffff;
}

.pages .prev {
	transform: rotate(180deg);
	width: 48px;
	height: 25px;
	background: #5accd8;
	background-image: url("../../assets/img/home/fanye3.png");
	background-repeat: no-repeat;
	background-size: 14px;
	background-position: center;
	border-radius: 3px;
}

.pages .next {
	width: 48px;
	height: 25px;
	background: #5accd8;
	background-image: url("../../assets/img/home/fanye3.png");
	background-repeat: no-repeat;
	background-size: 14px;
	background-position: center;
	border-radius: 3px;
}

// .pages{
//   display: flex;
//   justify-content: center;
//   margin-bottom: 20px;
// }
a {
	text-decoration: none;
	color: #5dbbbf;
}

li {
	color: #5dbbbf;
	list-style: none;
	cursor: pointer;
	margin-left: 5px;
}

.scrollBar {
	overflow-y: auto;
}

.bcxg {
	width: 116px;
	height: 36px;
	margin-left: 15px;
	line-height: 36px;
	text-align: center;
	color: #0c424f;
	cursor: pointer;
	background: #5accd8;
	border: none;
	border-radius: 7px;
}

/*滚动条整体样式*/
.scrollBar::-webkit-scrollbar {
	width: 18px;
	height: 10px;
	background: #35363f;
}

/*滚动条滑块*/
.scrollBar::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: #5accd8;
}

/*滚动条轨道*/
.scrollBar::-webkit-scrollbar-track {
	border: 1px solid #5accd8;
	border-radius: 4px;
}

.wrapper {
	display: flex;
	justify-content: space-between;
	margin: 14.77px auto;
}

.centerContent{
	flex:1;
	margin-left: 20px;
}
.wrapper .centerContent .nav1 {
	overflow: hidden;
	height: 197px;
	border-radius: 7px;
	background: #35363f;
}

.wrapper .centerContent .nav1 .head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 38px;
	margin: 8px 8px 0px 8px;
	padding: 0 5px;
	color: #5dbbbf;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	background: #28262e;
}

.wrapper .centerContent .nav1 .head .change {
	cursor: pointer;
}

.wrapper .centerContent .nav1 .introduce {
	padding: 8px 15px;
	font-size: 14px;
	font-weight: lighter;
	color: #5dbbbf;
}

.wrapper .centerContent .nav1 .introduce .text {
	line-height: 28px;
}

.wrapper .centerContent .nav2 {
	overflow: hidden;
	// height: 375px;
	margin-top: 14.77px;
	border-radius: 7px;
	background: #35363f;
}

.wrapper .centerContent .nav2 .head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 38px;
	margin: 8px 8px 0px 8px;
	padding: 0 5px;
	color: #5dbbbf;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	background: #28262e;
}

.wrapper .centerContent .nav2 .worksBox {
	display: flex;
	justify-content: flex-start;
	// justify-content: space-around;
	flex-wrap: wrap;
	padding-top: 1rem;
}

.wrapper .centerContent .nav2 .worksBox .item {
	display: flex;
	margin: 0.1rem;
	padding-left: 14px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	// margin-bottom: 26px;
	// width: 246px;
	// height: 246px;
	// margin: 1rem;
	width: 180px;
	height: 246px;
	// overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}

.wrapper .centerContent .nav2 .worksBox .item img {
	// width: 100%;
	// height: 100%;
	width: 180px;
	height: 180px;
	object-fit: cover;
	border-radius: 7px;
}

.wrapper .centerContent .nav2 .worksBox .item .name {
	padding-top: 10px;
	color: #5dbbbf;
}

.wrapper .centerContent .nav2 .worksBox .item .revisespan {
	padding: 0 0 4px 1px;
	font-size: 1rem;
	height: 1.25rem;
}

.state {
	position: absolute;
	right: 0;
	top: -4px;

}

.sj {
	height: 0;
	width: 0;
	float: left;
	left: -20px;
	border-top: solid 12px rgb(53, 54, 63);
	border-left: solid 12px transparent;
	border-right: solid 12px rgb(53, 54, 63);
	border-bottom: solid 12px transparent;
}

// .revisespan::after {
//   content: '';
//   // height:0;
//   // width:0;
//   position: absolute;
//   left: -16px;
//   // top: 10px;
//   // border-left: 20px solid transparent;
//   // border-right: 20px solid transparent;#494c4c
//   border-top: 20px solid red;
//   // border-bottom: 20px solid transparent;
// }

.wrapper .centerContent .nav2 .more {
	display: flex;
	justify-content: flex-end;
	// padding: 0 20px 0 0;
	color: #5dbbbf;
	font-size: 14px;
	font-weight: lighter;
	cursor: pointer;
}

.wrapper .centerContent .nav3 {
	overflow: hidden;
	// height: 459px;
	margin-top: 14.77px;
	border-radius: 7px;
	background: #35363f;
}

.wrapper .centerContent .nav3 .head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 38px;
	margin: 8px 8px 0px 8px;
	padding: 0 5px;
	color: #5dbbbf;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	background: #28262e;
}

.wrapper .centerContent .nav3 .message {
	position: relative;
	overflow-y: auto;
	min-height: 68px;
	margin: 10px 10px 0 0;
	padding: 10px 50px 20px 20px;
	color: #5dbbbf;
}

.wrapper .centerContent .nav3 .message .item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
	padding-bottom: 12px;
	border-bottom: 1px solid #46535a;
}

.wrapper .centerContent .nav3 .message .item .right {
	display: flex;
	width: 90%;
}

.wrapper .centerContent .nav3 .message .item .right .avatar {
	width: 40px;
	height: 40px;
	border-radius: 7px;
}

.wrapper .centerContent .nav3 .message .item .right .info {
	margin-left: 20px;
}

.wrapper .centerContent .nav3 .message .item .right .info .name {
	font-size: 13px;
	font-weight: lighter;
}

.wrapper .centerContent .nav3 .message .item .right .info .name .time {
	font-size: 12px;
	padding-left: 20px;
}

.wrapper .centerContent .nav3 .message .item .right .info .text {
	font-size: 13px;
	font-weight: lighter;
	color: #ffffff;
}

.wrapper .centerContent .nav3 .message .item .right .info .text .replyName {
	color: #5dbbbf;
}

.wrapper .centerContent .nav3 .message .item .reply {
	font-size: 14px;
	font-weight: lighter;
	cursor: pointer;
}

.wrapper .centerContent .nav3 .formInfo {
	display: flex;
	align-items: center;
	margin: 14px 15px 0px 15px;
}

.wrapper .centerContent .nav3 .formInfo .inputInfo {
	width: 100%;
	height: 32px;
	padding-left: 10px;
	color: #ffffff;
	background: #28262e;
	border-radius: 7px;
	border: none;
}

.wrapper .centerContent .nav3 .formInfo .send {
	width: 116px;
	height: 36px;
	margin-left: 15px;
	line-height: 36px;
	text-align: center;
	color: #0c424f;
	cursor: pointer;
	background: #5accd8;
	border: none;
	border-radius: 7px;
}

.red {
	color: red !important;
}

.green {
	color: green !important;
}

.org {
	color: orange !important;
}

.color {
	color: #5dbbbf !important;
}

.w_p {
	margin-top: 5px;
	display: flex;

	p {
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}
	}
}
</style>
