<template>
  <div class="main_center">
    <router-link to="/register"></router-link>
    <form action="" class="login-form">
      <el-steps :active="active" :align-center="true">
        <el-step title="确认账号"></el-step>
        <el-step title="重置密码"></el-step>
        <el-step title="重置成功"></el-step>
      </el-steps>
      <input
        type="text"
        placeholder="用户名"
        v-model="form.name"
        class="username"
        @click="sure"
      />
      <input
        type="password"
        placeholder="新密码"
        v-model="form.pwd"
        class="password"
        @click="sure"
      />
      <input
        type="password"
        placeholder="确认密码"
        v-model="form.repwd"
        class="password"
        @click="reset"
      />
      <div class="flex">
        <input
          type="text"
          placeholder="请输入图片验证码"
          v-model="form.verifycode"
          class="verifycode"
        />
        <img :src="src" @click="refresh" alt="!" />
      </div>
      <div class="flex">
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="form.mac"
          class="verifycode"
        />
        <button type="button" class="btn-get" @click="getMac">
          获取验证码
        </button>
      </div>
      <div class="flex ">
        <button type="button" class="btn-submit" @click="forgetPwd">
          提交
        </button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active:1,
      form: {
        name: "",
        pwd: "",
        repwd: "",
        verifycode: "",
        //message authentication code 短信验证码
        mac: "",
        type: "1",
      },
      src: "http://api.aboom.net/captcha?a=" + Math.random(),
    };
  },
  mounted() {
  },
  methods: {
    reset(){
      this.active = 2
    },
    sure(){
      this.active = 1
    },
    //图片验证码刷新
    refresh() {
      this.src = "http://api.aboom.net/captcha?a=" + Math.random();
    },
    //获取验证码
    getMac() {
      console.log(this.form);
      this.$netWork
        .getMac({
          method: "post",
          url: "/verify/getVerify",
          data: {
            account: this.form.name,
            template: "register",
            type: this.form.type,
          },
        })
        .then((result) => {
          if (result.code !== 0) {
            alert(result.msg);
            return;
          }
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //修改密码
    forgetPwd() {
      this.$netWork
        .forgotPassword({
          method: "post",
          url: "/user/forgotPassword",
          data: {
            account: this.form.name,
            password: this.form.pwd,
            repassword: this.form.repwd,
            captcha: this.form.verifycode,
            code: this.form.mac,
          },
        })
        .then((result) => {
          if (result.code !== 0) {
            alert(result.msg);
            return;
          }
          alert(result.msg);
          this.$router.push({ path: "/login" });
        });
        this.active = 3
    },
  },
};
</script>
<style scoped lang="less">
.el-steps {
  margin-bottom: 30px;
}
.el-step__title {
    font-size: 14px;
    line-height: 38px;
}
.pointer {
  cursor: pointer;
}
main,
.main {
  background-color: #08072a !important;
}
.login-form {
  width: 350px;
  height: 350px;
  margin: 150px auto;
}
input {
  border-radius: 8px;
  border: none;
  margin-bottom: 18px;
}
.username,
.password {
  box-sizing: border-box;
  width: 300px;
  height: 28px;
  text-indent: 1em;
}
// .password {
//   margin: 18px 0;
// }
.verifycode {
  width: 70%;
  height: 28px;
  text-indent: 10px;
}
.login-form > input:nth-child(3)::-webkit-input-placeholder {
  color: #b4b8bd;
}

img {
  width: 78px;
  height: 28px;
  cursor: pointer;
}
.fz12 {
  font-size: 12px;
}
.btn-get {
  border: none;
  width: 78px;
  height: 28px;
  margin: 0;
  cursor: pointer;
}

.btn-submit {
  border: none;
  width: 140px;
  height: 28px;
  margin: 0 auto;
  color: #fff;
  background-color: #f30b32;
  cursor: pointer;
}
.flex {
  display: flex;
  justify-content: space-between;
}
</style>
