<template>
  <div class="wrapper clearfix">
    <!-- 侧边栏 -->
    <common-left1></common-left1>
    <!-- 内容 -->
    <div class="centerContent">
      <div class="title">申请版权保护</div>
      <div class="copyrightWorkContent">
        <div class="explain">审核通过的作品</div>
        <!-- 作品 -->
        <div class="worksBox">
          <div
            class="item"
            v-for="(item, index) in allWorkList"
            :key="index"
            @click="pass"
          >
          <!-- @click="goCopyrightProtection(item.id)" -->
            <img :src="item.fData[0].file_path" 
            :title="item.title"
            alt="" 
            />
            <p class="name">
              {{ item.title }}
              <span
                style="fontsize: 14px"
                :class="{
                  red: item.status == 3,
                  green: item.status == 2,
                  org: item.status == 1,
                }"
                v-text="auditStatus(item.status)"
                ></span>
            </p>
          </div>
        </div>
        <!-- 分页 -->
        <!-- <ul v-if="totalPage > 1" class="pages bottomPages">
          <li class="prev" v-if="totalPage > 1" @click="prev"></li>
          <li
            v-for="item in totalPage"
            :key="item"
            :class="{ active: item == currPage }"
            @click="refash(item)"
          >
            {{ item }}
          </li>
          <li class="next" @click="next" v-if="totalPage > 1"></li>
        </ul> -->
        <!-- 按钮 -->
        <div class="btn">
          <!-- <div class="submit">提交</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonLeft1 from "../../components/common_left1.vue";
export default {
  name: "copyrightWork",
  components: { commonLeft1 },
  data() {
    return {
      allWorkList: [], //全部作品
      totalPage: "",
      currPage: "1",
    };
  },
  created() {
    this.getCopyrightMangerAllWorks();
    this.getProtetion();
  },
  methods: {
    refash(item) {
      this.currPage = item;
      this.getCopyrightMangerAllWorks();
    },
    //上一页
    prev() {
      if (this.currPage == 1) {
        this.$message.error("已经是第一页了");
        return false;
      }
      this.currPage--;
      this.getCopyrightMangerAllWorks();
    },
    //下一页
    next() {
      if (this.currPage >= this.totalPage) {
        this.$message.error("已经是最后一页了");
        return false;
      }
      this.currPage++;
      this.getCopyrightMangerAllWorks();
    },
    // 获取全部作品
    async getCopyrightMangerAllWorks() {
      if (!localStorage.getItem("userInfo")) {
        this.$message.warning("用户登录过期");
        return false;
      }
      let { id } = JSON.parse(localStorage.getItem("userInfo"));
      let res = await this.$netWork.getCopyrightMangerAllWorks({
        url: "/works/getWorksList",
        method: "post",
        data: {
          type: "my",
          page: this.currPage,
          id: `${id}`,
          works_type: "所有",
        },
      });

      this.totalPage = res.data.totalPage;
      console.log(res,"作品全集")
      if (res.code === 0) {
        console.log(res)
        // this.allWorkList = res.data.list
        console.log(this.allWorkList,"这是全部作品")
      }
    },
    //处理 url
    getUrl(url) {
      return "http://api.aboom.net" + url;
    },
    // 去申请版权保护页面
    // goCopyrightProtection(id) {
    //   this.$router.push({
    //     name: "copyrightProtection",
    //     query: { works_id: id },
    //   });
    // },

    pass(){
      this.$message.success("您已经成功申请版权保护")
    },

        auditStatus(status) {
          console.log(status,"这是审核是否通过")
          switch (status) {
            case 1:
                return "待审核";
              break;
            case 2:
              return "审核通过";
              break
            default :
              return "审核未通过";
              break;
          }
      // if (status == 1) {
        
      //   return "待审核";
      // }
      // if (status == 2) {
      //   return "审核通过";
      // }
      // if (status == 3) {
      //   return "审核未通过";
      // }
    },

    //审核通过作品
    async getProtetion(){
      let res = await this.$netWork.getCopyrightMangerAllWorks({
        url:"/protection/getprotection",
        method:"post",
        data:{
          token : localStorage.getItem("token")
        }
      })
      this.allWorkList = res.data
      console.log(res,'参观参观参观参观工厂从')
    }
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
@fsColor: #5dbbbf;
@bgColor: #28262e;
@btnColor: #5accd8;
@btnfsColor: #0c424f;
@iptfsColor: #ffffff;
@bg1Color: #35363f;
@lineColor: #46535a;

.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14.77px auto;
  width: 1200px;

  .centerContent {
    margin-left: 14.77px;
    width: 996px;
    // height: 1100px;
    border-radius: 7px;
    background: @bg1Color;
    .title {
      height: 38px;
      margin: 8px 8px 0px 8px;
      padding-left: 5px;
      line-height: 38px;
      color: @fsColor;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: @bgColor;
    }
    .copyrightWorkContent {
      position: relative;
      color: @fsColor;
      .explain {
        padding: 20px 40px;
        font-size: 20px;
      }
      // 作品
      .worksBox {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-left: 40px;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          width: 280px;
          margin-bottom: 30px;
          cursor: pointer;
          border-radius: 7px;
          margin-right: 38px;
          img {
            width: 280px;
            height: 202px;
            border-radius: 7px;
          }
          .name {
            margin-top: 10px;
          }
          .gou {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 76px;
          }
        }
      }
      // 分页
      .pages {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        li {
          margin-right: 16px;
          cursor: pointer;
        }
        .active {
          color: @iptfsColor;
          &:hover {
            color: @iptfsColor;
          }
        }
        .next {
          width: 48px;
          height: 25px;
          background: @btnColor;
          background-image: url(../../assets/img/copyright/fanye3.png);
          background-repeat: no-repeat;
          background-size: 14px;
          background-position: center;
          border-radius: 3px;
        }
      }
      // 按钮
      .btn {
        margin: 30px;
        // padding-top: 20px;
        border-top: 1px solid @lineColor;
        .submit {
          width: 116px;
          height: 36px;
          margin-top: 30px;
          margin-left: 50%;
          transform: translate(-50%);
          line-height: 36px;
          text-align: center;
          color: @btnfsColor;
          cursor: pointer;
          border-radius: 7px;
          background: @btnColor;
        }
      }
    }
  }
}

.red {
  color: red !important;
}

.green {
  color: green !important;
}

.org {
  color: orange !important;
}
</style>