import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/home/index.vue"),
      },
      {
        path: "/infoMessage",
        name: "infoMessage",
        component: () => import("../views/new/infoMessage"),
      },
      {
        path: "/designation",
        name: "designation",
        component: () => import("../views/new/designation"),
      },

      {
        path: "/login",
        name: "login",
        component: () => import("../views/new/login"),
      },
      //链接
      {
        path: "/link",
        name: "link",
        component: () => import("../views/new/Link"),
      },
      //绑定手机
      {
        path: "/bindTel",
        name: "bindTel",
        component: () => import("../views/new/BindTel"),
      },
      //绑定邮箱
      {
        path: "/bindEmail",
        name: "bindEmail",
        component: () => import("../views/new/BindEmail"),
      },
      //联系
      {
        path: "/contact",
        name: "contact",
        component: () => import("../views/new/Contact"),
      },
      //反馈
      {
        path: "/feed",
        name: "feed",
        component: () => import("../views/new/feedBack"),
      },
      //帮助中心
      {
        path: "/helpcenter",
        name: "helpcenter",
        component: () => import("../views/new/helpCenter"),
      },
      //法律
      {
        path: "/law",
        name: "law",
        component: () => import("../views/new/Law"),
      },
      {
        path: "/search",
        name: "search",
        component: () => import("../views/new/search"),
      },
      {
        path: "/announcement",
        name: "announcement",
        component: () => import("../views/new/announcement"),
      },
      {
        path: "/aboutBenZ",
        name: "aboutBenZ",
        component: () => import("../views/new/aboutBenZ"),
      },
	  {
	    path: "/about",
	    name: "about",
	    component: () => import("../views/new/about"),
	  },
      {
        path: "/seniority",
        name: "seniority",
        component: () => import("../views/new/seniority"),
      },
      {
        path: "/seniority2",
        name: "seniority2",
        component: () => import("../views/new/seniority2"),
      },
      //个人主页
      {
        path: "/userProfile",
        name: "userProfile",
        component: () => import("../views/new/userProfile"),
      },
      //上传头像
      {
        path: "/avatar",
        name: "avatar",
        component: () => import("../views/new/Avatar"),
      },
      {
        path: "/otherUserProfile",
        name: "otherUserProfile",
        component: () => import("../views/new/otherUserProfile"),
      },
      //信箱
      {
        path: "/myEmail",
        name: "myEmail",
        component: () => import("../views/new/myEmail"),
      },
      {
        path: "/myEmail2",
        name: "myEmail2",
        component: () => import("../views/new/myEmail2"),
      },
      {
        path: "/motif",
        name: "motif",
        component: () => import("../views/new/motif"),
      },
      {
        path: "/zuanti",
        name: "zuanti",
        component: () => import("../views/new/zuanti"),
      },
      {
        path: "/host",
        name: "host",
        component: () => import("../views/host/index"),
      },
      {
        path: "/zuanti2",
        name: "zuanti2",
        component: () => import("../views/new/zuanti2"),
      },
      //最新投稿
      {
        path: "/newContribution",
        name: "newContribution",
        component: () => import("../views/new/newContribution"),
      },
      {
        path: "/upload",
        name: "upload",
        component: () => import("../views/upload/index"),
      },
      // 版权
      {
        path: "copyrightCenter",
        name: "copyrightCenter",
        component: () => import("../views/copyright/copyrightCenter/index.vue"),
      },
      {
        path: "copyrightQuery",
        name: "copyrightQuery",
        component: () => import("../views/copyright/copyrightQuery/index.vue"),
      },
      {
        path: "copyrightProtection",
        name: "copyrightProtection",
        component: () => import("../views/copyright/copyrightProtection/index.vue"),
      },
      // 版权保护说明
      {
        path: 'protectionExplain',
        name: 'protectionExplain',
        component: () => import('../views/copyright/copyrightProtection/protectionExplain/index.vue')
      },
      // 版权保护作品
      {
        path: 'protectionWork',
        name: 'protectionWork',
        component: () => import('../views/copyright/copyrightProtection/protectionWoks/index.vue')
      },
      {
        path: "copyrightEconomy",
        name: "copyrightEconomy",
        component: () => import("../views/copyright/copyrightEconomy/index.vue"),
      },
      // 版权托管说明
      {
        path: "manageExplain",
        name: "manageExplain",
        component: () => import("../views/copyright/copyrightManage/manageExplain.vue"),
      },
      // 版权托管作品
      {
        path: 'manageWork',
        name: 'manageWork',
        component: () => import("../views/copyright/copyrightManage/index.vue")
      },
	  // 版权托管申请
	  {
	    path: 'copyrightEscrow',
	    name: 'copyrightEscrow',
	    component: () => import("../views/copyright/copyrightManage/copyrightEscrow.vue")
	  },
      // 热门关键字
      {
        path: "hotKeyword",
        name: "hotKeyword",
        component: () => import("../views/hotKeyword/index.vue"),
      },
      // 任务
      {
        path: "task",
        name: "task",
        component: () => import("../views/task/index.vue"),
      },
      // 收藏
      {
        path: "collect",
        name: "collect",
        component: () => import("../views/collect/index.vue"),
      },
      // 投稿
      {
        path: 'contribute',
        name: 'contribute',
        component: () => import("../views/material/index.vue")
      },
      // 推荐用户
      {
        path: 'recommend',
        name: 'recommend',
        component: () => import("../views/recommend/index.vue")
      },
      // 关注用户
      {
        path: 'attention',
        name: 'attention',
        component: () => import("../views/attention/index.vue")
      },
      //注册
      {
        path: "/register",
        name: "register",
        component: () => import("../views/new/register")
      },
      //忘记密码
      {
        path: "/forgetPwd",
        name: "forgetPwd",
        component: () => import("../views/new/forgetPwd")
      },
      //用户协议
      {
        path: "/userAgreement",
        name: "userAgreement",
        component: () => import("../views/new/userAgreement")
      },
	  //隐私协议
	  {
	    path: "/privacyAgreement",
	    name: "privacyAgreement",
	    component: () => import("../views/new/privacyAgreement")
	  },
      // 获取公告详情
      {
        path: 'noticeDetails',
        name: 'noticeDetails',
        component: () => import("../views/new/noticeDetails.vue")
      },
      // 作品页面一级（本人）
      {
        path: 'userWorkOne',
        name: 'userWorkOne',
        component: () => import("../views/works/userWork/one/index.vue")
      },
      // 作品页面二级（本人）
      {
        path: 'userWorkTwo',
        name: 'userWorkTwo',
        component: () => import("../views/works/userWork/two/index.vue")
      },
      // 作品页面一级（其它用户）
      {
        path: 'otherUserWorkOne',
        name: 'otherUserWorkOne',
        component: () => import('../views/works/otherUserWork/one/index.vue')
      },
      // 作品页面二级（其它用户）
      {
        path: 'otherUserWorkTwo',
        name: 'otherUserWorkTwo',
        component: () => import('../views/works/otherUserWork/two/index.vue')
      },
      //测试页面
      {
        path: "/test",
        name: "test",
        component: () => import('../views/new/test')
      },
      // 作品详情1
      {
        path: '/workDetail1',
        name: 'workDetail1',
        component: () => import('../views/workDetail/index.vue')
      },
      {
        path: '/workDetail',
        name: 'workDetail',
        component: () => import('../views/new/workDetail')
      },
      //漫画邦
      {
        path:"/cartoon",
        name:"cartoon",
        component: () => import('../views/new/Cartoon')
      },
      //原画邦
      {
        path:"/original",
        name:"original",
        component: () => import('../views/new/Original')
      },
      //实名认证
      {
        path:"/realName",
        name:"realName",
        component: () => import("../views/new/realName")
      },
      // 充值页面
      {
        path:'/money',
        name:'money',
        component:()=>import('../views/new/money.vue')
      },
      
    ],
  },
];

//解决路由原地跳转报错
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;
