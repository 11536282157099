<template>
	<div class="wrapper">
		<!-- 侧边栏 -->
		<common-left1 v-if="bool"></common-left1>
		<common-left2 v-else :userid="userid"></common-left2>
		<!-- 内容 -->
		<div class="centerContent">
			<div class="title" v-if="Attention">关注用户列表</div>
			<div class="title" v-else>粉丝用户列表</div>
			<div class="userBox scrollBar">
				<div v-if="Attention" class="userInfo" @click="otherUser(item.uid)" v-for="(item, index) in Attention" :key="index">
					<img class="avatar" :src="item.file_path" alt="" />
					<span class="name">{{ item.nickname }}</span>
				</div>
				<div v-if="Fans" class="userInfo" @click="otherUser(item.uid)" v-for="(item, index) in Fans" :key="index">
					<img class="avatar" :src="item.file_path" alt="" />
					<span class="name">{{ item.nickname }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import commonLeft1 from "../../components/common_left1.vue";
	import commonLeft2 from "../../components/components_left2.vue";
	export default {
		name: "attention",
		components: {
			commonLeft1,
			commonLeft2
		},
		data() {
			return {
				Attention: null, // 关注列表
				Fans: null, // 粉丝列表
				bool: false, //
				userInfo:{},//
				userid:"",
				isfans:false,
			};
		},
		created() {
			this.isfans = Boolean(this.$route.query?.fans);
			if(this.isfans){
				this.getUserFans();//粉丝列表
			}else if(!this.isfans){
				this.getMyAttention();//关注列表
			}
			this.userid = this.$route.query.uid;
			this.userInfo =JSON.parse(localStorage.getItem("userInfo")) ;
			console.log(this.userid,this.userInfo.id);
			if (this.userid == this.userInfo.id) {
				this.bool = true;
			}
		},
		methods: {
			//获取粉丝
			async getUserFans() {
				let uid = this.$route.query.uid
				console.log(uid);
				let res = await this.$netWork.getUserFans({
					method: "post",
					url: "/user/getFansListById",
					data: {
						uid: uid
					}
				})
				console.log(res, "888");
				if (res.code == 0) {
					this.Fans = res.data
				}
			},
			//获取路由参数
			getquery() {
				this.userid = this.$route.query.uid;
				console.log(this.userid, "userid");
			},
			//去关注用户的主页
			otherUser(uid) {
				this.$router.push({
					name: "otherUserProfile",
					query: {
						uid: uid
					},
				});
			},
			//关注用户
			async getMyAttention() {
				let uid = this.$route.query.uid
				console.log(uid);
				if (uid) {
					let res = await this.$netWork.getAttentionByID({
						method: "post",
						url: "Attention/getAttentionByID",
						data: {
							uid: uid
						}
					})
					if (res.code == 0) {
						this.Attention = res.data
						console.log('--关注用户--',this.Attention);
					}
				} else {

					const userInfo = JSON.parse(localStorage.getItem("userInfo"));
					const res = await this.$netWork.getMyAttention({
						method: "post",
						url: "/Attention/getMyAttention",
						data: {
							token: localStorage.getItem("token"),
						},
					});
					let datalist = res.data.filter(item => item.uid != userInfo.id);
					this.Attention = datalist;
					console.log("关注用户attention", datalist);
				}
			},
			//处理图片url
			getUrl(url) {
				return "http://api.aboom.net" + url;
			},
		},
	};
</script>

<style lang="less" scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.scrollBar {
		overflow-y: auto;
	}

	/*滚动条整体样式*/
	.scrollBar::-webkit-scrollbar {
		width: 18px;
		height: 10px;
		background: @bgColor;
	}

	/*滚动条滑块*/
	.scrollBar::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: @btnColor;
	}

	/*滚动条轨道*/
	.scrollBar::-webkit-scrollbar-track {
		border: 1px solid @btnColor;
		border-radius: 4px;
	}

	@fsColor: #5dbbbf;
	@bgColor: #28262e;
	@btnColor: #5accd8;
	@btnfsColor: #0c424f;
	@iptfsColor: #ffffff;
	@bg1Color: #35363f;

	.wrapper {
		display: flex;
		justify-content: space-between;
		margin: 14.77px auto;

		.centerContent {
			margin-left: 14.77px;
			height: 736px;
			border-radius: 7px;
			background: @bg1Color;
			flex:1;
			margin-left: 20px;
			.title {
				height: 38px;
				margin: 8px 8px 0px 8px;
				padding-left: 5px;
				line-height: 38px;
				color: @fsColor;
				border-top-left-radius: 7px;
				border-top-right-radius: 7px;
				background: @bgColor;
			}

			.userBox {
				height: 670px;
				margin: 28px 20px 30px 30px;

				.userInfo {
					display: inline-block;
					width: 300px;
					margin-bottom: 40px;

					.avatar {
						width: 60px;
						height: 60px;
						vertical-align: middle;
						border-radius: 10px;
					}

					.name {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin: 0 20px;
						color: @fsColor;
					}
				}
			}
		}
	}
</style>
