<template>
  <div class="main_left">
    <div class="main_left_1">
      <!-- 头像和呢称 v-if="isLogin"-->
      <div class="main_left_1_photo">
        <!-- 其他用户头像 -->
        <img v-if="userIcon" :src="userIcon" alt="" />
        <img v-else src="../assets/imgs/icontouxiang2.png" alt="" />
        <div class="level">
          <div class="main_left_1_photo_level">LV1</div>
          <div class="triangle"></div>
        </div>
      </div>
      <div class="prompt">
        {{ userInfo.nickname }}
      </div>
      <!-- 收藏点赞 -->
      <div class="main_left_1_info">
        <div @click="toOtherUserProfile" style="cursor: pointer;">
          <img src="~@/assets/img/home/icon.png" alt="" title="作品" />
          <span>{{ userInfo ? userInfo.works : 0 }}</span>
        </div>
        <div @click="toAttention" style="cursor: pointer;">
          <img src="~@/assets/img/home/fensi.png" alt="" title="粉丝" />
          <span>{{ userInfo ? userInfo.fans : 0 }}</span>
          <!-- <span>{{ length ? length : 0 }}</span> -->
        </div>
        <div @click="collect" style="cursor: pointer;">
          <img src="~@/assets/img/home/guanzhu.png" alt="" title="收藏" />
          <span>{{ userInfo ? userInfo.collection : 0 }}</span>
        </div>
      </div>
    </div>
    <!-- 关注 私信 -->
    <div class="main_left_2">
      <div class="link" @click="attention">
        <img src="~@/assets/img/home/fensi.png" alt="" />
        <span>&nbsp;{{ isFloow ? '已关注' : '关注' }}</span>
      </div>
      <div class="link" @click="myEmail2">
        <img src="../assets/img/home/xinxiang.png" alt="" width="100%" />
        <span>&nbsp;发送私信</span>
      </div>
    </div>
    <!-- 标签 -->
    <div class="main_left_3 text">
      <div class="main_left_3_gz">
        <div class="main_left_3_gz_user">
          <img src="../assets/icon/zhuangtai.png" />
          <span>&nbsp;标签</span>
        </div>
      </div>

      <div class="bqlist">
        <span v-for="(item, index) in tags" :key="index" @click="changeSearch(item.tag_name)">{{ item.tag_name }}({{
          item.id
        }})</span>
      </div>
      <div class="main_left_1_more">
        <span @click="toInfoMessage">更多</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonLeft2",
  props: {
    userid: {},
  },
  data() {
    return {
      isFloow: false,
      userIcon: "", // 用户头像
      files: null, //用户头像文件
      isLogin: false, // 是否登录
      userInfo: {}, // 用户信息
      Attention: {}, // 关注列表
      tags: [], //标签列表
      uid: "",
      length: '',
    };
  },
  components: {},
  created() {
    // console.log(this.userid);
    this.uid = window.location.hash;
    let parameter = this.uid.split("?");
    parameter = parameter[1].split("&");
    for (let index = 0; index < parameter.length; index++) {
      if (parameter[index].indexOf("uid") >= 0) {
        let parameter1 = parameter[index].split("=");
        this.uid = parameter1[1];
      }
    }
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log('---用户信息---', userInfo);
    if (token) {
      this.isLogin = true;
      this.userInfo = userInfo;
    }
    let uid = this.$route.query.uid ? this.$route.query.uid : this.uid;
    if (uid == userInfo?.id) {
      this.getMyAttention();
    } else {
      this.getAttentionByID()
    }
    this.getTag();
    this.qtuser();
    // console.log(userInfo, "hello");
  },
  activated() {

    // const token = localStorage.getItem("token");
    // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // if (token) {
    //   this.isLogin = true;
    //   this.userInfo = userInfo;
    // }
  },

  methods: {
    //收藏
    collect() {
      console.log(this.$route.query.uid);
      if (this.$isToken("收藏"))
        this.$router.push({
          path: "/collect",
          query: { uid: this.$route.query.uid }
        });
    },
    toAttention() {
      this.$router.push({
        path: "/attention",
        query: { uid: this.$route.query.uid, fans: true }
      });
    },
    toOtherUserProfile() {
      this.$router.push({
        name: "otherUserProfile",
        query: {
          uid: this.$route.query.uid
        }
      })
    },
    //拼接url
    geturl(url) {
      return `http://api.aboom.net/${url}`;
    },
    //获取标签列表
    async getTag() {
      let res = await this.$netWork.getTag({
        method: "post",
        url: "/Activity/getTag",
      });
      console.log(res.data);
      let _this = this;
      let num = 0;
      for (let index = 0; index < res.data.length; index++) {
        if (res.data[index].uid == this.uid) {
          _this.tags[num] = res.data[index];
          num++;
        }
        // console.log(this.tags,"标签列表")
      }
      // this.tags = res.data[index]
      this.tags = this.tags.slice(0, 21);
      console.log(this.tags);
    },
    cj() {
      this.$router.push({
        path: "./designation",
      });
    },
    // photo() {
    //   if (this.$route.path == "/userProfile") {
    //     return false;
    //   }
    //   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //   if (this.userid != userInfo.id) {
    //     this.$router.push({
    //       name: "otherUserProfile",
    //       query: {
    //         uid: this.userid,
    //       },
    //     });
    //   } else {
    //     this.$router.push({
    //       path: "./userProfile",
    //     });
    //   }
    // },
    login() {
      if (this.isLogin) {
        return false;
      }
      this.$router.push("/login");
    },
    async getAttentionByID() {//其他用户
      let uid = this.$route.query.uid;
      let res = await this.$netWork.getAttentionByID({
        url: "/Attention/getAttentionByID",
        method: "post",
        data: {
          uid: uid
        }
      });
      console.log(uid);
      console.log(res);
      this.achievementList = res.data
      this.length = res.data.length
    },
    //自己的关注用户
    async getMyAttention() {
      const { data } = await this.$netWork.getMyAttention({
        method: "post",
        url: "/Attention/getMyAttention",
        data: {
          token: localStorage.getItem("token"),
        },
      });
      this.Attention = data;
      this.length = data.length
      console.log('关注列表', data);
    },
    //关注  等待开发其他用户数据接口
    async attention() {
      if (!this.$isToken("关注")) {
        return
      }
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (this.userid == userInfo?.id) {
        this.$message.warning("不能关注自己");
      } else {
        let res = await this.$netWork.attention({
          method: "post",
          url: "/Attention/attention",
          data: {
            token: localStorage.getItem("token"),
            attention_id: this.userid,
          },
        });
        if (res.code === 0) {
          this.$message.success(res.msg);
        } else if (res.code === 1) {
          this.$message.warning(res.msg);
        }
        console.log(res, "关注");
      }
    },
    //其他用户信息
    async qtuser() {
      let res;
      this.userInfo = {};
      if (this.userInfo?.id != this.userid) {
        //其他用户
        res = await this.$netWork.attention({
          method: "post",
          url: "/user/getUserInfoById",
          data: {
            uid:
              this.userid ||
              this.$route.query.uid ||
              this.$route.query.userid ||
              (this.$route.query.friend_id != this.userInfo?.id
                ? this.$route.query.friend_id
                : "") ||
              (this.$route.query.send_user_id != this.userInfo?.id
                ? this.$route.query.send_user_id
                : ""),
          },
        });
        if (res.code == 0) {
          this.userInfo = res.data;
          this.userIcon = "http://api.aboom.net" + res.data.avatar;
          console.log("其他用户信息:", res.data)
          let nmbd = await this.$netWork.getMyAttention({
            method: "post",
            url: "/Attention/getMyAttention",
            data: {
              token: localStorage.getItem("token"),
            },
          });
          let arr = nmbd.data.filter(v => v.uid == res.data.id);
          if (arr.length == 0) {
            this.isFloow = false;
          } else {
            this.isFloow = true;
          }
          // 只有在别人主页才会跳动
          if (this.$route.path == '/otherUserProfile') {
            let imgDoc = document.querySelector('.main_left_1_photo img');
            imgDoc.style = "position: absolute;left:0;right:0;top:0px;transition:all 0.5s linear "
            setTimeout(() => {
              imgDoc.style.top = '-10px'
            }, 100);
            setTimeout(() => {
              imgDoc.style.top = '0px'
            }, 200);
          }
        }
        if (res.code == 1) {
          this.$message.warning("此用户已不存在！");
          this.userInfo = "";
          this.userIcon = "";
        }
      } else {
        //自己
        res = await Promise.resolve("自己");
        this.userIcon = "http://api.aboom.net" + this.userInfo.avatar_path;
        console.log(this.userInfo, "自己");
      }
    },
    //私信
    myEmail2() {
      if (this.$isToken("私信"))
        this.$router.push({
          name: "myEmail2",
          query: {
            uid: this.userid,
          },
        });
    },
    // //收藏
    // collect() {
    // 	if (!localStorage.getItem("token")) {
    // 		this.$message.warning("请先登录");
    // 		return false;
    // 	}
    // 	this.$router.push({
    // 		path: "/collect"
    // 	});
    // },
    //去推荐用户页面
    toRecommend() {
      this.$router.push({
        path: "/recommend",
      });
    },
    //去个人信息页面
    toInfoMessage() {
      // if (this.$route.path == "/infoMessage") {
      // 	return false;
      // }
      if (window.location.href.split("?")[1].split("&")[1]) {
        console.log("是");
        this.$router.push({
          path: "/hotKeyWord",
          query: {
            msg: "更多",
            uid: window.location.href.split("?")[1].split("&")[1].split("=")[1],
          },
        });
      } else {
        this.$router.push({
          path: "/hotKeyWord",
          query: {
            msg: "更多",
            uid: this.$route.query.uid,
          },
        });
      }
    },
    changeSearch(parameter) {
      console.log(parameter, "看看是什么");
      this.$router.push({
        path: "/search",
        query: { search: parameter },
      });
    },
  },
};
</script>

<style scoped lang="less">
.link img {
  width: 18px;
  height: 21px;
}

.prompt {
  color: white;
  font-size: 20px;
  margin-top: 20;
}

//头像上传begin
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

// .avatar-uploader-icon {
// 	font-size: 28px;
// 	color: #8c939d;
// 	width: 178px;
// 	height: 178px;
// 	line-height: 178px;
// 	text-align: center;
// }
.avatar {
  width: 100%;
  height: 100%;
}

//头像上传end
.main_left_1_photo {
  img {
    border-radius: 7px;
  }
}

.main_left_1_photo1 {
  position: relative;
  border-radius: 7px;
  margin-top: 8px;
  width: 173px;
  height: 188px;
  background-color: rgb(50, 52, 51);
}

.main_left_1_info {
  width: 100%;
  margin-top: 14.77px;
  display: flex;
  height: 52px;

  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #4eb8c2;
    font-size: 12px;

    img {
      width: 15.69px;
      height: 17px;
    }
  }

  .text {
    text-align: left !important;
  }

  div:not(:last-child) {
    border-right: 1px solid rgb(67, 80, 87);
  }
}

.main_left_1_cj {
  width: 100%;
  padding: 0 8px;
  margin-top: 10px;
  box-sizing: border-box;

  .main_left_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    color: #4eb8c2;
    background: rgb(38, 39, 44);

    .main_left_1_cj_text {
      display: flex;
      align-items: center;
      height: 26px;
      color: #4eb8c2;
      font-size: 12px;
      line-height: 24px;
      box-sizing: border-box;
      background-color: rgb(38, 39, 44);

      img {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 16px;
      }
    }
  }

  .main_left_1_cj_icon {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;

    div {
      min-width: 20px;
      height: 22px;
      margin-left: 5px;
      background: url("~@/assets/imgs/icon.png") no-repeat center/contain;
    }
  }
}

.main_left_1_more {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0 8px 8px 0;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 14px;

  span {
    color: #4eb8c2;
    cursor: pointer;
  }
}

.main_left_2 {
  width: 188.31px;
  min-height: 96px;
  margin-top: 14.77px;
  border-radius: 7px;
  background: #35353f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #4eb8c2;

  :nth-child(1) {
    &:hover {
      background: #26272c;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }
  }

  :nth-child(2) {
    &:hover {
      background: #26272c;
    }
  }

  :nth-child(3) {
    &:hover {
      background: #26272c;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }

  &>div {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    // padding-left: 50px;
    justify-content: center;
    box-sizing: border-box;

    i {
      width: 18px;
      height: 22px;
    }
  }

  >div:not(:last-child) {
    border-bottom: 1px solid #46b3bd;
  }
}

.bqlist {
  flex-wrap: wrap;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0 8px;
  align-items: flex-end;
  font-size: 14px;
  cursor: pointer;

  span {
    color: #4eb8c2;
    padding: 2px 3px;
    font-size: 14px;
  }
}

.main_left_3,
.main_left_4 {
  width: 175px;
  min-height: 50px;
  border-radius: 7px;
  margin-top: 14.77px;
  padding: 7px;
  background: #35353f;

  .main_left_3_gz {
    display: flex;
    flex: 1;
    align-items: center;
    height: 25px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-color: rgb(38, 39, 44);
    font-size: 14px;
    justify-content: space-between;
    padding: 0 5px;
    color: #4eb8c2;

    &>.main_left_3_gz_user {
      display: flex;
      align-items: center;

      i {
        min-width: 16.62px;
        height: 17px;
        background: #d8d8d8;
      }

      img {
        width: 14px;
        height: 14px;
      }
    }
  }

  .main_left_3_icon {
    margin-top: 7px;
    display: flex;
    flex-wrap: wrap;

    .main_left_3_icon_item {
      min-width: 40.62px;
      height: 44px;
      background: #d8d8d8;
      margin: 3px 0 0 3px;

    }
  }
}

.main_left_5 {
  margin-top: 14.77px;
  width: 188.31px;
  height: 381px;
  border-radius: 7px;
  background: url("~@/assets/imgs/guanggao.png") no-repeat center/cover;
}

.level {
  position: absolute;
  top: 0;
  right: 0;
  width: 75px;
  height: 26px;
  overflow: hidden;

  .main_left_1_photo_level {
    position: absolute;
    right: 0;
    top: 0;
    width: 29px;
    height: 26px;
    line-height: 26px;
    padding-right: 5px;
    font-weight: bold;
    background-color: #494c4c;
    color: #4eb8c2;
    border-top-right-radius: 7px;
    opacity: 0.9;
    z-index: 999;
  }

  .triangle {
    position: absolute;
    top: -26px;
    right: 34px;
    // width: 0;
    // height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid #494c4c;
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    opacity: 0.9;
    z-index: 9;
  }
}

// @media screen and (max-width: 1700px) {
.main_left {
  width: 189px;
  border-radius: 7px;

  .main_left_1 {
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 265px;
    background-color: rgb(55, 52, 67);

    .main_left_1_photo {
      position: relative;
      border-radius: 7px;
      margin-top: 8px;
      width: 173px;
      height: 188px;
      background: no-repeat center/contain;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .main_left_1_nickname {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      text-align: center;
      font-size: 22px;
      color: white;

      div {
        overflow: hidden;
        max-width: 133px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

// }

// @media screen and (min-width: 1700px) {
//   .main_left {
//     width: 189px;
//     border-radius: 7px;
//     .main_left_1 {
//       border-radius: 7px;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       width: 100%;
//       min-height: 428px;
//       background-color: rgb(55, 52, 67);
//       .main_left_1_photo {
//         position: relative;
//         border-radius: 7px;
//         margin-top: 8px;
//         width: 173px;
//         height: 188px;
//         background: no-repeat center/contain;
//         img {
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//           object-position: center;
//         }
//       }
//       .main_left_1_nickname {
//         cursor: pointer;
//         display: flex;
//         flex-direction: column;
//         padding-top: 10px;
//         text-align: center;
//         font-size: 22px;
//         color: white;
//         div {
//           overflow: hidden;
//           max-width: 133px;
//           text-overflow: ellipsis;
//           white-space: nowrap;
//         }
//       }
//     }
//   }
// }

.progressBar img {
  float: left;
  width: 20px;
  padding-right: 10px;
}

.progressBar2 img {
  float: left;
  width: 16px;
  padding-right: 13px;
}

/deep/.el-progress-bar__outer {
  background-color: #26272c;
}

/deep/.el-progress-bar__outer {
  border-radius: 2px;
}

/deep/.el-progress-bar__inner {
  border-radius: 2px;
}

/deep/.el-progress--without-text .el-progress-bar {
  margin: 15px 0;
  padding-top: 2px;
}
</style>
